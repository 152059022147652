<section class="main-content dashboard mt-2" *ngIf="!showMobileSocialAspects">
    <div class="section-body">
        <div class="row mx-md-n2">
            <div class="col-lg-8 col-md-6 col-12 px-md-2">
                <div class="card border-apply mb-3 text-center" *ngIf="ecardLoading || fetchingSocialAspects">
                    <div class="card-header table-card pt-4">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                </div>
                <div class="card border-apply details mb-3 mt-2" *ngIf="!ecardLoading && !fetchingSocialAspects">
                    <div class="card-header table-card reason-customise pt-5">
                        <div *ngIf="ecardDeleted" class="text-center deleted-ecard-container">
                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/ecard-deleted.png" alt="Ecard not found!">
                            <p class="font-16 font-weight-normal">The ecard you're looking for has either been deleted or does not exist.</p>
                            <a type="button" [routerLink]="['/hr/dashboard']"
                                class="btn btn-secondary d-lg-inline-block d-md-inline-block btn-nominate mr-md-2 mb-3" tabindex="0"> Go back to
                                dashboard </a>
                        </div>
                        <div *ngIf="!ecardDeleted" class="card cta-card mb-1 order-4 order-sm-3">
                            <div class="card-body p-0 dashboard-ecard-tooltip">
                            <div class="recognition-meta">
                                <div class="row">
                                <div class="col-6 text-left">
                                    <label class="font-16 font-weight-bold mb-0">{{ 'To' | translate }}:</label>
                                    <p class="font-16 font-weight-normal text-muted mb-0">{{ ecardDetail?.receiver?.firstName || ecardDetail?.receiver?.email }}</p>
                                </div>
                                <div class="col-6 text-right">
                                    <label class="font-16 font-weight-bold mb-0">{{ 'From' | translate }}:</label>
                                    <p class="font-16 font-weight-normal text-muted mb-0">{{ ecardDetail?.from?.firstName || ecardDetail?.from?.email }}</p>
                                    <p class="text-muted font-weight-normal">{{ userService.dateAgo(ecardDetail?.createdAt) }}</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="card dashboard-card-content mb-1">
                            <!-- ...see more should appear if the text message is more than 4 line count in desktop and 3 lines count in mobile.
                                here do not use ngIf because the truncation will not work as expected as the message will be completely hidden and truncatio
                            happen in wrong place -->
                            <p #content class="font-weight-normal font-16"
                                [ngClass]="{'show-full': ecardDetail?.isFullText, 'd-none': ecardDetail?.messageType && ecardDetail?.messageType.toLowerCase() === 'private'}">
                                <span [ngClass]="{'ecard-message': ecardDetail?.shouldShowSeeMore}">{{ ecardDetail?.message }}</span>
                                <a *ngIf="ecardDetail?.shouldShowSeeMore" href="javascript:void(0)" class="see-more text-primary"
                                (click)="showFullText()">...{{ 'see more' | translate }}</a>
                                <a *ngIf="!ecardDetail?.shouldShowSeeMore && ecardDetail?.isFullText" href="javascript:void(0)"
                                class="text-primary" (click)="showLessText()">...{{ 'see less' | translate }}</a>
                            </p>
                            <div class="badge-container">
                                <img [src]="ecardDetail?.templateUrl" alt="Image"
                                style="width: 100%; object-fit: contain;border-radius: 40px;">
                                <div class="reason-badge" *ngIf="ecardDetail?.reasonId">
                                <p class="text-white m-0"><b>{{ 'Reason' | translate }}:</b> {{ ecardDetail?.reasonId }}</p>
                                </div>
                            </div>

                            <!-- social aspects component: start -->
                            <app-social-aspects 
                            *ngIf="ecardDetail?.social"
                            [showInputBox]="showDesktopCommentsByDefault"
                            [ecardId]="ecardDetail?.id"
                            [socialAspectData]="ecardDetail?.social"
                            [commentIdFromUrl]="commentIdFromUrl"
                            (displaySocialAspectMobileView)="displayMobileSocialAspects($event)"></app-social-aspects>
                            <!-- social aspects component: end -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- when the mobile view is enabled, the design should show only the mobile design and 
  hide the dashboard and footer sections. This was not possible from social-aspects component 
  as the mobile design would display within the component. So in order to hide eveything others and display
just the mobile view of social aspects when the Comments is clicked, this is done -->
<!-- mobile view should be enabled if the width of the device is less than or equal to 767px: start -->
<ng-container #lazyLoadMobileSocialAspectContainer>
<!-- lazy loaded from .ts file so not using template loading -->
</ng-container>
<!-- mobile view should be enabled if the width of the device is less than or equal to 767px: end -->