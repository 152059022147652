import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../core/service/dashboard.service';
import { eapDetails, linkTargetViaGoNative, matchingPartner } from '../core/_helpers/global-functions';
import { User } from '../core/models/user';
import { AuthService } from '../core/service/auth.service';

@Component({
  selector: 'app-eap',
  templateUrl: './eap.component.html',
  styleUrls: ['./eap.component.sass']
})
export class EAPComponent implements OnInit {
  eapCredentials: any = null;
  linkTarget: string = linkTargetViaGoNative();
  imperialClient: boolean = false;
  eapDetails: any = eapDetails();
  partnerId: number = null;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(
      (userDetail: User) => {
        if(userDetail) {
          this.imperialClient = matchingPartner(userDetail?.partnerId);
          this.partnerId = userDetail?.partnerId;
        }
      }
    )
    // get EAP detail of the client
    this.dashboardService.getClientEAPDtail().subscribe(
      response => {
        this.eapCredentials = response;
      }
    )
  }

}
