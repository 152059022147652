import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-magic-link',
  templateUrl: './magic-link.component.html',
  styleUrls: ['./magic-link.component.scss']
})
export class MagicLinkComponent implements OnInit {
  magicLinkForm: FormGroup;
  magicLinkFormSubmitting: boolean = false;
  showCheckEmail: boolean = false;
  userEmail: string = '';
  errorMessage: string = '';
  currentYear: number = null;
  constructor(private authService: AuthService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.magicLinkForm = new FormGroup({
      email: new FormControl("", [Validators.required]),

    });
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("error")){
        this.errorMessage = params.error;
      }
    });

    this.getCurrentYear();
  }

  getCurrentYear() {
    this.currentYear = (new Date()).getFullYear();
  }

  backButtonEvent(event){
    this.showCheckEmail=event;
  }
  magicLinkFormSubmit(){
    this.magicLinkFormSubmitting = true;

    console.log('submitting')

    this.userEmail = this.magicLinkForm.get('email').value;
    this.authService.passwordLessSendEmail(this.magicLinkForm.get('email').value).subscribe((res)=> {
      this.magicLinkFormSubmitting = false;
      this.showCheckEmail = true;
    },
    (error)=>{
      this.errorMessage = error;
      this.magicLinkFormSubmitting = false;
    })

  }

}
