import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AccessIfSetupCompletedGuard } from './core/guard/access-if-setup-completed.guard';
import { Page404Component } from './authentication/page404/page404.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NoAccessComponent } from './authentication/no-access/no-access.component';
import { CanAccessAppGuard } from './core/guard/can-access-app.guard';
import { CheckRedirectionComponent } from './authentication/check-redirection/check-redirection.component';
import { GdprComponent } from './authentication/gdpr/gdpr.component';
import { CheckGdprGuard } from './core/guard/check-gdpr.guard';
import { SsoVerificationComponent } from './authentication/sso-verification/sso-verification.component';
import {AccessWithRolesGuard} from "./core/guard/access-with-roles.guard";
import { LoginComponent } from './authentication/login/login.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { MagicLinkComponent } from './authentication/magic-link/magic-link.component';
import { CheckEmailComponent } from './authentication/check-email/check-email.component';
import { LoginGuard } from './core/guard/login.guard';
import {PasswordGuard} from "./core/guard/password.guard";
import { PasswordlessLoginVerifyComponent } from './authentication/passwordless-login-verify/passwordless-login-verify.component';
import { CheckSsoSigninComponent } from './authentication/check-sso-signin/check-sso-signin.component';
import { LoginHandlerComponent } from './authentication/login-handler/login-handler.component';
import {APIdocumentationEnableGuard} from "./core/guard/apidocumentation-enable.guard";
import {ShopOnlyGuard} from "./core/guard/shop-only.guard";
import {FaceIdComponent} from "./authentication/face-id/face-id.component";
import {EAPComponent} from "./eap/eap.component";
import { EapGuard } from "./core/guard/eap.guard";
import {GpOnDemandGuard} from "./core/guard/gp-on-demand.guard";
import { GpOnDemandComponent } from './gp-on-demand/gp-on-demand.component';
import { SocialAspectDetailComponent } from './dashboard/social-aspect-detail/social-aspect-detail.component';
import { BenefitsComponent } from "./benefits/benefits.component";
import { BenefitsExpandedComponent } from "./benefits/benefits-expanded/benefits-expanded.component";
import { BenefitsFormComponent } from "./benefits/benefits-form/benefits-form.component";
import { EarnedWageAccessEmployeeComponent } from "./earned-wage-access-employee/earned-wage-access-employee.component";
import { EwaGuard } from "./core/guard/ewa.guard";
import { MicrosoftCallbackComponent } from './authentication/microsoft-callback/microsoft-callback.component';
import { SpecsaversEmployeeFormComponent } from './specsevers-employee/specsavers-employee-form/specsavers-employee-form.component';
import { SpecsaversEmployeeFormSummaryComponent } from './specsevers-employee/specsavers-employee-form-summary/specsavers-employee-form-summary.component';
import { SpecsaversEmployeeSuccessComponent } from './specsevers-employee/specsavers-employee-success/specsavers-employee-success.component';
import { SpecseversEmployeeComponent } from './specsevers-employee/specsevers-employee.component';
import {AccessIfEmployeeNotSubmittedEyecareForm} from './core/guard/eyecare-guard/access-if-eyecare-employee-form-not-submitted'
import {AccessIfEmployeeSubmittedEyecareForm} from './core/guard/eyecare-guard/access-if-eyecare-employee-form-submitted'

import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { BenefitsReminderComponent } from "./benefits-reminder/benefits-reminder.component";
import { AccessEachpersonSuperadminGuard } from "./core/guard/access-eachperson-superadmin.guard";



const routes: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "sso", component: SsoVerificationComponent },
      { path: "microsoft/callback", component: MicrosoftCallbackComponent },
      { path: "face-id", component: FaceIdComponent }, // when shop depends on eachperson for login and redirection
      { path: "login-handler", component: LoginHandlerComponent },
      { path: "seamless-redirection", component: CheckRedirectionComponent }, // used for seamless login from shop to eachperson
      { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
      { path: "logout", component: LogoutComponent, canActivate: [LoginGuard] },
      { path: "check-sso-signin", component: CheckSsoSigninComponent },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [PasswordGuard],
      },
      {
        path: "reset-password",
        component: ChangePasswordComponent,
        canActivate: [PasswordGuard],
      },
      // { path: 'magic-link', component: MagicLinkComponent,  canActivate: [LoginGuard] },
      // {path:'passwordless-login-verify/:email/:authenticationCode', component:PasswordlessLoginVerifyComponent, canActivate:[LoginGuard]},
      { path: "404", component: Page404Component },
      { path: "no-access", component: NoAccessComponent },
      { path: "join/gdpr", component: GdprComponent },
    ],
  },
  {
    path: "",
    component: MainLayoutComponent,
    // difference between canActivate and canActivateChild - https://www.linkedin.com/pulse/short-notes-angular-routing-guards-ramanathan-chockalingam#:~:text=or%20default%20route.-,CanActivateChild,before%20instantiating%20the%20child%20component.
    canActivateChild: [
      AuthGuard,
      CanAccessAppGuard,
      CheckGdprGuard,
      ShopOnlyGuard,
    ], // canActivateChild works for parent are all its children routes
    children: [
      { path: "", redirectTo: "hr/dashboard", pathMatch: "full" },
      { path: "eap", component: EAPComponent, canActivate: [EapGuard] },
      {
        path: "earned-wage-access",
        component: EarnedWageAccessEmployeeComponent,
        canActivate: [EwaGuard],
      },
      {
        path: "gp-on-demand",
        component: GpOnDemandComponent,
        canActivate: [GpOnDemandGuard],
      },
      {
        path: "hr/dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [AccessIfSetupCompletedGuard],
      },
      {
        path: "benefits",
        component: BenefitsComponent,
      },
      {
        path: "benefits/new",
        component: BenefitsFormComponent,
        canActivate: [AccessWithRolesGuard],
        data: {
          roles: ["superAdmin"],
        },
      },
      {
        path: "benefits-reminder",
        component: BenefitsReminderComponent,
        canActivate: [AccessEachpersonSuperadminGuard],
      },
      {
        path: "eyecare-employee-form",
        component: SpecsaversEmployeeFormComponent,
        canActivate: [AccessIfEmployeeNotSubmittedEyecareForm],
      },
      {
        path: "eyecare-employee-form-summary",
        component: SpecsaversEmployeeFormSummaryComponent,
        canActivate: [AccessIfEmployeeNotSubmittedEyecareForm],
      },
      {
        path: "eyecare",
        component: SpecseversEmployeeComponent,
        canActivate: [AccessIfEmployeeNotSubmittedEyecareForm],
      },
      {
        path: "eyecare-employee-success",
        component: SpecsaversEmployeeSuccessComponent,
        canActivate: [AccessIfEmployeeSubmittedEyecareForm],
      },
      {
        path: "benefits/edit/:id",
        component: BenefitsFormComponent,
        canActivate: [AccessWithRolesGuard],
        data: {
          roles: ["superAdmin"],
        },
      },
      {
        path: "benefits/:id",
        component: BenefitsExpandedComponent,
      },
      {
        path: "social-aspect-detail",
        component: SocialAspectDetailComponent,
        canActivate: [AccessIfSetupCompletedGuard],
      },
      // lazy loading setup module. load only when needed. That is, loaded when set-up is accessed
      {
        path: "set-up",
        loadChildren: () =>
          import("./setup/setup.module").then((m) => m.SetupModule),
      },
      {
        path: "hr",
        loadChildren: () => import("./hr/hr.module").then((m) => m.HrModule),
      },
      {
        path: "reporting",
        loadChildren: () =>
          import("./reporting/reporting.module").then((m) => m.ReportingModule),
      },
      { path: "contact-us", component: ContactUsComponent },
      // reporting api doc not used for now - 21/12/2022
      {
        path: "reporting-documentation",
        loadChildren: () =>
          import("./api-documentation/api-documentation.module").then(
            (m) => m.ApiDocumentationModule
          ),
        canActivate: [
          AccessIfSetupCompletedGuard,
          AccessWithRolesGuard,
          APIdocumentationEnableGuard,
        ],
        data: {
          roles: ["superAdmin"],
        },
      },
      {
        path: "salary-sacrifice",
        loadChildren: () =>
          import("./salary-sacrifice/salary-sacrifice.module").then(
            (m) => m.SalarySacrificeModule
          ),
      },
    ],
  },
  { path: "**", redirectTo: "404" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top' // scroll page position to top on each navigation change
    }
  )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
