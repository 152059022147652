import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';


import { AuthService } from '../service/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private authService: AuthService
  ) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let currentUser = this.authService.getLoginSession();
    if (currentUser.token && this.authService.currentUserValue) {
      return true;
    }
    else {
      // failed
      // if not logged in - but check if there is viaSso flag in localStorage
      this.authService.handleCognitoLoginRedirection();
      return false;
    }
  }
}
