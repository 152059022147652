
<div class="header-wrapper">
  <div class="header-container">
    <app-header></app-header>
  </div>
</div>

<div class="body-wrapper">
  <div class="body-container">
    <app-sidebar></app-sidebar>
    <!-- <app-right-sidebar></app-right-sidebar> -->
    <router-outlet></router-outlet>
  </div>
</div>

<!-- GDPR Signup started -->
<app-gdpr-mailing-signup class="iat-gdpr-signup"></app-gdpr-mailing-signup>
<!-- GDPR Signup end -->

<div class="footer-wrapper">
  <div class="footer-container">
    <app-footer></app-footer>
  </div>
</div>
<!-- contact us chat widget -->
<app-contact-us-chat-widget></app-contact-us-chat-widget>