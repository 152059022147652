<section class="main-content dashboard">
    <div class="section-body">
        <div class="bg-white eap-customise gp-customise mb-4">
            <div class="row">
                <div class="col-lg-5 col-12 px-md-2 mobile-order-2">
                    <div class="eapHero-content gpHero-content">
                        <h2 [innerHTML]="'<span>Discover</span> GP On Demand' | translate"></h2>
                        <p>{{ 'GP On Demand offers limitless, fully remote access to a GP for both you and your family 24/7. Receive a call within 15 mins of the appointment time.' | translate }}</p>
                    </div>
                    <div class="gp-book-cta">
                        <p class="font-16 font-weight-bold mb-0 text-dark">{{ 'Call to book an appointment on:' | translate }} </p>
                        <h2 class="gp-tel font-weight-bold text-left pt-0 mb-3">
                            <a href="tel:0800 206 2576" class="text-dark font-weight-bold">0800 206 2576</a>
                        </h2>
                    </div>
                </div>
                <div class="heroImage mobile-order-1">
                    <img src="../../../assets/images/gp-header.svg">
                </div>
            </div>
        </div>

        <div class="bg-white eap-customise gp-customise-section mb-4">
            <div class="row">
                <div class="col-md-4 col-sm-4 col-4 dashBorder">
                    <div class="text-center gp-cta-grid">
                        <img src="assets/images/gp-call.svg" alt="gp-call" [title]="'Call the GP Number' | translate" />
                        <h3 [innerHTML]="'Call the GP <br> Number' | translate"></h3>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-4 dashBorder">
                    <div class="text-center gp-cta-grid">
                        <img src="assets/images/gp-book-appointment.svg" alt="gp-call" [title]="'Book Appointment' | translate" />
                        <h3 [innerHTML]="'Book <br> Appointment' | translate"></h3>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-4 dashBorder">
                    <div class="text-center gp-cta-grid">
                        <img src="assets/images/gp-doctor-calls.svg" alt="gp-doctor-call" [title]="'Doctor Calls you Back' | translate" />
                        <h3 [innerHTML]="'Doctor Calls <br> you Back' | translate"></h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white eap-sections gp-cover pt-4 mb-4">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-md-6 col-sm-5 gp-image">
                    <div class="gp-desktop-image">
                        <img src="assets/images/gp-family-cover.png">
                    </div>
                    <div class="gp-mobile-image mb-4">
                        <img src="assets/images/family-cover-mobile.svg">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-5">
                    <h3>{{ 'Family Cover' | translate }}</h3>
                    <p [innerHTML]="familyCoverText | translate"></p>
                </div>
            </div>

            <div class="row py-3 pb-4 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6 px-5 cust-px-6 gp-mobile-order-2">
                    <h3>{{ 'Prescription Service' | translate }}</h3>
                    <p>{{ 'Get perscriptions delivered directly to your door. Should you have had a previous prescription raised by another doctor, this will show on the system so the doctor can ensure it is appropriate to raise a second prescription.' | translate }} </p>
                    <p class="font-14 font-weight-bold">{{ 'Additional costs may apply.' | translate }}</p>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-5 gp-mobile-order-1 gp-image">
                    <div class="gp-desktop-image cust-px-6">
                        <img src="assets/images/gp-prescription.svg">
                    </div>
                    <div class="gp-mobile-image">
                        <img src="assets/images/doctor-prescription-mobile.svg">
                    </div>
                </div>
            </div>

            <div class="row py-3 pb-4 justify-content-between align-items-center">
                <div class="col-lg-5 col-md-6 col-sm-5 gp-image">
                    <div class="gp-desktop-image">
                        <img src="assets/images/gp-fit-schedule.svg">
                    </div>
                    <div class="gp-mobile-image mb-4">
                        <img src="assets/images/fit-your-schedule-mobile.svg">
                    </div>
                    
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-5">
                    <h3>{{ 'Fit Your Schedule' | translate }}</h3>
                    <p>{{ 'Appointments are conducted via video calls or over the phone. Enjoy 24/7 access to phone consultations. Video consultations are available from 8am to 10pm, seven days a week.' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="bg-white gp-customise-faq eap-customise mb-4">
            <div class="gpOnDemand-faq">
                <div class="text-dark panel-for-tc">
                    <h3>{{ 'FAQ’s' | translate }}</h3>
                    <ngb-accordion #faqAcc="ngbAccordion">
                        <ngb-panel id="faq-1">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button ngbPanelToggle class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"> 
                                    <p class="font-weight-bold m-0">{{ 'How Much Does It Cost?' | translate }}
                                    </p>
                                    <div>
                                        <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                        <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                                    </div>
                                </button>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                {{ 'This service is free. This is a benefit from your company. Please note, any perscriptions neeed will be at your personal expense.' | translate }}
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="faq-2">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button ngbPanelToggle class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"> 
                                    <p class="font-weight-bold m-0">{{ 'What Happens to My Existing GP?' | translate }}
                                    </p>
                                    <div>
                                        <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                        <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                                    </div>
                                </button>
                              </ng-template>
                            <ng-template ngbPanelContent>
                                {{ 'Our service is designed to provide you with additional support whenever you or your family may need that help. Our service absolutely does not require that you change or de-register from your existing GP. You will still be registered with your existing GP and can use their services exactly as you do currently. Our service will simply give you more help.' | translate }}
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="faq-3">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button ngbPanelToggle class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"> 
                                    <p class="font-weight-bold m-0">{{ 'Can I Call in an Emergency?' | translate }}
                                    </p>
                                    <div>
                                        <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                        <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                                    </div>
                                </button>
                              </ng-template>
                            <ng-template ngbPanelContent>
                                {{ 'No. This service is not a replacement for your own NHS GP or the emergency services. It is not designed to be used for urgent medical problems for which you should seek immediate advice from your registered GP, or alternatively call the emergency services.' | translate }} 
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="faq-4">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button ngbPanelToggle class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"> 
                                    <p class="font-weight-bold m-0">{{ 'Data and Confidentiallity' | translate }}
                                    </p>
                                    <div>
                                        <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                        <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                                    </div>
                                </button>
                              </ng-template>
                            <ng-template ngbPanelContent>
                                {{ 'GP On Demand only require basic personal details from you to provide you with membership and access to the GP On Demand service. GP On Demand is not the provider of the GP / Clinical services. Rather we are providing you as a member, with access to a Professional GP service, provided and operated by a General Medical Council (GMC) authorised GP service, that is assessed by the Care Quality Commission (CQC). That provider operates systems and data controls that are ISO27001 accredited. GP On Demand will therefore never ask you for sensitive personal information or medical or health information. You will only share that information voluntarily with the GP service provider directly.' | translate }}
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="faq-5" *ngIf="viewAll">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button ngbPanelToggle class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"> 
                                    <p class="font-weight-bold m-0">{{ 'What Happens if I Leave My Company?' | translate }}
                                    </p>
                                    <div>
                                        <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                        <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                                    </div>
                                </button>
                              </ng-template>
                            <ng-template ngbPanelContent>
                                {{ 'As this is an employee benefit provided by your employer, when you leave the company you will no longer have access to this benefit.' | translate }}
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <div class="faqBtn">
                        <a *ngIf="!viewAll" class="font-16 font-weight-bold" href="javascript:void(0);" (click)="viewAllFaqs()">{{ 'View All FAQs' | translate }}</a>
                        <a *ngIf="viewAll" class="font-16 font-weight-bold" href="javascript:void(0);" (click)="viewLessFaqs()">{{ 'View Less FAQs' | translate }}</a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>