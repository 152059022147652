import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { encryptToken, setEPCookie } from '../_helpers/global-functions';

@Injectable({
  providedIn: 'root'
})
export class ShopOnlyGuard implements CanActivateChild, OnDestroy {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Observable<boolean>(obs => {
        this.authService.currentUser
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            if (data) {
              // redirect to shop's landing page
              if(data?.gdprAccepted && data?.shopOnly) {
                // set token in the cookie
                if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
                  setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
                  setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
                }

                this.authService.shopOnlyUserRedirection();
                obs.next(false);                
              }
              else {
                // if the user is not shopOnly, then allow access                
                obs.next(true);            
              }
            } else {
              obs.next(false);  
            }
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
