<nav class="navbar navbar-expand-lg main-navbar sticky" *ngIf="router.url.split('/')[1] !== 'reporting-documentation'">
	<div class="form-inline mr-auto">
		<ul class="navbar-nav mr-3">
			<li>
				<a data-toggle="sidebar" class="nav-link sidebar-toggle nav-link-lg collapse-btn menu"
					(click)="openMobileSidebar($event)">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 25 15">
						<g fill="none" fill-rule="evenodd">
							<g fill="#333">
								<g transform="translate(-287 -30) translate(287 30)">
									<rect width="22" height="3" x="3" y="12" rx="1.5" />
									<rect width="22" height="3" y="6" rx="1.5" />
									<rect width="22" height="3" x="3" rx="1.5" />
								</g>
							</g>
						</g>
					</svg>
				</a>
			</li>
		</ul>
	</div>
	<ul class="navbar-nav navbar-right">
		<li ngbDropdown [autoClose]=false #settingsDropdown='ngbDropdown' (openChange)="onClickSettingsIcon($event)">
			<a ngbDropdownToggle class="nav-link nav-link-lg message-toggle remove-caret">
				<i-feather name="settings" class="icon-sm"></i-feather>
			</a>
			<div ngbDropdownMenu id="settings-dropdown-menu" class="dropdown-menu dropdown-list accessibility dropdown-menu-right pullDown">
				<!-- start: settings title -->
				<div>
					<div class="dropdown-header pb-0 pt-0">
						<p class="section-title font-20 font-weight-bold text-dark pt-4">{{ 'Settings' | translate }}
							<span><img class="accessibility-reset" (click)="accessibility.resetAll()"
									src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/rotate-cw.svg"></span>
							<span class="float-right"><img class="accessibility-close" (click)="settingsDropdown.close()"
								src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/cross-icon-black.png"></span>
						</p>
						
					</div>
					
				</div>
				<!-- end: settings title -->

				<!-- start: faceid/touchid settings -->
				<div *ngIf="displayBiometricsSettings">
					<div>
						<div class="dropdown-header pt-0 pb-0 mt-4">
							<p class="font-weight-bold text-dark m-0 font-16">{{ 'Login Settings' | translate }}</p>
						</div>

					</div>
					<div [ngClass]="{ 'form-disable': fetchingBiometricAuth}">

						<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3">
							<span>
                        		<img [src]="biometricsImg" width="14px"><small class="turn-on">
									{{ biometricsObj?.enabled ? ('Turn off' | translate) : ('Turn on' | translate) }} {{ biometricsType | translate }}
								</small>
							</span>
							<span>		
								<label class="custom-switch mt-2 pr-4" (click)="allowToggle ?  null : toggleBiometricsStatus()">
									<input type="checkbox" [(ngModel)]="enabled" disabled="true"
										name="custom-switch-checkbox" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</span>
						</div>
					</div>
				</div>
				<!-- end: faceid/touchid settings -->

				<!-- start: notification settings -->
				<div *ngIf="
				currentUser &&
				currentUser.setUpCompleted &&
				currentUser.businessUser &&
				currentUser.gdprAccepted
				">
					<div>
						<div class="dropdown-header pt-0 pb-0 mt-2">
							<p class="font-weight-bold text-dark m-0 font-16">{{ 'Notifications Settings' | translate }}</p>
						</div>
					</div>
					<div [ngClass]="{ 'form-disable': toggleNotificationProcessing}">

						<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 font-16">
							<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/chat.svg" width="14px"><small class="turn-on">{{ allNotificationsTurnedOff ? ('Turn on all notifications' | translate) : ('Turn off all notifications' | translate) }}</small></span>
							<span>		
								<label class="custom-switch mt-2 pr-4" (click)="toggleNotificationProcessing ? null : toggleAllNotifications()">
									<input type="checkbox" [(ngModel)]="!allNotificationsTurnedOff" disabled="true"
										name="custom-switch-checkbox" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</span>
						</div>
					</div>
				</div>
				<!-- end: notification settings -->

				<!-- start: accessibility -->
				<div class="dropdown-header pt-0 pb-0 mt-2">
					<p class="font-weight-bold text-dark mb-2 font-16">{{ 'Accessibility Options' | translate }}</p>
				</div>
				<div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.increaseText()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/zoom-in.svg"><small class="turn-on">{{ 'Increase text size' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.decreaseText()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/zoom-out.svg"><small class="turn-on">{{ 'Decrease text size' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.increaseTextSpacing()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/increase-text-spacing.svg"><small class="turn-on">{{ 'Increase text spacing' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.decreaseTextSpacing()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/decrease-text-spacing.svg"><small class="turn-on">{{ 'Decrease text spacing' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.invertColors()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/invert-colors.svg"><small class="turn-on">{{ 'Invert colors' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.grayHues()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/gray-hues.svg"><small class="turn-on">{{ 'Gray hues' | translate }}</small></span>
					</div>
					<div class="pb-0 pt-0 d-flex justify-content-between align-items-center pl-3 cursor" (click)="accessibility.menuInterface.textToSpeech()">
						<span><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/text-to-speech.svg"><small class="turn-on">{{ 'Text to speech' | translate }}</small></span>
					</div>
				</div>
				<!-- end: accessibility -->

			</div>
		</li>
		<!-- notification section -->
		<li class="dropdown dropdown-list-toggle mob-notif-icon" *ngIf="
			currentUser &&
			currentUser.setUpCompleted &&
			currentUser.businessUser &&
			currentUser.gdprAccepted
			">			
			<app-notification></app-notification>
		</li>
		<!-- end: notification section -->

		<li class="dropdown dropdown-list-toggle" *ngIf="currentUser &&
	   currentUser.setUpCompleted &&
	   currentUser.businessUser &&
	   currentUser.gdprAccepted &&
	   !languageLoading">
			<a class="nav-link nav-link-lg cursor" (click)="openLangSettingsModal()">
				<img src="{{ defaultCountry?.imageUrl }}" alt="Country Flag" height="20" width="20"
					class="mr-1 rounded-circle" />
			</a>
		</li>

		<li ngbDropdown>
			<a ngbDropdownToggle data-toggle="dropdown"
				class="nav-link dropdown-toggle header nav-link-lg nav-link-user">
				<i-feather name="user" class="mailAnim1 collapse-btn"></i-feather>
				<i class="fas fa-caret-down"></i>
			</a>
			<div ngbDropdownMenu class="dropdown-menu header dropdown-menu-right pullDown">
				<span *ngIf="
				currentUser &&
				currentUser.setUpCompleted &&
				currentUser.businessUser &&
				currentUser.gdprAccepted
				">
					<a href="{{ accountsUrl.account }}" class="dropdown-item">{{ 'Your Account' | translate }}
					</a>
					<a href="{{ accountsUrl.details }}" class="dropdown-item">{{ 'Your Details' | translate }}
					</a>
					<a href="{{ accountsUrl.activity }}" class="dropdown-item">{{ 'Your Activity' | translate }}
					</a>
					<a href="{{ accountsUrl.orders }}" class="dropdown-item">{{ 'Your Orders' | translate }}
					</a>
					<a *ngIf="showAddressDropDown" href="{{ accountsUrl.addresses }}"
						class="dropdown-item">{{ 'Your Addresses' | translate }}
					</a>
					<a href="{{ accountsUrl.preferences }}" class="dropdown-item">{{ 'Marketing Preferences' | translate }}
					</a>
					<a href="{{ accountsUrl.membership }}" class="dropdown-item">{{ 'Membership Details' | translate }}
					</a>
					<a href="{{ accountsUrl.payments }}" class="dropdown-item">{{ 'Payment Details' | translate }}
					</a>
					<!-- redirecting to donate component -->
					<a routerLink="/hr/donate" *ngIf="showDonationDropDown" class="dropdown-item">{{ 'Donate' | translate }}
					</a>
					<a href="{{ accountsUrl.profile }}" class="dropdown-item">{{ 'Work Profile' | translate }}
					</a>
				</span>
				<a href="javascript:void(0);" class="dropdown-item" (click)="logout()">{{ 'Logout' | translate }}
				</a>
			</div>
		</li>
	</ul>
</nav>

<ng-template #promptPassword let-c="close" let-d="dismiss">
	<div class="popup">
		<div class="modal-content modal-popup">
			<div class="modal-body modal-popup pt-lg-4 pt-md-3">
				<div class="pop-up">
					<div class=" d-flex justify-content-between align-items-center">
						<span (click)="!confirmPasswordSubmitting ? closeSetupPrompt() : null"><img class="accessibility-close"
								src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/accessibility-icons/x.svg"></span>
					</div>
					<h4 class="text-center mb-2 font-20" [innerHTML]="'Please Enter Your <br> Password to Confirm' | translate"></h4>
					<p class="font-16 font-weight-normal m-0 pt-2">
						<label for="password">{{ 'Password' | translate }}</label><br>
						<input id="password" type="password" [(ngModel)]="params.password" class="form-control label-customise">
					</p>

					<div class="text-center pt-3"><button [disabled]="!params.password || params.password == '' || confirmPasswordSubmitting"
							class="btn btn-primary btn-popup mt-3" (click)="confirmPassword()">
							<b class="font-16">{{ 'Confirm' | translate }}</b>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>


<ng-template #setup let-c="close" let-d="dismiss">
  <div class="faceid">
    <div class="modal-content modal-faceid">
      <div class="modal-body modal-faceid pt-lg-4 pt-md-3">
        <div class="success-card" style="background: white">
          <h4 class="text-center mb-3 font-20">{{ 'Enable Biometrics' | translate }}</h4>
          <p class="font-16 font-weight-normal text-center m-0">{{ 'We recommend setting up biometrics on your device so you can use it for login with eachperson, making it more convenient and secure for you.' | translate }} <br></p>
          <div class="text-center"><button (click)="closeSetupPrompt()" class="btn btn-secondary btn-successcard mt-3">
            <b class="font-16">{{ 'Close' | translate }}</b>
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- start: country, language settings -->
<ng-template #countryLanguageSetting let-c="close" let-d="dismiss">
	<div class="modal-header">
		<button (click)="c('close click')" type="button" aria-label="Close" class="close cursor"><span aria-hidden="true">×</span></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-12 col-12 form-group text-center">
				<h4 class="modal-title">{{ 'Update your settings' | translate }}</h4>
				<p *ngIf="multiCountryEnabled">{{ 'Set where you live and what language you speak.' | translate }}</p>
				<p *ngIf="!multiCountryEnabled">{{ 'Set what language you speak.' | translate }}</p>
			</div>
		</div>
		<form [formGroup]="settingsForm" class="form-group mb-3" (ngSubmit)="onSaveSettings()">
			<div class="row" *ngIf="multiCountryEnabled">
				<div class="col-lg-12 col-12 form-group">
					<label>{{ 'Region' | translate }}</label>
					<ng-select id="countrySelect" [items]="countries"
						[searchable]="true"
						bindLabel="displayName"
						formControlName="country"
						[placeholder]="'Select a Region' | translate">
						<!-- template to display selected label -->
						<ng-template ng-label-tmp let-item="item">
                            <img *ngIf="item.imageUrl" style="height: 26px; width: 26px;" [src]="item.imageUrl" />
                            <span class="ml-1" *ngIf="item.displayName">{{ item.displayName }}</span>
						</ng-template>
						<!-- end: template to display selected label -->

						<!-- template to display option -->
						<ng-template ng-option-tmp let-item="item">
							<img *ngIf="item.imageUrl" style="height: 22px; width: 22px;" [src]="item.imageUrl" />
							<span class="ml-1" *ngIf="item.displayName">{{ item.displayName }}</span>
						</ng-template>
						<!-- end: template to display option -->
					</ng-select>
				</div>
			</div>		

			<div class="row">
				<div class="col-lg-12 col-12 form-group">
					<label>{{ 'Language' | translate }}</label>
					<ng-select [items]="languages" [searchable]="true" formControlName="language" bindLabel="displayLanguage" [placeholder]="'Select a Language' | translate">
					</ng-select>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn btn-secondary btn-event mr-2" (click)="c('close click')">{{ 'Cancel' | translate }}</button>
				<button type="submit" class="btn btn-primary btn-event">{{ 'Save' | translate }}</button>
			</div>
		</form>
	</div>
</ng-template>
<!-- end: country, language settings -->


