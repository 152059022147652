<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="page-error">
      <div class="page-inner">
        <h1>404</h1>
        <div class="page-description">
          {{ 'The page you were looking for could not be found.' | translate }}
        </div>
        <div class="page-search">
          <div class="mt-3">
            <a [href]="gotolink" >{{ 'Back to Home' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
