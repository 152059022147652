import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {User, UserFlags} from "../../../core/models/user";
import {UserService} from "../../../core/service/user.service";
import {LanguageService} from "../../../core/service/language.service";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../../../core/service/auth.service";
import {DestroyService} from "../../../core/service/destroy.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {OrdersService} from "../../../core/service/orders.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {ToastrCustomMessageService} from "../../../core/service/toastr-custom-message.service";
import {unicodeToChar} from "../../../core/_helpers/validators/chars.validator";
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-preview-ecard",
  templateUrl: "./preview-ecard.component.html",
  styleUrls: ["./preview-ecard.component.sass"],
})
export class PreviewEcardComponent implements OnInit {
  @Input("response") response: any;
  @Input("isSelfServiceUser") isSelfServiceUser: boolean = false;
  showRedeemButton: boolean = false;
  @Output("update") update: EventEmitter<any> = new EventEmitter<any>();

  selectedRowToView: any;
  currentUser: User;
  userFlags: UserFlags;
  currentCurrency = this.languageService.getCurrency();
  currentCountry = this.languageService.getCountry();

  //for address modal / redeem conditions
  addressForm: FormGroup;
  @ViewChild("addressModal") addressModalElement: ElementRef; // Address modal content
  addressModalReference = null;
  redeemAddressSubmitting: boolean = false;

  //for email modal / redeem conditions
  @ViewChild("confirmEmailModal") confirmEmailModalElement: ElementRef; // confirm email modal content
  confirmEmailModalReference = null;
  redeemEmailSubmitting: boolean = false;

  //in case of refund
  @ViewChild("cashoutModal") cashoutModalElement: ElementRef; // cash out modal content
  cashoutModalReference = null;
  exchangeEpointsSubmitting: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public userService: UserService,
    private authService: AuthService,
    private ordersService: OrdersService,
    private readonly destroy$: DestroyService,
    private router: Router,
    private toastrService: ToastrCustomMessageService,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata) => {
        this.currentUser = userdata;
      });
    /** start: user flags */
    this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.userFlags = flags;
      });
    /** end: user flags */

    this.languageService
      .watchCountryStorage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.currentCurrency = this.languageService.getCurrency();
        this.currentCountry = this.languageService.getCountry();
      });
  }

  ngOnInit(): void {
    this.formatEcardDetail(this.response);
    this.initAddressForm();
  }

  // form to fill up address details - to redeem products using address
  initAddressForm() {
    this.addressForm = this.formBuilder.group({
      address1: [null, [Validators.required]],
      address2: [null],
      city: [null, [Validators.required]],
      county: [null],
      postCode: [null, Validators.required],
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.update.emit(false);
  }

  onRedeem() {
    let showConfirmEmailModal = true;
    let noGiftCardLists = this.selectedRowToView.products.filter(
      (eachProduct) => {
        return !eachProduct.gift;
      }
    );

    if (noGiftCardLists.length > 0) {
      showConfirmEmailModal = false;
    }

    if (showConfirmEmailModal) {
      // assign the modal content in a variable so the reference can be close whenever required
      this.confirmEmailModalReference = this.modalService.open(
        this.confirmEmailModalElement,
        { centered: true }
      );
    } else {
      this.initAddressForm();
      this.addressModalReference = this.modalService.open(
        this.addressModalElement,
        { centered: true }
      );
    }
  }

  /**
   * if a single product with a same denomination is a gift card
   * if multiple products have all gift cards, then show a confirm email view
   * on confirm email submit, call this function
   */
  /**
   * disabled on Sep 7, 2022
   * using normal view even if the selected products are all gift cards and email view used
   */
  onRedeemFormSubmitUsingEmail() {
    this.redeemEmailSubmitting = true;
    /**
     * here gift object in the payload determines if the products being ordered are gift/reward.
     * isGift in the database is true if someone recognises using gifts or products.
     * isGift in the database is false if someone buys the products and performs checkout.
     * as the same API is used when someone buys or claims the products, gift object is needed to identify if the products are gifts or not.
     */
    let payload = {
      eCardId: this.selectedRowToView.id,
      gift: {
        relationship: "",
        occasion: "",
        message: "",
      },
    };

    /**
     * if the ecard has a single product with max 10 quantity then call WeGift API
     * else call normal order API
     */
    let callWeGiftAPI = this.callWeGiftAPIToRedeem(
      this.selectedRowToView.products
    );
    if (callWeGiftAPI) {
      this.ordersService
        .redeemGiftCardWithWeGiftAPI(
          this.selectedRowToView.receiver.email,
          this.currentUser.userCountryCode || 'GB',
          payload
        )
        .subscribe(
          (response) => {
            this.redeemEmailSubmitting = false;

            // on success, fetch new updated data and close only redeem section modal and display success modal
            this.userService
              .getEcardDetail(
                this.selectedRowToView.id,
                this.currentUser.userCountryCode || 'GB'
              )
              .subscribe((detailResponse) => {
                this.formatEcardDetail(detailResponse);
                this.update.emit(true);
                // this.fetchEmployeeHistory();
                // this.fetchStatsForUsersAllocationHistory();
              });
            this.confirmEmailModalReference.close();
            this.redeemSuccessPopup();
          },
          (error) => {
            this.redeemEmailSubmitting = false;
            this.confirmEmailModalReference.close();
            this.cashoutModalReference = this.modalService.open(
              this.cashoutModalElement,
              { centered: true }
            );
          }
        );
    } else {
      this.onRedeemFormSubmitUsingAddress(false);
    }
  }

  callWeGiftAPIToRedeem(products) {
    let callWeGift = false;
    if (products && products.length === 1) {
      products.forEach((eachProduct) => {
        if (eachProduct.quantity <= 10) {
          callWeGift = true;
        } else {
          callWeGift = false;
        }
      });
    } else {
      callWeGift = false;
    }

    return callWeGift;
  }

  redeemSuccessPopup() {
    var html = this.translate.instant(extractTsText(`<p class="mt-2">Your ecard reward has been redeemed!</p>
    <p class="mb-0">Feeling rewarded? Why not pass it on and send your colleague an ecard!</p>`));
    const redeemSuccessSwal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
    redeemSuccessSwal
      .fire({
        html,
        title: this.translate.instant("Ecard Details"),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant(extractTsText("Recognise / reward")),
        cancelButtonText: this.translate.instant(extractTsText("Close")),
        reverseButtons: true, // reverse buttons positions
      })
      .then((result) => {
        if (result.value) {
          // on Clicking Recognise/reward, go to recognise page by closing all the opened modals
          this.modalService.dismissAll();
          this.router.navigate(["/hr/points-allocation/choose-an-ecard"]);
        }
      });
  }

  /**
   * if one or more of the products is not a gift card, then popup address view
   * on address input submit call this function
   */
  onRedeemFormSubmitUsingAddress(sendAddress: boolean = true) {
    this.redeemAddressSubmitting = true;
    /**
     * here gift object in the payload determines if the products being ordered are gift/reward.
     * isGift in the database is true if someone recognises using gifts or products.
     * isGift in the database is false if someone buys the products and performs checkout.
     * as the same API is used when someone buys or claims the products, gift object is needed to identify if the products are gifts or not.
     */
    let payload = {
      eCardId: this.selectedRowToView.id,
      address: {
        addressId: null,
        country: this.currentCountry.name,
        county: this.addressForm.get("county").value,
        city: this.addressForm.get("city").value,
        postCode: this.addressForm.get("postCode").value,
        street: this.addressForm.get("address2").value,
        house: this.addressForm.get("address1").value,
      },
      gift: {
        relationship: "",
        occasion: "",
        message: "",
      },
    };

    /**
     * when this API is called using confirm email UI with all Gift Card
     * cases:
     * 1. when a single product has more than 10 quantity
     * 2. when there are mixed products (can be different denominations of same retailer or different retailers)
     */
    if (!sendAddress) {
      payload.address = null;
      this.redeemAddressSubmitting = false;
      this.redeemEmailSubmitting = true;
    }

    this.ordersService
      .redeemProductsWithNormalAPI(this.currentUser.userCountryCode || 'GB', payload)
      .subscribe(
        (response) => {
          this.redeemAddressSubmitting = false;
          this.redeemEmailSubmitting = false;

          // on success, fetch new updated data and close only redeem section modal and display success modal
          this.userService
            .getEcardDetail(
              this.selectedRowToView.id,
              this.currentUser.userCountryCode || 'GB'
            )
            .subscribe((detailResponse) => {
              this.formatEcardDetail(detailResponse);
              this.update.emit(true);
            });
          // reference - https://stackoverflow.com/questions/40382319/how-to-programmatically-close-ng-bootstrap-modal

          if (!sendAddress) {
            this.confirmEmailModalReference.close();
          } else {
            this.addressModalReference.close();
          }
          this.redeemSuccessPopup();
        },
        (error) => {
          this.redeemAddressSubmitting = false;
          this.redeemEmailSubmitting = false;
          if (!sendAddress) {
            this.confirmEmailModalReference.close();
          } else {
            this.addressModalReference.close();
          }
          this.cashoutModalReference = this.modalService.open(
            this.cashoutModalElement
          );
        }
      );
  }

  /**
   * if the product is out of stock,
   * cashoutModal opens
   * and on hitting the confirm button, this function is called
   */
  exchangeEpoints() {
    this.exchangeEpointsSubmitting = true;
    this.ordersService
      .exchangeEpoints(this.selectedRowToView.id, this.currentUser.userCountryCode || 'GB')
      .subscribe(
        (response) => {
          this.exchangeEpointsSubmitting = false;
          // on success, fetch new updated data and close only redeem section modal and display success modal
          this.userService
            .getEcardDetail(
              this.selectedRowToView.id,
              this.currentUser.userCountryCode || 'GB'
            )
            .subscribe((detailResponse) => {
              this.formatEcardDetail(detailResponse);
              this.update.emit(true);
            });
          this.cashoutModalReference.close();
          this.redeemSuccessPopup();
        },
        (error) => {
          this.exchangeEpointsSubmitting = false;
          this.toastrService.error(error);
        }
      );
  }

  // format ecard detail response
  formatEcardDetail(response) {
    this.selectedRowToView = response;
    // this.selectedRowToView["message"] = unicodeToChar(response.message); // the message can have emoji so decode it
    if (response.message !== null && response.message !== undefined) {
      this.selectedRowToView["message"] = unicodeToChar(response.message);
      this.selectedRowToView["isMessageDeleted"] = false;
    } else {
      this.selectedRowToView["message"] = this.translate.instant(extractTsText('Message deleted'));
      this.selectedRowToView["isMessageDeleted"] = true;
    }
    this.selectedRowToView["sentFrom"] = response.from;
    this.selectedRowToView["sentTo"] = response.receiver;
    this.selectedRowToView["points"] = response.pointsValue;
    this.selectedRowToView["reason"] = response.reasonId;
    this.selectedRowToView["nominationName"] = response.nominationName;
    this.selectedRowToView["description"] = response.nominationDescription;
    this.selectedRowToView["criteria"] = response.nominationCriteria;
    this.selectedRowToView["eCardPreviewLink"] = response.templateUrl;
    this.selectedRowToView["products"] = response.products || [];
    if (
      (response.status == "fulfilled" || response.status == "pointexchanged") &&
      response.awardType == "product"
    ) {
      this.selectedRowToView["redeemed"] = true;
    } else {
      this.selectedRowToView["redeemed"] = false;
    }
    /**
     * enable redeem reward(s) button, if the receiver is a currentUser,
     * if the ecard has products with eCardType "Ecard with Gift",
     * if the status of ecard is pending
     */
    this.selectedRowToView["receiver"]["id"] === this.currentUser.id &&
    !this.isSelfServiceUser &&
    response.products.length > 0 &&
    response.status === "pending" &&
    response.awardType === "product"
      ? (this.showRedeemButton = true)
      : (this.showRedeemButton = false); // condition to show redeem button
  }
}
