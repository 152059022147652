import { Component, Input, OnInit, ViewChild, Output, EventEmitter, SimpleChanges, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/core/service/user.service';
import { encodeEmojisInMessage, unicodeToChar } from 'src/app/core/_helpers/validators/chars.validator';
import { Comment, Reaction, ReactionPayload } from 'src/app/core/models/social-aspect.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { SocialAspectService } from 'src/app/core/service/socialAspect.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import Swal from 'sweetalert2';
import { sliceArray } from 'src/app/core/_helpers/environment-variables';
import { sortArrayByDateInDescendingOrder } from 'src/app/core/_helpers/data-sorter';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MentionService } from '../mentions/mention.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { DashboardService } from 'src/app/core/service/dashboard.service';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.sass']
})
export class CommentsComponent implements OnInit {
  @ViewChild('editDeleteModal') editDeleteModal: TemplateRef<any>;
  @ViewChild('reactionsModal') reactionsModal: TemplateRef<any>;
  editDeleteModalRef: NgbModalRef;
  reactionsModalRef: NgbModalRef;

  @Input() comments: Comment;
  @Input() fetchingSocialAspects: boolean = false;
  canUserEditOrDelete: boolean = false;
  formattedParentComment: any;
  userReactionInComments: Reaction | null;
  @Input() shareableDataForComments: any;
  showCommentReplies: boolean = false
  showEditInput: boolean = false
  @Input() ecardId: string;
  @Output() callGetIndividualCardSocialAspectDataAPI = new EventEmitter<any>();
  @Output() updateSocialFetchStatus = new EventEmitter<any>();
  emitvalues = {
    "callApi": true,
    "showReply": false,
    commentId: null,
    pagesToShowReplies: null,
    socialFetchStatus: false
  }

  replyPagination = {
    repliesPerPage: 4,
    currentPage: 1
  }
  displayReplies = [];

  commentIdFromUrl: number = null;
  replyIdFromUrl: number = null; // used to scroll to the specific reply

  commentPlaceholder: string = 'Add a Comment...';

  constructor(
    private toastr: ToastrCustomMessageService,
    private socialAspectService: SocialAspectService,
    public authService: AuthService,
    private modalService: NgbModal,
    private mentionService: MentionService,
    private route: ActivatedRoute,
    public userService: UserService,
    private translate: TranslateService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCommentReplies = this.shareableDataForComments?.showReply?.commentId === this.comments?.id && this.shareableDataForComments?.showReply?.show ? true : false
    this.comments.commentText = unicodeToChar(this.comments.commentText)
    // get commentText with highlighted mentions
    this.formattedParentComment = this.mentionService.getMentionedText(this.comments?.commentText, this.comments?.mentionedUsersInComment) || this.comments.commentText.replace(/\n/g, '<br>')
    this.canUserEditOrDeleteFunc();
    this.getUserReactionInComments(this.comments, this.authService.currentUserValue.id)

    // enable this if to be sorted by createdAt date
    this.comments.replies = sortArrayByDateInDescendingOrder(this.comments?.replies, 'createdAt');

    // if the replies were shown more than 5, that is of more than 1 page, then retore the previous state for every actions in the replies
    this.displayReplies = []
    if (this.shareableDataForComments?.totalPageReplies) {
      this.replyPagination.currentPage = this.shareableDataForComments?.totalPageReplies
      for (let i = 0; i < this.shareableDataForComments?.totalPageReplies; i++) {
        this.sliceReplies(i + 1)
      }
    } else {
      this.replyPagination.currentPage = 1;
      //comment slicing for desktop show only 4
      this.displayReplies = this.comments?.replies.slice(0, this.replyPagination.repliesPerPage);
    }

    // check the query parameter and apply the logic to scroll down to specific reply in a comment
    this.route.queryParams.subscribe(
      params => {
        this.commentIdFromUrl = params['commentId'] || null;
        this.replyIdFromUrl = params['replyId'] || null;
        if(this.replyIdFromUrl) {
          this.showCommentReplies = true;
          setTimeout(() => {
            this.scrollToSpecificReply();
          }, 50)
        }
      }
    );
  }

  sliceReplies(page?) {
    let replies = sliceArray(this.comments?.replies, this.replyPagination.repliesPerPage, page || this.replyPagination.currentPage)
    replies.forEach(eachReply => {
      this.displayReplies.push({ ...eachReply })
    })
  }  

  viewMore() {
    this.replyPagination.currentPage = this.replyPagination.currentPage + 1
    this.sliceReplies()
  }

  viewLess() {
    this.replyPagination.currentPage = 1
    let comments = sliceArray(this.comments?.replies, this.replyPagination.repliesPerPage, this.replyPagination.currentPage)
    this.displayReplies = comments
  }

  onEdit() {
    if(this.shareableDataForComments?.isMobileView) {
      this.dismissModals();
    }
    this.showEditInput = true
  }

  canUserEditOrDeleteFunc() {
    if (this.authService.currentUserValue.id === this.comments.commentedById) {
      this.canUserEditOrDelete = true
    }
  }
  toggleReply() {
    this.showCommentReplies = !this.showCommentReplies

    /**
     * when the reply is toggled, it should emit the showReply boolean true/false to its parent so that
     * the reply is opened/closed as desired
     */
    this.emitvalues.commentId = this.comments?.id
    this.emitvalues.showReply = this.showCommentReplies
    this.emitvalues.socialFetchStatus = false
    this.updateSocialFetchStatus.emit(this.emitvalues)
  }

  updateComment(eventData) {
    let data = eventData?.data,
    mentions = eventData?.mentions;
    // if on edit, the text is not changed by typing, then do not send data
    if (this.fetchingSocialAspects || !data) {
      return false
    }

    this.emitvalues.commentId = this.comments?.id
    this.emitvalues.showReply = this.showCommentReplies
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchStatus.emit(this.emitvalues)
    this.showEditInput = false;

    let mentionedUsersId = []
    if(mentions?.length > 0) {
      mentions.forEach((eachMention: any) => {
        let eachUser = {
          userId: eachMention?.id,
          name: eachMention?.name
        }
        mentionedUsersId.push(eachUser)
      })
    }
    
    let commentData = {
      comment: encodeEmojisInMessage(data),
      userId: `${this.authService.currentUserValue.id}`,
      eCardId: `${this.ecardId}`,
      parentCommentId: null,
      mentionedUsersId
    }
    let _commentText = this.comments.commentText
    this.comments.commentText = unicodeToChar(commentData.comment)
    this.socialAspectService.updateComment(this.comments.id, commentData).subscribe(
      res => { },
      error => {
        this.updateSocialFetchStatus.emit(null)
        this.toastr.error('Something went wrong, please try again later!')
        //reverting the state of commentText
        this.comments.commentText = _commentText;
      },
      () => {
        this.emitvalues.commentId = this.comments?.id
        this.emitvalues.showReply = this.showCommentReplies
        this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
      }
    )
  }

  cancelEdit(event) {
    this.showEditInput = event
  }

  getUserReactionInComments(commentsData, currentUserID) {
    // Find the user's reaction
    this.userReactionInComments = commentsData.reactions.find(reaction => reaction.reactedById === currentUserID);
  }

  likeParentComment() {
    if (this.fetchingSocialAspects) {
      return false
    }

    this.emitvalues.commentId = this.comments?.id
    this.emitvalues.showReply = this.showCommentReplies
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchStatus.emit(this.emitvalues)
    let reactionPayload: ReactionPayload = {
      commentId: `${this.comments.id}`,
      eCardId: `${this.ecardId}`,
      userId: `${this.authService.currentUserValue.id}`,
      reaction: 'LIKE',
    }
    if (this.userReactionInComments?.id) {
      //IF THERE IS ALREADY USER REACTION IN COMMENTS, DELETE THE REACTION
      //optimistic update 
      let _userReactionInComments = this.userReactionInComments
      // commmented out the optimistic update as the div is disabled until API response completed
      // this.userReactionInComments = null;
      this.socialAspectService.deleteReaction(`${_userReactionInComments?.id}`)
        .subscribe(res => { },
          error => {
            this.updateSocialFetchStatus.emit(null)
            this.toastr.error('Something went wrong, please try again later!')
            //reverting the state of userReactionInComments
            this.userReactionInComments = _userReactionInComments;

          },
          () => {
            this.emitvalues.commentId = this.comments?.id
            this.emitvalues.showReply = this.showCommentReplies
            this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
          })
    } else {
      let _userReactionInComments = this.userReactionInComments
      const now = new Date();
      const datetimeSignature = now.toISOString();
      //optimistic update
      // commmented out the optimistic update as the div is disabled until API response completed
      // this.userReactionInComments = {
      //   "id": null,
      //   "reactionType": `${reactionPayload.reaction}`,
      //   "reactedById": `${this.authService.currentUserValue.id}`,
      //   "reactedByName": `${this.authService.currentUserValue.name}`,
      //   "createdAt": `${_userReactionInComments?.createdAt}`,
      //   "updatedAt": `${datetimeSignature}`
      // }

      this.socialAspectService.saveReaction(reactionPayload)
        .subscribe(
          res => { },
          error => {
            this.updateSocialFetchStatus.emit(null)
            this.toastr.error('Something went wrong, please try again later!')
            //reverting the state of userReactionInComments
            this.userReactionInComments = _userReactionInComments;
          },
          () => {
            this.emitvalues.commentId = this.comments?.id
            this.emitvalues.showReply = this.showCommentReplies
            this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
          }
        )
    }
  }

  // remove parent comment for desktop view
  removeParentComment() {
    if(this.fetchingSocialAspects) {
      return false
    }

    this.emitvalues.commentId = this.comments?.id
    this.emitvalues.showReply = this.showCommentReplies
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchStatus.emit(this.emitvalues)
    const removeParentTemplate = Swal.mixin({
      customClass: {
        cancelButton: 'btn btn-secondary',
        confirmButton: 'btn btn-success',
        popup: 'social-alert-popup'
      },
      buttonsStyling: false
    })
    removeParentTemplate.fire({
      title: this.translate.instant(extractTsText(`Are You Sure You Want <br> to Delete Your Comment?`)),
      // width: '1000px',
      // text: `You want to delete this template ?`,
      html: ` 
      <div class="custom-close-btn">
        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/cross-icon-black.png">  
      </div>
      <div class="comments-after-input custom-modal">
      <div class="card card-header comment-description bg-lightgrey-socialaspect mt-2">
        <div class="d-flex justify-content-between flex-column align-items-start p-0">
          <div class="name-container">
            <p class="font-16 font-weight-bold m-0 mb-1" style="text-align:left">${this.comments.commentedByName}</p>
            <p class="font-weight-normal font-16 mb-1 text-left">${this.formattedParentComment}</p>
          </div>
          <small class="font-weight-normal text-lightgrey font-10">${this.userService.dateAgo(this.comments.createdAt)}
          </small>
        </div>
      </div>
    </div>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: this.translate.instant(extractTsText('Delete')),
      cancelButtonText: this.translate.instant(extractTsText('Cancel'))
    }).then((result) => {
      if (result.value) {
        this.deleteCommentAction()
      } else {
        this.updateSocialFetchStatus.emit(null)
      }
    });

    // listen to the click event of custom close button and fire close event of sweetalert
    const customButton = document.getElementsByClassName('custom-close-btn');
    
    customButton[0].addEventListener('click', function () {
      Swal.close()
    })

  }

  dismissModals() {
    if(this.editDeleteModalRef) {
      this.editDeleteModalRef.dismiss('close modal');
    }

    if(this.reactionsModalRef) {
      this.reactionsModalRef.dismiss('close modal');
    }
  }

  // remove parent comment for mobile view
  
  removeCommentForMobile() {
    this.dismissModals();

    if (this.fetchingSocialAspects) {
      return false;
    }
    this.emitvalues.commentId = this.comments?.id;
    this.emitvalues.showReply = this.showCommentReplies;
    this.emitvalues.socialFetchStatus = true;
    this.updateSocialFetchStatus.emit(this.emitvalues);
    const removeParentTemplate = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-secondary",
        confirmButton: "btn btn-success",
        container: "socialaspect-mobile-delete-container",
        popup: "socialaspect-mobile-delete-popup",
      },
      buttonsStyling: false,
    });
    removeParentTemplate
      .fire({
        title: this.translate.instant(extractTsText(`Are You Sure You Want <br> to Delete Your Comment?`)),
        // width: '1000px',
        // text: `You want to delete this template ?`,
        html: ` 
      <div class="custom-close-btn">
        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/cross-icon-black.png">  
      </div>
      <div class="comments-after-input custom-modal">
      <div class="card card-header comment-description bg-lightgrey-socialaspect mt-2">
        <div class="d-flex justify-content-between flex-column align-items-start p-0">
          <div class="name-container">
            <p class="font-16 font-weight-bold m-0 mb-1" style="text-align:left">${this.comments.commentedByName}</p>
            <p class="font-weight-normal font-16 mb-1 text-left">${this.comments.commentText}</p>
          </div>
          <small class="font-weight-normal text-lightgrey font-10">${this.userService.dateAgo(this.comments.createdAt)}
          </small>
        </div>
      </div>
    </div>`,
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.translate.instant("Delete"),
        cancelButtonText: this.translate.instant('Cancel')
      })
      .then((result) => {
        if (result.value) {
          // hit delete API
          this.deleteCommentAction();
        } else {
          this.updateSocialFetchStatus.emit(null);
        }
      });
    // listen to the click event of custom close button and fire close event of sweetalert
    const customButton = document.getElementsByClassName("custom-close-btn");

    customButton[0].addEventListener("click", function () {
      Swal.close();
    });
  }

  deleteCommentAction() {
    this.socialAspectService.deleteComment(this.comments.id).subscribe(
      response => {
        this.updateSocialFetchStatus.emit(null)
        this.toastr.success('Deleted!');
      },
      error => {
        this.updateSocialFetchStatus.emit(null)
        this.toastr.error('Unable to delete the selected comment!')
      },
      () => {
        this.emitvalues.commentId = this.comments?.id
        this.emitvalues.showReply = this.showCommentReplies

        this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
      }
    )
  }

  //send event to the social aspect component from reply component, act as a middle man - event emitter.
  getIndividualSocailAspect(event) {
    event.commentId = this.comments?.id
    event.showReply = true
    event['pagesToShowReplies'] = this.replyPagination.currentPage
    this.callGetIndividualCardSocialAspectDataAPI.emit(event)
  }

  // add reply part
  saveReply(eventData) {
    let data = eventData?.data,
    mentions = eventData?.mentions;
    if (this.fetchingSocialAspects || !data) {
      return false
    }

    let mentionedUsersId = []
    if(mentions?.length > 0) {
      mentions.forEach((eachMention: any) => {
        let eachUser = {
          userId: eachMention?.id,
          name: eachMention?.name
        }
        mentionedUsersId.push(eachUser)
      })
    }
    
    this.emitvalues.commentId = this.comments?.id
    this.emitvalues.showReply = this.showCommentReplies
    this.emitvalues.socialFetchStatus = true
    this.emitvalues.pagesToShowReplies = this.replyPagination?.currentPage 
    this.updateSocialFetchStatus.emit(this.emitvalues)
    let commentData = {
      comment: encodeEmojisInMessage(data),
      userId: `${this.authService.currentUserValue.id}`,
      eCardId: `${this.ecardId}`,
      parentCommentId: `${this.comments.id}`,
      mentionedUsersId,
    }
    this.socialAspectService.saveComment(commentData).subscribe(
      res => {},
      error => {
        this.updateSocialFetchStatus.emit(null)
        this.toastr.error('Something went wrong. Please try again!')
      },
      () => {
        this.emitvalues.commentId = this.comments?.id
        this.emitvalues.showReply = this.showCommentReplies
        this.emitvalues.pagesToShowReplies = this.replyPagination?.currentPage // to display all the replies which were shown before - after edit reply, react reply, add reply
        this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
      })
  }

  trackById(index: number, item) {
    return item.id
  }

  // for mobile view
  openEditDeleteModal() {
    this.editDeleteModalRef = this.modalService.open(this.editDeleteModal, {centered: true, windowClass:'social-aspect-mobile'});
    this.dashboardService.registerOpenedModals(this.editDeleteModalRef);
  }

  // event emitter for fetching social aspect status
  updateSocialFetchStatusFromReply(event) {
    if(event) {
      this.emitvalues.commentId = event?.commentId
      this.emitvalues.showReply = event?.showReply
      this.emitvalues.socialFetchStatus = true
      event['pagesToShowReplies'] = this.replyPagination.currentPage
      this.updateSocialFetchStatus.emit(this.emitvalues)
    } else {
      this.updateSocialFetchStatus.emit(null)
    }
  }
  
  openReactionModal() {
    this.reactionsModalRef = this.modalService.open(this.reactionsModal, {centered: true, windowClass:'reactionList-modal'});  
    this.dashboardService.registerOpenedModals(this.reactionsModalRef);
  }

  // to scroll down to a specific reply with a focused view
  scrollToSpecificReply() {
    const replyElement = document.getElementById(`reply_display_${this.replyIdFromUrl}`);
    if (replyElement) {
      replyElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      replyElement.classList.add('focused-content');
    // if the reply is not found in the displayed view, then apply view more until the reply is not found
    } else if(this.displayReplies.length < this.comments?.replyCount) {
      this.viewMore();

      setTimeout(() => {
        this.scrollToSpecificReply();
      }, 50);
    }
  }

}

