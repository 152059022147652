import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EapGuard implements CanActivate {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      /**
        * here, the guard runs before the API call and since the data is not stored in localStorage,
        * the obseravabled in the guard does not find the data and does not work.
        * For this, the API is called inside the guard.
       */
      this.authService.getMeFlags()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data) {
            // if eapEnabled is true, then allow access
            if(data.eapEnabled) {
              obs.next(true);
            }
            else {
              // location.reload() inside then breaks the app in ios devices, so used .then() only
              this.router.navigate(['/hr/dashboard']).then()
              obs.next(false);              
            }
          } else {
            obs.next(false);  
          }
        },
        error => {
          this.router.navigate(['/hr/dashboard']).then();
          obs.next(false);
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
