<!-- Social aspect: start -->
<!-- display reacted emojis, names and count: start -->
<div  class="socialaspect-reaction d-flex justify-content-between align-items-center p-0" >
  <p (click)="openReactionModal()" *ngIf="(groupByReactionType | keyvalue)?.length"  class="text-lightgrey font-weight-normal m-0 cursor">
    <!-- Heart reaction -->
    <span *ngIf="(groupByReactionType?.HEART )?.length" class="heart-reaction user-react">
      <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/heart.svg">
    </span>

    <!-- Smile reaction -->
    <span *ngIf="(groupByReactionType?.SMILE )?.length" class="smile-reaction user-react">
      <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/smile.svg">
    </span>

    <!-- Thumps up reaction -->
    <span *ngIf="(groupByReactionType?.LIKE )?.length" class="like-reaction user-react">
      <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/like.svg">
    </span>

    <!-- Idea reaction -->
    <span *ngIf="(groupByReactionType?.IDEA )?.length" class="idea-reaction user-react">
      <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/idea.svg">
    </span>

    <!-- Applause reaction -->
    <span *ngIf="(groupByReactionType?.APPLAUSE )?.length" class="applause-reaction user-react">
      <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/applause.svg">
    </span>
    <span *ngIf="socialAspectData?.reactionCount >= 2"
      class="social-media-likes tablet-none">{{socialAspectData?.reactions[0]?.reactedByName}} &
      {{socialAspectData?.reactionCount - 1}} {{socialAspectData?.reactionCount === 2 ? ('other' | translate) : ('others' | translate)}}</span>
    <span *ngIf="socialAspectData?.reactionCount === 1"
      class="social-media-likes tablet-none">{{socialAspectData?.reactions[0]?.reactedByName}} </span>
  </p>
  <p *ngIf="socialAspectData.commentCount > 0 || socialAspectData.reactionCount > 0" class="text-lightgrey font-weight-normal m-0">
    <span class="like-count">{{socialAspectData?.reactionCount}} {{socialAspectData?.reactionCount === 1 ? ('like' | translate) : ('likes' | translate)
        }} </span>&nbsp;|&nbsp;<span class="comment-count">{{socialAspectData?.commentCount}}
          {{socialAspectData?.commentCount === 1 ? ('comment' | translate) : ('comments' | translate) }}</span>
  </p>
</div>
<!-- display reacted emojis, names and count: end -->
<hr class="social-aspect-hr-line" *ngIf="socialAspectData.commentCount > 0 || socialAspectData.reactionCount > 0">
<div class="likecmt-container" [ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''">
  <div class="socialaspect-likecmt d-flex justify-content-between align-items-center p-0">
    <!-- for desktop - reaction option show on hover -->
    <p *ngIf="!enableMobileView" class="font-16 text-grey font-weight-normal like-group m-0"
    (click)="addReaction(userReaction?.reactionType ? userReaction?.reactionType : 'LIKE')"
    (mouseover)="showReactionOptions(true)" 
    (mouseout)="showReactionOptions(false)"
    (contextmenu)="onContextMenu($event)">
      <!-- When like is not selected: start-->
      <span class="like-icon" >
        <img [src]="userReactionIcon ? userReactionIcon  : 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/unLike.svg'" >
      </span>
      <span class="like-action">{{matchingReaction?.name ? ((matchingReaction?.name === 'LIKE' ? 'Like' : matchingReaction?.name) | translate | titlecase) : ("Like" | translate) }}</span>
    </p>

    <!-- for mobile view - show reaction popup on long pressed -->
    <p *ngIf="enableMobileView" class="font-16 text-grey font-weight-normal like-group m-0 user-react-button"
    (touchstart)="onTouchStart($event)"
    (touchend)="onTouchEnd($event)"
    (click)="addReaction(userReaction?.reactionType ? userReaction?.reactionType : 'LIKE')"
    (contextmenu)="onContextMenu($event)"
    >
      <!-- When like is not selected: start-->
      <span class="like-icon" (contextmenu)="onContextMenu($event)">
        <img [src]="userReactionIcon ? userReactionIcon  : 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/unLike.svg'" (contextmenu)="onContextMenu($event)">
      </span>
      <span class="like-action">{{matchingReaction?.name ? ((matchingReaction?.name === 'LIKE' ? 'Like' : matchingReaction?.name) | translate | titlecase) : ("like" | translate | titlecase) }}</span>
    </p>
    <p (click)="showInputBox = !showInputBox; showDesktopOrMobileView()" class="font-weight-normal font-16 text-grey comment-group m-0" [ngClass]="enableMobileView ? 'user-react-button' : ''">
      <span class="comment-action">{{ 'comment' | translate | titlecase}}</span>
      <span class="comment-icon">
        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/commentIcon.svg">
        <!-- Comment count: start-->
        <span class="badge badge-success social-aspect">{{ socialAspectData?.commentCount }}</span>
        <!-- Comment count: end-->
      </span>
      
    </p>
  </div>

  <!-- Reactions on hover over Like: start -->
  <div (mouseover)="onMouseEnterPopup()" 
  (mouseout)="onMouseLeavePopup()" *ngIf="showDefaultReactions" class="like-popup" [ngClass]="enableMobileView ? 'user-react-button' : ''">
    <span (click)="addReaction(eachReaction?.name);" class="{{ eachReaction.name.toLowerCase() }}-reaction" *ngFor="let eachReaction of defaultReactions; trackBy: trackById">
      <img [src]="eachReaction?.icon">
    </span>
  </div>
  <!-- Reactions on hover over Like: end -->
</div>

<!-- Comment input: start-->
<app-mention
*ngIf="showInputBox"
[placeholder]="commentPlaceholder"
[showInputBox]="showInputBox"
[fetchingSocialAspects]="fetchingSocialAspects"
(saveText)="saveComment($event)"
[isMobileView]="enableMobileView"
[uniqueTextId]="ecardId"
></app-mention>
<!-- Comment input: end -->

<!-- display comments: start -->
<div *ngIf="showInputBox" class="cmt-edit container p-0" [ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''">
  <div class="cmt-edit parent" *ngFor="let commentObj of displayComments; trackBy: trackById">
    <app-comments 
    [shareableDataForComments]="shareableDataForComments"
    (callGetIndividualCardSocialAspectDataAPI)="getIndividualSocailAspect($event)" 
    [ecardId]="ecardId"
    [comments]="commentObj"
    [fetchingSocialAspects]="fetchingSocialAspects"
    (updateSocialFetchStatus)="updateSocialFetchStatus($event)"></app-comments>
  </div>
</div>
<!-- display comments: end -->

<!-- show view more when the number of comments loaded is less than the commentCount: start -->
<div *ngIf="showInputBox && !viewLessbool && displayComments.length < socialAspectData?.commentCount"
class="text-right text-primary" [ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''"><a href="javascript:void(0)" (click)="viewMore()" class="font-16 font-weight-bold">{{ 'View More' | translate }}</a></div>
<!-- show view more when the number of comments loaded is less than the commentCount: end -->

<!-- show view less when the total number of comments is greater than 4 and the number of comments loaded is equal to the commentCount: start -->
<div *ngIf="showInputBox && viewLessbool" class="text-right text-primary"
[ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''"><a href="javascript:void(0)" (click)="viewLess()" class="font-16 font-weight-bold">{{ 'View less' | translate }}</a></div>
<!-- show view less when the total number of comments is greater than 4 and the number of comments loaded is equal to the commentCount: end -->
<!-- List of reaction modal for desktop view: start -->
<ng-template #reactionsModal let-c="close" let-d="dismiss">
  <app-social-aspect-reaction-modal
  [data]="socialAspectData"
  [currentUserId]="authService.currentUserValue.id"
  [isMobileView]="enableMobileView"
  ></app-social-aspect-reaction-modal>
</ng-template>
<!-- Social aspect: end -->
