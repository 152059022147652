import { Pipe, PipeTransform } from '@angular/core';
import { TranslateApiService } from '../service/translate-api.service';
import { LanguageService } from '../service/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'dynamicTranslate'
})
export class DynamicTranslatePipe implements PipeTransform {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateAPIService: TranslateApiService,
    private languageService: LanguageService
  ) {}

  transform(key: string, lang?: string, values?: { [key: string]: any }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let translations = this.languageService.getLanguageData();
      const targetLang = lang || this.languageService.getSelectedLanguageCode();
      const target = `${targetLang}`;

      if (translations && translations[key]) {
        resolve(translations[key]); // Return immediately if translation is found in cache
      } else {
        this.translateAPIService.googleTranslate([key], target)
          .then(translatedJson => {
            if (translatedJson && translatedJson.data.translations.length > 0) {
              const translatedText = translatedJson.data.translations[0].translatedText;
              this.translateAPIService.saveTranslatedText(key, translatedText, target);
              resolve(translatedText); // Resolve with translated text
            } else {
              reject("Translation not found");
            }
          })
          .catch(error => {
            console.error("Error fetching translation:", error);
            reject(error);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
