import { Component, OnInit, ViewChild, ElementRef, HostListener, Renderer2, ViewContainerRef, ComponentFactoryResolver, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { unicodeToChar } from 'src/app/core/_helpers/validators/chars.validator';
import { SocialAspectService } from 'src/app/core/service/socialAspect.service';
import { SocialAspectsMobileComponent } from '../social-aspects-mobile/social-aspects-mobile.component';
import { UserService } from 'src/app/core/service/user.service';

@Component({
  selector: "app-social-aspect-detail",
  templateUrl: "./social-aspect-detail.component.html",
  styleUrls: ["./social-aspect-detail.component.sass"],
  providers: [DestroyService],
})
export class SocialAspectDetailComponent implements OnInit {
  ecardIdFromUrl: number = null;
  commentIdFromUrl: number = null;
  replyIdFromUrl: number = null;
  currentUser: User = null;
  ecardDetail: any = null;
  ecardLoading: boolean = false;
  ecardDeleted: boolean = false;

  // start - truncate a text message depending on lines
  private readonly MAX_LINES_MOBILE = 3; // Maximum number of lines on mobile
  private readonly MAX_LINES_DESKTOP = 4; // Maximum number of lines on desktop
  @ViewChild("content") contentElement: ElementRef;
  // end - truncate a text message depending on lines

  isSmallerWidth: boolean = window.innerWidth < 768 ? true : false;
  isLargerWidth: boolean = window.innerWidth >= 768 ? true : false;

  showDesktopCommentsByDefault: boolean = false;

  // start: mobile social aspects
  @ViewChild("lazyLoadMobileSocialAspectContainer", { read: ViewContainerRef })
  mobileSocialAspectContainer: ViewContainerRef;
  showMobileSocialAspects: boolean = false;
  initialScrollPosition = 0;
  currentLazyComponentRef: any;
  hostScrollPosition = 0;
  updatedSocialAspectOfSelectedEcard: any = null;
  // end: mobile social aspects

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private destroy$: DestroyService,
    private socialAspectService: SocialAspectService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    public userService: UserService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdetail) => {
        this.currentUser = userdetail;
      });
  }

  ngOnInit(): void {
    this.checkQueryParams();
    this.getEcard();
  }

  getEcard() {
    this.ecardLoading = true;
    if (this.ecardIdFromUrl) {
      this.socialAspectService
        .getSocialAspectDetail(
          this.ecardIdFromUrl,
          this.currentUser?.userCountryCode
        )
        .pipe(
          finalize(() => {
            this.ecardLoading = false;
          })
        )
        .subscribe((ecardDetail) => {
          if (ecardDetail === null) {
            this.ecardDeleted = true;
            return;
          }
          this.ecardDetail = ecardDetail;
          if (!this.ecardDetail?.message) {
            this.ecardDetail.messageType = "private";
          }
          // message can be null or "" if the messageType is "PRIVATE"
          this.ecardDetail.message = this.ecardDetail?.message
            ? unicodeToChar(this.ecardDetail?.message)
            : null;

          // setTimeout is used because the method to show see more was not working properly.
          setTimeout(() => {
            this.calculateNumberOfLinesForMessage();
            this.fetchSocialAspectsForEachEcard();
          }, 0);
        });
    }
  }

  commentPagination = {
    commentsPerPage: 4,
    currentPage: 1,
  };
  fetchingSocialAspects: boolean = false;
  fetchSocialAspectsForEachEcard() {
    this.fetchingSocialAspects = true;
    this.socialAspectService
      .getIndividualCardSocialAspectData(this.ecardIdFromUrl)
      .pipe(
        finalize(() => {
          this.fetchingSocialAspects = false;
        })
      )
      .subscribe(
        (res) => {
          this.ecardDetail.social = this.deepClone(res);

          // if the query has commentId then open the comments by default in both desktop and mobile views
          if (this.isLargerWidth && this.commentIdFromUrl) {
            this.showDesktopCommentsByDefault = true;
          }
          if (this.isSmallerWidth && this.commentIdFromUrl) {
            this.showMobileSocialAspects = true;
            this.showDesktopCommentsByDefault = false;
            this.loadLazyComponent(
              this.ecardDetail.social,
              this.ecardIdFromUrl
            );
            this.renderer.addClass(this.document.body, "hide-main-content");
          }
        },
        (error) => {},
        () => {}
      );
  }

  // start: mobile social aspect view logics
  // event emitter from social-aspects.component.ts - this event is fired when the "Comments with count" is clicked from the mobile view
  displayMobileSocialAspects(event) {
    this.toggleSocialAspectComponent(event);
  }

  loadLazyComponent(socialAspectData: any, ecardId: number) {
    const lazyComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        SocialAspectsMobileComponent
      );
    const lazyComponentRef =
      this.mobileSocialAspectContainer.createComponent(lazyComponentFactory);

    // Store the reference to the created component
    this.currentLazyComponentRef = lazyComponentRef;

    lazyComponentRef.instance.socialAspectData = socialAspectData;
    lazyComponentRef.instance.ecardId = ecardId;

    // Subscribe to the Output event of the Lazy Component
    lazyComponentRef.instance.displaySocialAspectMobileView.subscribe(
      (data: any) => {
        if (!data?.showMobileView) {
          this.toggleSocialAspectComponent(data); // when 'Back' is clicked from mobile view
        }
      }
    );
  }

  hideLazyComponent() {
    if (this.currentLazyComponentRef) {
      this.currentLazyComponentRef.destroy(); // Destroy the component to hide it
      this.currentLazyComponentRef = null; // Reset the reference
    }
  }

  toggleSocialAspectComponent(eventData: any) {
    if (eventData?.showMobileView) {
      this.showMobileSocialAspects = true;
      this.renderer.addClass(this.document.body, "hide-main-content");

      // get the scroll position of the main component, here it is the host component - to restore the same
      // previous position when the user gets back
      this.hostScrollPosition = window.scrollY;
      if (eventData.componentType === "SocialAspectComments") {
        this.loadLazyComponent(eventData?.socialAspectData, eventData?.ecardId);
      }

      // the mobile view should be positioned at top, otherwise the partial content of the mobile view will be hidden
      window.scrollTo(0, 0);
    } else {
      this.showMobileSocialAspects = false;
      this.renderer.removeClass(this.document.body, "hide-main-content");
      this.hideLazyComponent();

      // eventData?.showMobileView will be false, when the user clicks "Back" button from the mobile view.
      // when the user gets back, then previous position of the ecard should be restored.
      setTimeout(() => {
        window.scrollTo(0, this.hostScrollPosition);
      });
    }
  }
  // end: mobile social aspect view logics

  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  calculateNumberOfLinesForMessage() {
    const lineHeight = parseFloat(
      window.getComputedStyle(this.contentElement.nativeElement).lineHeight
    );
    const contentHeight = this.contentElement.nativeElement.offsetHeight;
    const lineCount =
      window.innerWidth >= 768 ? this.MAX_LINES_DESKTOP : this.MAX_LINES_MOBILE;
    const maxHeight = lineCount * lineHeight;

    this.ecardDetail = {
      ...this.ecardDetail, // Ensure immutability
      shouldShowSeeMore: contentHeight > maxHeight,
    };
  }

  @HostListener("window:resize")
  onResize() {
    const innerWidth = window.innerWidth;

    // for social aspect mobile - start
    // if the social aspect mobile view is already opened, then on reaching desktop view, close the component
    if (innerWidth > 767) {
      this.showMobileSocialAspects = false;
      this.hideLazyComponent();
    }
    // for social aspect mobile - end

    if (innerWidth < 768 && !this.isSmallerWidth) {
      this.isSmallerWidth = true;
      this.calculateNumberOfLinesForMessage();
      this.showDesktopCommentsByDefault = false;
      this.loadLazyComponent(this.ecardDetail?.social, this.ecardIdFromUrl);
      this.renderer.addClass(this.document.body, "hide-main-content");
    } else if (innerWidth >= 768 && !this.isLargerWidth) {
      this.isLargerWidth = true;
      this.calculateNumberOfLinesForMessage();
    }

    if (innerWidth >= 768 && this.isSmallerWidth) {
      this.isSmallerWidth = false;
    } else if (innerWidth < 768 && this.isLargerWidth) {
      this.isLargerWidth = false;
    }
  }

  checkQueryParams() {
    this.route.queryParams.subscribe((params) => {
      this.ecardIdFromUrl = params["ecard"] || null;
      this.commentIdFromUrl = params["commentId"] || null;
      this.replyIdFromUrl = params["replyId"] || null;
    });
  }

  showFullText() {
    this.ecardDetail.isFullText = true;
    this.ecardDetail.shouldShowSeeMore = false;
  }

  showLessText() {
    this.ecardDetail.isFullText = false;
    this.ecardDetail.shouldShowSeeMore = true;
  }
}
