<!-- List of reaction modal for desktop view: start -->
<span *ngIf="!isMobileView">
    <span class="custom-close-btn">
        <span (click)="closeReactionModal()">
            <svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="DASHBOARD-(Social-Design)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                stroke-linecap="round" stroke-linejoin="round">
                <g id="-Dashboard-/-Social-/-Emoges-Reaction-Section-2---Desk" transform="translate(-929.5, -286.5)"
                stroke="#212322" stroke-width="2">
                <g id="Popup-/-Emojis-Reactions" transform="translate(483, 266)">
                    <g id="x" transform="translate(446.5, 20.5)">
                    <line x1="9" y1="0" x2="0" y2="9" id="Path"></line>
                    <line x1="0" y1="0" x2="9" y2="9" id="Path"></line>
                    </g>
                </g>
                </g>
            </g>
            </svg>
        </span>
    </span>
</span>
<div *ngIf="!isMobileView">
    <h2>{{ 'Reactions' | translate }}</h2>
</div>
<div *ngIf="isMobileView" (click)="closeReactionModal()" class="reactionBack">
    <span>{{ 'Back' | translate }}</span>
</div>
<div class="reactionList">
    <ul ngbNav #nav="ngbNav" class="nav-tabs reactionlist-nav">
        <li ngbNavItem class="d-flex align-items-center" *ngIf="!showLikeOnly">
            <a ngbNavLink>{{ 'All' | translate }} {{data?.reactionCount}}</a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                    <div class="ps" style="position: relative; max-height: 400px;" [perfectScrollbar]="config">
                        <ul>
                            <ng-container *ngIf="(groupByReactionType?.SMILE )?.length">
                                <li *ngFor="let smileReactionObj of groupByReactionType?.SMILE" >
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/smile.svg" alt="smile-emoji">
                                {{smileReactionObj.reactedByName}}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="(groupByReactionType?.HEART )?.length">
                                <li *ngFor="let heartReactionObj of groupByReactionType?.HEART" >
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/heart.svg" alt="heart-emoji">
                                {{heartReactionObj.reactedByName}}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="(groupByReactionType?.LIKE )?.length">
                                <li *ngFor="let likeReactionObj of groupByReactionType?.LIKE" >
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/like.svg" alt="like-emoji">
                                {{likeReactionObj.reactedByName}}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="(groupByReactionType?.IDEA )?.length">
                                <li *ngFor="let ideaReactionObj of groupByReactionType?.IDEA" >
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/idea.svg" alt="idea-emoji">
                                {{ideaReactionObj.reactedByName}}
                                </li>
                            </ng-container>
                            <ng-container *ngIf="(groupByReactionType?.APPLAUSE )?.length">
                                <li *ngFor="let applauseReactionObj of groupByReactionType?.APPLAUSE" >
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/applause.svg" alt="applause-emoji">
                                {{applauseReactionObj.reactedByName}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-template>
        </li>
        <li *ngIf="(groupByReactionType?.HEART )?.length > 0 && !showLikeOnly" ngbNavItem>
            <a ngbNavLink>
                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/heart.svg" alt="">
                <span>{{(groupByReactionType?.HEART )?.length}}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                    <ul>
                        <li *ngFor="let heartReactionObj of groupByReactionType?.HEART">
                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/heart.svg" alt="heart-emoji">
                        {{heartReactionObj.reactedByName}}
                        </li>
                    </ul>
                </div>
            </ng-template>
        </li>
        <li *ngIf="(groupByReactionType?.LIKE )?.length > 0" ngbNavItem>
            <a ngbNavLink>
                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/like.svg" alt="">
                <span>{{(groupByReactionType?.LIKE )?.length}}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                <ul>
                    <li *ngFor="let likeReactionObj of groupByReactionType?.LIKE">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/like.svg" alt="like-emoji">
                    {{likeReactionObj.reactedByName}}
                    </li>
                </ul>                                     
                </div>
            </ng-template>
        </li>
        <li *ngIf="(groupByReactionType?.IDEA )?.length > 0 && !showLikeOnly" ngbNavItem>
            <a ngbNavLink>
                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/idea.svg" alt="">
                <span>{{(groupByReactionType?.IDEA )?.length}}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                <ul>
                    <li *ngFor="let ideaReactionObj of groupByReactionType?.IDEA">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/idea.svg" alt="idea-emoji">
                    {{ideaReactionObj.reactedByName}}
                    </li>
                </ul>
                </div>
            </ng-template>
        </li>
        <li *ngIf="(groupByReactionType?.SMILE )?.length > 0 && !showLikeOnly" ngbNavItem>
            <a ngbNavLink>
                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/smile.svg" alt="">
                <span>{{(groupByReactionType?.SMILE )?.length}}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                <ul>
                    <li *ngFor="let smileReactionObj of groupByReactionType?.SMILE">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/smile.svg" alt="smile-emoji">
                    {{smileReactionObj.reactedByName}}
                    </li>
                </ul>
                </div>
            </ng-template>
        </li>
        <li *ngIf="(groupByReactionType?.APPLAUSE )?.length > 0 && !showLikeOnly" ngbNavItem>
            <a ngbNavLink>
                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/applause.svg" alt="">
                <span>{{(groupByReactionType?.APPLAUSE )?.length}}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="reactionIndv-list">
                <ul>
                    <li *ngFor="let applauseReactionObj of groupByReactionType?.APPLAUSE">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/applause.svg" alt="applause-emoji">
                    {{applauseReactionObj.reactedByName}}
                    </li>
                </ul>
                </div>
            </ng-template>
        </li>
        
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>