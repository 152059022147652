import { Component, OnInit } from '@angular/core';
import { EyecareService } from '../../hr/eyecare/eyecare-service/eyecare-service.service';
import {SpecsaverEyecareService} from 'src/app/core/service/eyecare.service'
import { AuthService } from 'src/app/core/service/auth.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import { Router } from '@angular/router';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-specsavers-employee-form-summary',
  templateUrl: './specsavers-employee-form-summary.component.html',
  styleUrls: ['./specsavers-employee-form-summary.component.sass']
})
export class SpecsaversEmployeeFormSummaryComponent implements OnInit {
 firstName:string
 surname:string
 email:string
 workWithVDU:boolean

  constructor(
    private eyecareService:EyecareService,
    private eyecareApiService:SpecsaverEyecareService,
    private authService:AuthService,
    private router: Router,
    private toastr: ToastrCustomMessageService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.email = this.eyecareService.employeeData.email
    this.surname = this.eyecareService.employeeData.surname
    this.firstName = this.eyecareService.employeeData.firstName
    this.workWithVDU = this.eyecareService.employeeData.workWithVDU
  }

  submitForm(){
    const data = {
      firstName:this.firstName,
      lastName:this.surname,
      userId:this.authService.currentUserValue.id,
      requestedEmail:this.email,
      isWorkWithVDU:this.workWithVDU
    }
    this.eyecareApiService.submitEmployeeEyecareForm(data).subscribe(
      response => {
        this.eyecareService.clearEmployeeData();
        this.router.navigate(['/eyecare-employee-success']);
      }, error => {
        error.errors ? this.toastr.error(error.errors[0].message) : this.toastr.error(this.translate.instant(extractTsText('Unable to submit the details.')));;
      }
    );
  }
}
