// benefits.service.ts
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BenefitData } from "../models/benefit.model";
import { AuthService } from "src/app/core/service/auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BenefitsService {
  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) {
  }

  private getApiUrl(): string {
    const partnerId = this.authService.currentUserValue?.partnerId;
    return `${ environment.apiUrl }/benefits/fetchBenefits?partnerId=${partnerId}`;
  }

  fetchBenefits(): Observable<BenefitData[]> {
    const apiUrl = this.getApiUrl();
    return this.http.get<BenefitData[]>(apiUrl);
  }

  getBenefitDetail(benefitId: number) {
    return this.http.get<any>(`${ environment.apiUrl }/benefits/${ benefitId }`);
  }

  addBenefit(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/benefits/addBenefit`, payload);
  }

  updateBenefit(benefitId: number, payload: any) {
    return this.http.put<any>(`${ environment.apiUrl }/benefits/editBenefit/${ benefitId }`, payload);
  }

  deleteBenefit(benefitId: number) {
    return this.http.delete<any>(`${ environment.apiUrl }/benefits/delete/${ benefitId }`);
  }

  getBenefitFilters() {
    return this.http.get<any[]>(`${ environment.apiUrl }/benefits/fetchBenefitFilters?partnerId=${ this.authService.currentUserValue?.partnerId }`);
  }

  addNewFilter(filter: any): Observable<any> {
    const url = `${ environment.apiUrl }/benefits/addBenefitFilter`;

    return this.http.post<any>(url, filter);
  }

  saveFilter(filterId: number, payload: any) {
    return this.http.put<any>(`${ environment.apiUrl }/benefits/editBenefitFilter/${ filterId }`, payload);
  }

  deleteFilter(filterId: number) {
    return this.http.delete<any>(`${ environment.apiUrl }/benefits/benefitFilter/${ filterId }`);
  }

  uploadBenefitIcon(formData: FormData) {
    return this.http.post<any>(`${ environment.apiUrl }/image/benefits`, formData);
  }
}
