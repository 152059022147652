import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { groupByReactionTypeFunc } from 'src/app/core/_helpers/global-functions';
import { staticReactions } from '../social-aspects-reactions';
import { Reaction } from 'src/app/core/models/social-aspect.model';

@Component({
  selector: 'app-social-aspect-reaction-modal',
  templateUrl: './social-aspect-reaction-modal.component.html',
  styleUrls: ['./social-aspect-reaction-modal.component.sass']
})
export class SocialAspectReactionModalComponent implements OnInit {
  @ViewChild('reactionsModal') reactionsModal: TemplateRef<any>;
  @Input() data: any = null;
  @Input() currentUserId = null;
  @Input() isMobileView: boolean;
  @Input() showLikeOnly: boolean = false;
  

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.formatUserReactionFromAPI();
  } 
  

  closeReactionModal(){
    this.modalService.dismissAll();
  }

  // formatUserReaction
  groupByReactionType = null;
  userReactionIcon: string | null;
  userReaction: Reaction | null;
  matchingReaction: any;
  formatUserReactionFromAPI() {
    this.groupByReactionType = null;

    this.userReactionIcon = this.getUserReactionIcon(this.data, this.currentUserId, staticReactions);
    this.groupByReactionType = groupByReactionTypeFunc(this.data.reactions);
  }

  getUserReactionIcon(socialAspectData, currentUserID, defaultReactions) {
    // Find the user's reaction

    // since no optimistic value is used, reset every data 
    // so that new data will be replaced after fetching social aspect API.
    // this.userReaction = this.userReaction || socialAspectData.reactions.find(reaction => reaction.reactedById === currentUserID); // when optimistic update is done 
    this.userReaction = socialAspectData.reactions.find(reaction => reaction.reactedById === currentUserID);
    // If a user reaction was found, find the corresponding icon
    if (this.userReaction) {
         this.matchingReaction = defaultReactions.find(reaction => reaction.name === this.userReaction.reactionType);
        return this.matchingReaction ? this.matchingReaction.name==="LIKE" ? "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/liked.svg" : this.matchingReaction.icon : null;
    }
    // If no user reaction was found, return null
    return null;
  }

}
