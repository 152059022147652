import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
// used example: https://stackblitz.com/github/theoklitosBam7/ng-unsubscribe-examples/tree/5-destroy-service?file=src%2Fapp%2Ffirst%2Ffirst.component.ts
// https://coder-question.com/cq-blog/640201
export class DestroyService extends Subject<void> implements OnDestroy {
  ngOnDestroy() {
    this.next();
    this.complete();
  }
}
