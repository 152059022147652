import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { objToQueryString } from 'src/app/core/_helpers/global-functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MentionService {

  constructor(
    private http: HttpClient
  ) { }

  // get mentioned users and text from API
  getMentionedText(text: string, mentions: any) {
    if(!mentions || mentions?.length === 0) {
      return null
    }

    // replace the ids to names - in order to show in the textarea and also highlight the mentioned users
    mentions.forEach((eachChoice) => {
      if(text.includes(eachChoice.name)) {
        text = text.replace(`@${eachChoice.name}`, `<span class="user-mentions">@${eachChoice.name}</span>`)
      }
    })

    return text.replace(/\n/g, '<br>');
  }

  getUserListForMentions(term: string) {
    return this.http.get<any>(`${environment.apiUrl}/ecards/users?search=${encodeURIComponent(term)}`).pipe(
      map((response) => {
        let finalUsers = [];
        if (response?.length > 0) {
          response?.forEach((eachUser: any) => {
            let userDetail = {
              id: eachUser?.uuid,
              name: eachUser?.name || eachUser?.email
            };
            finalUsers.push(userDetail);
          });
        }
        return finalUsers; // Return the transformed data as an observable
      })
    );
  }
}
