import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// https://iat-technology.atlassian.net/jira/software/c/projects/WS/boards/133?search=notifica&selectedIssue=WS-1081

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private mutedNotifArray = new BehaviorSubject<any>(null);
  public mutedNotifArray$ = this.mutedNotifArray.asObservable();

  constructor(private http: HttpClient) { }

  fetchNotifications(params?: any){
    return this.http.get<any>(`${environment.apiUrl}/notifications`, {params});
  }
  fetchNotificationMutedStatus(){
    return this.http.get<any>(`${environment.apiUrl}/notifications/muted-notification-detail`)
    .pipe(map(res => {
      this.mutedNotifArray.next(res);
      return res;
    }));
  }
  exportNotificationFile(name){
    return this.http.get<any>(`${environment.apiUrl}/ecards/export?key=${name}`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  // Change Notification status from unread to read
  changeNotificationStatus(notificationId: number) {
    return this.http.put<any>(`${environment.apiUrl}/notifications/${notificationId}`, null);
  }

  // call API on notification bell icon click and make the status viewed. This will reset the count
  updateNotificationViewStatus() {
    return this.http.put<any>(`${environment.apiUrl}/notifications/updateViewedNotificationStatus`, null);
  }

  // get the count of unviewed notifications
  getNotificationCount(parentNotificationType: string) {
    let params = {
      parentNotificationType
    };
    return this.http.get<any>(`${environment.apiUrl}/notifications/notification-count`, {params});
  }

  // mute all notifciations to not receive notifications
  muteAllNotifications() {
    return this.http.post<any>(`${environment.apiUrl}/notifications/muteAll`, null);
  }

  // unmute all notifciations to receive notifications
  unmuteAllNotifications() {
    return this.http.delete<any>(`${environment.apiUrl}/notifications/unMuteAll`);
  }

  // turn on specific type of notification
  turnOnSpecificNotification(notificationType: string) {
    return this.http.delete<any>(`${environment.apiUrl}/notifications/unMuteSpecificType?notificationType=${notificationType}`);
  }

  // turn off specific type of notification
  turnOffSpecificNotification(notificationType: string) { 
    return this.http.post<any>(`${environment.apiUrl}/notifications/muteSpecificType?notificationType=${notificationType}`, null);
  }

  deleteSpecificNotification(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/notifications/${id}`);
  }

  undoDeleteNotification(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/notifications/undoDelete/${id}`);
  }

}
