import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../service/auth.service';
import {takeUntil} from "rxjs/operators";
import {enableAPIRoutes} from "../_helpers/environment-variables";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class APIdocumentationEnableGuard implements CanActivate {
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((obs) => {
      this.authService.currentUser
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          if (user) {
            let enabled = enableAPIRoutes.find((client) => client == user?.companyShortName)
            obs.next((!!enabled))
            if (!enabled) this.router.navigateByUrl("/hr/dashboard").then(() => {this.spinner.hide()});
          } else {
            obs.next(false);
          }
        });
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
