import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { User } from '../core/models/user';
import { AuthService } from '../core/service/auth.service';
import { DestroyService } from '../core/service/destroy.service';

declare var LiveAgent: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.sass'],
  providers: [DestroyService]
})
export class ContactUsComponent implements OnInit, OnDestroy {
  currentUser: User;

  private scriptElement: HTMLScriptElement | null = null; // Reference to the script element
  contactUsId = window.location.hostname === 'admin.eachperson.com' ? 'w95ehwl8' : '3dfjh51b';
  chatUrl = window.location.hostname === 'admin.eachperson.com' ? 'https://iat-ltd.ladesk.com/scripts/track.js' : 'https://iat-ltd.ladesk.com/scripts/track.js';
  loading: boolean = false;

  constructor(
    private readonly destroy$: DestroyService,
    private renderer: Renderer2,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const prev = this.authService.currentUserValue?.userLanguageCode;
    this.loadLAContactformScript();
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(userdetail => {
      this.currentUser = userdetail;
      const newLang = userdetail?.userLanguageCode;
      
      if(newLang !== prev) {
        this.loading = true;
        this.removeLiveAgentContactFormScript();
        setTimeout(() => {
          this.restoreChatForm(); // Ensure chatForm element is available before creating the form
          this.loadLAContactformScript();
        }, 300);
      }
    });
  }

  loadLAContactformScript(): void {
    if (!document.querySelector('script[data-la-contactForm-script="true"]')) {  // Check if the script is already loaded
      // Create a new script element
      this.scriptElement = document.createElement('script');
      this.scriptElement.id = 'la_x2s6df8d';
      this.scriptElement.defer = true;
      this.scriptElement.src = this.chatUrl;
      this.scriptElement.setAttribute("data-la-contactForm-script", "true");

      // Set up the onload event listener
      let self = this;
      this.scriptElement.onload = () => {
        this.initializeLiveAgent();
        this.loading = false;
      };

      // Append the script to the document
      document.getElementsByTagName('head')[0].appendChild(this.scriptElement);
    }
  }

  initializeLiveAgent(): void {
    const contactFormElement = document.getElementById("chatForm");  // Check for the element in the DOM
    if (contactFormElement && typeof LiveAgent !== 'undefined') {
      LiveAgent.createForm(this.contactUsId, contactFormElement);  // Initialize the LiveAgent button
      // console.log('LiveAgent contact frorm created');
    } else {
      // console.error("Chat form element or LiveAgent is missing");
    }
  }

  removeLiveAgentContactFormScript(): void {
    const existingScript = document.querySelector('script[data-la-contactForm-script="true"]');
    if (existingScript) {
      this.renderer.removeChild(document.head, existingScript);
      this.scriptElement = null;
    }

    // remove chatbutton
    const divElements = document.querySelectorAll('div');
    divElements.forEach(element => {
      if (element.id.includes(this.contactUsId)) { // Check if the id contains laChatId
        element.remove();
      }
    });
    
    // remove iframes
    const iframeElements = document.querySelectorAll('iframe');
    iframeElements.forEach(element => {
      if (element.id.includes(this.contactUsId)) { // Check if the id contains laChatId
        element.remove();
      }
    });
    
    const styles = document.querySelectorAll('style');
    styles.forEach(style => {
      if (style.textContent.includes(this.contactUsId)) {
        style.remove();
      }
    });
  }

  restoreChatForm() {
    const formWrapper = document.querySelector('.form-wrapper'); // Target the form-wrapper div
    let chatForm = document.getElementById('chatForm'); // Check if chatForm already exists
  
    if (!chatForm && formWrapper) { // If chatForm does not exist and formWrapper is found
      chatForm = document.createElement('div');
      chatForm.id = 'chatForm';
      chatForm.className = 'test'; // Add class 'test' to the new chatForm
  
      formWrapper.appendChild(chatForm); // Append the chatForm inside the form-wrapper
    } else if (!formWrapper) {
      console.error('Form wrapper not found.');
    }
  }
  

  ngOnDestroy(): void {
    this.removeLiveAgentContactFormScript(); // Clean up the script on component destroy
  }
}
