<form class="form" [formGroup]="forceChangePassword" (ngSubmit)="submitForceChangePassword()" autocomplete="off">
  <div class="logo text-center mb-4">
    <img width="180px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"/>
  </div>
  <h5 class="text-center mb-3">Change Password</h5>
  <p class="font-weight-normal text-center">Please enter your new password below.</p>
  <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>


  <label for="signInFormPassword" class="label-customizable mt-3 mb-1">New Password</label>
  <div class="customised-password">
    <input id="signInFormPassword" (ngModelChange)="errorMessage = null" name="password" [type]="showPassword ? 'text':'password'"
           class="form-control inputField-customizable" placeholder="Password" formControlName="password"
           [ngClass]="{ 'is-invalid' : !forceChangePassword.get('password').valid && (forceChangePassword.get('password').touched || forceChangePassword.get('password').dirty) }">
    <i-feather [name]="showPassword ? 'eye':'eye-off'" class="text-secondary icon-sm customise-icon" (click)="changeKeyType('pass')"></i-feather>
  </div>


  <label for="signInFormConfirmPassword" class="label-customizable mt-3 mb-1">Enter New Password Again</label>
  <div class="customised-password mb-2">
    <input id="signInFormConfirmPassword" (ngModelChange)="errorMessage = null" name="password" [type]="showConfirmPassword ? 'text':'password'"
           class="form-control inputField-customizable padding-right mb-1" formControlName="confirmPassword"
           placeholder="Confirm Password"
           [ngClass]="{ 'is-invalid' : !forceChangePassword.get('confirmPassword').valid && (forceChangePassword.get('confirmPassword').touched || forceChangePassword.get('confirmPassword').dirty) }">
    <i-feather [name]="showConfirmPassword ? 'eye':'eye-off'" class="text-secondary icon-sm customise-icon" (click)="changeKeyType('cPass')"></i-feather>
  </div>

  <div *ngIf="forceChangePassword.controls['password'].dirty">
    <label [ngClass]="errorCatcher('hasSmallCase','password')  ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm" [name]="errorCatcher('hasSmallCase','password') ? 'X' :'check'"></i-feather>
      <small
        class="font-weight-normal alert-text-inner">Password
        must contain a lower case letter</small>
    </label>
    <label [ngClass]="errorCatcher('hasCapitalCase','password')  ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm" [name]="errorCatcher('hasCapitalCase','password') ? 'X' :'check'"></i-feather>
      <small
        class="font-weight-normal alert-text-inner">
        Password must contain an upper case letter</small>
    </label>
    <label [ngClass]="errorCatcher('hasNumber','password')  ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm" [name]="errorCatcher('hasNumber','password') ? 'X' :'check'"></i-feather>
      <small
        class="font-weight-normal alert-text-inner">
        Password must contain a number</small>
    </label>
    <label [ngClass]="errorCatcher('minlength','password')  ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm" [name]="errorCatcher('minlength','password') ? 'X' :'check'"></i-feather>
      <small
        class="font-weight-normal alert-text-inner">
        Password must contain at least 8 characters</small>
    </label>
    <label [ngClass]="errorCatcher('hasSpecialCharacters','password')  ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm" [name]="errorCatcher('hasSpecialCharacters','password') ? 'X' :'check'"></i-feather>
      <small
        class="font-weight-normal alert-text-inner">
        Password must contain a special characters</small>
    </label>
    <label [ngClass]="errorCatcher('mustMatch','confirmPassword') ? 'text-danger' : 'text-success'">
      <i-feather class="icon-sm"
                 [name]="errorCatcher('mustMatch','confirmPassword') ?
                           'X' : 'check' "></i-feather>
      <small class="font-weight-normal alert-text-inner">Passwords must match</small>
    </label>
  </div>

  <input name="signInSubmitButton" type="Submit" value="Change password"
         class="btn btn-primary submitButton-customizable" aria-label="submit"
         [disabled]="!forceChangePassword.valid || loginSubmitting" >
  <div class="logo text-center mt-3">
    <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise"/><span> @ Each
                    Person {{ currentYear }}</span>
  </div>
  <div class="logo text-center">
    Powered by <img width="38px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png"/>
  </div>
</form>

