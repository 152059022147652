<section class="main-content activity">
    <div class="payment-sub-header-binder setup-completed">
        <p class="mb-3 font-weight-normal text-left"><a routerLink="/benefits" class="text-dark"><strong>{{ 'Back' | translate }}</strong></a> <span
                class="d-lg-inline-block ml-2">{{ 'Benefits' | translate }} / {{ isEditMode ? ('Edit' | translate) : ('Create' | translate) }}</span></p>
        <h4 class="page-title mt-md-1 mb-1">{{ isEditMode ? ('Edit Benefit' | translate) : ('Create Benefit' | translate) }}</h4>
        <p class="mb-2 mb-sm-0 font-16 font-weight-normal">{{ 'Create a tailored benefit for your company' | translate }}</p>
    </div>
    <div class="section-body">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="benefitsForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label class="info-group">{{ 'Title' | translate }} <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="title"
                                    [placeholder]="'e.g. Salary Sacrifice: Childcare' | translate" (keyup)="updatePreview('title')"
                                    [ngClass]="{
                                        'is-invalid':
                                        !benefitsForm.get('title').valid &&
                                        (benefitsForm.get('title').touched || benefitsForm.get('title').dirty) &&
                                        benefitsForm.get('title').errors
                                      }">
                                <div class="d-flex mt-1">
                                    <small *ngIf="!benefitsForm.get('title').valid &&
                                                                    (benefitsForm.get('title').touched || benefitsForm.get('title').dirty) &&
                                                                    benefitsForm.get('title').errors?.required" class="form-text text-danger">
                                        {{ 'Please enter a benefit title.' | translate }}
                                    </small>
                                    <small class="form-text font-12 font-weight-normal ml-auto"
                                        [ngClass]="{'text-dark': !benefitsForm.get('title').errors?.maxlength, 'text-danger': benefitsForm.get('title').touched && benefitsForm.get('title').errors?.maxlength}">{{ '(Max 30 characters)' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="info-group">{{ 'Icon URL' | translate }} <span class="text-danger">*</span></label>
                                
                                <div class="custom-dropzone" [ngClass]="{'hasImage': benefitsForm.get('iconURL').value}"
                                    ngx-dropzone [accept]="'.jpg, .gif, .png, .jpeg, .jfif, .jpe, .svg'"
                                    (change)="onFileUpload($event)" [multiple]="false" [disabled]="imageUploading"
                                    [disableClick]="disableFileExplorer">
                                    <ngx-dropzone-label>
                                        <div *ngIf="!imageUploading && !benefitsForm.get('iconURL').value"
                                            class="text-center pt-4 pb-4">
                                            <div class="text-center mb-3">
                                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/benefits/camera-sm.svg"
                                                    width="25">
                                            </div>
                                            <label class="mb-0 text-dark" style="pointer-events: none;">{{ 'Drop files here or click to upload' | translate }} <button
                                                    type="button" class="btn btn-tooltip tooltip-hover"
                                                    data-bs-toggle="tooltip" [ngbTooltip]="iconHelperTooltip | translate" style="pointer-events: all;">
                                                    <svg class="mt-n2" width="18.2857143px" height="18.2857143px"
                                                        viewBox="0 0 18.2857143 18.2857143" version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g id="BENEFITS-(FRAN)" stroke="none" stroke-width="1"
                                                            fill="none" fill-rule="evenodd">
                                                            <g id="Dashboard-/-Benefits---Creating-a-Benefit---Desk"
                                                                transform="translate(-651.8571, -475.8571)">
                                                                <rect fill="#FFFFFF" x="0" y="0" width="1440"
                                                                    height="1728"></rect>
                                                                <g id="Group" transform="translate(456, 473)"
                                                                    stroke="#000000" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2">
                                                                    <g id="Icons-/-Info"
                                                                        transform="translate(196.8571, 3.8571)">
                                                                        <g id="info" transform="translate(0, -0)">
                                                                            <circle id="Oval" cx="8.14285714"
                                                                                cy="8.14285714" r="8.14285714"></circle>
                                                                            <line x1="8.14285714" y1="11.1428571"
                                                                                x2="8.14285714" y2="7.71428571"
                                                                                id="Path"></line>
                                                                            <line x1="8.13857143" y1="4.71428571"
                                                                                x2="8.14714286" y2="4.71428571"
                                                                                id="Path"></line>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button></label>
                                        </div>
                                        <div class="spinner-border" *ngIf="imageUploading"></div>
                                        <div class="col-12 p-0 d-flex align-items-center"
                                            *ngIf="!imageUploading && benefitsForm.get('iconURL').value">
                                            <div class="dropzone-image-preview">
                                                <div class="custom-dropzone-preview ecards">
                                                    <img [src]="benefitsForm.get('iconURL').value" />
                                                </div>
                                                <div class="preview-caption d-flex mt-2">
                                                    <div class="ecard-caption float-left text-left">
                                                        <span *ngFor="let f of files"
                                                            class="font-weight-bold">{{f.name}}</span>
                                                        <p class="mb-0">{{ 'Click here to replace file' | translate }}</p>
                                                    </div>
                                                    <div class="float-right">
                                                        <button type="button" class="btn btn-secondary btn-remove"
                                                            (click)="onRemove()">{{ 'Remove' | translate }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ngx-dropzone-label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="info-group">{{ 'Preview Text' | translate }} <span class="text-danger">*</span></label>
                                <textarea class="form-control" formControlName="previewText"
                                    [placeholder]="'Start writing your preview text' | translate"
                                    (keyup)="onKeyup('previewText'); updatePreview('previewText')"
                                    [ngClass]="{
                                        'is-invalid':
                                        !benefitsForm.get('previewText').valid &&
                                        (benefitsForm.get('previewText').touched || benefitsForm.get('previewText').dirty) &&
                                        benefitsForm.get('previewText').errors
                                      }"></textarea>
                                <div class="d-flex mt-1">
                                    <small class="form-text text-danger"
                                        *ngIf="!benefitsForm.get('previewText').valid &&
                                        (benefitsForm.get('previewText').touched || benefitsForm.get('previewText').dirty) &&
                                        benefitsForm.get('previewText').errors?.required">{{ 'Please enter a preview message.' | translate }}</small>
                                    <small class="form-text font-12 font-weight-normal ml-auto"
                                        [ngClass]="{'text-dark': !benefitsForm.get('previewText').errors?.maxlength, 'text-danger': benefitsForm.get('previewText').touched && benefitsForm.get('previewText').errors?.maxlength}">{{ '(Max 100 characters)' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="info-group">{{ 'Long Description' | translate }} <span class="text-danger">*</span></label>
                                <textarea class="form-control tall" formControlName="longDescription"
                                    [placeholder]="'Start writing your long description text' | translate"
                                    (keyup)="onKeyup('longDescription'); updatePreview('longDescription')"
                                    [ngClass]="{
                                    'is-invalid':
                                    !benefitsForm.get('longDescription').valid &&
                                    (benefitsForm.get('longDescription').touched || benefitsForm.get('longDescription').dirty) &&
                                    benefitsForm.get('longDescription').errors
                                    }"></textarea>
                                <div class="d-flex mt-1">
                                    <small class="form-text text-danger"
                                        *ngIf="!benefitsForm.get('longDescription').valid &&
                                        (benefitsForm.get('longDescription').touched || benefitsForm.get('longDescription').dirty) &&
                                        benefitsForm.get('longDescription').errors?.required">{{ 'Please enter a long description.' | translate }}</small>
                                    <small class="form-text font-12 font-weight-normal ml-auto"
                                        [ngClass]="{'text-dark': !benefitsForm.get('longDescription').errors?.maxlength, 'text-danger': benefitsForm.get('longDescription').touched && benefitsForm.get('longDescription').errors?.maxlength}">{{ '(Max 1000 characters)' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="info-group">{{ 'Button Text' | translate }}
                                    <!-- on mobile/tablet view -->
                                    <button type="button" class="btn btn-tooltip tooltip-click" [ngbTooltip]="buttonLabelTooltip | translate" triggers="click:blur">
                                        <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                    <!-- on desktop view -->
                                    <button type="button" class="btn btn-tooltip tooltip-hover" data-bs-toggle="tooltip"
                                        [ngbTooltip]="buttonLabelTooltip | translate">
                                        <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="buttonText"
                                    (keyup)="onKeyup('buttonText'); updatePreview('buttonText')"
                                    [ngClass]="{
                                        'is-invalid':
                                        !benefitsForm.get('buttonText').valid &&
                                        benefitsForm.get('buttonText').errors
                                      }">
                                <div class="d-flex mt-1">
                                    <small class="form-text text-danger"
                                        *ngIf="!benefitsForm.get('buttonText').valid &&
                                        benefitsForm.get('buttonText').errors?.buttonTextRequired">{{ 'Please enter a button text if the button URL is added.' | translate }}</small>
                                    <small class="form-text font-12 font-weight-normal ml-auto"
                                        [ngClass]="{'text-dark': !benefitsForm.get('buttonText').errors?.maxlength, 'text-danger': !benefitsForm.get('buttonText').valid &&
                                        (benefitsForm.get('buttonText').touched || benefitsForm.get('buttonText').dirty) &&
                                        benefitsForm.get('buttonText').errors?.maxlength}">{{ '(Max 30 characters)' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="info-group">{{ 'Button URL' | translate }}
                                    <!-- on mobile/tablet view -->
                                    <button type="button" class="btn btn-tooltip tooltip-click" [ngbTooltip]="buttonUrlTooltip | translate" triggers="click:blur">
                                        <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                    <!-- on desktop view -->
                                    <button type="button" class="btn btn-tooltip tooltip-hover" data-bs-toggle="tooltip"
                                        [ngbTooltip]="buttonUrlTooltip | translate">
                                        <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="buttonURL" (keyup)="updatePreview('buttonURL')" [ngClass]="{
                                                                    'is-invalid':
                                                                    !benefitsForm.get('buttonURL').valid &&
                                                                    benefitsForm.get('buttonURL').errors
                                                                  }">
                                <small *ngIf="!benefitsForm.get('buttonURL').valid &&
                                                                (benefitsForm.get('buttonURL').touched || benefitsForm.get('buttonURL').dirty) &&
                                                                benefitsForm.get('buttonURL').errors && benefitsForm.get('buttonURL').hasError('invalidUrl')" class="form-text text-danger">
                                    {{ 'Please enter a button URL.' | translate }}
                                </small>
                                <small *ngIf="!benefitsForm.get('buttonURL').valid &&
                                                                benefitsForm.get('buttonURL').errors && benefitsForm.get('buttonURL').hasError('buttonUrlRequired')" class="form-text text-danger">
                                    {{ 'Please enter a button URL if button text is added.' | translate }}
                                </small>
                            </div>
                            <div class="form-group benefit-filter">
                                <div class="info-group">{{ 'Benefit Filter' | translate }} <span class="text-danger">*</span>
                                    <!-- on mobile/tablet view -->
                                    <button type="button" class="btn btn-tooltip tooltip-click" [ngbTooltip]="filterLabelTooltip | translate" triggers="click:blur">
                                        <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                    <!-- on desktop view -->
                                    <button type="button" class="btn btn-tooltip tooltip-hover" data-bs-toggle="tooltip" [ngbTooltip]="filterLabelTooltip | translate">
                                    <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                    </button>
                                </div>
                                <ng-select #filterSelect [items]="benefitFilters" bindLabel="filterValue" [placeholder]="newFilterPlaceholder | translate"
                                    formControlName="filterValue" [loading]="loading" notFoundText="No filters found"
                                    (change)="onFilterChange($event)" (close)="onDropdownClose()">
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <div class="custom-dropdown-item">
                                            <div class="d-flex justify-content-between filter-row align-items-center">
                                                <div class="flex-fill">
                                                    <div class="filter-value" *ngIf="!item.isEditing">
                                                        {{ item.filterValue }} ({{ item.usageCount }})
                                                        <button *ngIf="item.filterType !== 'DEFAULT'" type="button" class="btn btn-tooltip tooltip-hover" [ngbTooltip]="filterInfoTooltip | translate" triggers="hover" container="body">
                                                            <i-feather name="help-circle" class="tooltip-icon"></i-feather>
                                                        </button>
                                                    </div>
                                                    <input id="filterItem{{item.filterId}}" class="edit-filter-input" *ngIf="item.isEditing" type="text" [value]="item.editInputValue" (click)="preventSelection($event)" (input)="$event.stopPropagation(); item.editInputValue = $event.target.value;" (keydown)="handleKeydown($event, item)">
                                                </div>
                                                <div class="filter-button-area">
                                                    <a *ngIf="!item.isEditing && item.id !== 'new' && item.filterType !== 'DEFAULT'" (click)="editFilter(item, $event)"><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/benefits/edit-icon.svg"></a>
                                                    <a *ngIf="item.isEditing" (click)="saveFilter(item, $event)">{{ 'Save' | translate }}</a>
                                                    <a *ngIf="item.isEditing" class="text-danger" (click)="deleteFilter(item, $event)">{{ 'Delete' | translate }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-footer-tmp>
                                        <a (click)="onNewClick()">{{ 'New' | translate }} +</a>
                                    </ng-template>
                                </ng-select>

                                <div *ngIf="showNewFilterInput">
                                    <input type="text" id="newFilterValue" formControlName="newFilterValue"
                                        class="form-control mt-2" [placeholder]="'Type to create your new filter' | translate"
                                        [ngClass]="{ 'is-invalid' : benefitsForm.get('newFilterValue').invalid && showNewFilterInput }" />
                                </div>
                                <small
                                    *ngIf="benefitsForm.get('filterValue').touched && benefitsForm.get('filterValue').invalid && !showNewFilterInput"
                                    class="form-text text-danger">
                                    {{ 'Please select or enter a new benefit filter.' | translate }}
                                </small>

                                <small *ngIf="benefitsForm.get('newFilterValue').invalid && showNewFilterInput"
                                    class="form-text text-danger">
                                    {{ 'Please enter a new benefit filter.' | translate }}
                                </small>
                            </div>
                            <div class="nominate-btn">
                                <button *ngIf="isEditMode" type="button"
                                    class="btn btn-secondary d-none d-lg-inline-block d-md-inline-block btn-nominate mr-md-2"
                                    (click)="deleteBenefit(benefit)">{{ 'Delete' | translate }}</button>
                                <button *ngIf="!isEditMode" type="button"
                                    class="btn btn-secondary d-none d-lg-inline-block d-md-inline-block btn-nominate mr-md-2"
                                    (click)="cancelBenefit()">{{ 'Cancel' | translate }}</button>
                                <button type="submit" class="btn btn-primary btn-nominate" [disabled]="benefitsForm.invalid || formSubmitting">{{ 'Publish' | translate }}</button>
                                <button *ngIf="isEditMode" type="button"
                                    class="btn btn-secondary btn-nominate d-lg-none d-md-none d-inline-block"
                                    (click)="deleteBenefit(benefit)">{{ 'Delete' | translate }}</button>
                                <button *ngIf="!isEditMode" type="button"
                                    class="btn btn-secondary btn-nominate d-lg-none d-md-none d-inline-block"
                                    (click)="cancelBenefit()">{{ 'Cancel' | translate }}</button>
                                <button type="button"
                                    class="btn btn-secondary btn-nominate d-lg-none d-md-none d-inline-block" (click)="openPreviewModal()">{{ 'Preview' | translate }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Preview Section -->
            <div #previewSection class="col-12 col-md-6 col-lg-6 pl-lg-0 pl-md-0 Preview-section">
                <div class="benefits-preview">
                    <h4 class="page-title nominate pt-0 mb-2">{{ 'Preview' | translate }}</h4>
                    <div class="card nominate">
                        <div class="card-body px-3">
                            <div class="d-flex align-items-sm-end align-items-start">
                                <img [src]="previewData?.iconURL || 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/benefits/camera.svg'"
                                    class="mb-3">
                                <div class="pl-3">
                                    <div class="benefit-title mb-1">{{ previewData?.title || ('Salary Sacrifice: Childcare' | translate) }}
                                    </div>
                                    <p class="">
                                        {{ previewData?.previewText || ('Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei' | translate) }}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p class="whitespace-pre-line">
                                    {{ previewData?.longDescription || ('Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat eiLorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi' | translate ) }}
                                </p>
                            </div>
                            <div class="benefit-button" *ngIf="previewData?.buttonText">
                                <button class="btn btn-secondary px-5 mt-2 font-16">{{ previewData?.buttonText || ('Button Name' | translate) }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--  start: modal for mobile preview-->
<ng-template #mobilePreviewModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="page-title">&nbsp;</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body px-4 pb-4 py-0">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 pl-lg-0 pl-md-0">
                <div class="benefits-preview mb-0">
                    <div class="card nominate">
                        <div class="card-body px-3 py-0">
                            <div class="d-flex align-items-end">
                                <img [src]="previewData?.iconURL || 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/benefits/camera.svg'"
                                    class="mb-3">
                                <div class="pl-3">
                                    <div class="benefit-title mb-1 font-weight-bold">{{ previewData?.title || ('Salary Sacrifice: Childcare' | translate) }}
                                    </div>
                                    <p class="">
                                        {{ previewData?.previewText || ('Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei' | translate) }}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p class="whitespace-pre-line">
                                    {{ previewData?.longDescription || ('Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat eiLorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi intellegebat ei Lorem ipsum dolor sit amet, nec reque dicta concludaturque ad, harum mundi' | translate) }}
                                </p>
                            </div>                            

                            <div class="d-flex justify-content-center mt-4 mb-3" *ngIf="previewData?.buttonText">
                                <div class="d-flex flex-md-row flex-column">
                                    <div>
                                        <button class="btn btn-secondary btn-length-md mr-2">{{ previewData?.buttonText || ('Button Name' | translate) }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>