import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _SidebarMobileDetail = new BehaviorSubject<any>(false);
  public SidebarMobileView$ = this._SidebarMobileDetail.asObservable();

  private _companyLogo = new BehaviorSubject<any>(null);
  public companyLogoUrl$ = this._companyLogo.asObservable();

  private _sfsDetail = new BehaviorSubject<any>({
    isEligibleforSFSActive: false,
    sfsRoute: '/hr/save-from-salary/information'
  });
  public sfsDetail$ = this._sfsDetail.asObservable();

  constructor() { }

  updateSidebarMobileView(status: boolean) {
    this._SidebarMobileDetail.next(status)
  }

  updateSfsDetail(status: boolean, route: string) {
    let detail = {
      isEligibleforSFSActive: status,
      sfsRoute: route
    }

    this._sfsDetail.next(detail);
  }

  updateCompanyLogo(url: string) {
    this._companyLogo.next(url);
  }
}
