<div class="card d-block d-md-none" [ngClass]="styleClass">
    <!-- <div class="section-title m-0">
      <h4 class="pt-3 text-center text-lg-left"[translate]="'dashboard_greeting'" [translateParams]="{ greetingName: greetingName }">      
        Hi, {{ greetingName }}!
      </h4>
    </div> -->
    <div class="card-body text-center pt-0">
      <div class="sidebar-user-balance dashboard-customise mt-2" *ngIf="userFlags && !userFlags?.onlyDashboard">
        <div class="table-points-custom">
          <span class="header font-weight-bold font-18">{{ 'Balance' | translate }}</span>
          <span *ngIf="currentUser?.role !== 'user'" class="epoints-final-total font-20 font-weight-normal">
            {{ currency }}{{ balance?.companyBalanceInEpoints
            | epointsToCurrency
            | number: ".2-2" }}
          </span>
          <div *ngIf="currentUser?.role === 'user' && personalBalance?.loadingEpointsBalance" class="text-center mt-2">
            <div class="spinner-border" role="status">
            </div>
          </div>
          <span *ngIf="currentUser?.role === 'user' && !personalBalance?.loadingEpointsBalance" class="epoints-final-total font-20 font-weight-normal">
            {{ currency }}{{ personalBalance?.confirmedEpoints | epointsToCurrency | number: '.2-2' }}
          </span>
        </div>
      </div>
     
      <small><a *ngIf="(currentUser?.role !== 'user' || (currentUser?.role === 'user' &&  !personalBalance?.loadingEpointsBalance)) && userFlags && !userFlags?.onlyDashboard" (click)="userRedirection()" class="spend-link text-primary font-weight-bold">{{
          userEpointsTitle | translate }}
        </a></small>

    </div>
  </div>