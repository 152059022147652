import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mergeMap, map } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { setEPCookie } from '../_helpers/global-functions';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  
  constructor(
    private http: HttpClient,
    private languageService: LanguageService
  ) { }

  // get multi countries for a partner or a company
  getCountryConfiguration(){
    return this.http.get<any>(`${environment.apiUrl}/partner/countries`).
    pipe(map(response => {
      setEPCookie('multiCountryEnabled', response?.multiCountryEnabled ? 'true' : 'false');
      return response;
    }));  
  }

  getExchangeRate(currentCountry: any) {
    if(currentCountry?.currencyCode !== 'GBP') {
      return this.http.get<any>(`${ environment.apiUrl }/exchangeRate/base/${currentCountry?.currencyCode}/exchangeTo/GBP`).pipe(
        map(response => {
          currentCountry['exchangeRate'] = response.exchangeRate;
          this.languageService.setCountry(currentCountry);
          return currentCountry;
        })
      );
    } else {     
      currentCountry['exchangeRate'] = 1; // for GBP
      this.languageService.setCountry(currentCountry);
      return of(currentCountry);
    }
  }
}