import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { DropInfo } from "./modal/data";
import Swal from "sweetalert2";
import { extractTsText } from "src/app/core/_helpers/global-functions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-generic-tree-drag',
  templateUrl: './generic-tree-drag.component.html',
  styleUrls: ['./generic-tree-drag.component.scss']
})
export class GenericTreeDragComponent  implements OnInit,OnChanges{

  @Input('treeData') treeData: any = [];
  @Input('loading') loading: boolean = false;
  @Output() onMove = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();

  // filter options
  searchTypes = []
  defaultSearchType = 'all'; // default filter
  searchField = '';
  displayTree: any[] = [];

  initFilter() {
    this.searchTypes = [
      {
        name: this.translate.instant(extractTsText('All')),
        value: 'all'
      },
      {
        name: this.translate.instant(extractTsText('Department')),
        value: 'department'
      },
      {
        name: this.translate.instant(extractTsText('ID')),
        value: 'id'
      }
    ];
    this.defaultSearchType = 'all'; // default filter
    this.searchField = '';
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.update();
    if (this.expandStatusArr.length === 0) this.toggleAll(this.treeData, true);
    if (this.expandStatusArr.length > 0) {
      this.triggerOpenNodeONChange(this.treeData);
      this.checkExpandAllState()
    }

  }

  ngOnInit(): void {
    this.update();
  }

  update(){
    this.prepareDragDrop(this.treeData);
    this.displayTree = [...this.treeData];
    this.initFilter();
  }
  disableDrag: boolean = true;
  toggleExpand: boolean = true;

  // ids for connected drop lists
  dropTargetIds = [];
  nodeLookup = {};
  dropActionTodo: DropInfo = null;
  expandStatusArr: any[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
  }

  prepareDragDrop(nodes: any) {
    nodes.forEach(node => {
      this.dropTargetIds.push(node.id.toString());
      this.nodeLookup[node.id.toString()] = node;
      this.prepareDragDrop(node.subdepartments);
    });
  }


  dragMoved(event) {
    let e = this.document.elementFromPoint(event.pointerPosition.x,event.pointerPosition.y);

    if (!e) {
      this.clearDragInfo();
      return;
    }
    let container = e.classList.contains("node-item") ? e : e.closest(".node-item");
    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute("data-id")
    };
    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo["action"] = "before";
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo["action"] = "after";
    } else {
      // inside
      this.dropActionTodo["action"] = "inside";
    }
    this.showDragInfo();
  }


  drop(event) {
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(this.dropActionTodo.targetId, this.treeData, 'main');

    const from = draggedItemId;
    let to = null;
    if(this.dropActionTodo.action === 'inside') {
      to = this.dropActionTodo.targetId;
    } else if(this.dropActionTodo.action === 'before' || this.dropActionTodo.action === 'after') {
      to = targetListId
    }


    // !!!!!! do not remove the comment below; used for debugging node ids and list movement !!!!!!

    // console.log(
    //   '\nmoving\n[' + draggedItemId + '] from list [' + parentItemId + ']',
    //   '\n[' + this.dropActionTodo.action + ']\n[' + this.dropActionTodo.targetId + '] from list [' + targetListId + ']');
    this.clearDragInfo();
    if (to == 'main'){
      return;
    }
    if(to === undefined){
      // error in moving!
      return;
    }
    if(from !== to){
      // this.nodeIdToBeExpanded = to;
      const moveNodeSwal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-secondary'
        },
        buttonsStyling: false
      });
      moveNodeSwal.fire({
        title: this.translate.instant('Are you sure?'),
        text: this.translate.instant(extractTsText('You want to move department?')),
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: this.translate.instant(extractTsText('Yes!')),
        cancelButtonText: this.translate.instant('Cancel')
      }).then((result)=>{
        if (result.value){
          if (this.dropActionTodo.action === 'inside'){
            let _obj = this.expandStatusArr.find(da => da.id == to)
            if (_obj){
              this.expandStatusArr[this.expandStatusArr.indexOf(_obj)].expanded = true;
            }
          }
          this.onMove.emit({from: from, to: to});
        }
      });
    }
  }
  getParentNodeId(id: string, nodesToSearch: any, parentId: string): string {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.subdepartments, node.id);
      if (ret) return ret;
    }
    return null;
  }
  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document.getElementById('node-'+this.dropActionTodo.targetId).classList.add("drop-" + this.dropActionTodo.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach(element => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach(element => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach(element => element.classList.remove("drop-inside"));
  }

  toggleAll(data: any,expand: boolean){
    this.toggleExpand = expand;
    this.expandStatusArr = [];
    for (let da of data){
      da.isExpanded = expand;
      let _temp = {id: da.id, expanded: expand};
      this.expandStatusArr.push(_temp);
      this.toggleAll(da.subdepartments, expand);
    }
  }

  toggle(node: any, add?: boolean){
    if (add){
      node.isExpanded = true;
    } else {
      node.isExpanded = !node.isExpanded;
    }

    let _obj = this.expandStatusArr.find(n => n.id == node.id);
    if (_obj){
      this.expandStatusArr[this.expandStatusArr.indexOf(_obj)].expanded = node.isExpanded;
    }
    this.checkExpandAllState();
  }

  triggerOpenNodeONChange(data){
    for (let node of data){
      let _obj = this.expandStatusArr.find(n => n.id == node.id);
      if (_obj){
        node.isExpanded = _obj.expanded
      }
      this.triggerOpenNodeONChange(node.subdepartments)
    }
  }

  onAdd(node){
    this.add.emit({id: node.id});
    this.toggle(node, true)
  }

  checkExpandAllState(){
    for (let data of this.treeData){
      if (data.isExpanded){
        this.toggleExpand = true; return;
      }
      this.toggleExpand = false
    }
  }

  // on keyup or on search type change, search for the matching tree node

  onFilter(value: string) {
    let key = this.defaultSearchType === 'department' ? 'name' : (this.defaultSearchType === 'id' ? this.defaultSearchType : 'all')
    this.displayTree = this.filterRecursiveTree(key, value, [...this.treeData])
    this.toggleAll(this.displayTree, true);
  }

  filterRecursiveTree = (compareKey: string, term: string, arr: any[]) => {
    // https://stackblitz.com/edit/angular-material-tree-dropdown-bcqbkk?file=src%2Fapp%2Fapp.component.ts
    let filteredTreeData;
    if (term) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          if(compareKey === 'all') {
            if ((object.name.toString().toLowerCase().indexOf(text.toString().toLowerCase()) > -1) || object.id.toString().toLowerCase().indexOf(text.toString().toLowerCase()) > -1) {

              // make subdepartments array [] in order to not append the subdepartments if there are no matching items
              result.push({...object, subdepartments: []}); // objects are assigned as spread operators in order to not change the original data
              return result;
            }
          } else {
            if (object[compareKey].toString().toLowerCase().indexOf(text.toString().toLowerCase()) > -1) {

              // make subdepartments array [] in order to not append the subdepartments if there are no matching items
              result.push({...object, subdepartments: []}); // objects are assigned as spread operators in order to not change the original data
              return result;
            }
          }
          if (Array.isArray(object.subdepartments)) {
            const subdepartments = object.subdepartments.reduce(getChildren, []);
            if (subdepartments.length) result.push({ ...object, subdepartments });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(arr, term);
    } else {
      // Return the initial tree
      filteredTreeData = arr;
    }
    return filteredTreeData
  };

  dragDisable(bool: boolean){
    if (!(this.searchField == '' || this.searchField == null)){
      this.disableDrag = true; return;
    }
    this.disableDrag = bool
  }
}
