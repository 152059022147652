<section class="main-content">
    <div class="payment-sub-header-binder setup-completed d-flex" *ngIf="currentUser && currentUser.setUpCompleted">
        <h4 class="page-title mt-md-1">{{ 'Contact Us' | translate }}</h4>
        <div class="configure-tops d-none d-md-inline-block">
            <app-balance></app-balance>
        </div>
    </div>
    <div class="d-block d-md-none d-lg-none mb-4" *ngIf="currentUser && currentUser.setUpCompleted">
        <app-balance></app-balance>
    </div>

    <div class="section-body">
        <div class="row">
            <div class="col-12">
                <div class="card card-spacious border-apply py-4 px-md-0 px-10">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-8 col-12 text-center">
                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/contact-banner.jpeg" alt="Contact us"
                                    style="max-width: 340px;" class="">
                                    <p class="font-16 text-center mt-5">
                                        {{ 'Contact us' | translate }}: <br>
                                        <a href="tel:0118 230 5531" class="text-dark"><strong>0118 230 5531</strong></a><br>
                                        <a href="mailto:support@eachperson.com"><strong>support@eachperson.com</strong></a>
                                    </p>
                            </div>
                            <div class="col-lg-6 col-md-8 col-12">
                                <h3>{{ 'How can we help?' | translate }}</h3>
                                <p class="font-16 mb-4">{{ "Please fill out the contact form below, and we\'ll get back to you as soon as possible." | translate }}</p>
                                <div class="form-wrapper">
                                    <div id="chatForm" class="test"></div>
                                    <div class="spinner-border" role="status" *ngIf="loading">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>