import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiDocumentationService {

  constructor(private http: HttpClient) { }

  getApiKey(){
    return this.http.get<any>(`${environment.apiUrl}/api-key`);
  }

  getApiDocumentDetail(docType: string){
    return this.http.get<any>(`${environment.apiUrl}/api-docs?docType=${docType}`);
  }
}
