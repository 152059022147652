import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { takeUntil, finalize } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { NotificationsService } from 'src/app/core/service/notifications.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { staticReactions } from 'src/app/dashboard/social-aspects-reactions';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { UserService } from 'src/app/core/service/user.service';
import { NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [DestroyService],
  animations: [
    trigger('slideUpDown', [
      state('up', style({
        transform: 'translateY(100%)',
      })),
      state('down', style({
        transform: 'translateY(0)',
      })),
      transition('up <=> down', animate('300ms ease-in-out')),
    ]),
  ]
})
export class NotificationComponent implements OnInit {
  pollingNotification: any;
  currentUser: User;
  notifications: any = {
    all: [],
    temporaryAll: [],
    social: [],
    temporarySocial: [],
    file: [],
    temporaryFile: []
  };
  notificationParams: any = {
    pageNo: 1,
    pageSize: 6,
    parentNotificationType: 'ALL'
  };
  totalUnviewedNotification: number = null;
  totalAllNotification: number = 0;
  totalSocialNotification: number = 0;
  totalFileNotification: number = 0;
  isMobileView: boolean = window.innerWidth <= 767;
  activeTab = null;
  mutedNotifications: any[] = [];
  allNotificationsTurnedOff: boolean = false;
  @ViewChild('mobileNotifActionsModal') mobileNotifActionsModal: TemplateRef<any>;
  @ViewChild('deletedPopupModal') deletedPopupModal: TemplateRef<any>;
  deletedModalRef: NgbModalRef;
  notificationActionModalRef: NgbModalRef;

  socialIcons: any = {
    'FILE_READY': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/report.svg',
    'MENTION': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/tags.svg',
    'REACTION': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/likes.svg',
    'REPLY': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/comment.svg',
    'COMMENT': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/comment.svg',
    'ECARD': 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ecard.svg'
  }

  // hardcoded for translation
  hardcodedNotificationDesc = [
    extractTsText("Commented on your ecard"),
    extractTsText("Replied on your comment"),
    extractTsText("Reacted on your Ecard"),
    extractTsText("Reacted on your Comment"),
    extractTsText("Sent you an ecard"),
    extractTsText('You have received a nomination'),
    extractTsText('You have received an ecard')
  ];

  constructor(
    private authService: AuthService,
    private destroy$: DestroyService,
    private notificationService: NotificationsService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: NgbModal,
    public userService: UserService
  ) {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      userdetail => {
        if(userdetail) {
          this.currentUser = userdetail;
        }
      }
    )
  }

  /**
   * when the default close action of the ngbdropdown is clicked, it closes the dropdown even if the click is triggered outside of it.
   * That is, we are using modal, and custom UIs for ngbDropdown, on clicking those the dropdown is closed.
   * In order to prevent that, [autoClose]="false" is used and using our own custom code which prevents the dropdown close action
   * if the modal-backrop is clicked.
   * 
   * Also, event.stopPropagation() is used in most of the methods. This is to prevent the other mouse events like clicks
   * to trigger when the methods are called.
   * Because when the notification delete button was clicked, it triggered dropdown close too.
   */
  @ViewChild('notificationDropdown', { static: false }) notificationDropdown: NgbDropdown;
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    /**
     * close dropdown if the clicked element and the element inside html are not same,
     * if the dropdown does not have modal in it
     * and if the notification dropdown is open
     */
    if (!this.elementRef.nativeElement.contains(event.target) && document.getElementsByTagName('ngb-modal-window')[0] !== event.target && this.notificationDropdown.isOpen()) {
      // Clicked outside the dropdown, so close it
      // You can add logic here to close your dropdown
      this.notificationDropdown.close();
    }
  }
  

  ngOnInit(): void {
    this.enableContinuousPolling();
    this.getNotificationMutedStatus();

    this.notificationService.mutedNotifArray$
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      this.allNotificationsTurnedOff = response?.muteAllNotificationStatus;
      this.mutedNotifications = response?.mutedNotificationStatus;
    });
  }

  // method for continuous polliong
  enableContinuousPolling() {
    // fetch notification every 30 seconds
    if (
      this.currentUser &&
      this.currentUser.setUpCompleted &&
      this.currentUser.businessUser &&
      this.currentUser.gdprAccepted
    ) {
      this.loadingNotification = true;
      this.fetchNotifications();

      this.pollingNotification = setInterval(() => {
        this.fetchNotifications();
      }, 30000);
    }
  }

  /**fetch notification periodically at interval */
  fetchNotifications() {    
    this.getNotifications();
    this.notificationService.getNotificationCount('ALL').subscribe(
      count => {
        this.totalUnviewedNotification = count?.notification_count;
      }
    );
  }

  getNotificationMutedStatus() {
    this.notificationService.fetchNotificationMutedStatus().subscribe();
  }


  // when the tabs are changed
  onChangeNavTab(activeNav: any) {
    this.activeTab = activeNav?.nextId;

    let parentNotificationType = this.activeTab === 1 ? 'ALL' : (this.activeTab === 2 ? 'SOCIAL' : 'FILE');
     
    this.notifications = {
      all: [],
      file: [],
      social: []
    };
    this.notificationParams.parentNotificationType = parentNotificationType;
    this.notificationParams.pageNo = 1;
    this.loadingNotification = true;
    // make the value of notificationDeleted and notificationTurnedOff false
    this.notificationDeleted = false;
    this.notificationTurnedOff = false;

    if(parentNotificationType === 'ALL') {
      this.fetchNotifications();
    } else {
      this.getNotifications();
    }
  }

  getReactionIcon(reactionType: string) {
    if(!reactionType) return null;
    let matchingReaction = staticReactions.find(reaction => reaction.name === reactionType);
    return matchingReaction ? matchingReaction.name === "LIKE" ? this.socialIcons[matchingReaction.name] : matchingReaction.icon : null;
  }

  // get notifciations from the API
  loadingNotification: boolean = false;
  getNotifications(viewMore?: boolean) {
    let resetValues = () => {
      this.notifications.temporaryAll = [];
      this.notifications.temporarySocial = [];
      this.notifications.temporaryFile = [];
    }
    
    let getNotificationList = (pageNo: number, pageSize: number, parentNotificationType: string, maxPage: number) => {
      let finalParams = {
        pageNo,
        pageSize,
        parentNotificationType
      };
      this.notificationService.fetchNotifications(finalParams)
      .pipe(finalize(() => {
        this.loadingNotification = false;
      }))
      .subscribe(
        (response) => {
          this.formatNotificationResponse(response, pageNo, maxPage);

          // if the max page is there, then call the notifications API until the pageNo gets to the maxPage
          if(maxPage && pageNo < maxPage) {
            pageNo++;
            getNotificationList(pageNo, this.notificationParams?.pageSize, this.notificationParams?.parentNotificationType, maxPage);
          }          
        }
      );
    }

    /**
     * if viewMore = false and there are page numbers more than 1
     * this is suitable when the notifications API is called in every 30 secs
     */
    if(!viewMore && this.notificationParams?.pageNo > 1) {
      resetValues();
      // first the API is called with page 1 and the API is called again for the max page numbers once the response is received
      getNotificationList(1, 6, this.notificationParams?.parentNotificationType, this.notificationParams?.pageNo);
    } else {
      if(!viewMore) {
        resetValues();
      }
      getNotificationList(this.notificationParams?.pageNo, this.notificationParams?.pageSize, this.notificationParams?.parentNotificationType, this.notificationParams?.pageNo);
    }
    
  }

  formatNotificationResponse(response: any, pageNo: number, maxPage: number) {
    if(this.notificationParams?.parentNotificationType === 'ALL') {
            
      let allNotifications = response?.notifications || [];
      this.totalAllNotification = response?.notificationCount;

      for (let i = 0; i < allNotifications.length; i++) {
        allNotifications[i]['mainTitle'] = allNotifications[i]?.notificationType !== 'FILE_READY' ? allNotifications[i]?.createdBy?.name : allNotifications[i]?.title;
        allNotifications[i].description = allNotifications[i]?.notificationType !== 'FILE_READY' ? allNotifications[i]?.title : (allNotifications[i]?.read ? extractTsText('Report Downloaded') : extractTsText('Report Download'));
        allNotifications[i]['reactionIcon'] = this.getReactionIcon(allNotifications[i]?.emoji);
      }

      // Combine the existing array with the new array
      const combinedArray = [...this.notifications.temporaryAll, ...allNotifications];

      // Filter out duplicates based on a unique identifier (assuming 'id' here)
      const uniqueArray = combinedArray.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id // Change 'id' to the appropriate unique identifier property
        ))
      );

      // Update this.notifications.temporaryFile with the uniqueArray
      this.notifications.temporaryAll = uniqueArray;
      // assign the value to display array when the pageNo === maxPage
      if(maxPage && maxPage === pageNo) {
        this.notifications.all = this.notifications.temporaryAll;
      }
      
    } else if(this.notificationParams?.parentNotificationType === 'SOCIAL') {
      let socialNotifications = response?.notifications || [];
      this.totalSocialNotification = response?.notificationCount;
      for (let i = 0; i < socialNotifications.length; i++) {
        socialNotifications[i]['mainTitle'] = socialNotifications[i]?.createdBy?.name;
        socialNotifications[i].description = socialNotifications[i]?.title;
        socialNotifications[i]['reactionIcon'] = this.getReactionIcon(socialNotifications[i]?.emoji);
      }
      // Combine the existing array with the new array
      const combinedArray = [...this.notifications.temporarySocial, ...socialNotifications];

      // Filter out duplicates based on a unique identifier (assuming 'id' here)
      const uniqueArray = combinedArray.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id // Change 'id' to the appropriate unique identifier property
        ))
      );

      // Update this.notifications.temporaryFile with the uniqueArray
      this.notifications.temporarySocial = uniqueArray;
      // assign the value to display array when the pageNo === maxPage
      if(maxPage && maxPage === pageNo) {
        this.notifications.social = this.notifications.temporarySocial;
      }
    } else if(this.notificationParams?.parentNotificationType === 'FILE') {
      let fileNotifications = response?.notifications || [];
      this.totalFileNotification = response?.notificationCount;
      for (let i = 0; i < fileNotifications.length; i++) {
        fileNotifications[i]['mainTitle'] = fileNotifications[i]?.title;
        fileNotifications[i].description = (fileNotifications[i]?.read ? extractTsText('Report Downloaded') : extractTsText('Report Download'));
      }
      // Combine the existing array with the new array
      const combinedArray = [...this.notifications.temporaryFile, ...fileNotifications];

      // Filter out duplicates based on a unique identifier (assuming 'id' here)
      const uniqueArray = combinedArray.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id // Change 'id' to the appropriate unique identifier property
        ))
      );

      // Update this.notifications.temporaryFile with the uniqueArray
      this.notifications.temporaryFile = uniqueArray;
      // assign the value to display array when the pageNo === maxPage
      if(maxPage && maxPage === pageNo) {
        this.notifications.file = this.notifications.temporaryFile;
      }
    }
  }

  // when the notification bell icon is clicked
  onClickNotificationBellIcon(event: boolean) {
    let oldOverlay = this.el.nativeElement.querySelector('.content-overlay');
    if (oldOverlay) {
      this.renderer.removeChild(oldOverlay.parentElement, oldOverlay);
      oldOverlay = null;
    }
    if(event) {
      this.changeNotificationViewStatus();
      
      // Use nativeElement to access the DOM element from the ElementRef
      const dropdown = this.el.nativeElement.querySelector('#notification-dropdown-menu');      
      
      if (dropdown) {
        // Create a new div element
        const contentOverlay = this.renderer.createElement('div');     
        
        // Add a class to the new div element
        this.renderer.addClass(contentOverlay, 'content-overlay');

        // Insert the new div element after the dropdown
        this.renderer.insertBefore(dropdown.parentElement, contentOverlay, dropdown.nextSibling);
      }

      // add a div after dropdown to cover the whole page in the mobile view and a class in body to prevent body scroll
      // this.document.body.classList.add('noscroll-phone');
      if (window.innerWidth < 768 && window.scrollY === 0) {
        setTimeout(() => {
          window.scrollBy(0, 5);
        }, 100); // Add a short delay
      }
    } else {
      this.resetNotificationValues();
      this.getNotifications();
      this.document.body.classList.remove('noscroll-phone');
    } 
    
  }

  isNotificationMuted(notificationType: string) {
    let muted = this.mutedNotifications.filter((value) => {
      return value.notificationType === notificationType && value.muted;
    });
    return muted && muted.length ? true : false;
  }

  // when the notification bell icon is clicked and totalUnviewedNotification > 0 then change view status
  changeNotificationViewStatus() {
    if(this.totalUnviewedNotification > 0) {
      this.notificationService.updateNotificationViewStatus().subscribe(
        response => {
          this.fetchNotifications();
        }
      );
    }
  }

  // reset the notifications
  resetNotificationValues() {
    this.notificationParams = {
      pageNo: 1,
      pageSize: 6,
      parentNotificationType: 'ALL'
    };

    this.loadingNotification = true;

    // make the value of notificationDeleted and notificationTurnedOff false
    this.notificationDeleted = false;
    this.notificationTurnedOff = false;

    this.activeTab = 1;
    this.notifications = {
      all: [],
      temporaryAll: [],
      social: [],
      temporarySocial: [],
      file: [],
      temporaryFile: []
    };
  }

  viewMore(notificationType: string) {
    this.notificationParams.parentNotificationType = notificationType;
    this.notificationParams.pageNo = this.notificationParams.pageNo + 1;
    this.getNotifications(true);
  }

  viewLess(notificationType: string) {
    this.notificationParams.parentNotificationType = notificationType;
    this.notificationParams.pageNo = 1;
    this.getNotifications();
  }

  // when the specific notification is clicked
  onClickNotification(eachNoticiation: any) {
    if(eachNoticiation?.notificationType === 'FILE_READY') {
      // download file and mark as read
      this.notificationService.exportNotificationFile(eachNoticiation?.title).subscribe(
        (response) => {
          this.downLoadFile(response, eachNoticiation?.title);
  
          //read notifications api call
          if(!eachNoticiation?.read) {
            this.notificationService.changeNotificationStatus(eachNoticiation?.id).subscribe(
              (response) => {
                this.fetchNotifications();
              }
            );
          }
        }
      );
    } else {
      var redirectToDetail = () => {
        let commentId = eachNoticiation?.parentCommentId || eachNoticiation?.commentId || null;
        let replyId = eachNoticiation?.parentCommentId ? eachNoticiation?.commentId : null;
        setTimeout(() => {          
          window.location.replace(`/social-aspect-detail?ecard=${eachNoticiation?.eCardId}${commentId ? `&commentId=${commentId}` : ''}${replyId ? `&replyId=${replyId}` : ''}`);
        }, 50); 
      }
      if(!eachNoticiation?.read) {
        // redirect to individual ecard page and mark as read if not read
        // read notifications api call
        this.notificationService.changeNotificationStatus(eachNoticiation?.id).subscribe(
          (response) => {            
            this.fetchNotifications();
            redirectToDetail();
          }
        ); 
      } else {
        redirectToDetail();
      }      
           
    }    
  }

  downLoadFile(response: any, filename: string) {
    let binaryData = [];
    binaryData.push(response.body);
    let downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: "blob" })
    );
    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  notificationDeleted: boolean = false;
  recentlyDeletedNotificationId: number = null;
  deleteNotification(event: any, id: number) {
    event.stopPropagation(); // stops the bubbling of an event to parent elements, preventing any parent event handlers from being executed
    this.recentlyDeletedNotificationId = id;
    this.notificationService.deleteSpecificNotification(id)
    .subscribe(response => {
      this.notificationDeleted = true;
      this.toggleDeletedPopup();
      this.fetchNotifications();
    });
  }

  undoDeleteNotification(event: any) {
    event.stopPropagation();
    this.notificationService.undoDeleteNotification(this.recentlyDeletedNotificationId)
    .pipe(finalize(() => {
      
    }))
    .subscribe(response => {
      this.notificationDeleted = false;      
      this.recentlyDeletedNotificationId = null;
      this.toggleDeletedPopup();
      this.fetchNotifications();
    });
  }

  notificationTurnedOff: boolean = false;
  recentlyTurnedOffNotificationType: string = null;
  turnOffNotification(event: any, notificationType: string) {
    event.stopPropagation();
    this.recentlyTurnedOffNotificationType = notificationType;
    this.notificationService.turnOffSpecificNotification(notificationType)
    .subscribe(response => {
      this.notificationTurnedOff = true;
      this.toggleDeletedPopup();
      this.fetchNotifications();
      this.getNotificationMutedStatus();
    });
  }

  turnOnNotification(event: any, notificationType?: string) {
    event.stopPropagation();
    this.notificationService.turnOnSpecificNotification(notificationType || this.recentlyTurnedOffNotificationType)
    .pipe(finalize(() => {
      
    }))
    .subscribe(response => {
      this.notificationTurnedOff = false;      
      this.recentlyTurnedOffNotificationType = null;
      this.toggleDeletedPopup();
      this.fetchNotifications();
      this.getNotificationMutedStatus();
    });
  }

  closeAlertNotification(event?: any) {
    if(event) {
      event.stopPropagation();
    }
    this.notificationDeleted = false;    
    this.recentlyDeletedNotificationId = null;
    this.notificationTurnedOff = false;
    this.recentlyTurnedOffNotificationType = null;
    this.toggleDeletedPopup();
  }

  // destroy any periodic polling, otherwise keeps on running
  ngOnDestroy() {
    if (this.pollingNotification) {
      clearInterval(this.pollingNotification);
    }
  }  

  toggleDeletedPopup() {
    // if there are open modals, then close them
    if(this.deletedModalRef) {
      this.deletedModalRef.dismiss('close modal');
    }

    if(this.notificationActionModalRef) {
      this.notificationActionModalRef.dismiss('close modal');
    }

    if(this.isMobileView && (this.notificationDeleted || this.notificationTurnedOff)) {
      this.deletedModalRef = this.modalService.open(this.deletedPopupModal, {centered: true})
    }  
  }

  trackByFunction(index, item) {
    return item.id; // Use a unique identifier for your items
  }

  selectedNotification: any = null;
  openMobileActionModal(notification: any) {
    this.selectedNotification = notification;
    this.notificationActionModalRef = this.modalService.open(this.mobileNotifActionsModal, {centered: true, windowClass: 'mobile-notification-action'});
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobileView = window.innerWidth <= 767;

    /**
     * never use dismissAll for the modals as the notification component is used in the header and 
     * the header is a global layout and window:resize event triggers in all the components.
     * closing all the modals created problem in android devices when the modal had the input elements.
     * this closed the modal when the input element was focused.
     * So, closing only the opened modals from notification.
     */
    this.closeAlertNotification();
  }
}
