<div class="login-wrapper">
<section class="login">
  <div class="signin-dialog">
    <form class="form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" autocomplete="off">
      <div class="logo text-center mb-4">
        <img width="180px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"/>
      </div>
      <h5 class="text-center mb-3">We have sent a code to reset your password</h5>
      <p class="font-weight-normal text-center">Enter your code and then enter your new password</p>
      <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>
      <label for="changePasswordFormCode" class="label-customizable pb-0 mb-1">Code</label>
      <input id="changePasswordFormCode" (ngModelChange)="errorMessage = null" name="confirmationCode" type="text" class="form-control inputField-customizable"
             placeholder="" formControlName="confirmationCode">

      <label for="signInFormPassword" class="label-customizable mt-3 mb-1">Create new password</label>
      <div class="customised-password">
        <input id="signInFormPassword" (ngModelChange)="errorMessage = null" name="newPass" [type]="showPassword ? 'text':'password'"
        autocomplete="new-password" 
        class="form-control inputField-customizable" placeholder="Password" formControlName="password"
        [ngClass]="{ 'is-invalid' : !resetPasswordForm.get('password').valid && (resetPasswordForm.get('password').touched || resetPasswordForm.get('password').dirty) }">
        <i-feather [name]="showPassword ? 'eye':'eye-off'" class="text-secondary icon-sm customise-icon" (click)="changeKeyType('pass')"></i-feather>
      </div>

      <label for="signInFormConfirmPassword" class="label-customizable mt-3 mb-1">Confirm new password</label>
      <div class="customised-password mb-2">
        <input id="signInFormConfirmPassword" (ngModelChange)="errorMessage = null" name="confirmPass" [type]="showConfirmPassword ? 'text':'password'"
        autocomplete="new-password"       
        class="form-control inputField-customizable padding-right mb-1" formControlName="confirmPassword"
        placeholder="Confirm Password"
        [ngClass]="{ 'is-invalid' : !resetPasswordForm.get('confirmPassword').valid && (resetPasswordForm.get('confirmPassword').touched || resetPasswordForm.get('confirmPassword').dirty) }">
        <i-feather [name]="showConfirmPassword ? 'eye':'eye-off'" class="text-secondary icon-sm customise-icon" (click)="changeKeyType('cPass')"></i-feather>
      </div>

      <div *ngIf="resetPasswordForm.controls['password'].dirty">
        <label [ngClass]="errorCatcher('hasSmallCase','password')  ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm" [name]="errorCatcher('hasSmallCase','password') ? 'X' :'check'"></i-feather>
          <small
            class="font-weight-normal alert-text-inner">Password
            must contain a lower case letter</small>
        </label>
        <label [ngClass]="errorCatcher('hasCapitalCase','password')  ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm" [name]="errorCatcher('hasCapitalCase','password') ? 'X' :'check'"></i-feather>
          <small
            class="font-weight-normal alert-text-inner">
            Password must contain an upper case letter</small>
        </label>
        <label [ngClass]="errorCatcher('hasNumber','password')  ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm" [name]="errorCatcher('hasNumber','password') ? 'X' :'check'"></i-feather>
          <small
            class="font-weight-normal alert-text-inner">
            Password must contain a number</small>
        </label>
        <label [ngClass]="errorCatcher('minlength','password')  ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm" [name]="errorCatcher('minlength','password') ? 'X' :'check'"></i-feather>
          <small
            class="font-weight-normal alert-text-inner">
            Password must contain at least 8 characters</small>
        </label>
        <label [ngClass]="errorCatcher('hasSpecialCharacters','password')  ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm" [name]="errorCatcher('hasSpecialCharacters','password') ? 'X' :'check'"></i-feather>
          <small
            class="font-weight-normal alert-text-inner">
            Password must contain a special characters</small>
        </label>
        <label [ngClass]="errorCatcher('mustMatch','confirmPassword') ? 'text-danger' : 'text-success'">
          <i-feather class="icon-sm"
                     [name]="errorCatcher('mustMatch','confirmPassword') ?
                               'X' : 'check' "></i-feather>
          <small class="font-weight-normal alert-text-inner">Passwords must match</small>
        </label>
      </div>


      <input name="signInSubmitButton" type="Submit" value="Change password"
             class="btn btn-primary submitButton-customizable" aria-label="submit"
             [disabled]="!resetPasswordForm.valid || disableBtn">
      <div class="logo text-center mt-3">
        <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise"/><span> @ Each
                        Person {{ currentYear }}</span>
      </div>
      <div class="logo text-center">
        Powered by <img width="38px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png"/>
      </div>
    </form>

  </div>
</section>
</div>
