<div class="sidebar-user-balance" *ngIf="!load else loading;">
  <div class="header ng-star-inserted">{{ 'Personal balance' | translate }}</div>
  <div *ngIf="personalBalance?.loadingEpointsBalance" class="text-center mt-2">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div class="table-points-custom" *ngIf="!personalBalance?.loadingEpointsBalance">
    <span class="font-18">
      {{ currency }}{{ personalBalance?.confirmedEpoints | epointsToCurrency | number: '.2-2' }}
    </span>
  </div>
  
  <a href="javascript:void(0)" *ngIf="!personalBalance?.loadingEpointsBalance && !userFlags?.onlyDashboard" (click)="goToShop()" class="spend-link">{{ 'Spend Your Balance' | translate }}<i-feather name="arrow-right" class="more-icon"></i-feather></a>
</div>

<ng-template #loading>
  <div class="fullscreen-loader">
      <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
      </div>
      <!-- <p>Redirecting ...</p> -->
  </div>
</ng-template>
