<a data-toggle="sidebar" class="nav-link sidebar-toggle nav-link-lg collapse-btn menu" (click)="openMobileSidebar($event)">
  <!-- <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/menu-icon.svg" alt="menu-bar"> -->
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 25 15">
    <g fill="none" fill-rule="evenodd">
      <g fill="#333">
        <g transform="translate(-287 -30) translate(287 30)">
          <rect width="22" height="3" x="3" y="12" rx="1.5" />
          <rect width="22" height="3" y="6" rx="1.5" />
          <rect width="22" height="3" x="3" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
</a>

<div class="bread-crumbs">
  <span *ngFor="let c of bc; let i = index">
    <span [routerLink]="c?.path">{{c?.val | translate}}</span><span *ngIf="i !== (bc.length - 1)">&nbsp;&nbsp;<i-feather name="chevron-right" style="width: 10px"></i-feather>&nbsp;&nbsp;</span>
  </span>
</div>
