<div *ngIf="!load else loading;">
    <div class="header-wrapper" *ngIf="!appAccessible">
        <div class="header-container">
            <app-header></app-header>
        </div>
    </div>

    <div class="body-wrapper" *ngIf="!appAccessible">
        <div class="body-container">
            <app-sidebar></app-sidebar>
            <section class="main-content d-flex align-items-center justify-content-center">
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card text-center mb-0">
                                <div class="card-body m-auto">
                                    <img width="160px" alt="logo" [src]="'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png'" />
                                    <hr>
                                    <h4 class="page-title">{{ 'No Access' | translate }}</h4>
                                    <img class="mt-2 mb-3" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/rebranding-images/red-cross-icon.svg">
                                    <p class="px-lg-2">{{ "You don’t currently have access to this area on Each Person." | translate }}</p>
                                    <button type="button" class="btn btn-secondary btn-reward" (click)="goBack()">{{ 'Back to Epoints' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="footer-wrapper" *ngIf="!appAccessible">
        <div class="footer-container">
            <app-footer></app-footer>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="fullscreen-loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
        </div>
        <!-- <p>Redirecting ...</p> -->
    </div>
</ng-template>