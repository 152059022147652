import { Component, OnInit, Input } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { User, UserFlags } from "src/app/core/models/user";
import { AuthService } from "src/app/core/service/auth.service";
import { DestroyService } from "src/app/core/service/destroy.service";
import { LanguageService } from "src/app/core/service/language.service";
import { ShareBalanceService } from "src/app/core/service/share-balance.service";
import { UserPermissionService } from "src/app/core/service/user-permission.service";
import {
  getWPShopDomain,
} from "src/app/core/_helpers/environment-variables";
import { encryptToken, linkTargetViaGoNative, setEPCookie } from "src/app/core/_helpers/global-functions";

@Component({
  selector: "app-personal-balance",
  templateUrl: "./personal-balance.component.html",
  styleUrls: ["./personal-balance.component.sass"],
  providers: [DestroyService]
})
export class PersonalBalanceComponent implements OnInit {
  currentUser: User;
  appAccessible: boolean;
  currency: string = this.languageService.getCurrency(); // get if there is value in the localStorage
  userFlags: UserFlags;
  @Input() callBalanceAPIsByDefault: boolean = true;
  load: boolean = false;
  personalBalance = null;

  constructor(
    private authService: AuthService,
    private shareBalanceService: ShareBalanceService,
    private userPermissionService: UserPermissionService,
    private languageService: LanguageService,
    private readonly destroy$: DestroyService
  ) {
    // get the current user value
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe((userData) => {
      if(userData) {
        this.currentUser = userData;
        this.appAccessible = this.userPermissionService.isAppAccessible(userData);
      }
    });

    /** start: user flags */
    this.authService.userFlags
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      flags => {
        this.userFlags = flags
      }
    )
    /** end: user flags */   

    // get the country selected from localStorage -- by checking if changed
    this.languageService.watchCountryStorage()
    .pipe(takeUntil(this.destroy$))
    .subscribe((status) => {
      this.currency = this.languageService.getCurrency();
    });

    // get personal balance
    this.shareBalanceService.personalBalance
    .pipe(takeUntil(this.destroy$))
    .subscribe((balance) => {
      this.personalBalance = balance;
    });
  }

  ngOnInit(): void {
    if (
      this.currentUser &&
      this.currentUser.setUpCompleted &&
      this.appAccessible &&
      this.currentUser.gdprAccepted
    ) {
      if(this.callBalanceAPIsByDefault) {
        this.shareBalanceService.fetchPersonalPot();
      }
    }
  }

  goToShop() {
    let hrefTarget = linkTargetViaGoNative();
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }

    if(hrefTarget === '_blank') {
      this.load = false;
    }
    let shopUrl = getWPShopDomain();
    window.open(shopUrl, hrefTarget);
  }
}
