import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StripeElementsOptions, StripeCardNumberElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';

import {
  StripeCardNumberComponent,
  StripeService,
} from 'ngx-stripe';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import { takeUntil, switchMap, map } from 'rxjs/operators';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { PaymentProcessService } from 'src/app/core/service/payment-process.service';
import { StripeApiService } from 'src/app/core/service/stripe-api.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/service/language.service';
import { environment } from 'src/environments/environment';

// working example taken from https://richnologies.gitbook.io/ngx-stripe/core-concepts/element-components

@Component({
  selector: 'app-custom-stripe',
  templateUrl: './custom-stripe.component.html',
  styleUrls: ['./custom-stripe.component.scss'],
  providers: [DestroyService]
})
export class CustomStripeComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  @Input() queryParams: any = null;
  @Input() successButtonName?: string = null;
  @Input() defaultToPound?: boolean = false;
  @Output() successCallback = new EventEmitter();
  @Output() cancelCallback = new EventEmitter();

  pE = this.paymentProcessService.getMembershipPlan();
  topupAmount = 0;

  checkoutForm: FormGroup;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  paying = false;  

  get amount() {
    if (
      !this.checkoutForm.get('amount') ||
      !this.checkoutForm.get('amount').value
    )
      return 0;
    const amount = this.checkoutForm.get('amount').value;
    return Number(amount) / 100;
  }

  currentUser: User;
  currency: string = null;
  country: any = null;

  cardNumberHasError: boolean = true;
  cardExpiryHasError: boolean = true;
  cardCvcHasError: boolean = true;
  cardNumberError: string = null;
  cardExpiryError: string = null;
  cardCvcError: string = null;
  cardBrandIcon: string = '';
  rtlOptions = { style: { base: { direction: 'ltr', textAlign: 'left' } } };

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrCustomMessageService,
    private stripeService: StripeService,
    private authService: AuthService,
    private destroy$: DestroyService,
    private paymentProcessService: PaymentProcessService,
    private stripeApiService: StripeApiService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(userdata => {
      if(userdata) {
        this.currentUser = userdata;
        if(this.currentUser?.rtlFlag) {
          this.rtlOptions = { style: { base: { direction: 'rtl', textAlign: 'right' } } };
        } else {
          this.rtlOptions = { style: { base: { direction: 'ltr', textAlign: 'left' } } };
        }
      }
    });

    // get the country selected from localStorage -- by checking if changed value
    if(!this.defaultToPound) {
      this.languageService
        .watchCountryStorage()
        .pipe(takeUntil(this.destroy$))
        .subscribe((status) => {
          this.currency = this.languageService.getCurrency();
          this.country = this.languageService.getCountry();
        });
    }
  }

  ngOnInit() {
    this.currency = this.defaultToPound ? environment.defaultCurrencySymbol : this.languageService.getCurrency();
    this.country = this.defaultToPound ? null : this.languageService.getCountry();
    this.topupAmount = this.pE?.totalPay;
    this.initForm();
  }

  initForm() {
    this.checkoutForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      address: [null, Validators.required],
      zipcode: [null, Validators.required],
      amount: [0, [Validators.required, Validators.pattern(/\d+/)]],
    });

    this.initializeValues();
  }

  initializeValues() {
    if(this.topupAmount > 0) {
      this.checkoutForm.get('name').setValue(this.currentUser?.name);
      this.checkoutForm.get('email').setValue(this.currentUser?.login);

      /**
       * Math.floor is used to get the Integer value as the stripe payment does not take float value and gives folloring error 
       * if float value is used.
       * {
        "error": {
          "code": "parameter_invalid_integer",
          ...
        }
       */
      let stripeAmount = Math.floor(+this.topupAmount.toFixed(2) * 100);
      this.checkoutForm.get('amount').setValue(stripeAmount);
    }
  }

  paymentId: string = null;
  displayText: string = null;
  pay(): void {
    if (this.paying) return;
    if (this.checkoutForm.invalid) {
      return;
    }

    this.paying = true;
    this.displayText = this.translate.instant(extractTsText("We're processing your payment. Do not refresh the page or close the browser until the payment is successfully completed."));
    this.paymentProcessWarning(this.displayText, 'warning');
    let topupAmt = this.checkoutForm.get('amount');
    if (topupAmt.value <= 0) {
      this.toastr.info(this.translate.instant(extractTsText('The amount should be greater than 0.')));
      return; 
    }

    this.pE.apiQuery['uuid'] = this.currentUser?.id;
    this.pE.apiQuery['partnerId'] = this.currentUser?.partnerId;
    this.pE.apiQuery['partnerName'] = this.currentUser?.companyName;
    this.pE.apiQuery['name'] = this.checkoutForm.get('name').value;
    this.pE.apiQuery['email'] = this.checkoutForm.get('email').value;
    this.pE.apiQuery['address'] = this.checkoutForm.get('address').value;
    this.pE.apiQuery['postcode'] = this.checkoutForm.get('zipcode').value;
    let metadata = this.pE?.apiQuery;
    // supported currencies: https://stripe.com/docs/currencies?presentment-currency=GB
    const country = this.defaultToPound ? null : this.languageService.getCountry();
    let apiRequest = this.stripeApiService.createPaymentIntent(topupAmt.value, country?.currencyCode.toLocaleLowerCase() || 'gbp', metadata);
    if(this.paymentId) {
      apiRequest = this.stripeApiService.updatePaymentIntent(this.paymentId, topupAmt.value, country?.currencyCode.toLocaleLowerCase() || 'gbp', metadata);
    }
    
    apiRequest
    .pipe(
      map((payment) => {
        this.paymentId = payment.id
        return payment
      })
    )
    .pipe(
      switchMap((response) =>
        this.stripeService.confirmCardPayment(response?.client_secret, {
          payment_method: {
            card: this.card.element,
            billing_details: {
              name: this.checkoutForm.get('name').value,
              email: this.checkoutForm.get('email').value,
              address: {
                line1: this.checkoutForm.get('address').value,
                postal_code: this.checkoutForm.get('zipcode').value,
                country: this.country?.code || 'GB'
              }
            },
          },
        })
      )
    )
    .subscribe({
      next: (result) => {        
        if (result.error) {
          Swal.close();
          this.paying = false;
          this.toastr.error(result.error.message);
        } else if (result.paymentIntent.status === 'succeeded') {
          this.paymentId = null;
          // save amount to our database
          // the payment_intent.succeeded event in the stripe will trigger the backend API to add the amount
          setTimeout(() => {
            this.displayText = this.translate.instant(extractTsText('Your payment has been processed. If you do not see your company balance being added, then please visit sometime later by refreshing the page.'));
            Swal.close();
            if(this.pE.apiQuery?.page !== 'eachpersondonation' && this.pE.apiQuery?.page !== 'salary_sacrifice_gym_pay') {
              this.paymentProcessWarning(this.displayText, 'success');
            }
            this.paying = false;
            this.successCallback.emit(true);
          }, 1000);
        }
      },
      error: (err) => {
        Swal.close();
        this.paying = false;
        this.toastr.error(err.message || this.translate.instant(extractTsText('Unknown Error')))
      },
    });
  }

  paymentProcessWarning(text: string, status: SweetAlertIcon) {
    const paymentSwal = Swal.mixin({
      customClass: {
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false
    })
    paymentSwal.fire({
      text,
      icon: status,
      showCancelButton: status === 'warning' ? false : true,
      showCloseButton: status === 'warning' ? false : true,
      showConfirmButton: false,
      cancelButtonText: this.translate.instant(extractTsText('Close')),
      allowOutsideClick: false // disable outside click
    }).then((result) => {
      if (result.value) {
        // confirmed
      } else {
        // closed
      }
    });
  }

  onChangeCardNumber(ev: StripeCardNumberElementChangeEvent) {
    if(ev?.brand === 'unknown') {
      this.cardBrandIcon = '';
    } else {
      this.cardBrandIcon = `fab fa-cc-${ev.brand}`;
    }
    
    if(ev.empty || ev.error) {
      this.cardNumberHasError = true;
      this.cardNumberError = ev.empty ? this.translate.instant(extractTsText('Card number is required.')) : ev.error.message;
    } else {
      this.cardNumberHasError = false;
      this.cardNumberError = '';
    }    
  }

  onChangeCardExpiry(ev: StripeCardExpiryElementChangeEvent) {
    if(ev.empty || ev.error) {
      this.cardExpiryHasError = true;
      this.cardExpiryError = ev.empty ? this.translate.instant(extractTsText('Expiry date is required.')) : ev.error.message;
    } else {
      this.cardExpiryHasError = false;
      this.cardExpiryError = '';
    } 
  }

  onChangeCardCvc(ev: StripeCardCvcElementChangeEvent) {
    if(ev.empty || ev.error) {
      this.cardCvcHasError = true;
      this.cardCvcError = ev.empty ? this.translate.instant(extractTsText('CVC is required.')) : ev.error.message;
    } else {
      this.cardCvcHasError = false;
      this.cardCvcError = '';
    } 
  }

  clear() {
    this.initForm()
    this.cancelCallback.emit(true)
  }

}
