<div class="row align-items-center my-2">
    <div class="col-md-4 col-12 pl-4 d-lg-inline-block d-md-inline-block d-none">
        <p class="pagination-helper">
            {{ +pagination.pageSize * (+pagination.currentPage - 1) + 1 }}-{{ pagination.totalResults > +pagination.pageSize + +pagination.pageSize * (+pagination.currentPage - 1) ? +pagination.pageSize * +pagination.currentPage : pagination.totalResults }} {{ 'of' | translate }} {{
            pagination.totalResults }} {{ 'records' | translate }}
        </p>
    </div>
    <div class="col-12 d-lg-none d-md-none d-block" *ngIf="!hidePageSize">
        <div class="per-page ">
            {{ 'Show Rows:' | translate }} <select [(ngModel)]="pagination.pageSize" class="pagination-select" (ngModelChange)="onPageSizeChanged($event)">
            <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
               </select>
        </div>
    </div>
    <div class="col-12 d-lg-none d-md-none d-block">
        <p class="pagination-helper">
            {{ +pagination.pageSize * (+pagination.currentPage - 1) + 1 }}-{{ pagination.totalResults > +pagination.pageSize + +pagination.pageSize * (+pagination.currentPage - 1) ? +pagination.pageSize * +pagination.currentPage : pagination.totalResults }} {{ 'of' | translate }} {{
            pagination.totalResults }} {{ 'records' | translate }}
        </p>
    </div>
    <div class="col-md-4 col-12">
        <ngb-pagination [collectionSize]="pagination.totalResults" [(page)]="pagination.currentPage" [pageSize]="pagination.pageSize" [maxSize]="5" [rotate]="true" [ellipses]="true" [boundaryLinks]="true" (pageChange)="onPageChanged($event)">
            <!-- <ng-template ngbPaginationFirst>First</ng-template>
            <ng-template ngbPaginationLast>Last</ng-template> -->

            <!-- to work ngbPaginationEllipsis, [ellipses] should be true or do not include [ellipses] attribute in ngb-pagination -->
            <ng-template ngbPaginationEllipsis>...</ng-template>
        </ngb-pagination>
    </div>
    <div class="col-md-4 pr-4 col-12 d-lg-inline-block d-md-inline-block d-none" *ngIf="!hidePageSize">
        <div class="per-page ">
            {{ 'Show Rows:' | translate }} <select [(ngModel)]="pagination.pageSize" class="pagination-select" (ngModelChange)="onPageSizeChanged($event)">
            <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
               </select>
        </div>
    </div>
</div>