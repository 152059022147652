<!-- className mobile-commentinput helps to keep Add a Comment... and Add a Reply... textboxes at the bottom -->
<div [ngClass]="{'mobile-commentinput' : isAddModeFormobile}">
  <div *ngIf="showInputBox" class="{{parentDivClass}}" [ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''">
    <!-- this is phase 1 which will contain just like, comment and reactions so disabling mentions -->
    <!-- <div
      contenteditable="true"
      (itemSelected)="itemSelected($event)"
      id="{{ messageContentId }}"
      (input)="onCommentChange($event)"
      (keydown.enter)="triggerEventEmitter($event)"
      (keydown.escape)="onEditCancel()"
      (keyup)="getInputSelection()"
      class="social-aspect-input before-input"
    >
    </div> -->

    <!-- enable mentions for phase 2 -->
    <div
      [mention]="httpItems | async" (searchTerm)='search($event)'
      [mentionConfig]="mentionConfig"
      [mentionListTemplate]="mentionListTemplate"
      contenteditable="true"
      (itemSelected)="itemSelected($event)"
      id="{{ messageContentId }}"
      (input)="onCommentChange($event)"
      (keydown.enter)="triggerEventEmitter($event)"
      (keydown.escape)="onEditCancel()"
      (keyup)="getInputSelection()"
      class="social-aspect-input before-input"
    >
    </div>

    <div *ngIf="maxCharacterLimitExceeded" class="input-error">
      <small class="form-text text-danger">{{ 'Maximum character limit: 250' | translate }}</small>
    </div>
    
    <ng-template #mentionListTemplate let-item="item">
      <span>{{item.name}}</span>
    </ng-template>
    
    <!-- use https://github.com/dmacfarlane/angular-mentions/blob/master/projects/demo-app/src/app/demo-template/demo-template.component.html to change mention lists -->
    <div id="placeholder" class="comment-placeholder" *ngIf="!inputData">{{ placeholder | translate }}</div>
    <div class="aspect-commentButtons">
      <span [ngClass]="inputData ? 'icon icon-emoji' : 'icon icon-emoji-beforeinput'">
          <app-emoji class="dashboard-social-aspect-parent-comment-emoji" (selectedEmoji)="handleEmojiSelection($event)"
          [showReactionSvg]=true [showButton]=false [hideEmojiWindow]="hideEmojiWindow"></app-emoji>
      </span>
      <span (click)="triggerEventEmitter($event)" *ngIf="inputData" class="icon icon-send">
        <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="LIBRARY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group">
              <g id="Group-13" fill="#F4F0EC">
                <circle id="Oval" cx="18.5" cy="18.5" r="18.5"></circle>
              </g>
              <g id="Group-16" transform="translate(12, 10)" fill="#00B451">
                <path
                  d="M1.45521346,0.743986853 L16.8038509,8.59107235 L16.8038509,8.59107235 L2,8.59107235 C0.8954305,8.59107235 -7.52907669e-16,7.69564185 0,6.59107235 L0,1.63436919 C-6.76353751e-17,1.08208444 0.44771525,0.63436919 1,0.63436919 C1.15827451,0.63436919 1.31428863,0.671938166 1.45521346,0.743986853 Z"
                  id="Triangle"></path>
                <path
                  d="M1.45521346,10.3999265 L16.8038509,18.247012 L16.8038509,18.247012 L2,18.247012 C0.8954305,18.247012 -7.52907669e-16,17.3515815 0,16.247012 L0,11.2903088 C-6.76353751e-17,10.7380241 0.44771525,10.2903088 1,10.2903088 C1.15827451,10.2903088 1.31428863,10.3278778 1.45521346,10.3999265 Z"
                  id="Triangle" transform="translate(8.4019, 13.9515) scale(1, -1) translate(-8.4019, -13.9515)"></path>
                <rect id="Rectangle" stroke="#00B451" x="9.19034568" y="9.00172391" width="7.44894719" height="1" rx="0.5">
                </rect>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </div>
  </div>
</div>
  
<p class="cancel-edit" *ngIf="showInputBox && inputData && editText"><span class="press-esc-text">{{ 'Press Esc to' | translate }} </span><span (click)="onEditCancel()"
class="canceledit-Brand">{{ 'Cancel' | translate }}</span></p>