import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GdprService {

  constructor(
    private http: HttpClient
  ) { }

  getGDPRDetails() {
    return this.http.get<any>(`${ environment.accessTokenAPI }/user/gdpr/V2`);
  }

  saveGDPRDetails(data) {
    return this.http.put<any>(`${ environment.accessTokenAPI }/user/gdpr/update/V2`, data);
  }
}
