<div class="login-wrapper" *ngIf="!showCheckEmail">
    <section class="login">
        <div class="resetpassword-dialog">
            <form class="form" [formGroup]="magicLinkForm" (ngSubmit)="magicLinkFormSubmit()">
                <div class="logo text-center mb-4">
                    <img width="180px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" />
                </div>
                <h5 class="text-center mb-1">Sign in Without Password</h5>
                <p class="font-weight-normal text-center">We’ll send you an email with a link
                    that will log you in</p>
                    <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>
                <label for="signInFormUsername" class="label-customizable pb-0 mb-1">Email</label>
                <input id="signInFormEmail" name="text" type="text" formControlName="email" class="form-control inputField-customizable"
                    placeholder="Email">
                <input name="getLinkButton" type="Submit" value="Get link"
                    class="btn btn-primary resetButton-customizable" aria-label="submit" [disabled]="!magicLinkForm.valid || magicLinkFormSubmitting">
                <div class="logo text-center mt-4">
                    <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise" /> @ Each Person {{ currentYear }}
                </div>
                <div class="logo text-center">
                    Powered by <img width="38px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
                </div>
            </form>
        </div>


    </section>
    </div>
    <app-check-email [email]="userEmail" [currentYear]="currentYear" (backButtonEvent)="backButtonEvent($event)"  *ngIf="showCheckEmail"></app-check-email>


