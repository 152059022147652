<div class="login-wrapper" *ngIf="!load ; else loading">
  <section class="login">
    <div class="check-sso-dialog">
      <form class="form" (ngSubmit)="onConfirm()">
        <div class="logo text-center mb-4">
          <img width="180px" alt="eachperson-logo"
               src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"/>
        </div>
        <h5 class="text-center mb-3" *ngIf="!credentialsChanged">Sign in With Your <br>
          Email and Password</h5>
        <h5 class="text-center mb-3" *ngIf="credentialsChanged">Sign in</h5>
        <p class="credential-changed-class" *ngIf="credentialsChanged">Email/Password has changed, please
          login to resume Face/Touch ID.</p>
        <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>
        <label for="signInFormEmail" class="label-customizable pb-0 mb-1">Email</label>
        <div class="customised-email">
          <!-- if displayBiometricPrompt=true, then enable biometric prompt when selecting the field.
          if disableInput=true, then make the emailInput field null whenever the user tries to type.
          enable onConfirm() method only when disableInput=false.

          Sometimes faceid/touchid login may fail due to invalidCredentials, that time disable biometric prompt and enable manual login-->
          <input #emailInput id="signInFormEmail" name="email" type="text"
                 [formControl]="emailControl"
                 (click)="!invalidLoginCreds && displayBiometricPrompt ? enableBiometricPrompt() : null"
                 (ngModelChange)="(!disableInput || invalidLoginCreds) ? onConfirm() : emailInput.value = null"
                 [ngClass]="{ 'is-invalid' : !emailControl.valid && (emailControl.touched || emailControl.dirty)}"
                 class="form-control inputField-customizable" placeholder="Email">
          <i-feather name="check" class="text-primary icon-sm customise-icon" *ngIf="emailConfirmed"></i-feather>
          <small class="form-text text-danger"
                 *ngIf="!emailControl.valid && (emailControl.touched || emailControl.dirty)">Please
            enter valid email.</small>
        </div>
          <p class="text-secondary font-weight-bold text-center my-3" >
          <span *ngIf="emailConfirmed">Redirecting to SSO in {{redirectCounter}}…</span>
          <span *ngIf="emailPending">Validating Email ...</span>
          </p>
        <p class="text-center mb-0 font-weight-normal pb-1" style="line-height: 1.5;">Issues logging in? </p>
        <p class="text-center font-weight-normal pb-2" style="line-height: 1.5;">Contact us:
          <span class="text-primary">
            <a href="https://www.eachperson.com/contact/" [target]="authService.insideGoNativeWrapper()" style="color:#255105;">support@eachperson.com</a>
          </span></p>
        <div class="logo text-center mt-4">
          <img width="80px" alt="eachperson-logo"
               src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"
               class="logo-customise"/> @ Each Person {{ currentYear }}
        </div>
        <div class="logo text-center">
          Powered by <img width="38px" alt="epoints-logo"
                          src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png"/>
        </div>
      </form>
    </div>
  </section>
</div>

<ng-template #loading>
  <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden"></span>
    </div>
    <!-- <p>Redirecting ...</p> -->
  </div>
</ng-template>
