import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SetupAccessGuard implements CanActivate, OnDestroy {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService, private router: Router) {}
  /**
   * since this.authService.currentUserValue which is a BehaviourSubject value, did not detect
   * changes made in localStorage using API request from the component
   * was detected late
   * the new changes, we are using observable which detects the changes made using subscribe
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data) {
            // if setup not completed, redirect to setup
            if(!data.setUpCompleted) {
              obs.next(true);
            }
            else {
              // failed
              // set up completed, lets redirect
              this.router.navigateByUrl('/set-up/success').then();
              obs.next(false);              
            }
          } else {
            obs.next(false);  
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
