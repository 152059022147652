import { Pipe, PipeTransform } from '@angular/core';
import { environment } from "src/environments/environment";
import { LanguageService } from '../service/language.service';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/

/**
 * A pure pipe is only called when Angular detects a change in the value or the parameters passed to a pipe.
 * An impure pipe is called for every change detection cycle no matter whether the value or parameter(s) changes.
 * This is relevant for changes that are not detected by Angular.
 */
@Pipe({ name: 'epointsToCurrency', pure:false })
export class EpointsToCurrencyPipe implements PipeTransform {

  constructor(
    private languageService: LanguageService
  ) {}

  /**
   * here type is an argument passed with 'default' value if no conversion rate is required
   * default is pound
   * like in the Admin section, there is no conversion used --> used only epoints to pound
   */
  transform(value: number, type: string = null, round?: false): number {
    let translationRate = type && type === 'default' ? 1 : this.languageService.getRate();
    /**
     * ticket - https://iat-technology.atlassian.net/browse/WS-564
     * ticket - https://iat-technology.atlassian.net/jira/software/c/projects/WS/boards/133?modal=detail&selectedIssue=WS-571&assignee=557058%3Aa4a780f3-265c-4672-b79c-7de045030980
     * internationalization document: https://docs.google.com/document/d/1vEeVChJ-Mhkt6BcTHoDkyko48IR02lRfMqP45Q15-pw/edit
     *
     * as the conversionRate provided by /rest/countries or /api/partner/countries does not give the actual or dynamic conversionRates,
     * the back-end team has made an API to return the conversion rate by using exchangeFrom of the desired country and the exchangeTo of Pound (GBP)
     * Example: https://stag-admin.eachperson.com/api/exchangeRate/base/EUR/exchangeTo/GBP
     *
     * Before the conversionRate used to give the rate value from POund to EUR but now the conversion rate is the opposite one.
     * so need to change the calculation logic as well.
     *
     * as the exchangeRate given by the API is from EUR to GBP: 0.887885
     * if the Epoints value is 1776
     *
     * then, calculation is Epoints/200 - to pound and pound to EUR is (Epoints/200) / exchangeRate
     * that is; (1776/200)/0.887885 = 10.00 euro
     *
     * for UK, the exchangeRate will be 1
     * so 1776/200 = 8.88 pounds
     *
     */

    var epointsToCurrencyValue = (value / environment.defaultEpointsValuation) / translationRate;
    if(round) {
      return Math.round(epointsToCurrencyValue * 100) / 100;
    }
    return Math.floor(epointsToCurrencyValue * 100) / 100; // currency value should have 2 digits after decimal so used toFixed(2)
  }

}
