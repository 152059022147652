import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private http: HttpClient
  ) { }
  
  /**
   * rest/users/<email>/order/giftCard is a WeGift API
   * You need to call /wegift API if 
   * 1.Single retailer and count <= 10
   * 2.Single denomination count <=10.
   * 
   * that means just one type of product in an array or just one product which has a unique ID
   * with max 10 quantity
   */
  redeemGiftCardWithWeGiftAPI(email, countryCode = "GB", payload) {
    let region = countryCode === 'GB' ? 'UK' : countryCode;
    return this.http.post<any>(`${ environment.accessTokenAPI }/users/${ email }/order/giftCard?region=${ region }&zone=${ region }`, payload);
  }

  /**
   * this is a normal order API used when a product has more than 10 quantity or has multiple products
   */
  redeemProductsWithNormalAPI(countryCode = "GB", payload) {
    let region = countryCode === 'GB' ? 'UK' : countryCode;
    return this.http.post<any>(`${ environment.accessTokenAPI }/users/order/V2?region=${ region }&zone=${ region }`, payload);
  }

  exchangeEpoints(eCardId, countryCode = 'GB') {
    let region = countryCode === 'GB' ? 'UK' : countryCode;
    return this.http.put<any>(`${ environment.accessTokenAPI }/ecards/${ eCardId }/exchange-points/V2?region=${ region }`, null);
  }

  checkIfSelfServiceUser() {
    return this.http.get<any>(`${ environment.accessTokenAPI }/user/self-service-check`);
  }
}
