import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckGdprGuard implements CanActivateChild, OnDestroy {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      /**
       * using observable subscribe in service, using the assigned values and calling service function in guard or components do not work in .ts file
       * except in html files
       * 
       * in order to make the observable work you need to run the code inside the subscribe function itself
       */ 
      return new Observable<boolean>(obs => {
        this.authService.currentUser
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            if (data) {
              // if gdpr accepted, then allow
              if(data.gdprAccepted) {
                obs.next(true);
              }
              else {
                // else redirect to gdpr accept page
                this.router.navigate(['/join/gdpr']).then();
                obs.next(false);              
              }
            } else {
              obs.next(false);  
            }
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
