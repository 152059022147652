<div *ngIf="showButton" class="emoji-toggle"><span (click)="toggleEmoji()">{{ buttonLabel | translate }}</span></div>
<div *ngIf="showReactionSvg" class="emoji-reaction-svg"><span (click)="toggleEmoji()">
 <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <!-- <title>Group 13</title> -->
 <g id="LIBRARY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
   stroke-linejoin="round">
   <g id="Icons-/-Social-/-Smile-B&amp;W" transform="translate(-7, -7)" stroke="#979797" stroke-width="2">
     <g id="smile" transform="translate(8.175, 8.175)">
       <circle id="Oval" fill="#F4F0EC" cx="10" cy="10" r="10"></circle>
       <path d="M6.25,11.25 C6.25,11.25 7.65625,13.75 10,13.75 C12.34375,13.75 13.75,11.25 13.75,11.25" id="Path">
       </path>
       <line x1="6.86875" y1="6.875" x2="6.88125" y2="6.875" id="Path"></line>
       <line x1="13.11875" y1="6.875" x2="13.13125" y2="6.875" id="Path"></line>
     </g>
   </g>
 </g>
</svg>

</span></div>
<emoji-mart *ngIf="showEmojiWindow"  [showPreview] = "false"  [backgroundImageFn]="backgroundImage" [totalFrequentLines]="0"  [virtualize]="true"  [virtualizeOffset]="800"  (keydown)="restrictSubmit($event)"  title="Pick your emoji…" emoji="point_up" (emojiSelect)="emojiSelected($event)"></emoji-mart>
