import {Router, NavigationEnd} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
import {ROUTES, fetchAPIDocRoutes} from "./sidebar-items";
import {AuthService} from "src/app/core/service/auth.service";
import {SSChildcareModel, SSCycle2workModel, SSEVFlags, SSTechModel, User, UserFlags} from "src/app/core/models/user";
import {UserService} from "src/app/core/service/user.service";
import {AccountService} from 'src/app/core/service/account.service';
import {UserPermissionService} from "src/app/core/service/user-permission.service";
import {DomSanitizer} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";
import {Observable} from "rxjs";
import {DestroyService} from "src/app/core/service/destroy.service";
import {
  getWPShopDomain,
  globalHiddenRoutes,
  menuAccessControl
} from "src/app/core/_helpers/environment-variables";
import {SideNavUserDetailService, User_Detail} from "src/app/core/service/sidenavUserDetails.service";
import {encryptToken, linkTargetViaGoNative, matchingPartner, setEPCookie} from "../../core/_helpers/global-functions";
import { LayoutService } from "src/app/core/service/layout.service";
import { ShareBalanceService } from "src/app/core/service/share-balance.service";
import { SalarySacrificeService } from "src/app/core/service/salary-sacrifice.service"
import { WellbeingHubService } from "src/app/core/service/wellbeing-hub.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.sass"],
  providers: [DestroyService]
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userType: string;
  headerHeight = 60;
  routerObj = null;
  currentUser: User;
  userFlags: UserFlags;
  disableSidebar = false;
  logoUrl = "";
  appAccessible: boolean;

  enabledSidebarMobile: boolean = false;
  desktopView: boolean = window.innerWidth >= 768;

  //for userdetail UI update
  queryParams = {
    detailsType: "",
    dateFrom: null,
    dateTo: null,
  };
  UserDetail$: Observable<User_Detail>;

  routeInfo = ROUTES;
  apiDocumentationRoutesBoolean = false;
  isMac = false;
  loadShop: boolean = false;

  salarySacrificeEVStatus: SSEVFlags = null;
  salarySacrificeTechStatus: SSTechModel = null;
  salarySacrificeChildcareStatus: SSChildcareModel = null;
  salarySacrificeCycle2workStatus: SSCycle2workModel = null;
  currentUrl: string = '';
  imperialClient: boolean = false; // partern with ID 100267 and company short name imperial_london_hotels_
  isEligibleforSFSActive: boolean = false; // is eligible to view sfs
  sfsRoute: string = '/hr/save-from-salary/information';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private userPermissionService: UserPermissionService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private readonly destroy$: DestroyService,
    private sidenavDetail: SideNavUserDetailService,
    private layoutService: LayoutService,
    private shareBalanceService: ShareBalanceService,
    private salarySacrificeService: SalarySacrificeService,
    private wellbeingService: WellbeingHubService
  ) {
    this.isMac = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    this.routerObj = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.layoutService.updateSidebarMobileView(false);
        this.showMainContent();

        // logic for select active menu in dropdown --- by laxmi (as we do not have uniform route for the menus, this logic does not work)
        this.currentUrl = event.url.split("?")[0];
        // this.level1Menu = this.currentUrl.split('/')[1];
        // this.level2Menu = this.currentUrl.split('/')[2];
        
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
        this.sidebbarClose();
        if (this.currentUrl.split('/')[1] === 'reporting-documentation') {
          this.routeInfo = fetchAPIDocRoutes();
          this.apiDocumentationRoutesBoolean = true;
          this.manageSidebarRouteChange();
        } else if (this.currentUrl === '/hr/dashboard') {
          this.backToDashboard();
        }

        // make menu and submenu active
        this.level1Menu = this.sidenavDetail.getActiveMenu(this.currentUser, this.routeInfo, this.currentUrl);
      }
    });

    // get the current user value
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        if(userData) {
          this.currentUser = userData;
          this.appAccessible = this.userPermissionService.isAppAccessible(userData);

          // enable sidebar if the setup is completed
          if (this.currentUser && this.currentUser.setUpCompleted) {
            this.disableSidebar = false;
          } else {
            this.disableSidebar = true;
          }

          this.imperialClient = matchingPartner(this.currentUser?.partnerId);
        }
      });    
  }

  showMainContent() {
    this.document.body.classList.remove('iatHide-main-content');
  }

  hideMainContent() {
    this.document.body.classList.add('iatHide-main-content');
  }

  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize();
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
      this.sidebbarClose();
    }
  }

  closeSidebar() {
    this.renderer.addClass(this.document.body, "sidebar-gone");
    this.renderer.removeClass(this.document.body, "overlay-open");
  }

  callLevel1Toggle(event: any, element: any) {
    event.preventDefault();
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }

  // not in use now ---> this was used to simply add activeSub class in html
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }

  // not in use now ---> this was used to simply add activeSubSub class in html
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }

  // get sfs status
  getSfsStatus() {
    if (this.currentUser) {
      this.accountService.getSfsStatus().subscribe(
        response => {
          this.isEligibleforSFSActive = response.active;
          this.layoutService.updateSfsDetail(this.isEligibleforSFSActive, this.sfsRoute)
        }, error => {
          console.log("Err log on sidebar sfs status");
        }
      );
    }
  }

  // check if the user has configured SFS. if configured, then take him to statement else to information
  getEmployeeSfsInfo() {
    if (this.currentUser) {
      this.accountService.getEmployeeInfo().subscribe(
        response => {
          if (response?.saveFromSalaryConfigure) {
            this.sfsRoute = '/hr/save-from-salary/statement';
          }
          this.layoutService.updateSfsDetail(this.isEligibleforSFSActive, this.sfsRoute)
        }, error => {
          console.log("Err log on sidebar sfs employee status");
        }
      );
    }
  }
  
  ngOnInit() {
    this.layoutService.SidebarMobileView$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      status => {
        this.enabledSidebarMobile = status;
        this.desktopView = window.innerWidth >= 768;

        if(this.enabledSidebarMobile) {
          this.hideMainContent();
        } else {
          this.showMainContent();
        }
      }
    ); 
    // this.currentUser in sidebar seems to give the updated data everytime
    if (
      this.currentUser &&
      this.appAccessible &&
      this.currentUser.gdprAccepted
    ) {
      this.shareBalanceService.fetchPersonalPot(); // balance APIs are called from sidebar to prevent twice API calls from personal-balance component

      this.getSfsStatus();
      this.getEmployeeSfsInfo();
      
      //for new UI update
      this.sidenavDetail.fetchUserDetail().subscribe();

      //observavble to subscribe in template with async pipe
      this.UserDetail$ = this.sidenavDetail.userDetail$;

      // get user flags from API first
      this.authService.getMeFlags().subscribe();

      // subscribe user flags observable
      this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.userFlags = flags
      })

      // call salary sacrifice - electric vehicle status
      this.salarySacrificeService.getEVStatus().subscribe();

      // subscribe salary sacrifice - electric vehicle flags observable
      this.salarySacrificeService.ssEvFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeEVStatus = status
      })

      // call salary sacrifice - technology status
      this.salarySacrificeService.getTechDetails().subscribe();

      // subscribe salary sacrifice - technology flags observable
      this.salarySacrificeService.ssTechFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeTechStatus = status
      })

      // call salary sacrifice - childcare status
      this.salarySacrificeService.getChildcareStatus().subscribe();

      // subscribe salary sacrifice - childcare flags observable
      this.salarySacrificeService.ssChildcareFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeChildcareStatus = status
      })

      // call salary sacrifice - cycle2work status
      this.salarySacrificeService.getCycle2WorkStatus().subscribe();

      // subscribe salary sacrifice - cycle2work flags observable
      this.salarySacrificeService.ssCycle2WorkFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeCycle2workStatus = status
      })

      this.manageSidebarRouteChange();

      this.getCompanyLogoUrl();
      this.initLeftSidebar();
      this.bodyTag = this.document.body;
      if (!this.currentUser.setUpCompleted) {
        this.disableSidebar = true;
      }
    }
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize();
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }

  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }

  checkStatuForResize() {
    if(window.innerWidth < 768) {
      this.desktopView = false
      // when window is resized, do nothing for mobile view
    } else {
      this.layoutService.updateSidebarMobileView(false)
      this.desktopView = true
      if (window.innerWidth < 1251) {
        this.renderer.addClass(this.document.body, "sidebar-gone");
      } else {
        this.renderer.removeClass(this.document.body, "sidebar-gone");
      }
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }

  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  sidebbarClose() {
    if (window.innerWidth < 1251) {
      this.renderer.addClass(this.document.body, "sidebar-gone");
    }
  }

  activateSubMenu(submenu: any) {
    return this.sidenavDetail.getSubmenuActive(this.currentUser, submenu, this.currentUrl);
  }

  getCompanyLogoUrl() {
    this.userService
      .fetchClientBrandingColors(this.currentUser.partnerId)
      .subscribe(
        (response) => {
          if (response.applicationBrandingAvailable) {
            this.logoUrl = response.logoUrl;
            this.layoutService.updateCompanyLogo(response.logoUrl)
            if (response.headerColour) {
              document.documentElement.style.setProperty(
                "--primaryHeaderColour",
                response.headerColour
              );
            }
            if (response.headerIconColour) {
              document.documentElement.style.setProperty(
                "--headerIconColour",
                response.headerIconColour
              );
            }
            if (response.navigationColour) {
              document.documentElement.style.setProperty(
                "--navbar-color",
                response.navigationColour
              );
            }
            if (response.navigationTextColour) {
              document.documentElement.style.setProperty(
                "--navigationTextColour",
                response.navigationTextColour
              );
            }
            if (response.primaryButtonColour) {
              document.documentElement.style.setProperty(
                "--primaryButtonColour",
                response.primaryButtonColour
              );
            }
            if (response.primaryButtonTextColour) {
              document.documentElement.style.setProperty(
                "--primaryButtonTextColour",
                response.primaryButtonTextColour
              );
            }
          }
        }
      );
  }

  backToDashboard() {
    this.routeInfo = ROUTES;
    // this.removeNomination()
    // this.router.navigate(['hr/dashboard']);
    this.apiDocumentationRoutesBoolean = false;
    this.manageSidebarRouteChange();
  }

  // redirect to externalPath
  // window.open should be triggered directly by the user interaction such as click. Otherwise popup blocker can occur. 
  // Any delay in the function between the click or touch event can occur popup blocker. So API calls here.
  handleRedirection(externalPath: string, event: Event) {
    event.preventDefault(); // Prevent the default link behavior
    let hrefTarget = linkTargetViaGoNative();
    this.loadShop = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.loadShop = false 
      }, 30000)
    }
    if(externalPath === 'shop') {
      if(hrefTarget === '_blank') {
        this.loadShop = false;
      }
      // no redirection to our login page for code generation as the token will be shared via cookie to the shop
      let shopUrl = getWPShopDomain();
      window.open(shopUrl, hrefTarget);
    } else {
      if(hrefTarget === '_blank') {
        this.loadShop = false;
      }
      window.open(externalPath, hrefTarget);
    }   
  }

  // when sidenav changes between api documentation and normal sidenav
  manageSidebarRouteChange() {
    //removing donate link from the list, as it is only accesible by  users with countrycode GB or "".
    this.sidebarItems = this.routeInfo.filter((sidebarItem) => {
      // as SVG is used for donate icon sanitize the html to load in innerHTML
      if (sidebarItem.imageCode) {
        if (typeof sidebarItem.imageCode !== "object") {
          sidebarItem.imageCode = this.sanitizer.bypassSecurityTrustHtml(sidebarItem.imageCode);
        }
      }
      
      // return false if the user has no permission to shows Donate
      if (!this.isMac) {
        return sidebarItem;
      } else {
        return sidebarItem["path"] !== "/hr/donate";
      }     
    });
  }

  //to show / remove from sidebar
  displayMenuItem(title: string){
    // start: hide EAP from Wellbeing Hub menu if the eapEnabled is false for the client
    if (title === "EAP" && !this.userFlags?.eapEnabled) {
      return false;
    }
    // end: hide EAP from Wellbeing Hub menu if the eapEnabled is false for the client

    // start: hide GP On Demand from Wellbeing Hub menu if the gpEnabled is false for the client
    if (title === "GP On Demand" && !this.userFlags?.gpEnabled) {
      return false;
    }
    // end: hide GP on Demand from Wellbeing Hub menu if the gpEnabled is false for the client

    // start: hide Earned Wage Access from Wellbeing Hub menu if the ewaEnabled is false for the client
    if (title === "Earned Wage Access" && !this.userFlags?.ewaEnabled) {
      return false;
    }
    // end: hide Earned Wage Access from Wellbeing Hub menu if the ewaEnabled is false for the client

    // start: hide Shop if the onlyDashboard boolean is true for the client
    if (title === "Shop" && this.userFlags?.onlyDashboard) {
      return false;
    }
    // end: hide Shop if the onlyDashboard boolean is true for the client

    // start: hide Buy Epoints if the voucherOnly flag is true
    if (title === "Buy Epoints" && this.userFlags?.voucherOnly) {
      return false;
    }
    // end: hide Buy Epoints if the voucherOnly flag is true

    // start: hide Buy Vouchers if the voucherOnly flag is false
    if (title === "Buy Vouchers" && !this.userFlags?.voucherOnly) {
      return false;
    }
    // end: hide Buy Vouchers if the voucherOnly flag is false

    // start: hide Salary Sacrifice menu should be disabled if the EV, technology, cycle2work and childcare all are not enabled
    if (
      title === "Salary Sacrifice" &&
      !this.salarySacrificeEVStatus?.isEnabled &&
      !this.salarySacrificeTechStatus?.salarySacrificeEnabled &&
      !this.salarySacrificeChildcareStatus?.isEnabled &&
      !this.salarySacrificeCycle2workStatus?.isEnabled &&
      !this.imperialClient
    ) {
      return false;
    }
    // end: hide Salary Sacrifice menu should be disabled if the EV, technology, cycle2work and childcare all are not enabled

    // start: hide Salary Sacrifice > Electric Vehicles if the isEnabled boolean is false for the client
    if (
      title === "Electric Vehicles" &&
      !this.salarySacrificeEVStatus?.isEnabled
    ) {
      return false;
    }
    // end: hide Salary Sacrifice > Electric Vehicles if the isEnabled boolean is false for the client

    // start: hide Salary Sacrifice > Technology if the salarySacrificeEnabled boolean is false for the client
    if (
      title === "Technology" &&
      !this.salarySacrificeTechStatus?.salarySacrificeEnabled
    ) {
      return false;
    }
    // end: hide Salary Sacrifice > Technology if the salarySacrificeEnabled boolean is false for the client

    // start: hide Salary Sacrifice > Childcare if the isEnabled boolean is false for the client
    if (
      title === "Childcare" &&
      !this.salarySacrificeChildcareStatus?.isEnabled
    ) {
      return false;
    }
    // end: hide Salary Sacrifice > Childcare if the isEnabled boolean is false for the client

    // start: hide Benefits link if the showBenefitsMenu flag is false
    if (
      title === "Benefits" &&
      !this.userFlags?.showBenefitsMenu &&
      this.currentUser?.role !== "superAdmin"
    ) {
      return false;
    }
    // end: hide Benefits link if the showBenefitsMenu flag is false

    // start: hide Salary Sacrifice > Cycle 2 Work if the isEnabled boolean is false for the client
    if (
      title === "Cycle 2 Work" &&
      !this.salarySacrificeCycle2workStatus?.isEnabled
    ) {
      return false;
    }
    // end: hide Salary Sacrifice > Cycle 2 Work if the isEnabled boolean is false for the client

    /** start: enable Technology Salary Sacrifice Voucher Request for the superAdmins having permission 'hr_request_voucher'
     * and for each_person partner with the role superAdmin */
    if (title === "Technology Salary Sacrifice Voucher Request") {
      if (
        this.userPermissionService.hasPermission("hr_request_voucher") &&
        this.currentUser?.role === "superAdmin" &&
        this.currentUser?.companyShortName === "each_person"
      ) {
        return true;
      } else {
        return false;
      }
    }
    /** end: enable Technology Salary Sacrifice Voucher Request for the superAdmins having permission 'hr_request_voucher'
     * and for each_person partner with the role superAdmin */

    /** start: enable Technology Salary Sacrifice Voucher Request for the superAdmins having permission 'hr_request_voucher'
     * and for each_person partner with the role superAdmin */
    if (title === "Benefits Reminder") {
      if (
        this.userPermissionService.hasPermission("hr_request_voucher") &&
        this.currentUser?.role === "superAdmin" &&
        (this.currentUser?.companyShortName === "each_person" ||
          this.currentUser?.companyShortName ===
            "_royal_national_orthopaedic_hospital")
      ) {
        return true;
      } else {
        return false;
      }
    }
    /** end: enable Technology Salary Sacrifice Voucher Request for the superAdmins having permission 'hr_request_voucher'
     * and for each_person partner with the role superAdmin */

    //check if companies need any menu control
    let _menuAccessControl = menuAccessControl;
    let companiesToCompare = Object.keys(_menuAccessControl);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let matchCompany = companiesToCompare.find(
      (client) => client == user?.companyShortName
    );
    if (!matchCompany) {
      //hide globally disabled paths
      let hiddenItem = globalHiddenRoutes.find((path) => path == title);
      return !!!hiddenItem;
    }

    //if control required find if it needs to be hidden or displayed; since only globally hidden items needs to be displayed it needs to be hidden again as done in else

    let toBeEnabled = _menuAccessControl[user?.companyShortName].enable?.find(
      (_title) => _title === title
    );
    let toBeDisabled = _menuAccessControl[user?.companyShortName].disable?.find(
      (_title) => _title === title
    );
    if (toBeEnabled) {
      return true;
    } else if (toBeDisabled) {
      return false;
    } else {
      let hiddenItem = globalHiddenRoutes.find((path) => path == title);
      return !!!hiddenItem;
    }
  }
}
