import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrCustomMessageService {
  successIcon = '<img src="assets/images/success.svg">';
  errorIcon = '<img src="assets/images/error-cross-icon.svg">';
  infoIcon = '';
  warningIcon = '<img src="assets/images/warning-icon.svg">';

  toastrProperties = {
    enableHtml: true,
    // disableTimeOut: true
  }

  constructor(
    private toastrService: ToastrService
  ) { }

  /**
   * following functions are the custom functions to use the html template with messages
   * @param message 
   * @param title 
   */
  success(message: string = null, title: string = null) {
    this.toastrService.success(`${ this.successIcon } ${ message }`, title, this.toastrProperties);
  }

  error(message: string = null, title: string = null) {
    this.toastrService.error(`${ this.errorIcon } ${ message }`, title, this.toastrProperties);
  }

  info(message: string = null, title: string = null) {
    this.toastrService.info(`${ this.infoIcon } ${ message }`, title, this.toastrProperties);
  }

  warning(message: string = null, title: string = null) {
    this.toastrService.warning(`${ this.warningIcon } ${ message }`, title, this.toastrProperties);
  }
}
