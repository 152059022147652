<div *ngIf="(replies| keyvalue)?.length" [attr.id]="'reply_display_'+replies?.id" class="comment-reply" [ngClass]="isMobileView ? 'mobile-only mb-3' : 'desktop-only mb-2'">
  <div class="card card-header reply-description bg-lightgrey-socialaspect" [ngClass]="isMobileView ? 'mobile-only' : 'desktop-only'">
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="name-container">
        <p class="font-16 font-weight-bold" [ngClass]="isMobileView ? 'mb-1' : 'm-0'">{{replies?.commentedByName}}</p>
      </div>

      <!-- start: add/edit replies dropdown for desktop view-->
      <div *ngIf="!isMobileView" class="font-weight-normal d-flex text-lightgrey">{{userService.dateAgo(replies?.createdAt)}}        
        <div *ngIf="canUserEditOrDelete" ngbDropdown class="comment-edit-drop">
          <span ngbDropdownToggle class="three-dots ml-1"><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/three-dots.svg"></span>
          <div ngbDropdownMenu aria-labelledby="replyActions">
            <button (click)="onEdit()" class="dropdown-item font-weight-normal edit-select m-0"><img
                src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/edit.svg">{{ 'Edit' | translate }}</button>
            <button (click)="removeReplyComment()" class="dropdown-item font-weight-normal delete-select"><img
                src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/delete.svg">{{ 'Delete' | translate }}</button>
          </div>
        </div>                
      </div>
      <!-- end: add/edit replies dropdown for desktop view -->

      <!-- start: add/edit template for mobile view -->
      <div *ngIf="isMobileView" class="time-and-options text-lightgrey mt-n4">
        <small class="font-weight-normal mr-2">{{userService.dateAgo(replies?.createdAt)}}</small>
        <span *ngIf="canUserEditOrDelete" class="three-dots ml-1" (click)="openEditDeleteModal()"><img
          src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/three-dots-vertical.svg">
        </span>
      </div>
      <!-- start: add/edit template for mobile view -->
    </div>
    <p *ngIf="!showEditInput" class="font-weight-normal font-16 mb-0" [innerHTML]="formattedReply"></p>

    <!-- edit reply: start -->
    <app-mention
    *ngIf="showEditInput"
    [placeholder]="replyPlaceholder"
    [showInputBox]="showEditInput"
    [fetchingSocialAspects]="fetchingSocialAspects"
    (saveText)="updateReply($event)"
    [parentDivClass]="'aspect-commentWrapper mt-0'"
    [editText]="true"
    [textData]="replies?.commentText"
    [uniqueTextId]="replies?.id + 'edit-reply'"
    [mentionedUsersList]="replies?.mentionedUsersInComment"
    (cancelEdit)="cancelEdit($event)"
    [isMobileView]="isMobileView"
    ></app-mention>
    <!-- edit reply: end -->
  </div>

  <!-- reply reaction and count: start -->
  <div class="commentReply d-flex justify-content-between align-items-end p-0">
    <ul>
      <li>
        <span *ngIf="!(userReactionInReply | keyvalue)?.length" (click)="likeReplyComment()">{{ 'Like' | translate }}</span>
        <img (click)="likeReplyComment()" *ngIf="userReactionInReply?.reactionType === 'LIKE'"
          src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/liked.svg">
      </li>
      <li>
        <span>{{ 'Reply' | translate }} 
          <!-- <span *ngIf="comments?.replyCount > 0" class="reply-count">{{comments?.replyCount}}</span> -->
        </span>
      </li>
    </ul>

    <!-- show likes and reply count: start -->
    <p *ngIf="replies?.reactionCount > 0" class="text-lightgrey font-weight-normal mb-0" style="line-height: 1;">
      <span class="like-count cursor" (click)="replies?.reactionCount > 0 ? openReactionModal() : null">{{replies?.reactionCount}} {{replies?.reactionCount === 1 ? ('like' | translate) : ('likes' | translate)
          }} </span>
    </p>
    <!-- show likes and reply count: end -->
  </div>
  <!-- reply reaction and count: end -->

  <!-- edit/delete modal for mobile view: start -->
  <ng-template #editDeleteModal let-c="close" let-d="dismiss" class="social-aspect-mobile">
    <div class="modal-body p-0">
      <button (click)="onEdit()" class="font-weight-normal edit-select m-0 btn-edit-modal"><span class="edit-custom"><img
        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/edit.svg">{{ 'Edit' | translate }}</span></button><br>
      <button (click)="removeReplyForMobile()"
        class="font-weight-normal delete-select btn-delete-modal"><img
        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/delete.svg">{{ 'Delete' | translate }}</button>
    </div>
  </ng-template>
  <!-- edit/delete modal for mobile view: end -->

</div>

<ng-template #reactionsModal let-c="close" let-d="dismiss">
  <app-social-aspect-reaction-modal
  [data]="replies"
  [currentUserId]="authService.currentUserValue.id"
  [isMobileView]="isMobileView"
  [showLikeOnly]="true"
  ></app-social-aspect-reaction-modal>
</ng-template>