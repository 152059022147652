import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SSChildcareModel, SSEVFlags, SSEmployeeChildcareModel, SSTechModel, SSCycle2workModel, SSHolidayModel, SSEmployeeHolidayModel, SSEmployeeGymModel } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SalarySacrificeService {
  private evSubject: BehaviorSubject<SSEVFlags> = new BehaviorSubject<SSEVFlags>(null);;
  public ssEvFlag: Observable<SSEVFlags> = this.evSubject.asObservable();

  private holidaySubject: BehaviorSubject<SSHolidayModel> = new BehaviorSubject<SSHolidayModel>(null);;
  public ssHolidayFlag: Observable<SSHolidayModel> = this.holidaySubject.asObservable();


  private techSubject: BehaviorSubject<SSTechModel> = new BehaviorSubject<SSTechModel>(null);;
  public ssTechFlag: Observable<SSTechModel> = this.techSubject.asObservable();
  private childcareSubject: BehaviorSubject<SSChildcareModel> = new BehaviorSubject<SSChildcareModel>(null);;
  public ssChildcareFlag: Observable<SSChildcareModel> = this.childcareSubject.asObservable();
  private employeeChildcareSubject: BehaviorSubject<SSEmployeeChildcareModel> = new BehaviorSubject<SSEmployeeChildcareModel>(null);;
  public ssEmployeeChildcareFlag: Observable<SSEmployeeChildcareModel> = this.employeeChildcareSubject.asObservable();
  private cycleToWorkSubject: BehaviorSubject<SSCycle2workModel> = new BehaviorSubject<SSCycle2workModel>(null);;
  public ssCycle2WorkFlag: Observable<SSCycle2workModel> = this.cycleToWorkSubject.asObservable();

  private ssGymSubject: BehaviorSubject<SSEmployeeGymModel> = new BehaviorSubject<SSEmployeeGymModel>(null);;
  public ssGymFlag: Observable<SSEmployeeGymModel> = this.ssGymSubject.asObservable();

  constructor(
    private http: HttpClient
  ) {}

  // get status for Electric Vehicles
  getEVStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/electric-vehicle/status`)
    .pipe(
      map((response: SSEVFlags) => {
        this.evSubject.next(response);
        return response;
      })
    );
  }

  // form submission API for Electric Vehicles
  submitEVDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/electric-vehicle`, payload);
  }

  submitHolidayDetailsEmployee(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/holiday-employee`, payload);
  }

  submitHolidayDetailsEmployer(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/holiday-employer`, payload);
  }
  submitEmployeeTechDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/technology-employee`, payload);
  }

  // get details for SS - Technology
  getTechDetails() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice`)
    .pipe(
      map((response: SSTechModel) => {
        this.techSubject.next(response);
        return response;
      })
    );
  }

  // submit tech form details
  submitTechDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice`, payload);
  }

  // get details for SS - Childcare
  getChildcareStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/child-care-employer/status`)
    .pipe(
      map((response: SSChildcareModel) => {
        this.childcareSubject.next(response);
        return response;
      })
    );
  }

  // submit childcare form details (for employer)
  submitChildcareDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/child-care-employer`, payload);
  }

  // get details for SS - Childcare (for all employees)
  getEmployeeChildcareStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/child-care-employee/status`)
    .pipe(
      map((response: SSEmployeeChildcareModel) => {
        this.employeeChildcareSubject.next(response);
        return response;
      })
    );
  }

  // submit employee childcare form details (for employee)
  submitEmployeeChildcareDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/child-care-employee`, payload);
  }

  // get details for SS - cycle2Work
  getCycle2WorkStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/cycle2Work-employer/status`)
    .pipe(
      map((response: SSCycle2workModel) => {
        this.cycleToWorkSubject.next(response);
        return response;
      })
    );
  }

  // get details for SS - cycle2Work (for all employees)
  getEmployeeCycle2WorkStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/cycle2Work-employee/status`);
  }



// get details for SS - holiday (for all employees)
  getEmployeeHolidayStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/holiday-employee/status`);
  }

  getEmployerHolidayStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/holiday-employer/status`)
    .pipe(
      map((response: SSEmployeeHolidayModel) => {
        this.holidaySubject.next(response);
        return response;
      })
    );
  }



  getEmployeeTechStatus(userId:string) {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/technology-employee/status?userId=${userId}`);
  }

  getEmployeeRequestTechnology(email:string) {
    return this.http.get<any>(`${ environment.apiUrl }/request-technology/status/?email=${encodeURIComponent(email)}`);
  }

  // submit employee cycle2work form details (for employee)
  submitEmployeeCycle2WorkDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/salary-sacrifice/cycle2Work-employee`, payload);
  }

  // lets connect sso
  initiateSSO() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/get-signed-saml`);
  }

  // electronic salary sacrifice - request voucher
  postRequestVoucher(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/request-technology`, payload);
  }

  getRequestVoucher(queryParams: any) {
    return this.http.get<any>(`${ environment.apiUrl }/request-technology`, {params: queryParams});
  }

  checkVoucherBalance(orderId: number) {
    return this.http.get<any>(`${ environment.apiUrl }/request-technology/check-balance/${ orderId }`);
  }

  getGymEmployeeStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/salary-sacrifice/gym`)
    .pipe(
      map((response: SSEmployeeGymModel) => {
        this.ssGymSubject.next(response);
        return response;
      })
    );; 
  }
}
