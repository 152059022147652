<section class="main-content manage-people ss-flow">
  <div class="text-center text-md-left w-100 mt-4">
    <p class="mb-3 font-weight-normal text-left"><a routerLink="/eyecare"
            class="text-dark"><strong>{{ 'Back' | translate }}</strong></a> <span class="d-lg-inline-block ml-2"><strong>{{ 'Eyecare' | translate }}</strong>  /
            {{ 'Order eVouchers' | translate }}</span></p>
</div>
  <div class="payment-sub-header-binder setup-completed d-flex">
    <h4 class="page-title mt-md-1"> {{ "Specsavers Eyecare" | translate }}</h4>
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-lg-7">
        <div class="card techForm-wrap">
          <div class="card-body">
            <form [formGroup]="specsaverForm" (ngSubmit)="onSubmit()">
              <label class="font-16 mb-2">1. {{ "Personal Details" | translate }}</label>
              <div class="form-group">
                <label for="firstName"
                  > {{ "First Name" | translate }} <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  aria-describedby="firstNameHelp"
                  [placeholder]="'e.g Susan' | translate"
                  formControlName="firstName"
                  [ngClass]="{
                    'is-invalid':
                      !specsaverForm.get('firstName').valid &&
                      (specsaverForm.get('firstName').touched ||
                        specsaverForm.get('firstName').dirty) &&
                      specsaverForm.get('firstName').errors
                  }"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    !specsaverForm.get('firstName').valid &&
                    (specsaverForm.get('firstName').touched ||
                      specsaverForm.get('firstName').dirty)
                  "
                >
                   {{ "First Name is required." | translate }}
                </small>
              </div>
              <div class="form-group">
                <label for="surname"
                  >{{ "Surname" | translate }} <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="surname"
                  aria-describedby="surnameHelp"
                  [placeholder]="'e.g Fox' | translate"
                  formControlName="surname"
                  [ngClass]="{
                    'is-invalid':
                      !specsaverForm.get('surname').valid &&
                      (specsaverForm.get('surname').touched ||
                        specsaverForm.get('surname').dirty) &&
                      specsaverForm.get('surname').errors
                  }"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    !specsaverForm.get('surname').valid &&
                    (specsaverForm.get('surname').touched ||
                      specsaverForm.get('surname').dirty)
                  "
                >
                   {{ "Surname is required." | translate }}
                </small>
              </div>
              <div class="form-group">
                <label for="email"
                  > {{ "Email Address" | translate }}<span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  [placeholder]="'e.g fox@warrickinc.com' | translate"
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid':
                      !specsaverForm.get('email').valid &&
                      (specsaverForm.get('email').touched ||
                        specsaverForm.get('email').dirty) &&
                      specsaverForm.get('email').errors
                  }"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    !specsaverForm.get('email').valid &&
                    (specsaverForm.get('email').touched ||
                      specsaverForm.get('email').dirty) &&
                    specsaverForm.get('email').errors.required
                  "
                >
                   {{ "Email is required." | translate }}
                </small>
                <small
                  class="form-text text-danger"
                  *ngIf="
                    !specsaverForm.get('email').valid &&
                    (specsaverForm.get('email').touched ||
                      specsaverForm.get('email').dirty) &&
                    specsaverForm.get('email').errors.email
                  "
                  > {{ "Invalid email address." | translate }}</small
                >
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <div>


                <strong class="font-16"
                  >{{ 'Do you work in front of a Visual Display Unit?' | translate }}</strong
                >
                <button
                  type="button"
                  class="btn btn-tooltip tooltip-hover mb-1"
                  data-bs-toggle="tooltip"
                  [ngbTooltip]="buttonLabelTooltip | translate"
                >
                  <i-feather
                    name="help-circle"
                    class="tooltip-icon"
                  ></i-feather>
                </button>
                <!-- on mobile/tablet view -->
                <button
                [ngbTooltip]="buttonLabelTooltip | translate"
                  type="button"
                  class="btn btn-tooltip tooltip-click mb-1"
                  triggers="click:blur"
                >
                  <i-feather
                    name="help-circle"
                    class="tooltip-icon"
                  ></i-feather>
                </button>
              </div>


                <!-- on mobile/tablet view -->

                <div>


                <label
                  class="custom-switch make-public info-group-customise p-0 m-0 ml-2"
                >
                  <input
                    type="checkbox"
                    name="custom-switch-checkbox"
                    class="custom-switch-input"
                    formControlName="workWithVDU"
                    (change)="changeWorkWithVDU()"
                  />
                  <span
                    class="custom-switch-indicator toggle-customise mr-0"
                  ></span>
                </label>
              </div>
              </div>

              <div class="form-group cts-btn-ev mb-0 py-4">
                <button
                  class="mobile-order-1 btn btn-continue btn-sm-screen"
                  [disabled]="!specsaverForm.valid || !submitting"
                  type="button"
                  (click)="onSubmit()"
                >
                   {{ "Apply" | translate }}
                </button>
              </div>

              <div>
                <p class="font-16 btn-sm-screen-text">
                   {{ "You’ll receive a message letting you know if you’ve been successful!" | translate }}
                </p>

                <p class="btn-sm-screen-text">
           {{ "Any questions please contact us at" | translate }}
                  <a href="mailto:support@eachperson.com"
                    >support&#64;eachperson.com</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
