import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
/**
 * need enhancement - please do not forget to check your blue notebook with page title: check google translate fixes (22/05/2024).
 * if no notebook, check the image in your mobile.
 * 
 * -- the google translate API is being called unnecessarily for the english language too 'en'. don't call
 * but return the same string.
 */
export class TranslateApiService {
  private _translatedCurrentLang = new BehaviorSubject<any>({});
  translatedStrings = this._translatedCurrentLang.asObservable();


  constructor(
    private httpBackend: HttpBackend,
    private languageService: LanguageService
  ) {}

  googleTranslate(texts: string[], tagetLanguage: string) {
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers: headers };
    let payload = {
      q: texts,
      target: tagetLanguage
    };

    let googleTranslationKey = 'AIzaSyBY17L_apVe1faY-77voIVbN5ZGowVyCs0';
    
    return httpWithoutInterceptor.post<any>(`https://translation.googleapis.com/language/translate/v2?key=${googleTranslationKey}`, payload, requestOptions).toPromise();
  }

  saveTranslatedText(key: string, value: string, targetLanguage: string) {
    let storedData = this.getTranslatedText(targetLanguage) || {};
    if(storedData && !storedData[key]) {
      storedData[key] = value;
      this.languageService.setLanguageData(storedData);
      // this.uploadJsonData(storedData, 'eachperson-asset-images', `dashboard-i18n/${targetLanguage}.json`);
    }
    
  }
  
  getTranslatedText(targetLanguage: string) {
    return this.languageService.getLanguageData();
  }
  
  getS3StoredJSON(targetLang?: string) {
    targetLang = targetLang || this.languageService.getSelectedLanguageCode();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    
    return httpWithoutInterceptor.get<any>(`https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/dashboard-i18n/${targetLang}.json`).toPromise();
  }
  
  async getTranslatedJSONFromS3(targetLang?: string): Promise<any> { 
    try {
      const translations = await this.getS3StoredJSON(targetLang);
      this._translatedCurrentLang.next(translations); // Update BehaviorSubject with fetched translations
      return translations;
    } catch (error) {
      throw error; // Propagate the error further
    }
  }
}
