import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable()
export class AddHttpHeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = <User> JSON.parse(localStorage.getItem('currentUser'))
    let requestUrl = request?.url.split('?');
    if(currentUser && currentUser.role === 'user' && requestUrl && requestUrl.length > 0 && !request.url.includes('https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/dashboard-i18n')) {
      let queryParams = {
        role: 'user'
      }
      request = request.clone({
        setParams: queryParams
      });
    }   
    
    return next.handle(request);
  }
}