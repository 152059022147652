import { Component, Input, OnInit } from '@angular/core';
import { User, UserFlags } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { Observable } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { SideNavUserDetailService, User_Detail } from 'src/app/core/service/sidenavUserDetails.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ShareBalanceService } from 'src/app/core/service/share-balance.service';
import { encryptToken, extractTsText, linkTargetViaGoNative, setEPCookie } from 'src/app/core/_helpers/global-functions';
import { getWPShopDomain } from 'src/app/core/_helpers/environment-variables';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-personal-balance',
  templateUrl: './mobile-personal-balance.component.html',
  styleUrls: ['./mobile-personal-balance.component.sass'],
  providers: [DestroyService]
})
export class MobilePersonalBalanceComponent implements OnInit {
  currentUser: User;
  UserDetail$: Observable<User_Detail>;
  userFlags: UserFlags;
  currency: string = this.languageService.getCurrency();
  //new ui variables
  balance = {
    companyBalanceInEpoints: null,
  };

  personalBalance = {
    confirmedEpoints: 0,
    loadingEpointsBalance: false
  };
  userEpointsTitle: string = "";
  @Input() styleClass: string = "mb-3";
  greetingName: string = null;
  loadShop: boolean = false;
  linkTarget: string = linkTargetViaGoNative();

  constructor(
    private authService: AuthService,
    private destroy$: DestroyService,
    private sideNavDetail: SideNavUserDetailService,
    private languageService: LanguageService,
    private shareBalanceService: ShareBalanceService,
    private router: Router
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata: any) => {
        this.currentUser = userdata;
        this.userEpointsTitle =
          userdata.role.toLowerCase() == "superadmin"
            ? extractTsText("Allocate company pot")
            : userdata.role.toLowerCase() == "manager"
            ? extractTsText("Reward your team")
            : extractTsText("Spend Your Balance");
      });
  }

  ngOnInit(): void {
    // the user_detail api is being called in the sidebar, so use observable to get the name
    this.UserDetail$ = this.sideNavDetail.userDetail$;
    this.UserDetail$.subscribe((userdetail: any) => {
      if (userdetail && userdetail?.name) {
        this.greetingName = `${userdetail.name}`;
      } else if (this.currentUser) {
        this.greetingName = `${this.currentUser.name || this.currentUser.login}`;
      }
    });

    // get current user flags
    this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        if(flags) {
          this.userFlags = flags
        }
      });

    // get the country selected from localStorage -- by checking if changed value
    this.languageService
      .watchCountryStorage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.currency = this.languageService.getCurrency();
      });

    // company/team balance if manager or superAdmin
    if (
      this.currentUser?.role === "manager" ||
      this.currentUser?.role === "superAdmin"
    ) {
      this.shareBalanceService.companyBalance
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.balance = response;
        });
    }

    // personal balance if user
    if (this.currentUser?.role === "user") {
      this.shareBalanceService.personalBalance
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.personalBalance = response;
        });
    }
  }

  goToShop() {
    this.loadShop = true;

    // set token in the cookie
    if (
      this.authService.getLoginSession()?.token &&
      this.authService.getLoginSession()?.refreshToken
    ) {
      setEPCookie(
        "access_token",
        encryptToken(this.authService.getLoginSession()?.token)
      );
      setEPCookie(
        "refresh_token",
        encryptToken(this.authService.getLoginSession()?.refreshToken)
      );
    }
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if (this.linkTarget === "_self") {
      setTimeout(() => {
        this.loadShop = false;
      }, 30000);
    }

    if (this.linkTarget === "_blank") {
      this.loadShop = false;
    }
    let shopUrl = getWPShopDomain();
    window.open(shopUrl, this.linkTarget);
  }

  //new ui functions and methods
  userRedirection() {
    if (this.currentUser.role.toLocaleLowerCase() == "superadmin")
      this.router.navigate(["/hr/points-allocation/grant-departments"]);
    if (this.currentUser.role.toLocaleLowerCase() == "manager")
      this.router.navigate(["/hr/points-allocation/choose-an-ecard"]);
    if (this.currentUser.role.toLocaleLowerCase() == "user") this.goToShop();
  }

}
