import { AuthService } from '../service/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { extractTsText } from '../_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {

  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authenticationService: AuthService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        /*
        * check if the 400 status error is thrown from the birthday or anniversary events,
        * then throw the whole object instead of just the message
        * birthday and anniversary milestones APIs are accessed by admin and managers only
        */
        var eventUrl = (err.url === `${location.origin}/api/ecards/milestones/birthdate/count` || err.url === `${location.origin}/api/ecards/milestones/workAnniversary/count`);
        if(err.status === 400 && eventUrl) {
          return throwError(err.error);
        }

        // if the error status is 400 and the message is in an array then throw the entire error object
        if (err.status === 400 && err?.error?.errors) {
          return throwError(err.error);
        }

        // if the status is 413 then throw max size exceeded message for image uploads --> check for image upload done by admins or managers only
        if(err.status === 413 && (err.url === `${location.origin}/api/image` || err.url === `${location.origin}/api/image/partner`)) {
          let maxExceedMessage = this.translate.instant(extractTsText('The image you are trying to upload exceeds the maximum weight allowed.'));
          return throwError(maxExceedMessage);
        }

        // check if logout failed
        let url = err?.url.split('?');
        let logoutAPI = `${environment.accessTokenAPI}/global-logout`;
        if(url && url.length > 0 && url[0] === logoutAPI) {
          // if sso user then show sso logout interface else normal logout interface
          this.authenticationService.handleCognitoLogoutRedirection();
        }        

        // if the status is 500, then change the message
        // if(err.status === 500) {
        //   var errMessage = 'Sorry, something went wrong. Please try again. If problem persists please contact support.';
        //   return throwError(errMessage);
        // }

        var errorArray = (err?.error && err?.error?.errors) ? err?.error?.errors[0].message : null;
        const error =  errorArray || err?.error?.message || err?.error?.error?.message || err?.statusText;
        return throwError(error);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
