import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
    NgbCalendar, NgbDate, NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { formatDate, formatDateRange } from 'src/app/core/_helpers/date-formatter';
import { extractTsText, getDateRangeItems } from 'src/app/core/_helpers/global-functions';

@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.sass']
})
export class DateRangeSelectionComponent implements OnInit, OnChanges {
  @Input('changedFromDate') fromDateChanged;
  @Input('changedToDate') toDateChanged;
  @Output() handleDateRangeChange = new EventEmitter();

  // date range
  dateRangeItems = getDateRangeItems();
  minDate = { year: 1940, month: 1, day: 1 };
  maxDate= { year:new Date().getFullYear(),month: 12,day:31 };

  constructor(
    private calendar: NgbCalendar,
    private formatter: NgbDateParserFormatter
  ) {
    this.dateRangeItems.range = formatDateRange(this.dateRangeItems.fromDate, this.dateRangeItems.toDate);
  }

  ngOnInit() {

  }
  ngOnChanges(){
     //change the date range input and calendar selection if the from and to dates are changed in URL/somewhere in the parent components
    if(this.fromDateChanged && this.toDateChanged) {
      var fromDate = new Date(this.fromDateChanged);
      var toDate = new Date(this.toDateChanged);
      this.dateRangeItems.fromDate = new NgbDate( fromDate.getFullYear(), fromDate.getMonth() + 1, fromDate.getDate() );
      this.dateRangeItems.toDate = new NgbDate(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate());
      this.dateRangeItems.range = formatDateRange(this.dateRangeItems.fromDate, this.dateRangeItems.toDate);
    }
  }

  emitChanges() {
    this.handleDateRangeChange.emit(this.dateRangeItems);
  }

  // on selecting date from the calendar datepicker
  onDateSelection(date: NgbDate, drop:any) {
    this.dateRangeItems.errors = null;
    if (!this.dateRangeItems.fromDate && !this.dateRangeItems.toDate) {
      this.dateRangeItems.fromDate = date;
    } else if (this.dateRangeItems.fromDate && !this.dateRangeItems.toDate && date.after(this.dateRangeItems.fromDate)) {
      this.dateRangeItems.toDate = date;
      drop.close(); // close the date picker on selection
    } else {
      this.dateRangeItems.toDate = null;
      this.dateRangeItems.fromDate = date;
    }

    // emit change input
    this.dateRangeItems.range = formatDateRange(this.dateRangeItems.fromDate, this.dateRangeItems.toDate);
    this.emitChanges();
  }

  isHovered(date: NgbDate) {
    return this.dateRangeItems.fromDate && !this.dateRangeItems.toDate && this.dateRangeItems.hoveredDate && date.after(this.dateRangeItems.fromDate) && date.before(this.dateRangeItems.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.dateRangeItems.fromDate) && date.before(this.dateRangeItems.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.dateRangeItems.fromDate) || date.equals(this.dateRangeItems.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(
    currentFromValue: NgbDate | null,
    currentToValue: NgbDate | null,
    input: string
  ) {
    this.dateRangeItems.errors = null;
    let value = input.split(' - ');

    if(value.length !== 2) {
      this.dateRangeItems.errors = extractTsText('Invalid Date. Enter a valid date to search.');
    } else {

      if(!this.checkDate(value[0]) || !this.checkDate(value[1])) {
        this.dateRangeItems.errors = 'Invalid Date. Enter a valid date to search.';
      } else {
        const fromParsed = this.formatter.parse(value[0]);
        const toParsed = this.formatter.parse(value[1]);
        if(formatDate(fromParsed) > formatDate(toParsed)) {
          this.dateRangeItems.errors = extractTsText('From Date cannot be greater than to date.');
        } else {
          if (this.calendar.isValid(NgbDate.from(fromParsed))) {
            this.dateRangeItems.fromDate = NgbDate.from(fromParsed);
          } else {
            // this.dateRangeItems.fromDate = currentFromValue;
            this.dateRangeItems.errors = extractTsText('Invalid from date. Enter a valid date to search.');
          }

          if (this.calendar.isValid(NgbDate.from(toParsed))) {
            this.dateRangeItems.toDate = NgbDate.from(toParsed);
          } else {
            // this.dateRangeItems.toDate = currentToValue;
            this.dateRangeItems.errors = extractTsText('Invalid to date. Enter a valid date to search.');
          }
        }
      }
    }
    this.dateRangeItems.range = formatDateRange(this.dateRangeItems.fromDate, this.dateRangeItems.toDate);
    this.emitChanges();
  }

  // check date with format
  checkDate(date) {
    return moment(date, 'DD/MM/YYYY', true).isValid();
  }

  resetToDefault() {
    if(this.dateRangeItems.errors) {
      this.dateRangeItems.range = formatDateRange(this.dateRangeItems.fromDate, this.dateRangeItems.toDate);
    }
  }


}
