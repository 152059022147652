import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';

declare let gonative_onesignal_info: any; // this data is used as global function variable

@Component({
  selector: 'app-sso-verification',
  templateUrl: './sso-verification.component.html',
  styleUrls: ['./sso-verification.component.sass'],
  providers: [DestroyService]
})
export class SsoVerificationComponent implements OnInit {
  cognitoLoginCode: string = null;
  companyShortName: string = null;
  redirectRoute: string = null;
  currentUser: User;
  token: string = null;
  loginSessions: any;
  state: string = null;
  loadingText: string = '';
  errorMessages: any = {
    title: null,
    message: null,
    buttonText: null
  };
  @ViewChild('loginErrorModal') loginErrorModal: TemplateRef<any>;
  redirectionParams = null

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private readonly destroy$: DestroyService,
    private modalService: NgbModal,
    private toastr: ToastrCustomMessageService,
    private translate: TranslateService
  ) {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      userdata => {
        this.currentUser = userdata;
      }
    );
  }
  /**
   * this is the alternative approach that hits to authenticate-token with code, redirectURI and companyShortName to get the access tokens
   */
  ngOnInit(): void {
    this.redirectionParams = this.authService.getOtherAppsRedirectURI();
    console.log('in sso page');
    this.spinner.hide();
    this.loginSessions = this.authService.getLoginSession();
    this.token = this.loginSessions.token;

    this.route.queryParams
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      params => {
        /**
         * you can check if the error exists in the queryParams by applying the condition as:
         * if(params.hasOwnProperty('error')) OR, if(Object.keys(params).indexOf('error') !== -1)
         */
        if(params.hasOwnProperty('code')) {
          this.cognitoLoginCode = params.code;
        }
        // state is the eachperson path where the user wants to redirect
        if(params.hasOwnProperty('state')) {
          this.state = params.state;
          this.authService.saveCognitoState(params.state);
        }
        if(params.hasOwnProperty('companyShortName')) {
          this.companyShortName = params.companyShortName
        }
        if(params.hasOwnProperty('redirectUrl')) {
          this.redirectRoute = params.redirectUrl;
        }
      }
    );

    if(this.companyShortName) {
      // store in localStorage as recognised sso user
      this.authService.storeSsoUser({enabled: true, companyShortName: this.companyShortName});
    }

    // if is sso user, has token and companyShortName
    if (this.authService.getSsoUser() && this.token && this.companyShortName && !this.cognitoLoginCode) {
      if(this.redirectRoute) { // if has redirectUrl
        this.router.navigateByUrl(`/${this.redirectRoute}`);
      } else { // has no redirectUrl
        this.authService.doRedirection(this.currentUser);
      }
    } else if (this.companyShortName && this.cognitoLoginCode) { // if code received with companyShortName
      this.getUserTokens();
    } else { // just check and redirect user
      // if(!this.token && !this.companyShortName) {
      //   this.spinner.show();
      // } else {
        this.authService.doRedirection(this.currentUser);
      // }

    }
  }

  getUserTokens() {
    let savedUrls = this.redirectionParams;
    if(savedUrls?.redirect_uri) {
      this.loadingText = extractTsText('Signing in ...');
    }

    this.authService.getAuthTokens(this.cognitoLoginCode, this.companyShortName).subscribe(
      response => {
        if(response.access_token) {
          if(navigator.userAgent.indexOf('gonative') > -1 && response?.uuid) {
            this.handleOneSignalData(response)
          } else {
            this.handleLoginRedirection(response)
          }
        }
      }, error => {
        console.log('sso login - get token error: ', error);
        if (this.token) { // sso login failed, token exists, do normal redirection
          if(!this.currentUser) {
            this.fetchMeAPI();
          } else {
            this.authService.doRedirection(this.currentUser); // do normal rediection
          }
        }
        else { // sso login failed, no token, redirect to login screen
          this.modalService.open(this.loginErrorModal, { centered: true, backdrop: 'static' });          
        }
      }
    );
  }
  
  async handleOneSignalData(loginResponse: any) {
    // this.toastr.info('i am here');
    let error = null;
    this.loadingText = "Signing in ..."
    
    /**
     * 
     * In eachperson, window.open is used in "Pick a reward", page.
     * In ios devices, When window.open is used, after logging out, the onesignal script is not found so the promise does not get executed.
     * For this, a timeout of 5 seconds is set. If the data or error is not received within 5 seconds, then a timeout throws an error.
     * It helps us to identify what to do next.
     * 
     * But now, we are implementing a second approach here, which stores the onesignal data to localStorage on first fetch.
     * 
     * If the script does not execute until 5 seconds, then use onesignal data stored in localStorage.
     * But if the data is not found in localStorage, then display the timeout error.
     */
    // call our API to save oneSignal info for the user and redirect
    const finalAction = (onesignaldata: any) => {
      // this.toastr.success(`gonative ${JSON.stringify(onesignaldata)}`);
      this.authService
      .postUserDataForOneSignal(loginResponse?.uuid, onesignaldata)
      .pipe(
        finalize(() => {
          // this.toastr.success('onesignal finalize');
          this.loadingText = ''
          this.handleLoginRedirection(loginResponse)
        })
      )
      .subscribe(
        (success) => {
          // this.toastr.success('Successfully registered onesignal info for push notification.');
        },
        (error) => {
          this.toastr.error(`${this.translate.instant(extractTsText('Error while registering your data for push notification:'))} ${error}`);
        }
      );
    }
    
    // fetch onesignal data from localStorage
    let gonativeData = this.authService.getGonativeData();
    
    // onesignal promise fuction check
    const fetchOneSignalData = async () => {
      try {
        if (typeof gonative_onesignal_info === 'function') {
          return await gonative_onesignal_info();
        } else {
          throw new Error(this.translate.instant(extractTsText('gonative_onesignal_info is not defined')));
        }
      } catch (error) {
        throw new Error(this.translate.instant(extractTsText('Failed to fetch OneSignal info: ')) + error);
      }
    };

    const timeout = (ms) => {
      return new Promise((_, reject) => {
        setTimeout(() => {
          reject(this.translate.instant(extractTsText('Timeout occurred while fetching onesginal data from device')));
        }, ms);
      });
    };
    
    const recursive = async (retries = 5, delay = 1000) => {
      // this.toastr.error('I am called');
      let oneSignalData = null;
      try {
        oneSignalData = await fetchOneSignalData();
      } catch (oneSignalError) {
        // alert(`failed fetching onesignal info ${retries}`);
        if (retries > 0) {
          setTimeout(() => {
            recursive(retries - 1, delay);
          }, delay);
        } else {
          error = oneSignalError + retries;
        }
      }
  
      if (error) {
        this.loadingText = ''
        this.toastr.error(this.translate.instant(extractTsText('Failed to fetch onesignal info! Proceed redirection')));
        setTimeout(() => {
          this.handleLoginRedirection(loginResponse)
        }, 3000)          
      } else if (oneSignalData) {
        // this.toastr.success('Found onesignal data from Gonative.')
        // save data to localStorage
        gonativeData.oneSignalData = oneSignalData
        this.authService.storeGonativeData(gonativeData)
        finalAction(gonativeData?.oneSignalData)
      }
    };
    
    try {
      await Promise.race([recursive(), timeout(5000)]);
    } catch (error) {
      this.loadingText = ''
      // if timeout error and found oneSignalData from localStorage, then use that data
      if(gonativeData?.oneSignalData) {
        // this.toastr.success('line 352: '+JSON.stringify(gonativeData?.oneSignalData))
        finalAction(gonativeData?.oneSignalData)
      } else{
        // Handle the error or timeout here
        this.toastr.error(this.translate.instant(extractTsText('Sorry something went wrong while fetching data for push notification. Please close and re-open the app.')));
        this.authService.destroyLoginSession()

        this.errorMessages.message = this.translate.instant(extractTsText('Sorry something went wrong while fetching data for push notification. Please close and re-open the app.'));
        this.modalService.open(this.loginErrorModal, { centered: true, backdrop: 'static' });
      }
    }
  }

  handleLoginRedirection(loginResponse: any) {
    /**
     * as new token is received, need to update user session and redirect user
     * so fetchMeAPI() is used to check /me API, update the session and redirect user
     */
    this.fetchMeAPI(); // check user and do redirection
  }

  fetchMeAPI() {
    // need fresh data from API so deleting cache me data
    this.authService.deleteMeCacheItem();

    this.authService.getMe()
      .pipe(take(1)) // take(1) is used to run the subscription once and not leak the subscription
      .subscribe(
        (res) => {
          if (res) {
            this.authService.doRedirection(res);
          }
        },
        (error) => {
          if(error === 'Logged user is not a business user') {
            // do nothing - handled in auth.service.ts already
          } else {
            this.errorMessages.message = this.translate.instant(extractTsText('Unable to fetch user detail. Please contact Each Person.'));
            this.modalService.open(this.loginErrorModal, { centered: true, backdrop: 'static' });
          }
        }
      );
  }

  goBack() {
    this.modalService.dismissAll();
    this.authService.handleCognitoLoginRedirection();
  }
}
