import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EyecareService {

  constructor() { }

  private employerFormData: any;
  private employeeFormData: any;



  // Getter method
  get employerData(): any {
    return this.employerFormData;
  }

  // Setter method
  set employerData(value: any) {
    this.employerFormData = value;
  }

  get employeeData(): any {
    return this.employeeFormData;
  }

  // Setter method
  set employeeData(value: any) {
    this.employeeFormData = value;
  }

  clearEmployerData(): void {
    this.employerFormData = null;
  }

  // Method to clear employee form data
  clearEmployeeData(): void {
    this.employeeFormData = null;
  }


}
