import { Injectable } from '@angular/core';
import { User, UserFlags } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  currentUser: User = null;
  userFlags: UserFlags = null;
  service = {
    userPermissions: [],
    availablePermissions: [
      "hr_global_config",
      "hr_reporting",
      "hr_grant_partners",
      "hr_grant_departments",
      "hr_grant_users",
      "hr_approve_points",
      "hr_add_users",
      "hr_add_managers",
      "hr_add_admin",
      "hr_add_super_admin",
      "hr_add_super_admin_foreign_partner",
      "hr_grant_partners",
      "hr_request_voucher"
    ]
  };

  constructor() {}

  
  setCurrentUser(userdata: any = null, userFlags: any = null) {
    this.currentUser = userdata;
    this.userFlags = userFlags;
    this.service.userPermissions = this.currentUser && this.currentUser.authorities ?
      this.currentUser.authorities :
      [];
  }

  // function to check logged-in user's permission
  hasPermission(permission: string) {
    var result = this.service.userPermissions.filter(
      eachPermission => {
        return eachPermission === permission;
      }
    );
    var finalresult = result.length > 0 ? true : false;
    return finalresult;
  }

  /**
   * function to check given permissions of logged-in user
   * if the user does not have permission that is specified then do not provide access
   * */ 
  hasPermissions(permissions) {
    var out;

    permissions.forEach(permission => {
      if (!this.hasPermission(permission)) {
        out = false;
        return false;
      } else {
        out = true;
      }
    })
    return out;
  }

  /**
   * function to check given permissions of logged-in user
   * if the user has any of the permissions that are specified then provide access
   * */
  hasPermissionsAny(permissions) {
    var out;

    permissions.forEach(permission => {
      if (this.hasPermission(permission) || out === true) {
        out = true;
        return true;
      } else {
        out = false;
      }
    })

    return out;
  }

  hasRoles(roles) {
    if(this.currentUser && !this.currentUser.role) {
      return false;
    }

    if (roles && roles.indexOf(this.currentUser.role) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * check if the disableUserUI of the logged-in user is true
   * if true, 
   * * then cannot add, edit (except role), delete, activate users and reset password
   * * Manage People should be hidden
   * * User should be redirected to the dashbord page if tries to access manage people route.
   */
  checkDisableUI() {
    return this.userFlags && this.userFlags.disableUserUI;
  }

  /**
   * check if the user is businessUser (eachperson's user) or not
   * check if the user is deleted
   * 
   * if not businessUser, then return false else true
   * if checked for positive response first, it might not work as expected
   */
  isAppAccessible(currentUser: any) {
    if(currentUser && (!currentUser.businessUser || (currentUser.status && currentUser.status !== 'active'))) {
      return false;
    }
    return true;
  }
}
