import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { UserPermissionService } from '../service/user-permission.service';

@Injectable({
  providedIn: 'root'
})
export class AccessEachpersonSuperadminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userPermissionService: UserPermissionService,
    private router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /** start: enable Electronic Salary Sacrifice for the superAdmins having permission 'hr_request_voucher'
     * and for each_person partner with the role superAdmin */
    let userdata = this.authService.currentUserValue;
    // set userdata for the UserPermissionService to check
    this.userPermissionService.setCurrentUser(userdata);
    
    // check if the user has the permission required by the router/URL.
    if (
      this.userPermissionService.hasPermission("hr_request_voucher") &&
      userdata?.role === "superAdmin" &&
      (userdata?.companyShortName === "each_person" ||
        userdata?.companyShortName === "_royal_national_orthopaedic_hospital")
    ) {
      return true;
    } else {
      //location.reload() insde then breaks the app and redirects user to login in IOS devices so used .then() only
      this.router.navigate(["/hr/dashboard"]).then();
      return false;
    }
  }
  
}
