import {Component, Inject, Input, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import { LayoutService } from 'src/app/core/service/layout.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.sass']
})
export class BreadCrumbComponent implements OnInit {
  @Input('bc') bc: any;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
  }

  openMobileSidebar(event: any) {
    if(window.innerWidth < 768) {
      // for mobile view
      this.layoutService.updateSidebarMobileView(true)
    } else {
      // for desktop view
      this.mobileMenuSidebarOpen(event, 'overlay-open')
    }
    
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    if (window.innerWidth < 1251) {
      const hasClass = event.target.classList.contains(className);
      if (hasClass) {
        this.renderer.removeClass(this.document.body, className);
        this.renderer.addClass(this.document.body, "sidebar-gone");
      } else {
        this.renderer.addClass(this.document.body, className);
        this.renderer.removeClass(this.document.body, "sidebar-gone");
      }
    } else {
      const hasClass = this.document.body.classList.contains("side-closed");
      if (hasClass) {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }

}
