import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, of, throwError } from 'rxjs';
import {catchError, delay, map, retryWhen, take, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {sortByPosition} from "../_helpers/data-sorter";
import { setEPCookie } from '../_helpers/global-functions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GrantUsersService {

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private authService: AuthService
  ) { }
  getFbStatus(){
    return this.http.get<any>(`${environment.apiUrl}/ecards/partner/isinfbworkplace`);
  }

  getTemplates(){
    return this.http.get<any>(`${environment.apiUrl}/ecards/templates/valid/v2`).pipe( tap(res => {
      res = sortByPosition(res, 'position')
    }));
  }

  getLimit(){
    const currentUser = this.authService.currentUserValue;
    let token = this.authService.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    let queryParams = {};
    //adding role user in query params, if the user has role=user.
    if (currentUser && currentUser.role === "user") {
      queryParams["role"] = "user";
    }
    let requestOptions = {headers: headers, params: queryParams};

    return defer(() => {
      return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/ecards/allocations/limit`, requestOptions);
    }).pipe(
      map(balance => {
        return balance;
      }), catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }), retryWhen((error) => {
        return error.pipe(
          delay(500),
          tap(() => {
            requestOptions.headers = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `${this.authService.getLoginSession()?.token}`,
            });
          }),
          take(10)
        );
      })
    );
  }

  // personal balance
  getPersonalBalance() {
    const currentUser = this.authService.currentUserValue;
    let token = this.authService.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    let queryParams = {};
    //adding role user in query params, if the user has role=user.
    if (currentUser && currentUser.role === "user") {
      queryParams["role"] = "user";
    }
    let requestOptions = {headers: headers, params: queryParams};
    return defer(() => {
      return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/user/balance`, requestOptions);
    })
    .pipe(
      map(balance => {
        setEPCookie('user_balance', balance?.confirmedEpoints);
        return balance;
      }), catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }), retryWhen((error) => {
        return error.pipe(
          delay(500),
          tap(() => {
            requestOptions.headers = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `${this.authService.getLoginSession()?.token}`,
            });
          }),
          take(10)
        );
      })
    );
  }

  findUsers(searchTerm: string = null, searchByEmployee: boolean){
    /**for employee search case (search by employee name) */
    if(searchByEmployee){
      if (searchTerm) {
        return this.http.get<any>(`${environment.apiUrl}/ecards/users?search=${encodeURIComponent(searchTerm)}`)
          .pipe(map(rsp => rsp));
      } else {
        return of([]);
      }
    }
    //* for department search case  (search by department name) */
    else {
      if (searchTerm) {
        var payload = {
          search: searchTerm
        }
        return this.http.put<any>(`${environment.apiUrl}/ecards/department/users`, payload)
          .pipe(map(rsp => rsp));
      } else {
        return of([]);
      }
    }

  }

  // API to send ecard (recgnise/reward)
  award(params: any, queryParams: any){
    return this.http.put<any>(`${environment.apiUrl}/ecards/award`, params)
  }

  // API to send ecard (recgnise/reward) by user role
  sendEcardByUserRole(data: any, queryParams: any) {
    return this.http.put<any>(`${environment.accessTokenAPI}/ecards/V2`, data, {params: queryParams});
  }

  // API to nominate by user role
  nominateByUserRole(data) {
    return this.http.put<any>(`${environment.accessTokenAPI}/ecards/nomination/V2`, data);
  }

  getNominations(){
    return this.http.get<any>(`${environment.apiUrl}/ecards/nomination`);
  }

  // API to nominate by superAdmin or manager
  sendNominations(params){
    return this.http.put<any>(`${environment.apiUrl}/ecards/award/nomination`, params);
  }
  getPrize(){
    return this.http.get<any>(`${environment.apiUrl}/ecards/prize`);
  }
  getSpecificReasons(){
    return this.http.get<any>(`${environment.apiUrl}/ecards/specificReason`);
  }

  fetchEcardTags(){
    return this.http.get<any>(`${ environment.apiUrl }/ecards/getTags`);
  }
}
