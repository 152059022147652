import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

/* function to format date of ngb-datepicker.
since the ngb-datepicker returns the value in like { year: 'yyyy', month: 'M', day: 'D' },
it is needed to format in yyyy-mm-dd format */
export function formatDate(ngbDate) {
  if(ngbDate) {
    let year = ngbDate.year;
    let month = ngbDate.month <= 9 ? '0' + ngbDate.month : ngbDate.month;;
    let day = ngbDate.day <= 9 ? '0' + ngbDate.day : ngbDate.day;;
    let finalDate = year + "-" + month + "-" + day;
    return finalDate;
  } else {
    return null;
  }
}

/**
 * format date in DD/MM/YYYY
 *
 */
export function formatDate2(ngbDate) {
  if(ngbDate) {
    let year = ngbDate.year;
    let month = ngbDate.month <= 9 ? '0' + ngbDate.month : ngbDate.month;;
    let day = ngbDate.day <= 9 ? '0' + ngbDate.day : ngbDate.day;;
    let finalDate = day + "/" + month + "/" + year;
    return finalDate;
  } else {
    return null;
  }
}

export function ordinalDate(dateValue) {
  var newdate = new Date(dateValue);
  // moment(newdate).format("Do MMMM YYYY");
  //Returns 1st January 2020

  return moment(newdate).format("Do");
}

export function dateAgo(value) {
  if (value) {
      const dateObject = new Date(value);
      const seconds = Math.floor((+new Date() - +dateObject) / 1000);

      if (seconds < 30) {
          return 'a few seconds ago';
      }

      const intervals = {
          'year': 31536000,
          'month': 2592000,
          'week': 604800,
          'day': 86400,
          'hour': 3600,
          'minute': 60,
          'second': 1
      };

      for (const interval in intervals) {
          const counter = Math.floor(seconds / intervals[interval]);

          if (counter > 0) {
              return counter === 1
                  ? `${counter} ${interval} ago`
                  : `${counter} ${interval}s ago`;
          }
      }
  }
  return value;
}


// format date range
export function formatDateRange(fromDate:NgbDate,toDate:NgbDate) {
  return (fromDate?''+('00'+fromDate.day).slice(-2)+"/"+('00'+fromDate.month).slice(-2)+"/"+fromDate.year:"")
          +" - "+
          (toDate?''+('00'+toDate.day).slice(-2)+"/"+('00'+toDate.month).slice(-2)+"/"+toDate.year:"");
}

//return date format DD-MM-yyyy
//for graph on insight page
export function formatDisplayDate(date){
  return moment(date).format('DD/MM/yyyy');
}

