import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DashboardService } from 'src/app/core/service/dashboard.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { DestroyService } from 'src/app/core/service/destroy.service';

@Component({
  selector: 'app-newfeature-popup-modal',
  templateUrl: './newfeature-popup-modal.component.html',
  styleUrls: ['./newfeature-popup-modal.component.sass'],
  providers: [DestroyService]
})
export class NewfeaturePopupModalComponent implements OnInit {
  @ViewChild("newfeaturePopupModal") newFeatureModal: ElementRef;
  currentUser: User;
  submitting: boolean = false
  
  constructor(
    private modalSerive: NgbModal,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private readonly destroy$: DestroyService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata: any) => {
        this.currentUser = userdata;
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getNewFeatureStatus()  
  }

  getNewFeatureStatus() {
    this.dashboardService.getNewFeaturePopupData(this.currentUser?.login).subscribe(
      response => {
        // if the user has never interacted with the public private new feature popup modal, then show it
        if(!response) {
          this.modalSerive.open(this.newFeatureModal, {'centered': true, 'backdrop': 'static'})
        }
      }
    )
  }

  onConfirm() {
    this.submitting = true
    this.dashboardService.postInteractionNewFeature(this.currentUser?.login)
    .pipe(
      finalize(() => {
        this.submitting = false
        this.modalSerive.dismissAll()
        this.getNewFeatureStatus()
        console.log('updates')
      })
    ).subscribe()
  }

}
