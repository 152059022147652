import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BenefitsService } from "../core/service/benefits.service";
import { AuthService } from "src/app/core/service/auth.service";
import { BenefitData } from "../core/models/benefit.model";
import { takeUntil } from "rxjs/operators";
import { DestroyService } from "src/app/core/service/destroy.service";
import { User } from "src/app/core/models/user";
import { orderBy } from "lodash";
import { extractTsText } from "../core/_helpers/global-functions";
import { TranslateService } from "@ngx-translate/core";

interface Benefit {
  id: number;
  image: string;
  title: string;
  text: string;
  longDesc: string;
  buttonText?: string;
  buttonLink?: string;
  benefitType: string;
  filterValue: string;
  isDefaultBenefit?: boolean;
}

interface Tab {
  name: string;
  benefits: Benefit[];
}

@Component({
  selector: "app-benefits",
  templateUrl: "./benefits.component.html",
  styleUrls: ["./benefits.component.sass"],
})
export class BenefitsComponent implements OnInit {
  currentUser: User;
  tabs: Tab[] = []; // for display
  selectedTab: Tab;
  displayedBenefits: Benefit[] = [];
  benefitsLoading: boolean = true;
  displayAllBenefits: boolean = false;
  viewAllLabel: string = extractTsText("View All");
  totalBenefitsLength: number = 0;
  displayedBenefitsCount: number = 0;
  benefitsPaginationText: string;

  constructor(
    private router: Router,
    private benefitsService: BenefitsService,
    public authService: AuthService,
    private readonly destroy$: DestroyService,
    private translate: TranslateService
  ) {
    const prevLang = this.authService.currentUserValue?.userLanguageCode;
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata: any) => {
        this.currentUser = userdata;
        
        const newLang = this.currentUser?.userLanguageCode;

        if(prevLang !== newLang) {
          this.getTranslation();
        }
      });
  }

  getTranslation() {
    this.translate.get(extractTsText(`Showing {{ displayedBenefitsCount }} of {{ totalBenefitsLength }} Benefits`), {
      displayedBenefitsCount: this.displayedBenefitsCount,
      totalBenefitsLength: this.totalBenefitsLength
    }).subscribe((res: string) => {
      this.benefitsPaginationText = res;
    });    
  }

  sortBenefits(benefits: any[]): any[] {
    return orderBy(benefits, ["isDefaultBenefit"], ["desc"]);
  }

  ngOnInit(): void {
    this.loadBenefits();
  }

  loadBenefits(): void {
    this.benefitsLoading = true;
    this.benefitsService.fetchBenefits().subscribe(
      (data) => {
        this.tabs = this.createTabs(data);
        this.selectTab(this.tabs[0]);
        this.benefitsLoading = false;
        this.getTranslation();
      },
      (error) => {
        this.benefitsLoading = false;
      }
    );
  }

  createTabs(apiBenefits: BenefitData[]): Tab[] {
    const tabs: { [key: string]: Benefit[] } = {};
    let benefitsWithoutTab = [];
    apiBenefits.forEach((apiBenefit) => {
      const tabName = apiBenefit.filterValue;

      // if tabName has value and this tabName does not have respective benefits or undefined then make tabs[tabName] = []
      if (tabName && (!tabs[tabName] || tabs[tabName] === undefined)) {
        tabs[tabName] = [];
      }
      // if tabName has value and also has respective benefits then push them in an array and map them
      if (tabName && tabs[tabName]) {
        tabs[tabName].push(this.mapBenefit(apiBenefit));
      }

      // if no tabName, then add this in All tab
      if (!tabName) {
        benefitsWithoutTab.push(this.mapBenefit(apiBenefit));
      }
    });

    let newTabs = Object.keys(tabs).map((tabName) => ({
      name: tabName,
      benefits: tabs[tabName],
    }));

    // if the filterValue is null, then they are considered benefits without tabs so include them in All tab.
    const allBenefits = newTabs
      .reduce((acc, tab) => acc.concat(tab.benefits), [])
      .concat(benefitsWithoutTab);
    this.totalBenefitsLength = allBenefits.length;
    newTabs.unshift({
      name: this.translate.instant(extractTsText("All")),
      benefits: allBenefits,
    });

    return newTabs;
  }

  selectTab(tab: Tab): void {
    this.selectedTab = tab;
    const sortedBenefits = this.sortBenefits(this.selectedTab.benefits);
    // this.displayedBenefits = tab.benefits.slice(0, 12);
    if (this.currentUser && this.currentUser.role === "superAdmin") {
      this.displayedBenefits = sortedBenefits.slice(0, 11);
    } else {
      this.displayedBenefits = sortedBenefits.slice(0, 12);
    }
    this.displayAllBenefits = false;
    this.displayedBenefitsCount = this.displayedBenefits?.length;
  }

  viewAll(): void {
    if (this.displayAllBenefits) {
      const sortedBenefits = this.sortBenefits(this.selectedTab.benefits);
      if (this.currentUser && this.currentUser.role === "superAdmin") {
        this.displayedBenefits = sortedBenefits.slice(0, 11);
      } else {
        this.displayedBenefits = sortedBenefits.slice(0, 12);
      }
      this.viewAllLabel = "View All";
      this.displayedBenefitsCount = this.displayedBenefits?.length;
    } else {
      this.displayedBenefits = this.selectedTab.benefits;
      this.viewAllLabel = extractTsText("View Less");
      this.displayedBenefitsCount = this.displayedBenefits?.length;
    }
    this.displayAllBenefits = !this.displayAllBenefits;
  }

  viewBenefitDetail(benefit: Benefit): void {
    this.router.navigate(["/benefits", benefit.id]);
  }

  addNewBenefit(): void {
    this.router.navigate(["/benefits/new"]);
  }

  editBenefit(benefit: Benefit): void {
    this.router.navigate(["/benefits/edit", benefit.id]);
  }

  private mapBenefit(apiBenefit: BenefitData): Benefit {
    return {
      id: apiBenefit.id,
      image: apiBenefit.iconURL,
      title: apiBenefit.title,
      text: apiBenefit.previewText,
      longDesc: apiBenefit.longDescription,
      buttonText: apiBenefit.buttonText,
      buttonLink: apiBenefit.buttonURL,
      filterValue: apiBenefit.filterValue,
      benefitType: apiBenefit.benefitType,
      isDefaultBenefit: apiBenefit.benefitType === "DEFAULT",
    };
  }
}
