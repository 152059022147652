import { Component, OnInit } from "@angular/core";
import { extractTsText } from "src/app/core/_helpers/global-functions";
import {
  BenefitsReminderService,
  ApiClient,
  EmailHistory,
} from "../core/service/benefit-reminder.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ToastrCustomMessageService } from "src/app/core/service/toastr-custom-message.service";

interface Client extends ApiClient {
  isChecked: boolean;
}

@Component({
  selector: "app-benefits-reminder",
  templateUrl: "./benefits-reminder.component.html",
  styleUrls: ["./benefits-reminder.component.sass"],
})
export class BenefitsReminderComponent implements OnInit {
  moreTooltip: string = extractTsText("list of partners here!");

  allClients: Client[] = []; // Store all clients
  filteredClients: Client[] = []; // Store filtered clients
  emailHistory: EmailHistory[] = [];
  allChecked: boolean = false;
  hasSelectedClients: boolean = false;
  itemsLoading: boolean = true;
  noResultsMessage: string = "";
  pagination = {
    currentPage: <number>1,
    pageSize: <number>10,
    totalResults: <number>0,
  };
  fetchingList: boolean = false;

  constructor(
    private benefitsReminderService: BenefitsReminderService,
    private translate: TranslateService,
    private toastr: ToastrCustomMessageService
  ) {}

  ngOnInit(): void {
    this.loadClients();
    this.loadEmailHistory();
  }

  loadClients(): void {
    this.itemsLoading = true;
    this.benefitsReminderService.getClients().subscribe((clients) => {
      this.allClients = clients.map((client) => ({
        ...client,
        isChecked: false,
      }));
      this.itemsLoading = false;
      this.filteredClients = [...this.allClients]; // Initialize filtered clients with all clients
      this.updateAllCheckedStatus();
      this.updateHasSelectedClients();
    });
  }

  loadEmailHistory(): void {
    this.fetchingList = true;
    let queries = {
      pageSize: this.pagination.pageSize,
      page: this.pagination.currentPage - 1,
    };
    this.benefitsReminderService
      .getEmailHistory(queries)
      .subscribe((history: any) => {
        this.fetchingList = false;
        this.emailHistory = history?.content;
        this.pagination.totalResults = history?.totalElements;
      });
  }

  toggleAllClients(): void {
    this.filteredClients.forEach((client) => {
      client.isChecked = this.allChecked;
      this.updateClientCheckedStatus(client.partnerId, client.isChecked);
    });
    this.updateHasSelectedClients();
  }

  toggleClient(client: Client): void {
    if (this.filteredClients.length > 0) {
      this.updateClientCheckedStatus(client.partnerId, client.isChecked);
      this.updateAllCheckedStatus();
      this.updateHasSelectedClients();
    }
  }

  updateAllCheckedStatus(): void {
    this.allChecked =
      this.filteredClients.length > 0 &&
      this.filteredClients.every((client) => client.isChecked);
  }

  updateHasSelectedClients(): void {
    this.hasSelectedClients = this.filteredClients.some(
      (client) => client.isChecked
    );
  }

  updateClientCheckedStatus(clientId: number, isChecked: boolean): void {
    const allClientIndex = this.allClients.findIndex(
      (c) => c.partnerId === clientId
    );
    const filteredClientIndex = this.filteredClients.findIndex(
      (c) => c.partnerId === clientId
    );

    if (allClientIndex !== -1) {
      this.allClients[allClientIndex].isChecked = isChecked;
    }
    if (filteredClientIndex !== -1) {
      this.filteredClients[filteredClientIndex].isChecked = isChecked;
    }
  }

  sendReminderEmail(): void {
    const selectedClients = this.filteredClients.filter(
      (client) => client.isChecked
    );
    const totalUsersToNotify = selectedClients.reduce(
      (sum, client) => sum + client.inactiveUsers,
      0
    );
    const totalPartnersToNotify = selectedClients.length;

    const notifyUsers = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });

    notifyUsers
      .fire({
        title: this.translate.instant(`Are you sure?`),
        html: this.translate.instant(
          extractTsText(
            `This action will send a reminder email to <b>{{totalUsersToNotify}}</b> users from <b>{{totalPartnersToNotify}}</b> selected clients.`
          ),
          {
            totalUsersToNotify,
            totalPartnersToNotify
          }
        ),
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: this.translate.instant("Confirm"),
        cancelButtonText: this.translate.instant("Cancel"),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.performSendReminderEmail(selectedClients);
        }
      });
  }

  performSendReminderEmail(clients: Client[]): void {
    const payload = clients.map((client) => client.partnerId);
    this.benefitsReminderService.sendBenefitReminder(payload).subscribe(
      (response) => {
        // Handle successful response
        this.toastr.success(
          this.translate.instant(
            extractTsText("Reminder emails sent successfully.")
          )
        );
        // Refresh the email history
        this.loadEmailHistory();
        // reset pagination
        this.pagination.currentPage = 1;
        this.pagination.pageSize = 10;
      },
      (error) => {
        // Handle error
        console.error("Error sending reminder emails:", error);
        this.toastr.error(
          this.translate.instant(
            extractTsText("Failed to send reminder emails.")
          )
        );
      }
    );
  }

  filterClients(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredClients = this.allClients.filter((client) =>
      client.partnerName.toLowerCase().includes(searchTerm)
    );

    if (this.filteredClients.length === 0 && searchTerm !== "") {
      this.noResultsMessage = `${this.translate.instant(extractTsText('No partners found with keyword'))} '${searchTerm}'`;
    } else {
      this.noResultsMessage = "";
    }

    this.updateAllCheckedStatus();
    this.updateHasSelectedClients();
  }

  onPageChanged(page: number) {
    this.pagination.currentPage = page;
    this.loadEmailHistory();
  }

  onPageSizeChanged(pageSizeCount: number) {
    this.pagination.pageSize = pageSizeCount;
    this.pagination.currentPage = 1;
    this.loadEmailHistory();
  }
}
