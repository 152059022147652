<!-- example from https://stackblitz.com/edit/ngx-stripe-live-dbyrvs?file=src%2Fapp%2Fpluto.service.ts,src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.module.ts -->
<form class="form-group mb-2" [formGroup]="checkoutForm"
ngxStripeCardGroup
[elementsOptions]="elementsOptions">
    <h4 class="text-center">{{ 'Your Details' | translate }}</h4>
    <div class="row">
        <div class="col-md-6 form-group mb-3">
            <label class="info-group">{{ 'Name' | translate }} <span class="text-danger">*</span>
            </label>
            <input type="text" class="form-control" formControlName="name" [ngClass]="{
                'is-invalid':
                !checkoutForm.get('name').valid &&
                (checkoutForm.get('name').touched ||
                checkoutForm.get('name').dirty)
            }">
            <small
                class="form-text text-danger"
                *ngIf="
                    !checkoutForm.get('name').valid &&
                    (checkoutForm.get('name').touched ||
                    checkoutForm.get('name').dirty)
                "
                >{{ 'Name is required.' | translate }}</small>
        </div>
        <div class="col-md-6 form-group mb-3">
            <label class="info-group">{{ 'Email' | translate }} <span class="text-danger">*</span>
            </label>
            <input type="email" class="form-control" formControlName="email" [ngClass]="{
                'is-invalid':
                !checkoutForm.get('email').valid &&
                (checkoutForm.get('email').touched ||
                checkoutForm.get('email').dirty)
            }">
            <small class="form-text text-danger" *ngIf="
                !checkoutForm.get('email').valid &&
                (checkoutForm.get('email').touched ||
                checkoutForm.get('email').dirty) && checkoutForm.get('email').errors && checkoutForm.get('email').errors.required
            ">{{ 'Email is required.' | translate }}</small>
            <small class="form-text text-danger" *ngIf="
                !checkoutForm.get('email').valid &&
                (checkoutForm.get('email').touched ||
                checkoutForm.get('email').dirty) && checkoutForm.get('email').errors && checkoutForm.get('email').errors.email
            ">{{ 'Invalid email address.' | translate }}</small>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 form-group mb-3">
            <label class="info-group">{{ 'Address' | translate }} <span class="text-danger">*</span>
            </label>
            <input type="text" class="form-control" formControlName="address" [ngClass]="{
                'is-invalid':
                !checkoutForm.get('address').valid &&
                (checkoutForm.get('address').touched ||
                checkoutForm.get('address').dirty)
            }">
            <small
                class="form-text text-danger"
                *ngIf="
                    !checkoutForm.get('address').valid &&
                    (checkoutForm.get('address').touched ||
                    checkoutForm.get('address').dirty)
                "
                >{{ 'Address is required.' | translate }}</small>
        </div>
        <div class="col-md-6 form-group mb-3">
            <label class="info-group">Postcode <span class="text-danger">*</span>
            </label>
            <input type="text" class="form-control" formControlName="zipcode" [ngClass]="{
                'is-invalid':
                !checkoutForm.get('zipcode').valid &&
                (checkoutForm.get('zipcode').touched ||
                checkoutForm.get('zipcode').dirty)
            }">
            <small
                class="form-text text-danger"
                *ngIf="
                    !checkoutForm.get('zipcode').valid &&
                    (checkoutForm.get('zipcode').touched ||
                    checkoutForm.get('zipcode').dirty)
                "
                >{{ 'Postcode is required.' | translate }}</small>
        </div>
    </div>

    <hr/>

    <h4 class="text-center">{{ 'Card Details' | translate }}</h4>
    <div class="carddetails">
        <div class="row">
            <div class="col-sm-12 form-group mb-3">
                <label class="info-group">{{'Card number' | translate }} <span class="text-danger">*</span>
                </label>
                <div class="cardNumber-field">
                    <ngx-stripe-card-number [options]="rtlOptions" (change)="onChangeCardNumber($event)"></ngx-stripe-card-number>
                    <i [class]="cardBrandIcon || 'far fa-credit-card card-icon'"></i>
                </div>
                <small class="form-text text-danger" *ngIf="cardNumberError">{{ cardNumberError }}</small>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 form-group mb-3">
                <label class="info-group">{{ 'Expiry' | translate }} <span class="text-danger">*</span>
                </label>
                <ngx-stripe-card-expiry [options]="rtlOptions" (change)="onChangeCardExpiry($event)"></ngx-stripe-card-expiry>
                <small class="form-text text-danger" *ngIf="cardExpiryError">{{ cardExpiryError }}</small>
            </div>
            <div class="col-sm-6 form-group mb-3">
                <label class="info-group">CVC <span class="text-danger">*</span>
                </label>
                <ngx-stripe-card-cvc [options]="rtlOptions" (change)="onChangeCardCvc($event)"></ngx-stripe-card-cvc>
                <small class="form-text text-danger" *ngIf="cardCvcError">{{ cardCvcError }}</small>
            </div>
        </div>

        <div class="row text-center mt-28 mb-1">
            <div class="col-sm-12">
                <div class="cardDetail-btn">
                    <button type="button" class="btn btn-secondary btn-nominate mr-2" (click)="clear()" [disabled]="paying">{{ 'Cancel' | translate }}</button>
                    <button class="btn btn-primary btn-nominate"
                    [disabled]="paying || checkoutForm.invalid || cardNumberHasError || cardExpiryHasError || cardCvcHasError"
                    (click)="pay()">
                        <span *ngIf="!paying">
                            {{ successButtonName || 'Topup' | translate }} <span *ngIf="amount">{{ currency }}{{ amount | number: '.2-2' }}</span>
                        </span>
                        <span *ngIf="paying">{{ 'PROCESSING...' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>


  