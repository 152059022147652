'use strict';
import { getCred } from "../../app/core/_helpers/gonativeGlobal";

export class GonativeGlobal {
  constructor() {}

  loadDeviceInfo() {
    // alert('In function')
    // alert('Finding device info')
    return new Promise(function(resolve, reject) {
      // alert('inside device promise')
      // alert('window.gonative: ' + !!window.gonative)
      window.gonative.deviceInfo().then(function(actualDeviceInfo) {
        if(actualDeviceInfo) {
          // alert('device info found')
          resolve(actualDeviceInfo)
        } else {
          // alert('device info not found')
          reject('Device info not found')
        }
      }).catch(function(error) {
        reject('Device info not found'+error)
      })
    })
  }

  checkBiometricStatus() {
    return new Promise(function(resolve, reject) {
      window.gonative.auth.status({
        'callbackFunction': function(data) {
          if(data) {
            resolve(data)
          } else {
            resolve(false)
          }
          // if (data && data.hasTouchId) {
          //   var secret = JSON.stringify({
          //     username: username,
          //     password: password
          //   });
          //   gonative.auth.save({'secret': secret});
          //   resolve('Authentication status updated successfully');
          // } else {
          //   reject('Authentication status update failed');
          // }
        }
      });
    });
  }

  promptBiometrics(){
    return new Promise(function(resolve, reject) {
      window.gonative.auth.get({
        'callbackFunction': function(data) {
          if (data && data.success && data.secret) {
            // alert("Authentication Successful" + data.secret);
            resolve(data.secret)
          } else {
            resolve("Authentication Failed");
            // Allow manual entry
          }
        }, 'callbackOnCancel' : 1
      });
    });
  }

  // delete secret if credentials are incorrect
  deleteBiometric() {
    // alert('inside delete')
    return new Promise(function(resolve, reject) {
      window.gonative.auth
      .delete()
      .then(function () {
        // alert("invalid cerds - deleted successfully")
        resolve("invalid cerds - deleted successfully");
      })
      .catch(function (error) {
        // alert('delete error -'+error)
        reject(error);
      });
    });
  }

  saveSecret(){
    return new Promise(function(resolve, reject) {
      window.gonative.auth.status({
        'callbackFunction': function(data) {
          if (data && data.hasTouchId) {
            let secret = JSON.stringify(getCred());
            // alert(`${secret}while saving secret`);
            window.gonative.auth.save({secret: secret});
            // alert("secret saved")
            resolve(true)
          } else {
            reject(false)
          }
        }
      });
    });
  }

  removeBottomNav(){
    window.gonative.tabNavigation.setTabs({'enabled': false});
  }
  addBottomNav(){
    window.gonative.tabNavigation.setTabs({'enabled': true});
  }
}
