import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpecsaverEyecareService } from '../../service/eyecare.service';
import { AuthService } from '../../service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AccessIfEmployeeNotSubmittedEyecareForm implements CanActivate {

  private destroy$: Subject<void> = new Subject<void>();


  constructor(
    private eyecareService: SpecsaverEyecareService,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Observable<boolean>
      (obs => {
        this.eyecareService.getEmployeeEyecareStatus()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            this.spinner.hide()
            if(data?.isSubmitted) {
              this.router.navigate(['/eyecare-employee-success']).then()
              obs.next(false);
            }
            else {
              obs.next(true);
            }
          },
          error => {
            this.spinner.hide()
            obs.next(false);
          }
        );
      });
  }
  }


