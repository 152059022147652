import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /**
   * below are the codes to change country flag, currency, currency value as per the country details of the logged-in useruser
   * received from the API
   * the APIs to look into are api/users/me and api/partner/countries
   */

  /**
   * Observable in Angular is a feature that provides support for delivering messages between different parts of your single-page application.
   * This feature is frequently used in Angular because it is responsible for handling multiple values, asynchronous programming in Javascript,
   * and also event handling processes.
   * You'll need to initialize an Observable method which returns a Subject variable to
   * track changes in the country values as per the country details received from the API.
   * reference - https://stackoverflow.com/questions/49881172/angular-how-to-always-get-the-newest-data-at-localstorage
   */
  private countryStatus = new Subject<string>(); // this is to detect if the country in the localStorage is changed

  private currentCountry: any = null;
  private currentLanguage: any = null;

  private languageStatus = new Subject<string>();
  languageUpdated = this.languageStatus.asObservable();
  
  languageData: any = null;

  liveAgentChatEvent: any = null;

  constructor() {
  }

  // as the api/partner/countries API is called from the header on every page change, no need to store the country data in localStorage
  public setCountry(country: any){
    this.currentCountry = country;
    this.countryStatus.next('changed country values');
  }

  // check if the country status is changed so that the getCountry() can be called in the components depending on this status
  watchCountryStorage(): Observable<any> {
    return this.countryStatus.asObservable();
  }

  // set language update status
  setLanguageStatus(language: any) {
    this.currentLanguage = language;
    this.languageStatus.next('language updated');
  }

  getLanguage() {
    return this.currentLanguage;
  }

  public getCurrency(){
    if(!this.getCountry()){
      return "£";
    }
    return this.getCountry().currency;

  }

  public getCountry() {
    return this.currentCountry;
  }

  public getRate() {
    let rate: number = 1;
    if (this.getCountry()) {
      rate = Number(this.getCountry().exchangeRate);
    } else {
      rate = 1;
    }
    return rate;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getSelectedCountryCode() {
    const country = this.getCountry()?.code || "GB";
    return country;
  }
  
  public getSelectedLanguageCode() {
    let localData = this.getLanguage()?.code || 'en_GB';
    return localData;
  }

  setLanguageData(data: any) {
    this.languageData = data;
  }

  public getLanguageData() {
    return this.languageData;
  }

  setLiveAgentChantEvent(event: any) {
    this.liveAgentChatEvent = event;
  }

  getLiveAgentChatEvent() {
    return this.liveAgentChatEvent;
  }
}