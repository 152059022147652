<div class="login-wrapper">
    <section class="login">
        <div class="resetpassword-dialog">
            <form class="form">
                <span class="back-customise" (click)="onBackButton()">
                    <i-feather name="chevron-left" class="icon-sm chevron-customise"></i-feather>Back
                </span>
                <div class="logo badge-customise text-center my-4 mt-5">
                    <img width="65px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/mail.png">
                    <span class="badge-inner bg-primary">1</span>
                </div>
                <h5 class="text-center mb-1">Check Your Email</h5>
                <p class="font-weight-normal text-center">We sent you an email with a link that will log you in at
                    {{email}}</p>
                <!-- <input name="signInSubmitButton" type="Submit" value="Open mail"
                    class="btn btn-primary resetButton-customizable mt-1" aria-label="submit"> -->
                <div class="logo text-center mt-4">
                    <img width="80px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise" /> @ Each Person
                    {{ currentYear }}
                </div>
                <div class="logo text-center">
                    Powered by <img width="38px" alt="logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
                </div>
            </form>
        </div>
    </section>
    </div>
