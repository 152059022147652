<div class="main-balance epoints-count-binder pt-0" *ngIf="currentUser && currentUser.setUpCompleted && (currentUser.role === 'superAdmin' || currentUser.role === 'manager') && userFlags && !userFlags?.onlyDashboard">
    <p class="mb-0">
        {{
        currentUser.role === "superAdmin"
            ? (userFlags?.voucherOnly
                ? ('Your Company Voucher Balance' | translate)
                : ('Your Company Epoints Balance' | translate))
            : (userFlags?.voucherOnly
                ? ('Your Team Voucher Balance' | translate)
                : ('Your Team Epoints Balance' | translate))
        }}: </p>
    <span>{{ currency }}{{ balance.companyBalanceInEpoints | epointsToCurrency | number: '.2-2' }}</span> <span class="dot-epoints" *ngIf="!userFlags?.voucherOnly"> <span class="ep-dots">.</span> <img class="epoints-logo-rebranded" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/rebranding-images/elogo-rebrand-small.png" width="16px">
    <span class="epoints-points-in-nums font-weight-bold">{{ balance.companyBalanceInEpoints | number}}</span></span>
</div>
