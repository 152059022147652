<!-- start: search  -->
<div class="row mb-3 align-items-center">
  <div class="col-md-4 form-group">
      <label for="keyword">{{ 'Search For' | translate }}</label>
      <input id="keyword" [(ngModel)]="searchField" type="text" class="form-control" [placeholder]="'Search for a team or ID…' | translate" (keyup)="onFilter(searchField)" />
  </div>
  <div class="col-md-4 form-group">
      <label for="userType">{{ 'Search In' | translate }}</label>
      <ng-select id="userType" [(ngModel)]="defaultSearchType" [items]="searchTypes" bindLabel="name" autofocus bindValue="value" [placeholder]="'In' | translate" class="payment-select" (change)="onFilter(searchField)">
      </ng-select>
  </div>
  <div class="col-md-4" *ngIf="displayTree && displayTree.length > 0 && !loading">
    <button class="cta-links department-cta float-right" *ngIf="toggleExpand" (click)="toggleAll(displayTree, false)">{{ 'Collapse all' | translate }} <i-feather name="minimize-2" class="collapse-icon"></i-feather></button>
    <button class="cta-links department-cta float-right" *ngIf="!toggleExpand" (click)="toggleAll(displayTree,true)">{{ 'Expand all' | translate }} <i-feather name="maximize-2" class="collapse-icon"></i-feather></button>
  </div>
</div>
<!-- end: search -->

<div class="col-12 mt-5 text-center" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden"></span>
  </div>
</div>

<div class="col-12 disableSelection" *ngIf="displayTree && displayTree.length > 0 && !loading">
  <ng-template #tmplNode let-node="node" let-i="i">
    <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-' + node.id">
      <div class="row col-12 ">
        <!-- <div class="mb-3 col-12 row justify-content-end" *ngIf="i === 0">
          <button class="cta-links department-cta pr-1" *ngIf="toggleExpand" (click)="toggleAll(displayTree, false)">Collapse all <i-feather name="minimize-2" class="collapse-icon"></i-feather></button>
          <button class="cta-links department-cta pr-1" *ngIf="!toggleExpand" (click)="toggleAll(displayTree,true)">Expand all <i-feather name="maximize-2" class="collapse-icon"></i-feather></button>
        </div> -->
        <div class="node-title col-12">
          <div class="d-flex align-items-center">
            <div class="department-drag-list-icon" (pointerenter)="dragDisable(false)"
                 (pointerleave)="dragDisable(true)">
              <i-feather role="button" name="list"></i-feather>
            </div>
            <div class="toggle-tree" *ngIf="node.subdepartments.length > 0">
              <button (click)="toggle(node)" class="cta-links" >
                <i-feather *ngIf="!node.isExpanded" name="chevron-right" class="action-icon"></i-feather>
                <i-feather *ngIf="node.isExpanded" name="chevron-down" class="action-icon"></i-feather>
              </button>
              <button class="cta-links" *ngIf="node.subdepartments.length === 0">
                <i-feather style="color: white;cursor: default" name="chevron-right" class="action-icon"></i-feather>
              </button>
            </div>
            <div class="department-node"><span> {{ node.name }}</span>&nbsp;<span>(ID: {{ node.id }})</span></div>
            <div class="w-35 d-none d-md-inline-block d-lg-inline-block text-right">
              <button class="cta-links">
                <i-feather name="plus-circle" class="action-icon" (click)="onAdd(node)"></i-feather>
              </button>
              <button class="cta-links">
                <i-feather name="edit-2" class="action-icon" (click)="edit.emit({id: node.id, clickedNodeName: node.name})"></i-feather>
              </button>
              <button class="cta-links">
                <i-feather name="trash" class="action-icon" (click)="delete.emit({id: node.id, name: node.name})"></i-feather>
              </button>
            </div>
            <!-- action dropdown on mobile view -->
            <div class="d-lg-none d-md-none d-inline-block department-actions text-right" ngbDropdown>
              <i-feather
                ngbDropdownToggle role="button" name="more-horizontal" id="departmentActions"></i-feather>
              <div ngbDropdownMenu aria-labelledby="departmentActions">
                <button class="dropdown-item" (click)="onAdd(node)">Add</button>
                <button class="dropdown-item" (click)="edit.emit({id: node.id, clickedNodeName: node.name})">{{ 'Edit' | translate }}</button>
                <button class="dropdown-item" (click)="delete.emit({id: node.id, name: node.name})">{{ 'Delete' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="node.isExpanded && node.subdepartments.length"
        class="node-children"
        cdkDropList
        [cdkDropListData]="node.subdepartments"
        [id]="node.id"
        [cdkDropListConnectedTo]="dropTargetIds"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListSortingDisabled]="true"
      >
        <div
          *ngFor="let child of node.subdepartments"
          cdkDrag [cdkDragDisabled]="disableDrag"
          [cdkDragData]="child.id"
          (cdkDragMoved)="dragMoved($event)"
        >
          <ng-container
            *ngTemplateOutlet="tmplNode; context: { node: child }"
          ></ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <div
    cdkDropList
    [cdkDropListData]="displayTree"
    [id]="'main'"
    [cdkDropListConnectedTo]="dropTargetIds"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListSortingDisabled]="true"
  >
    <div
      *ngFor="let node of displayTree;let i = index"
      cdkDrag [cdkDragDisabled]="disableDrag"
      [cdkDragData]="node.id"
      (cdkDragMoved)="dragMoved($event)"
    >
      <ng-container
        *ngTemplateOutlet="tmplNode; context: { node: node, i: i }"
      ></ng-container>
    </div>
  </div>
</div>

