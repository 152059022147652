import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../service/http-cancel.service';

@Injectable()
export class HttpCancelInterceptorInterceptor implements HttpInterceptor {

  // List of URLs to exclude from cancellation
  private excludedUrls: string[] = [
    'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/dashboard-i18n',
    '/api/salary-sacrifice/electric-vehicle/status',
    '/api/salary-sacrifice',
    '/api/application/branding',
    '/rest/user/gdpr/V2',
    '/api/partner/countries'
  ];

  constructor(private httpCancelService: HttpCancelService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const urlWithoutParams = req.url.split('?')[0];
    const isExcluded = this.excludedUrls.some(url => req.url.includes(url));

    // console.log('Request URL:', req.url);
    // console.log('Base URL (without params):', urlWithoutParams);
    // console.log('Is Excluded:', isExcluded);

    if (isExcluded) {
      // console.log('Request not cancelled:', req.url);
      return next.handle(req);
    } else {
      // console.log('Request cancelled:', req.url);
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
  }
}