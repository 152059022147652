
/**returns prize in ascending order */
export function prizeSorter(prize){
    return prize.sort((a, b) => a.epointsAmount - b.epointsAmount );
}

/**returns active ecardReasons */
export function giveActiveReasons(reasons){
    let ecardReason = [];
    reasons.forEach(element => {
        element.reasonAvailableForNomination === true ? ecardReason.push(element) : null;
    });
    return ecardReason;
}

export function sortArrayByDateInDescendingOrder(array, dateKey) {
  return array.sort((a, b) => {
    const dateA = new Date(a[dateKey]);
    const dateB = new Date(b[dateKey]);
    return dateB.getTime() - dateA.getTime();
  });
}

export function sortByPosition(arr ,key) {
  for (let card of arr){
    if (card[key] === 0){
      card[key] = arr.length;
    }
  }
   return arr.sort((a ,b) => compare(a,b, key));
}

export function sortByString(arr ,key) {
  return arr.sort((a, b) => a[key].localeCompare(b[key]))
}

function compare(a ,b , key){
  if ( a[key] < b[key] ){
    return -1;
  }
  if ( a[key] > b[key] ){
    return 1;
  }
  return 0;
}
