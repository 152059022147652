<div class="iatMobile-sidebar" *ngIf="!loadShop; else loading" >
  <div class="iatSidebar-header">
    <a class="sidebarClose-icon" (click)="closeSidebar()">
      <i-feather name="x"></i-feather>
    </a>
    <div class="iatSidebar-logo">
      <a [routerLink]="['/hr/dashboard']" [class.disabled]="disableSidebar || apiDocumentationRoutesBoolean ? true : null">
        <img [src]="logoUrl || 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png'" class="img-fluid" alt="image" />
      </a>
    </div>
    <div></div>
  </div>
  <div class="iat-menu">
    <!-- start: normal routes -->
    <ul *ngIf="!apiDocumentationRoutesBoolean">
      <ng-container *ngIf="currentUser && currentUser.setUpCompleted && appAccessible && currentUser.gdprAccepted">
        <li class="sidebarUser-panel" *ngIf="UserDetail$ | async as UserDetail">
          <div *ngIf="UserDetail?.apiRetriedFor < 10 && UserDetail?.apiFailed" class="text-center mb-2">
            <div class="spinner-border" role="status">
            </div>
          </div>
          <div *ngIf="UserDetail?.apiRetriedFor === 10 && UserDetail?.apiFailed" class="text-center mb-2">
            <div class="alert alert-danger">{{ 'Failed to load personal stats. Please refresh the page again or contact the support.' | translate }}</div>
          </div>
          <div class="iatprofile-usertitle" *ngIf="!UserDetail?.apiFailed">
            <div class="sidebar-userpic-name"> {{ UserDetail?.name }} </div>
            <div class="profile-usertitle-job"> {{ UserDetail?.departmentName }} </div>
          </div>
          <!-- callBalanceAPIsByDefault=false prevents default balance APIs call by default from the personal-balance component.
          It means the balance APIs are called from sidebar itself otherwise the balance APIs will be called twice. -->
          <app-personal-balance [callBalanceAPIsByDefault]="false"></app-personal-balance>
          <div class="sidebar-user-stats" *ngIf="!UserDetail?.apiFailed">
            <p class="font-weight-bold">{{ 'people recognised' | translate | titlecase }} <a [routerLink]="['/hr/points-allocation/choose-an-ecard']"><span>{{ UserDetail?.eCardsSent || 0}}</span></a> </p>
            <p class="font-weight-bold">{{ UserDetail?.treesDonated == 1 ? ('tree planted' | translate | titlecase) : ('trees planted' | translate | titlecase) }} <a [routerLink]="isMac ? undefined : ['/hr/donate']"><span>{{ UserDetail?.treesDonated }}</span></a></p>
          </div>
        </li>
      </ng-container>

      <li *ngIf="displayMenuItem('Dashboard')" class="menu-list" [ngClass]="{'active': activeMenu === 'dashboard', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="" [routerLink]="['/hr/dashboard']">
          <i-feather name="home" class="menuIcon"></i-feather>
          <span>{{ 'Dashboard' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Recognise')" class="menu-list" [ngClass]="{'active': activeMenu === 'recognise', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="" [routerLink]="['/hr/points-allocation/choose-an-ecard']">
          <i-feather name="gift" class="menuIcon"></i-feather>
          <span>{{ 'Recognise' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Nominate')" class="menu-list" [ngClass]="{'active': activeMenu === 'nominate', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="" [routerLink]="['/hr/points-allocation/nominate']">
          <i-feather name="award" class="menuIcon"></i-feather>
          <span>{{ 'Nominate' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Save From Salary')" class="menu-list" [ngClass]="{'active': currentUrl === sfsRoute || activeMenu === 'saveFromSalary', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}"
      [hidden]="!isEligibleforSFSActive">
        <a class="" [routerLink]="[sfsRoute]">
          <span class="menuIcon">
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="3 2 21 22"><g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd"><path stroke-linecap="round" stroke-linejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" /></g></svg>
          </span>
          <span>Save From Salary</span>
        </a>
      </li>
      <li *ngIf="!this.userFlags?.onlyDashboard && displayMenuItem('Shop')"
      class="menu-list" [ngClass]="{'form-disable': disableSidebar || loadShop}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="" href="javascript:void(0)" (click)="openShop()">
          <span class="menuIcon">
            <svg width="18" height="18"  viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg"> <g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd"> <path d="M2.798 4.639c4.793 0 9.588.001 14.381.003l-.678 7.239a1.942 1.942 0 0 1-1.933 1.76H5.386a1.942 1.942 0 0 1-1.934-1.766l-.654-7.236z"/> <path stroke-linecap="round" d="M1 4.639h18M3.664 4.567 5.418 1M16.336 4.567l-1.746-3.55M10 8.241v1.8M12.7 8.241v1.8M7.3 8.241v1.8"/> </g></svg>
          </span>
          <span>{{ 'Shop' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Benefits') && (this.currentUser?.role === 'superAdmin' || (this.userFlags?.showBenefitsMenu &&
      this.currentUser?.role !== 'superAdmin'))" class="menu-list"
        [ngClass]="{'active': activeMenu === 'Benefits', 'form-disable': disableSidebar}"
        [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null
      }">
        <a class="" [routerLink]="['/benefits']">
          <span class="menuIcon">
            <svg width="16px" height="15.216px" viewBox="0 0 16 15.216" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g transform="translate(-24, -491)" stroke="#000000"
                  stroke-width="1.5">
                  <g  transform="translate(0, 118)">
                    <g id="star" transform="translate(24, 373)">
                      <polygon id="Path"
                        points="8 0 10.472 5.008 16 5.816 12 9.712 12.944 15.216 8 12.616 3.056 15.216 4 9.712 0 5.816 5.528 5.008">
                      </polygon>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span>{{ 'Benefits' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Donate') && !this.isMac" class="menu-list" [ngClass]="{'active': activeMenu === 'donate', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="" [routerLink]="['/hr/donate']">
          <span class="menuIcon">
            <svg width="18" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg"> <g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd"> <ellipse cx="7" cy="7.105" rx="6.25" ry="6.355"/> <path stroke-linecap="round" d="M7 7.053v9.473M7 9.421 8.867 8M7 10.368 5.133 8.947"/> </g></svg>
          </span>
          <span>{{ 'Donate' | translate }}</span>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Wellbeing Hub')" class="menu-list" [ngClass]="{'active': activeMenu === 'wellbeing', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('wellbeing')" (hidden)="onToggle('')">
            <!-- match the id of ngb-panel with the moduleName of sidebar-items.ts.
            This is done because, getActiveMenu() method in the .ts checks for the active route from sidebar-items.ts
            whose moduleName will be same for the routes. If the moduleName of routes and the menu matches then make the menu activ. -->
            <ngb-panel id="wellbeing">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <span class="menuIcon">
                      <svg width="18" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#212322" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7493 17C12.7493 17 12.6753 14.377 12.7 12.3367C12.7 12.3367 17.2723 7.51176 13.5235 3.03906C13.5235 3.03906 11.7147 0.787123 8.1276 1.01639C8.1276 1.01639 4.87076 1.0623 3.23446 3.59895C3.23446 3.59895 1.99178 5.38658 2.55812 7.58712C2.55812 7.58712 0.938767 9.03259 1.00179 9.3993C1.00179 9.3993 1.06809 10.4734 2.24061 10.7743C2.24061 10.7743 2.12794 12.4057 2.69428 13.1698C2.69428 13.1698 3.59656 14.4634 6.0192 14.0051C6.0192 14.0051 5.97728 16.0255 5.92496 17H12.7493Z"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10678 11C9.10678 11 13.4199 8.0643 11.5138 6.08097C11.5138 6.08097 10.2074 4.78504 9.19808 5.9221C9.19808 5.9221 8.49128 4.54702 7.21766 5.15527C7.21766 5.15527 3.47767 7.05055 9.10678 11Z"/>
                        </g>
                      </svg>
                    </span>
                    <span>{{ 'Wellbeing Hub' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li *ngIf="displayMenuItem('Latest news')"
                    [ngClass]="{'active': activateSubMenu('Latest news')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a [routerLink]="['/hr/points-allocation/wellbeing']">
                        {{ 'Latest news' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>

      </li>
      <li *ngIf="displayMenuItem('Milestones')" class="menu-list" [ngClass]="{'active': activeMenu === 'milestones', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin', 'manager']
      }}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('milestones')" (hidden)="onToggle('')">
            <ngb-panel id="milestones">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <i-feather name="calendar" class="menuIcon"></i-feather>
                    <span>{{ 'Milestones' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li *ngIf="displayMenuItem('Birthdays')"
                    [ngClass]="{'active': activateSubMenu('Birthdays')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/events/birthday']">
                        {{ 'Birthdays' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Work Anniversaries')"
                    [ngClass]="{'active': activateSubMenu('Work Anniversaries')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/events/anniversary']">
                        {{ 'Work Anniversaries' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>
      </li>
      <li *ngIf="displayMenuItem('Reporting')" class="menu-list" [ngClass]="{'active': activeMenu === 'reporting', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('reporting')" (hidden)="onToggle('')">
            <ngb-panel id="reporting">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <i-feather name="pie-chart" class="menuIcon"></i-feather>
                    <span>{{ 'Reporting' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li *ngIf="displayMenuItem('Insights')"
                    [ngClass]="{'active': activateSubMenu('Insights')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/reporting/insights']">
                        {{ 'Insights' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('API Documentation')"
                    [ngClass]="{'active': activateSubMenu('API Documentation')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/reporting-documentation/introduction']">
                        {{ 'API Documentation' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Browse People')"
                    [ngClass]="{'active': activateSubMenu('Browse People')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/users-management/users']">
                        {{ 'Browse People' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('People Activity')"
                    [ngClass]="{'active': activateSubMenu('People Activity')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a [routerLink]="['/hr/history/users-allocation']">
                        {{ 'People Activity' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Team Activity')"
                    [ngClass]="{'active': activateSubMenu('Team Activity')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/history/departments-allocation']">
                        {{ 'Team Activity' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Nomination Activity')"
                    [ngClass]="{'active': activateSubMenu('Nomination Activity')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/selector/employee-selector']">
                        {{ 'Nomination Activity' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Nomination Winners')"
                    [ngClass]="{'active': activateSubMenu('Nomination Winners')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/selector/winners']">
                        {{ 'Nomination Winners' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Partner Activity')"
                    [ngClass]="{'active': activateSubMenu('Partner Activity')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withPermissions: 'hr_grant_partners'
                    }}">
                      <a [routerLink]="['/hr/history/partners-allocation']">
                        {{ 'Partner Activity' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>

      </li>
      <li *ngIf="displayMenuItem('Admin')" class="menu-list" [ngClass]="{'active': activeMenu === 'admin', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin', 'manager']
      }}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('admin')" (hidden)="onToggle('')">
            <ngb-panel id="admin">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <i-feather name="settings" class="menuIcon"></i-feather>
                    <span>{{ 'Admin' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li *ngIf="displayMenuItem('Salary Sacrifice Technology')"
                    [ngClass]="{'active': activateSubMenu('Salary Sacrifice Technology')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/salary-sacrifice/setup-technology']">
                        {{ 'Salary Sacrifice Technology' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Salary Sacrifice Electric Vehicles')"
                    [ngClass]="{'active': activateSubMenu('Salary Sacrifice Electric Vehicles')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/salary-sacrifice/setup-electric-vehicles']">
                        {{ 'Salary Sacrifice Electric Vehicles' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Salary Sacrifice Childcare')"
                    [ngClass]="{'active': activateSubMenu('Salary Sacrifice Childcare')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/salary-sacrifice/setup-childcare']">
                        {{ 'Salary Sacrifice Childcare' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Salary Sacrifice Cycle 2 Work')"
                    [ngClass]="{'active': activateSubMenu('Salary Sacrifice Cycle 2 Work')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/salary-sacrifice/setup-cycle2work']">
                        {{ 'Salary Sacrifice Cycle 2 Work' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Eyecare')"
                    [ngClass]="{'active': activateSubMenu('Eyecare')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/eyecare-employer']">
                        {{ 'Eyecare' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Salary Sacrifice Holiday')"
                    [ngClass]="{'active': activateSubMenu('Salary Sacrifice Holiday')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/salary-sacrifice/setup-holiday']">
                        {{ 'Salary Sacrifice Holiday' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Technology Salary Sacrifice Voucher Request') && (this.userPermissionService.hasPermission('hr_request_voucher') && this.currentUser?.role === 'superAdmin' && this.currentUser?.companyShortName === 'each_person')"
                    [ngClass]="{'active': activateSubMenu('Technology Salary Sacrifice Voucher Request')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a [routerLink]="['/hr/electronic-salary-sacrifice']">
                        {{ 'Technology Salary Sacrifice Voucher Request' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Company Settings')"
                    [ngClass]="{'active': activateSubMenu('Company Settings')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/config/points-sharing']">
                        {{ 'Company Settings' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Ecard Library')"
                    [ngClass]="{'active': activateSubMenu('Ecard Library')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/config/ecard-templates']">
                        {{ 'Ecard Library' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Recognition Reasons')"
                    [ngClass]="{'active': activateSubMenu('Recognition Reasons')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/config/reasons']">
                        {{ 'Recognition Reasons' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Milestone Settings')"
                    [ngClass]="{'active': activateSubMenu('Milestone Settings')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/automated-milestones']">
                        {{ 'Milestone Settings' | translate }}
                      </a>
                    </li>
                    <li
                      *ngIf="displayMenuItem('Benefits Reminder') && (this.userPermissionService.hasPermission('hr_request_voucher') && this.currentUser?.role === 'superAdmin' && (this.currentUser?.companyShortName === 'each_person' || this.currentUser?.companyShortName === '_royal_national_orthopaedic_hospital'))"
                      [ngClass]="{'active': activateSubMenu('Benefits Reminder')}"
                      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a [routerLink]="['/benefits-reminder']">
                        {{ 'Benefits Reminder' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Manage People')"
                    [ngClass]="{'active': activateSubMenu('Manage People')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager'],
                      hideIfDisableUserUI: true
                    }}">
                      <a [routerLink]="['/hr/users-management/create']">
                        {{ 'Manage People' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Manage Teams')"
                    [ngClass]="{'active': activateSubMenu('Manage Teams')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/department']">
                        {{ 'Manage Teams' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Team Allocations')"
                    [ngClass]="{'active': activateSubMenu('Team Allocations')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin', 'manager']
                    }}">
                      <a [routerLink]="['/hr/points-allocation/grant-departments']">
                       {{ 'Team Allocations' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Nomination Settings')"
                    [ngClass]="{'active': activateSubMenu('Nomination Settings')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/selector/configure']">
                        {{ 'Nomination Settings' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Buy Epoints')"
                    [ngClass]="{'active': activateSubMenu('Buy Epoints')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/payments/topup']" *ngIf="userFlags?.voucherOnly">
                        {{ 'Buy Vouchers' | translate }}
                      </a>
                      <a [routerLink]="['/hr/payments/topup']" *ngIf="!userFlags?.voucherOnly">
                        {{ 'Buy Epoints' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Company Allocations')"
                    [ngClass]="{'active': activateSubMenu('Company Allocations')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/hr/allocations']">
                        {{ 'Company Allocations' | translate }}
                      </a>
                    </li>
                    <li *ngIf="displayMenuItem('Partner Allocations')"
                    [ngClass]="{'active': activateSubMenu('Partner Allocations')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withPermissions: 'hr_grant_partners'
                    }}">
                      <a [routerLink]="['/hr/points-allocation/grant-partners']">
                        {{ 'Partner Allocations' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>

      </li>
      <li class="menu-list" [ngClass]="{'active': activeMenu === 'help'}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('help')" (hidden)="onToggle('')">
            <ngb-panel id="help">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <i-feather name="help-circle" class="menuIcon"></i-feather>
                    <span>{{ 'Help' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a href="https://support.eachperson.com/">
                        {{ 'Help Centre' | translate }}
                      </a>
                    </li>
                    <li [ngClass]="{'active': activateSubMenu('Contact Us'), 'form-disable': disableSidebar}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': null}">
                      <a [routerLink]="['/contact-us']">
                        {{ 'Contact Us' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>

      </li>
    </ul>
    <!-- end: normal routes -->

    <!-- start: API Documentation Routes -->
    <ul *ngIf="apiDocumentationRoutesBoolean">
      <li class="menu-list text-center apiMenu-center" [ngClass]="{'form-disable': disableSidebar}" [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin']
      }}">
        <a class="" [routerLink]="['/hr/dashboard']">
          <i-feather name="arrow-left" class="menuIcon"></i-feather>
          <span>{{ 'Back to Dashboard' | translate }}</span>
        </a>
      </li>
      <li class="menu-list" [ngClass]="{'active': activeMenu === 'intro', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin']
      }}">
        <a class="" [routerLink]="['/reporting-documentation/introduction']">
          <span>{{ 'Introduction' | translate }}</span>
        </a>
      </li>
      <li class="menu-list" [ngClass]="{'active': activeMenu === 'security', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin']
      }}">
        <a class="" [routerLink]="['/reporting-documentation/security']">
          <span>{{ 'Obtaining a Security Key' | translate }}</span>
        </a>
      </li>
      <li class="menu-list" [ngClass]="{'active': activeMenu === 'api-documentation', 'form-disable': disableSidebar}"
      [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
        withRoles: ['superAdmin']
      }}">
        <a class="nestedMenu">
          <ngb-accordion #a="ngbAccordion" activeIds="{{ activeMenu }}" (shown)="onToggle('api-documentation')" (hidden)="onToggle('')">
            <ngb-panel id="api-documentation">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle>
                    <span>{{ 'API Documentation' | translate }}</span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="firstLevel-menu">
                  <ul>
                    <li [ngClass]="{'active': activateSubMenu('Department Reporting API')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/reporting-documentation/documentation/department']">
                        {{ 'Department Reporting API' | translate }}
                      </a>
                    </li>
                    <li [ngClass]="{'active': activateSubMenu('Ecard Reporting API')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/reporting-documentation/documentation/ecard']">
                        {{ 'Ecard Reporting API' | translate }}
                      </a>
                    </li>
                    <li [ngClass]="{'active': activateSubMenu('Employee Reporting API')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/reporting-documentation/documentation/employee']">
                        {{ 'Employee Reporting API' | translate }}
                      </a>
                    </li>
                    <li [ngClass]="{'active': activateSubMenu('Redemption Reporting API')}"
                    [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': {
                      withRoles: ['superAdmin']
                    }}">
                      <a [routerLink]="['/reporting-documentation/documentation/redemption']">
                        {{ 'Redemption Reporting API' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </a>

      </li>
    </ul>
    <!-- end: API Documentation Routes -->
  </div>
</div>

<ng-template #loading>
  <div class="fullscreen-loader">
      <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
      </div>
      <!-- <p>Redirecting ...</p> -->
  </div>
</ng-template>