<section class="main-content mt-2" [ngClass]="fetchingSocialAspects ? 'socialaspect-disabled' : ''">
    <div class="card">
        <div class="card-header px-1 pb-4">
            <div class="socialaspect-comments-mobile d-flex justify-content-between align-items-center p-0">
                <p class="font-20 font-weight-bold m-0 pl-1" (click)="goBack()">{{ 'Back' | translate }}</p>
                <p class="m-0">
                    <img [src]="userReactionIcon ? userReactionIcon  : 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/unLike.svg'" >
                    <span class="like-action font-weight-400 text-gray pr-1">{{matchingReaction?.name ? ((matchingReaction?.name === 'LIKE' ? 'Like' : matchingReaction?.name)| translate | titlecase) : ("Like" | translate) }}</span>
                </p>
            </div>

            <!-- Comment input: start-->
            <app-mention
            *ngIf="showInputBox"           
            [showInputBox]="showInputBox"
            [fetchingSocialAspects]="fetchingSocialAspects"
            (saveText)="saveComment($event)"
            [placeholder]="'Add a Comment...'"
            (saveText)="saveComment($event)"
            [parentDivClass]="'aspect-commentWrapper'"
            [isAddModeFormobile]="shareableDataForComments?.isMobileView ? true : false"
            [isMobileView]="shareableDataForComments?.isMobileView"
            [uniqueTextId]="ecardId"
            ></app-mention>
            <!-- Comment input: end -->

            <!-- display comments: start -->
            <div *ngIf="showInputBox"
            infiniteScroll
            (scrolled)="onScrollDownComments()"
            infiniteScrollDistance="2"
            infiniteScrollUpDistance="1">
                <div *ngFor="let commentObj of commentsInfinite; trackBy: trackById">
                    <app-comments
                    [shareableDataForComments]="shareableDataForComments"
                    (callGetIndividualCardSocialAspectDataAPI)="getIndividualSocailAspect($event)"
                    [ecardId]="ecardId"
                    [comments]="commentObj"
                    (updateSocialFetchStatus)="updateSocialFetchStatus($event)"></app-comments>
                </div>
            </div>
            <!-- display comments: end -->

            
        </div>
    </div>
</section>