import { extractTsText } from "../core/_helpers/global-functions";

export const staticReactions = [
    {
        name: extractTsText("HEART"),
        icon: "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/heart.svg"
    },
    {
        name: extractTsText("SMILE"),
        icon: "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/smile.svg"
    },
    {
        name: extractTsText("LIKE"),
        icon: "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/like.svg"
    },
    {
        name: extractTsText("IDEA"),
        icon: "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/idea.svg"
    },
    {
        name: extractTsText("APPLAUSE"),
        icon: "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/applause.svg"
    }
]