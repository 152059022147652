import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { LanguageService } from 'src/app/core/service/language.service';

declare var LiveAgent: any;

@Component({
  selector: 'app-contact-us-chat-widget',
  templateUrl: './contact-us-chat-widget.component.html',
  styleUrls: ['./contact-us-chat-widget.component.sass']
})
export class ContactUsChatWidgetComponent implements OnInit, OnDestroy {

  private scriptElement: HTMLScriptElement | null = null;

  laChatId = window.location.hostname === 'admin.eachperson.com' ? 'nb35m9b9' : 'zzqba848';
  chatUrl = window.location.hostname === 'admin.eachperson.com' ? 'https://iat-ltd.ladesk.com/scripts/track.js' : 'https://iat-ltd.ladesk.com/scripts/track.js';

  constructor(
    private readonly destroy$: DestroyService,
    private renderer: Renderer2,
    private authService: AuthService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    const prev = this.authService.currentUserValue?.userLanguageCode;
    this.loadLAChatButtonScript();
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(updated => {
      const newLang = updated?.userLanguageCode;
      
      this.updateChatButtonLanguage(); // update chat button text on first reload
      if(newLang !== prev) {
        this.restoreChatButton();
        this.removeLiveAgentChatScript();
        setTimeout(() => {
          this.loadLAChatButtonScript();
        }, 300);
      }
    });

    this.languageService.languageUpdated
    .pipe(takeUntil(this.destroy$))
    .subscribe(updated => {
      this.updateChatButtonLanguage(); // update chat button text on first reload / language updated
    });
  }

  loadLAChatButtonScript(): void {
    if (!document.querySelector('script[data-la-chatButton-script="true"]')) {  // Check if the script is already loaded
      // Create a new script element
      this.scriptElement = document.createElement('script');
      this.scriptElement.id = 'la_x2s6df8d';
      this.scriptElement.defer = true;
      this.scriptElement.src = this.chatUrl;
      this.scriptElement.setAttribute("data-la-chatButton-script", "true");

      // Set up the onload event listener
      this.scriptElement.onload = () => {
        this.initializeLiveAgent();
      };

      // Append the script to the document
      document.getElementsByTagName('head')[0].appendChild(this.scriptElement);

      this.updateChatButtonLanguage();
    }
  }

  updateChatButtonLanguage() {
    // Translate chat button name and title
    let intervalId = setInterval(() => {
      let chatButtonText = document.querySelector('.circleRollButtonText');
      if(chatButtonText) {
        if(intervalId) {
          clearInterval(intervalId);
        }
        let possibleTexts = [
          extractTsText('Live Chat'),
          extractTsText('Contact Us'),
          extractTsText('Live chat button'),
          extractTsText('Contact us button')
        ];
        
        chatButtonText.textContent = this.translate.instant(chatButtonText.textContent);
        let titleDiv = chatButtonText.closest('div[title]');
        if (titleDiv) {
          titleDiv.setAttribute('title', this.translate.instant(titleDiv.getAttribute('title')));
        }
      }
    }, 500);
  }

  initializeLiveAgent(): void {
    const chatButtonElement = document.getElementById("chatButton");  // Check for the element in the DOM
    if (chatButtonElement && typeof LiveAgent !== 'undefined') {
      LiveAgent.createButton(this.laChatId, chatButtonElement);  // Initialize the LiveAgent button
      // console.log('LiveAgent button created');
    } else {
      // console.error("Chat button element or LiveAgent is missing");
    }
  }

  removeLiveAgentChatScript(): void {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }

    // Remove chat button
    const divElements = document.querySelectorAll('div');
    divElements.forEach(element => {
      if (element.id.includes(this.laChatId)) { 
        element.remove();
      }
    });

    // Remove iframes
    const iframeElements = document.querySelectorAll('iframe');
    iframeElements.forEach(element => {
      if (element.id.includes(this.laChatId)) {
        element.remove();
      }
    });

    // Remove styles
    const styles = document.querySelectorAll('style');
    styles.forEach(style => {
      if (style.textContent.includes(this.laChatId)) {
        style.remove();
      }
    });
  }

  restoreChatButton() {
    const chatButtonContainer = document.querySelector('#chatButtonContainer');
    let chatButton = document.getElementById('chatButton'); // Check if chatButton already exists
  
    if (!chatButton && chatButtonContainer) { 
      chatButton = document.createElement('div');
      chatButton.id = 'chatButton';
      chatButton.className = 'test'; // Add class
  
      chatButtonContainer.appendChild(chatButton);
    } else if (!chatButtonContainer) {
      console.error('Chat button container not found.');
    }
  }

  ngOnDestroy(): void {
    this.removeLiveAgentChatScript(); 
  }
}
