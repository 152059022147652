import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { RouteInfo } from './sidebar.metadata';

// class: '' denotes the menu has no sub-menu
// groupTitle: true denotes the menu is just a title with no click event
// helpLink: true denotes that the menus are the helo links that should not be disabled
export const ROUTES: RouteInfo[] = [
  {
    path: "/hr/dashboard",
    externalPath: "",
    title: extractTsText("Dashboard"),
    moduleName: "dashboard",
    iconType: "feather",
    icon: "home",
    imageCode: "",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ["/hr/dashboard"],
  },
  {
    path: "/hr/points-allocation/choose-an-ecard",
    externalPath: "",
    title: extractTsText("Recognise"),
    moduleName: "recognise",
    iconType: "feather",
    icon: "gift",
    imageCode: "",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ["/hr/points-allocation/choose-an-ecard"],
  },
  {
    path: "/hr/points-allocation/nominate",
    externalPath: "",
    title: extractTsText("Nominate"),
    moduleName: "nominate",
    iconType: "feather",
    icon: "award",
    imageCode: "",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ["/hr/points-allocation/nominate"],
  },
  {
    path: "",
    externalPath: "",
    title: "Save From Salary",
    moduleName: "saveFromSalary",
    iconType: "",
    icon: "",
    imageCode: `<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="3 2 21 22">
      <g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </g>
    </svg>`,
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: true,
    activateMenuFor: [
      "/hr/save-from-salary/statement",
      "/hr/save-from-salary/inforation",
      "/hr/save-from-salary/employee-signup",
      "/hr/save-from-salary/manage-setings",
    ],
  },
  {
    path: "",
    externalPath: "shop",
    title: extractTsText("Shop"),
    moduleName: "shop",
    iconType: "",
    icon: "",
    imageCode: `<svg width="20" height="15" viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg"> <g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd"> <path d="M2.798 4.639c4.793 0 9.588.001 14.381.003l-.678 7.239a1.942 1.942 0 0 1-1.933 1.76H5.386a1.942 1.942 0 0 1-1.934-1.766l-.654-7.236z"/> <path stroke-linecap="round" d="M1 4.639h18M3.664 4.567 5.418 1M16.336 4.567l-1.746-3.55M10 8.241v1.8M12.7 8.241v1.8M7.3 8.241v1.8"/> </g></svg>`,
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: [],
  },
  {
    path: "/benefits",
    externalPath: "",
    title: extractTsText("Benefits"),
    moduleName: "benefits",
    iconType: "",
    icon: "",
    imageCode: `
<svg width="16px" height="15.216px" viewBox="0 0 16 15.216" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="BENEFITS-(FRAN)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Dashboard-/-Benefits---Created-View---Desk" transform="translate(-24, -491)" stroke="#000000" stroke-width="1.5">
            <g id="Menu-/-Left-/-New-/-Benefits" transform="translate(0, 118)">
                <g id="star" transform="translate(24, 373)">
                    <polygon id="Path" points="8 0 10.472 5.008 16 5.816 12 9.712 12.944 15.216 8 12.616 3.056 15.216 4 9.712 0 5.816 5.528 5.008"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: [],
  },
  {
    path: "/hr/donate",
    externalPath: "",
    title: extractTsText("Donate"),
    moduleName: "donate",
    iconType: "",
    icon: "",
    imageCode: `<svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg"> <g stroke="#212322" stroke-width="1.5" fill="none" fill-rule="evenodd"> <ellipse cx="7" cy="7.105" rx="6.25" ry="6.355"/> <path stroke-linecap="round" d="M7 7.053v9.473M7 9.421 8.867 8M7 10.368 5.133 8.947"/> </g></svg>`,
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ["/hr/donate"],
  },
  {
    path: "",
    externalPath: "",
    title: extractTsText("Wellbeing Hub"),
    moduleName: "wellbeing",
    iconType: "",
    icon: "",
    imageCode: `<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#212322" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7493 17C12.7493 17 12.6753 14.377 12.7 12.3367C12.7 12.3367 17.2723 7.51176 13.5235 3.03906C13.5235 3.03906 11.7147 0.787123 8.1276 1.01639C8.1276 1.01639 4.87076 1.0623 3.23446 3.59895C3.23446 3.59895 1.99178 5.38658 2.55812 7.58712C2.55812 7.58712 0.938767 9.03259 1.00179 9.3993C1.00179 9.3993 1.06809 10.4734 2.24061 10.7743C2.24061 10.7743 2.12794 12.4057 2.69428 13.1698C2.69428 13.1698 3.59656 14.4634 6.0192 14.0051C6.0192 14.0051 5.97728 16.0255 5.92496 17H12.7493Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10678 11C9.10678 11 13.4199 8.0643 11.5138 6.08097C11.5138 6.08097 10.2074 4.78504 9.19808 5.9221C9.19808 5.9221 8.49128 4.54702 7.21766 5.15527C7.21766 5.15527 3.47767 7.05055 9.10678 11Z"/>
      </g>
    </svg>`,
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null,
    sfsEligible: false,
    activateMenuFor: [],
    submenu: [
      {
        path: "/hr/points-allocation/wellbeing",
        externalPath: "",
        title: extractTsText("Latest news"),
        moduleName: "wellbeing",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: null,
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/points-allocation/wellbeing",
          "/hr/points-allocation/wellbeing/featured",
          "/hr/points-allocation/wellbeing/latest",
          "/hr/points-allocation/wellbeing/mental-wellbeing",
          "/hr/points-allocation/wellbeing/physical-health",
          "/hr/points-allocation/wellbeing/financial-security",
        ],
      },
      // {
      //   path: "/specsavers",
      //   externalPath: "",
      //   title: "Specsavers",
      //   moduleName: "wellbeing",
      //   iconType: "",
      //   icon: "",
      //   imageCode: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   helpLink: false,
      //   accessAuthorities: null,
      //   sfsEligible: false,
      //   submenu: [],
      //   activateMenuFor: ["/specsavers"],
      // },
    ],
  },
  {
    path: "",
    externalPath: "",
    title: extractTsText("Milestones"),
    moduleName: "milestones",
    iconType: "feather",
    icon: "calendar",
    imageCode: "",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    sfsEligible: false,
    accessAuthorities: {
      withRoles: ["superAdmin", "manager"],
    },
    activateMenuFor: [],
    submenu: [
      {
        path: "/hr/events/birthday",
        externalPath: "",
        title: extractTsText("Birthdays"),
        moduleName: "milestones",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/events/birthday"],
      },
      {
        path: "/hr/events/anniversary",
        externalPath: "",
        title: extractTsText("Work Anniversaries"),
        moduleName: "milestones",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/events/anniversary"],
      },
    ],
  },
  {
    path: "",
    externalPath: "",
    title: extractTsText("Reporting"),
    moduleName: "reporting",
    iconType: "feather",
    icon: "pie-chart",
    imageCode: "",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: null,
    sfsEligible: false,
    activateMenuFor: [],
    submenu: [
      {
        path: "/reporting/insights",
        externalPath: "",
        title: extractTsText("Insights"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/reporting/insights"],
      },
      // disabled api doc for now - 21/12/2022
      {
        path: "/reporting-documentation",
        externalPath: "",
        title: extractTsText("API Documentation"),
        moduleName: "api-documentation",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        submenu: [],
        sfsEligible: false,
        activateMenuFor: ["/reporting-documentation"],
      },
      {
        path: "/hr/users-management/users",
        externalPath: "",
        title: extractTsText("Browse People"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/users-management/users"],
      },
      {
        path: "/hr/history/users-allocation",
        externalPath: "",
        title: extractTsText("People Activity"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        sfsEligible: false,
        accessAuthorities: null, // all user type can access
        submenu: [],
        activateMenuFor: ["/hr/history/users-allocation"],
      },
      {
        path: "/hr/history/departments-allocation",
        externalPath: "",
        title: extractTsText("Team Activity"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        sfsEligible: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        submenu: [],
        activateMenuFor: ["/hr/history/departments-allocation"],
      },
      {
        path: "/hr/selector/employee-selector",
        externalPath: "",
        title: extractTsText("Nomination Activity"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        sfsEligible: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        submenu: [],
        activateMenuFor: ["/hr/selector/employee-selector"],
      },
      {
        path: "/hr/selector/winners",
        externalPath: "",
        title: extractTsText("Nomination Winners"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/selector/winners"],
      },
      {
        path: "/hr/history/partners-allocation",
        externalPath: "",
        title: extractTsText("Partner Activity"),
        moduleName: "reporting",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withPermissions: "hr_grant_partners",
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/history/partners-allocation"],
      },
    ],
  },
  {
    path: "",
    externalPath: "",
    title: extractTsText("Admin"),
    moduleName: "admin",
    iconType: "feather",
    icon: "settings",
    imageCode: "",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: false,
    accessAuthorities: {
      withRoles: ["superAdmin", "manager"],
    },
    sfsEligible: false,
    activateMenuFor: [],
    submenu: [
      {
        path: "/hr/salary-sacrifice/setup-technology",
        externalPath: "",
        title: extractTsText("Salary Sacrifice Technology"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/salary-sacrifice/setup-technology",
          "/hr/salary-sacrifice/tech-form",
          "/hr/salary-sacrifice/tech-success",
          "/hr/salary-sacrifice/tech-activation-success",
        ],
      },
      {
        path: "/hr/salary-sacrifice/setup-electric-vehicles",
        externalPath: "",
        title: extractTsText("Salary Sacrifice Electric Vehicles"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/salary-sacrifice/setup-electric-vehicles",
          "/hr/salary-sacrifice/ev-form",
          "/hr/salary-sacrifice/ev-success",
          "/hr/salary-sacrifice/ev-activation-success",
        ],
      },
      {
        path: "/hr/salary-sacrifice/setup-childcare",
        externalPath: "",
        title: extractTsText("Salary Sacrifice Childcare"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/salary-sacrifice/setup-childcare",
          "/hr/salary-sacrifice/childcare-form",
          "/hr/salary-sacrifice/childcare-success",
          "/hr/salary-sacrifice/childcare-activation-success",
        ],
      },
      {
        path: "/hr/salary-sacrifice/setup-cycle2work",
        externalPath: "",
        title: extractTsText("Salary Sacrifice Cycle 2 Work"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/salary-sacrifice/setup-cycle2work",
          "/hr/salary-sacrifice/cycle2work-activation-success",
        ],
      },
      {
        path: "/hr/eyecare-employer",
        externalPath: "",
        title: extractTsText("Eyecare"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/eyecare-employer",
          "/hr/eyecare-employer-form",
          "/hr/eyecare-employer-form-summary",
          "/hr/eyecare-employer-success",
        ],
      },
      {
        path: "/hr/salary-sacrifice/setup-holiday",
        externalPath: "",
        title: extractTsText("Salary Sacrifice Holiday"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: [
          "/hr/salary-sacrifice/setup-holiday",
          "/hr/salary-sacrifice/holiday-form-employer",
          "/hr/salary-sacrifice/holiday-form-success-employer",
        ],
      },
      {
        path: "/hr/electronic-salary-sacrifice",
        externalPath: "",
        title: extractTsText("Technology Salary Sacrifice Voucher Request"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: null,
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/electronic-salary-sacrifice"],
      },
      {
        path: "/hr/config/points-sharing",
        externalPath: "",
        title: extractTsText("Company Settings"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/config/points-sharing"],
      },
      {
        path: "/hr/config/ecard-templates",
        externalPath: "",
        title: extractTsText("Ecard Library"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/config/ecard-templates"],
      },
      {
        path: "/hr/config/reasons",
        externalPath: "",
        title: extractTsText("Recognition Reasons"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/config/reasons"],
      },
      {
        path: "/hr/automated-milestones",
        externalPath: "",
        title: extractTsText("Milestone Settings"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/automated-milestones"],
      },
      {
        path: "/benefits-reminder",
        externalPath: "",
        title: extractTsText("Benefits Reminder"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
          hideIfDisableUserUI: true, // hide the menu if disableUserUI of the logged-in user is true
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/benefits-reminder"],
      },
      {
        path: "/hr/users-management/create",
        externalPath: "",
        title: extractTsText("Manage People"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
          hideIfDisableUserUI: true, // hide the menu if disableUserUI of the logged-in user is true
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/users-management/create"],
      },
      {
        path: "/hr/department",
        externalPath: "",
        title: extractTsText("Manage Teams"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/department"],
      },
      {
        path: "/hr/points-allocation/grant-departments",
        externalPath: "",
        title: extractTsText("Team Allocations"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin", "manager"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/points-allocation/grant-departments"],
      },
      {
        path: "/hr/selector/configure",
        externalPath: "",
        title: extractTsText("Nomination Settings"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/selector/configure"],
      },
      {
        path: "/hr/payments/topup",
        externalPath: "",
        title: extractTsText("Buy Epoints"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/payments/topup", "/hr/payments/success"],
      },
      {
        path: "/hr/payments/topup",
        externalPath: "",
        title: extractTsText("Buy Vouchers"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/payments/topup", "/hr/payments/success"],
      },
      {
        path: "/hr/allocations",
        externalPath: "",
        title: extractTsText("Company Allocations"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withRoles: ["superAdmin"],
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/allocations"],
      },
      {
        path: "/hr/points-allocation/grant-partners",
        externalPath: "",
        title: extractTsText("Partner Allocations"),
        moduleName: "admin",
        iconType: "",
        icon: "",
        imageCode: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: {
          withPermissions: "hr_grant_partners",
        },
        sfsEligible: false,
        submenu: [],
        activateMenuFor: ["/hr/points-allocation/grant-partners"],
      },
    ],
  },
  {
    path: "",
    externalPath: "",
    title: extractTsText("Help"),
    moduleName: "help",
    iconType: "feather",
    icon: "help-circle",
    imageCode: "",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    helpLink: true,
    accessAuthorities: null,
    sfsEligible: false,
    activateMenuFor: [],
    submenu: [
      {
        path: "",
        externalPath: "https://support.eachperson.com/",
        title: extractTsText("Help Centre"),
        moduleName: "blog",
        iconType: "feather",
        icon: "book-open",
        imageCode: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: true,
        accessAuthorities: null,
        submenu: [],
        sfsEligible: false,
        activateMenuFor: [],
      },
      {
        path: "/contact-us",
        externalPath: "",
        title: extractTsText("Contact Us"),
        moduleName: "contact",
        iconType: "feather",
        icon: "mail",
        imageCode: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        helpLink: false,
        accessAuthorities: null,
        submenu: [],
        sfsEligible: false,
        activateMenuFor: ["/contact-us"],
      },
    ],
  },
];

// reporting api doc not used for now - 21/12/2022
let ApiDocumentationRoutes: RouteInfo[] = [
  {
    path: '/hr/dashboard',
    externalPath: '',
    title: extractTsText('Back to Dashboard'),
    moduleName: 'btd',
    iconType: 'feather',
    icon: 'arrow-left',
    imageCode: '',
    class: 'center',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    helpLink: false,
    accessAuthorities: {
      withRoles: ['superAdmin']
    }, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ['/hr/dashboard']
  },
  {
    path: '/reporting-documentation/introduction',
    externalPath: '',
    title: extractTsText('Introduction'),
    moduleName: 'intro',
    iconType: '',
    icon: '',
    imageCode: '',
    class: 'no-icon',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    helpLink: false,
    accessAuthorities: {
      withRoles: ['superAdmin']
    }, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ['/reporting-documentation/introduction']
  },
  {
    path: '/reporting-documentation/security',
    externalPath: '',
    title: extractTsText('Obtaining a Security Key'),
    moduleName: 'security',
    iconType: '',
    icon: '',
    imageCode: '',
    class: 'no-icon',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    helpLink: false,
    accessAuthorities: {
      withRoles: ['superAdmin']
    }, // all user type can access
    submenu: [],
    sfsEligible: false,
    activateMenuFor: ['/reporting-documentation/security']
  },
  {
    path: '',
    externalPath: '',
    title: extractTsText('API Documentation'),
    moduleName: 'api-documentation',
    iconType: '',
    icon: '',
    imageCode: '',
    class: 'menu-toggle no-icon',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    helpLink: false,
    accessAuthorities: {
      withRoles: ['superAdmin']
    },
    sfsEligible: false,
    submenu: [],
    activateMenuFor: []
  }
  ];

//to dynamically render submenu content for api-documentation section
let subMenuofApiDocumentation =  [
  {title: extractTsText('Department Reporting API'), slug: 'department'},
  {title: extractTsText('Ecard Reporting API'), slug: 'ecard'},
  {title: extractTsText('Employee Reporting API'), slug: 'employee'},
  {title: extractTsText('Redemption Reporting API'), slug: 'redemption'}
  ];
export function fetchAPIDocRoutes() {
  let documentationSubMenu = ApiDocumentationRoutes[ApiDocumentationRoutes.length - 1].submenu = [];
  for (let {title , slug} of subMenuofApiDocumentation){
    documentationSubMenu.push(generateObject(title , slug))
  }
  return ApiDocumentationRoutes;
}

function generateObject(title,slug){
   return {
    path: `/reporting-documentation/documentation/${slug}`,
    title: title,
    moduleName: 'api-documentation',
    iconType: '',
    icon: '',
    imageCode: '',
    class: 'ml-menu',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    helpLink: false,
    accessAuthorities: {
      withRoles: ['superAdmin']
    },
    submenu: [],
    sfsEligible: false,
    activateMenuFor: [`/reporting-documentation/documentation/${slug}`]
  };
}