import { FormGroup } from '@angular/forms';
import { extractTsText } from '../global-functions';

/**
 * below is taken from https://www.regextester.com/106421 
 * and the pattern is /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/
 * you can simply use this pattern directly in line var exp = new RegExp(ranges.join('|'), 'g');
 */
var ranges = [
    '\u00a9',
    '\u00ae',
    '[\u2000-\u3300]',
    '\ud83c[\ud000-\udfff]',
    '\ud83d[\ud000-\udfff]',
    '\ud83e[\ud000-\udfff]'
];

var exp = new RegExp(ranges.join('|'), 'g');

// restrictEmoji function was used as a validator to check existence of emoji in a text and restrict -- not in use now
export function restrictEmoji(controlName: string) {
    return (formGroup: FormGroup) => {
        const formControl = formGroup.controls[controlName];
        const textValue = formControl.value;

        if (formControl.errors && !formControl.errors.emojiError) {
            // return if another validator has already found an error on the formControl
            return;
        }

        // this does not match all kinds of emojis --> matches only colorful ones
        // var ranges = [
        //     '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
        //     '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
        //     '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
        // ];

        
        if (exp.test(textValue)) {
            formControl.setErrors({ emojiError: true, emojiMessage: extractTsText('Sorry at the moment emojis are not allowed in the personal message.') });
        } else {
            formControl.setErrors(null);
        }
    }
}

// function to convert emojis in a message to unicodes
export function encodeEmojisInMessage(str: any) {    
    str = str.replace(exp, function(e) {
        let encoded = null;
        if(!isNaN(e.charCodeAt(0))) {
            encoded = "\\u" + e.charCodeAt(0).toString(16);
        }
        if(!isNaN(e.charCodeAt(1))) {
            if(encoded !== null) {
                encoded += "\\u" + e.charCodeAt(1).toString(16);
            } else {
                encoded = "\\u" + e.charCodeAt(1).toString(16);
            }
        }
        
        return encoded;        
        // return encodeURIComponent(e);
    });
    // console.log(unicodeToChar(str))
    return str;
}

// function to convert unicodes in a message to emoji characters
export function unicodeToChar(text) {
    // return decodeURIComponent(text)
    return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
}

/**
 * as the encoded emoji didnot work due to the string \u was not taken in query parameter.
 * \u takes \\u as a string but console log gives \u only
 * but while sending the emoji encoded string to query \u should be replaced by \\u
 * 
 * this emoji search is working now in Team Activity and Partner Activity but not on Winners
 */
export function formatUnicodeMessageForQueryParams(str) {
    return encodeEmojisInMessage(str).replace(/\u/g, '\\u');
}