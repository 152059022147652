import { Component, OnInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { User } from "src/app/core/models/user";
import { AuthService } from "src/app/core/service/auth.service";
import { UserPermissionService } from "src/app/core/service/user-permission.service";
import {
  getWPShopDomain,
} from "src/app/core/_helpers/environment-variables";
import { takeUntil } from "rxjs/operators";
import { DestroyService } from "src/app/core/service/destroy.service";
import { encryptToken, linkTargetViaGoNative, setEPCookie } from "src/app/core/_helpers/global-functions";

@Component({
  selector: "app-no-access",
  templateUrl: "./no-access.component.html",
  styleUrls: ["./no-access.component.sass"],
  providers: [DestroyService]
})
export class NoAccessComponent implements OnInit {
  currentUser: User;
  appAccessible: boolean;
  load: boolean = false

  constructor(
    private authService: AuthService,
    private userPermissionService: UserPermissionService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private readonly destroy$: DestroyService
  ) {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe((userdata) => {
      if(userdata) { // if this is not done, then this.userPermissionService.isAppAccessible(userdata); will return true value, which makes redirection break
        this.currentUser = userdata;
        this.appAccessible = this.userPermissionService.isAppAccessible(userdata);
        this.initialLoad();
      }
    });
  }

  ngOnInit(): void {}

  initialLoad() {
    // add class no-access-body to body tag
    if (!this.appAccessible && location.pathname === "/no-access") {
      this.renderer.addClass(this.document.body, "no-access-body");
    } else {
      this.renderer.removeClass(this.document.body, "no-access-body");
    }

    if (this.appAccessible) {
      this.authService.doRedirection(this.currentUser);
    }
  }

  goBack() {
    let hrefTarget = linkTargetViaGoNative();
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    // returnUrl from epoints
    var returnUrl = this.authService.getReturnUrl();
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      if(hrefTarget === '_blank') {
        this.load = false;
      }
      let shopUrl = getWPShopDomain();
      window.open(shopUrl, hrefTarget);
    }
  }
}
