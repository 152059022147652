import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { getStripeKeys } from '../../core/_helpers/environment-variables';
import { map, catchError } from 'rxjs/operators';
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable({
  providedIn: 'root'
})
export class StripeApiService {

  constructor(
    private httpBackend: HttpBackend
  ) { }

  createPaymentIntent(amount: number, currency: string, metadata?: any) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      Authorization: `Bearer ${getStripeKeys()?.sk}`, // Use your secret key here
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = { headers };
    let params = new URLSearchParams();
    for (const key in metadata) {
      if (metadata.hasOwnProperty(key)) {
        params.set(`metadata[${key}]`, metadata[key]);
      }
    }

    let data = `amount=${amount}&currency=${currency}&${params}`;
    return httpWithoutInterceptor.post<any>(`https://api.stripe.com/v1/payment_intents`, data, requestOptions)
    .pipe(map(response => {
      return response
    }),
    catchError((err: HttpErrorResponse) => {
      return throwError(err);
    }))
  }

  updatePaymentIntent(id: string, amount: number, currency: string, metadata?: any) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      Authorization: `Bearer ${getStripeKeys()?.sk}`, // Use your secret key here
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = { headers };
    let params = new URLSearchParams();
    for (const key in metadata) {
      if (metadata.hasOwnProperty(key)) {
        params.set(`metadata[${key}]`, metadata[key]);
      }
    }

    let data = `amount=${amount}&currency=${currency}&${params}`;
    return httpWithoutInterceptor.post<any>(`https://api.stripe.com/v1/payment_intents/${id}`, data, requestOptions)
    .pipe(map(response => {
      return response
    }),
    catchError((err: HttpErrorResponse) => {
      return throwError(err);
    }))
  }
}
