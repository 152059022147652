import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {AuthService} from "../../core/service/auth.service";
import {FormControl, Validators} from "@angular/forms";
import {getSSOLoginURL, getAccountsBaseURL} from "../../core/_helpers/environment-variables";
import { GonativeGlobal } from "src/assets/custom-JS/gonative-global-class.js";
import {finalize} from "rxjs/operators";
import {ToastrCustomMessageService} from "../../core/service/toastr-custom-message.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-check-sso-signin',
  templateUrl: './check-sso-signin.component.html',
  styleUrls: ['./check-sso-signin.component.scss']
})
export class CheckSsoSigninComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output('emailConfirmed') emailCheckSuccess = new EventEmitter<any>()
  @Output('biometricLogin') biometricLoginSuccess = new EventEmitter<any>()
  @Output('credentialsChanged') informCredChanged = new EventEmitter<any>()

  @Input() redirect_uri : string;
  @Input() response_type: string;
  @Input() state: string;
  @Input() currentYear: number;
  @Input('errorMessage') errorMessage: string = '';
  @Input('invalidLoginCreds') invalidLoginCreds: boolean = false;

  delayTimer: any;
  redirectTimer: any;
  apiReq: any = null;
  emailControl = new FormControl('', [Validators.email])

  redirectCounter: number = 3
  emailConfirmed: boolean = false
  emailPending: boolean = false

  // create object for the class GonativeGlobal
  gonativeGlobalClass = new GonativeGlobal();
  myDeviceInfo: any = null;
  biometricStatus: any = null;
  displayBiometricPrompt: boolean = false;
  disableInput: boolean = true;

  // save biometric status to local storage
  // type to show the value as face or touch id in header / settings in header
  // biometricsStatus to a) trigger prompt , b) to set toggle in header
  // isSetup for condition to trigger first setup page
  biometricsObj : any = null

  load: boolean = false
  credentialsChanged: boolean = false
  pollingBiometricsData: any;
  deviceData: any
  autoPopulateEmail: string = null

  constructor(
    public authService: AuthService,
    private toastr: ToastrCustomMessageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.load = false
    if (navigator.userAgent.indexOf('gonative') > -1){
      this.gonativeGlobalClass.addBottomNav();
    }

    // get autoPopulateEmail from queryParams, which comes from Mandrill templates via email
    this.route?.queryParams?.subscribe(
      param => {
        this.autoPopulateEmail = (param?.autoPopulateEmail)?.trim() || null
        if(this.autoPopulateEmail) {
          this.emailControl?.setValue(this.autoPopulateEmail)
          this.onConfirm()
        }
      }
    )
  }

  ngAfterViewInit() {
    if(navigator.userAgent.indexOf('gonative') > -1) {
      this.checkMyDevice()
    } else {
      this.load = false
      this.disableInput = false
    }
  }

  async checkMyDevice() {
    // alert('checkingdevice')
    let error = null;
    let load = true;

    const getDeviceData = async () => {
      try {
        if (typeof this.gonativeGlobalClass.loadDeviceInfo === 'function') {
          return await this.gonativeGlobalClass.loadDeviceInfo();
        } else {
          throw new Error('loadDeviceInfo is not defined');
        }
      } catch (error) {
        throw new Error('Failed to fetch device info: ' + error);
      }
    };

    const timeout = (ms) => {
      return new Promise((_, reject) => {
        setTimeout(() => {
          reject('Timeout occurred while fetching device info from device. Re-loading your page!');
        }, ms);
      });
    };

    // fetch device data from localStorage
    let gonativeData = this.authService.getGonativeData();

    const recursive = async (retries: number = 7, delay: number = 1000) => {
      // this.toastr.error('I am called');
      let device = null;
      try {
        device = await getDeviceData();
      } catch (deviceError) {
        // alert('failed fetching device info'+retries)
        if (retries > 0) {
          setTimeout(() => {
            recursive(retries - 1, delay);
          }, delay);
        } else {
          // alert('retried 5 times but could not get device info'+e)
          // if retries complete but no device data
          error = deviceError+retries
          load = false
        }
      }

      this.load = load

      if (error) {
        this.disableInput = false
        this.toastr.error('Failed to fetch device info! Proceed manual login')
      } else if (device) {
        // alert('line 102 - found device: '+JSON.stringify(device) + " retries: "+retries)
        // save data to localStorage
        // this.toastr.success('Found device data from Gonative.')
        gonativeData.deviceInfo = device
        this.authService.storeGonativeData(gonativeData)
        this.initializeBiometrics(device)
      }
    }

    try {
      await Promise.race([recursive(), timeout(5000)]);
    } catch (error) {

      // if timeout error and found deviceInfo from localStorage, then use that data
      if(gonativeData?.deviceInfo) {
        // this.toastr.success('line 352: '+JSON.stringify(gonativeData?.deviceInfo))
        this.initializeBiometrics(gonativeData?.deviceInfo)
      } else{
        this.load = false
        this.disableInput = false
        this.errorMessage = 'Sorry, something went wrong. Please close and re-open the app.'
      }
    }
  }

  async initializeBiometrics(deviceInfo: any){
    // alert('begin initialization')
    this.biometricsObj = this.authService.getBiometricStatus()
    // alert('localStrage data: '+JSON.stringify(this.biometricsObj))
    // start: for gonative - biometric login
    if(navigator.userAgent.indexOf('gonative') > -1) {
      if (!deviceInfo?.installationId) {
        this.disableInput = false
        this.load = false; return
      }
      this.deviceData = deviceInfo
      this.getBiometricsDataFromAPI()

      this.pollingBiometricsData = setInterval(() => {
        this.getBiometricsDataFromAPI();
      }, 30000);

    } else {
      this.load = false
      this.disableInput = false
    }
    // end: for gonative - biometric login
  }

  getBiometricsDataFromAPI() {
    // alert('fetching device from API')

    // alert('line 140: '+JSON.stringify(this.deviceData))
    let deviceInfo = this.deviceData
    //trigger fetch api to see if device id is saved in db
    // from db set biometrics enabled to true or false in biometricsObj.enabled
    // this.toastr.info('Line 212 Triggering API: ' + deviceInfo?.installationId)
    this.authService.getBiometricsData(deviceInfo?.installationId).pipe(finalize( () => {
      this.afterBiometricsDetailsFetch()
    })).subscribe((da) => {
      this.onBiometricsFetchedSuccess(da)
    }, async (err) => {
      let checkBiometricsExecuted = false;

      let checkBiometricsPromise = this.gonativeGlobalClass.checkBiometricStatus();

      try {
        checkBiometricsExecuted = await Promise.race([checkBiometricsPromise, new Promise((resolve) => setTimeout(resolve, 3000))]);
      } catch (error) {
        // Handle the error from checkBiometricStatus() if it doesn't execute successfully
        // this.toastr.error('from checkssoFailed to execute checkBiometrics:', error);
      }
      this.onBiometricsFetchedError()
    })
  }

  async enableBiometricPrompt() {
    // this.toastr.success('prompt enabled')
    const secretInfo = await this.gonativeGlobalClass.promptBiometrics()
    if (secretInfo){
      if(secretInfo === "Authentication Failed") {
        this.toastr.error('Failed to Authenticate.')
        this.onPromptError()
        return
      }
      let _secretInfoParsed = JSON.parse(secretInfo)
      if (_secretInfoParsed.username && _secretInfoParsed.password){
        let cred = {
          email: _secretInfoParsed.username,
          password: _secretInfoParsed.password
        }
        this.biometricLoginSuccess.emit(cred)
      }
    } else {
      this.toastr.error('Failed to Authenticate.')
      this.onPromptError()
      // alert('couldnt run callback')
    }
  }

  onPromptError(){
    this.displayBiometricPrompt = false;
    this.disableInput = false
  }

  onConfirm(){
    this.errorMessage = ""
    this.apiReq?.unsubscribe();
    if (typeof this.delayTimer === "number"){
      this.resetReq()
    }

    if (this.emailControl.hasError('email') || this.emailControl.value  == "" || this.emailControl.value == null ) return;

    this.delayTimer = setTimeout(() => {
      this.emailPending = true
      this.apiReq = this.authService.checkEmailForSso(this.emailControl.value).subscribe((da) => {
        this.emailPending = false
        this.emailConfirmed = true
        if (da == null){
          this.errorMessage = "Unable to find user"
          return
        }
        this.redirectInterval(da)
      }, err => {
        this.emailPending = false;
        this.emailConfirmed = false;
        this.errorMessage = err
      })
    }, 1000)
  }

  resetReq(){
    this.emailPending = false
    clearInterval(this.delayTimer)
  }

  redirectInterval(res){
    if (res.ssoUser == false){
      this.emailCheckSuccess.emit({email: this.emailControl.value});return;
    }
    this.handleEmailRedirection(res)
    this.redirectTimer = setInterval(() => {
      this.redirectCounter -= 1
      if (this.redirectCounter < 1){
        clearInterval(this.redirectTimer)
        this.redirectCounter = 0
        this.emailConfirmed = false;
      }
    }, 1000)
  }

  handleEmailRedirection(res){
    let accountDomain = getAccountsBaseURL();
    if(this.redirect_uri && this.response_type && this.response_type === 'token') {
      let loginPath = `${getSSOLoginURL(res?.companyShortName)}`;
      window.location.replace(loginPath)
    }
    else if(this.redirect_uri && this.redirect_uri.includes(accountDomain) && !(this.response_type || this.response_type === "token")) {
      let splitAccountsUrl = this.redirect_uri.split(`${accountDomain}/`);
      window.location.replace(`${accountDomain}/sso?companyShortName=${res?.companyShortName}${splitAccountsUrl.length === 2 && splitAccountsUrl[1] ? `&redirectUrl=${splitAccountsUrl[1]}` : ''}`)
    /**
     * check if there is just state parameter with no redirect_uri in the login
     * it means the state belongs to the same application
     */
    } else if (!this.redirect_uri && this.state) {
      let loginPath = `${getSSOLoginURL(res?.companyShortName)}${`&state=${this.state}`}`;
      window.location.replace(loginPath)
    }else {
      window.location.replace(getSSOLoginURL(res?.companyShortName))
    }
  }

  async afterBiometricsDetailsFetch(){
    // alert('241 : Finalize')
    const authStatus = await this.gonativeGlobalClass.checkBiometricStatus()
    if (authStatus) {
      // alert(JSON.stringify(authStatus))
      let biometrics = authStatus
      this.authService.setBiometrics(this.biometricsObj)
      // alert('253 biometrics secret: ' + biometrics.hasSecret)
      // alert('254 biometrics hasTouchID: ' + biometrics.hasTouchId)
      if (biometrics.hasSecret && biometrics.hasTouchId) {
        // alert('256 enabled: ' + this.biometricsObj.enabled)
        // alert('257 setup: ' + this.biometricsObj.setup)
        // alert('258 uuid: '+ this.biometricsObj.uuid)
        // alert(localStorage.getItem('biometrics'))
        if (this.biometricsObj.enabled && !this.biometricsObj.credentialsChanged) {
          this.displayBiometricPrompt = true;
        } else {
          this.disableInput = false
        }
        // alert('stop loading')
        this.load = false
      } else {
        // alert("265: biometrics status failed")
        // alert('stop loading')
        this.load = false
        this.disableInput = false
      }
    } else {
      // alert('no auth status')
      this.load = false
      this.disableInput = false
    }
  }

  onBiometricsFetchedSuccess(res){
    // this.toastr.success("269 API Success")
    // alert('270 uuid: ' + res.uuid)
    // alert('271 Enabled Status: ' + res.biometricStatus)
    // alert('271 Credential Status: ' + res.credentialStatus)
    this.biometricsObj.setup = true
    this.biometricsObj.enabled = res.biometricStatus
    if (res.uuid) {
      this.biometricsObj.uuid = res.uuid
    }
    this.biometricsObj.credentialsChanged = res?.credentialStatus

    if(this.biometricsObj?.enabled && res?.credentialStatus) {
      // enable manual login
      this.credentialsChanged = true
      this.informCredChanged.emit(this.credentialsChanged)
      this.disableInput = false
      this.displayBiometricPrompt = false
    }

    // if biometric prompt is to be shown on initial load then on success - enable biometric else disable it
    // if(this.biometricsObj?.enabled) {
    //   this.enableBiometricPrompt()
    // }

  }

  onBiometricsFetchedError(){
    this.load = false
    this.disableInput = false
    this.biometricsObj.setup = false
    this.biometricsObj.enabled = false
    this.biometricsObj.uuid = ""
    this.biometricsObj.credentialsChanged = false
  }

  ngOnDestroy() {
    if (this.pollingBiometricsData) {
      clearInterval(this.pollingBiometricsData);
    }
  }
}
