import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2, ViewChild,
} from "@angular/core";
import { Event, NavigationStart, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ConfigService } from "src/app/config/config.service";
import { User, UserFlags } from "src/app/core/models/user";
import { AuthService } from "src/app/core/service/auth.service";
import { CountriesService } from "src/app/core/service/countries.service";
import { DestroyService } from "src/app/core/service/destroy.service";
import { GrantUsersService } from "src/app/core/service/grant-users.service";
import { LanguageService } from "src/app/core/service/language.service";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { ShareBalanceService } from "src/app/core/service/share-balance.service";
import { UserPermissionService } from "src/app/core/service/user-permission.service";
import { Accessibility } from 'src/assets/third-party-js/accessibility/main.js';
import {
  getAccountsBaseURL,
  getSsoBaseLinks,
} from "src/app/core/_helpers/environment-variables";
import {GonativeGlobal} from "../../../assets/custom-JS/gonative-global-class";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {setCred} from "../../core/_helpers/gonativeGlobal";
import {ToastrCustomMessageService} from "../../core/service/toastr-custom-message.service";
import { LayoutService } from '../../core/service/layout.service';
import { TranslateService } from "@ngx-translate/core";
import { extractTsText, languages, setEPCookie } from "src/app/core/_helpers/global-functions";
import { NotificationsService } from "src/app/core/service/notifications.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "src/app/core/service/user.service";
import { sortByPosition } from "src/app/core/_helpers/data-sorter";
import { DynamicTranslatePipe } from "src/app/core/pipes/dynamic-translate.pipe";

const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [DestroyService]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  isNavbarCollapsed = true;
  currentUser: User;
  userFlags: UserFlags;
  accountsBaseURL = getAccountsBaseURL();
  ssoAccountsBaseUrl = getSsoBaseLinks().ssoAccountsBase;
  appAccessible: boolean;
  accountsUrl: any;
  selectedCountry: any = null;
  showDonationDropDown: Boolean = false; //only true for GB user
  showAddressDropDown: Boolean = false; // //only true for GB user

  //for biometrics
  displayBiometricsSettings: boolean = false;
  enabled: boolean = false
  biometricsObj : any = null
  gonativeGlobalClass = new GonativeGlobal();
  deviceInfo: any = null
  allowToggle: boolean = false
  @ViewChild('promptPassword') promptPasswordModal: ElementRef; // turn on confirm modal
  @ViewChild('setup') setupDeviceBiometricsModelElement: ElementRef; // setup biometrics prompt modal
  @ViewChild('countryLanguageSetting') langSettingsModal: ElementRef; // country, language modal
  params = {
    email: '', password: ''
  }
  biometricsType: string = extractTsText('Face / Touch ID');
  
  face_id: string = 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/face-id-small.png'
  touch_id: string = 'assets/images/touch-id-small.png'
  biometricsImg : string = this.face_id
  fetchingBiometricAuth: boolean = false
  confirmPasswordSubmitting: boolean = false;

  settingsForm: FormGroup;
  defaultLanguage = {"language": "English (UK)", "displayLanguage": "English (UK)", "code": "en_GB", "slug": "gb", "rtl": false};

  countries: any[] = [];
  languages: any[] = languages;
  defaultCountry: any = {
    "name": "United Kingdom",
    "displayName": "United Kingdom",
    "code": "GB",
    "imageUrl": "https://epoints-configs.s3.eu-west-1.amazonaws.com/countryflag-qa/gb.png",
    "conversionRate": 1.0,
    "currency": "£",
    "bannerUrl": "https://iat-ui-design.s3.eu-west-1.amazonaws.com/Gift+Card+Images/Internationalisation/International+Banners/Banner+Germany+Desktop.jpg",
    "bannerUrlMobile": "url",
    "currencyCode": "GBP"
  };
  // for notification settings
  allNotificationsTurnedOff: boolean = false;
  toggleNotificationProcessing: boolean = false;
  multiCountryEnabled: boolean = false;
  languageLoading: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    public languageService: LanguageService,
    private userPermissionService: UserPermissionService,
    private countriesService: CountriesService,
    public router: Router,
    private grantUsersService: GrantUsersService,
    private shareBalanceService: ShareBalanceService,
    private readonly destroy$: DestroyService,
    private modalService: NgbModal,
    private toastrService: ToastrCustomMessageService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    // get the current user value

    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        if(userData) {
          this.currentUser = userData;

          // as me api is called on every country or language change and on page reload, the country and language details should be updated at this time. There was country switch issue so this logic is applied.
          this.getSelectedUserDetails();
          this.appAccessible =
            this.userPermissionService.isAppAccessible(userData);
          // show/hide donate link & addresslink , as it is only accesible by users with countrycode GB or "".
          if (
            this.currentUser.userCountryCode === "GB" ||
            !this.authService.currentUserValue.userCountryCode ||
            this.authService.currentUserValue.userCountryCode == ""
          ) {
            let _isMac =  /(iPhone|iPod|iPad)/i.test(navigator.platform);
            this.showDonationDropDown = !_isMac;
            this.showAddressDropDown = true;
          }
        }
      });
    
    // get current user flags
    this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.userFlags = flags;
      });      
  }  

  //creating new accessibility object, so that Accessibility API can be used from template
  accessibility = new Accessibility();

  translateCountryLanguageMenu() {
    this.countries = this.countries.filter(eachCountry => {
      eachCountry.displayName = this.translateService.instant(eachCountry.name);
      return eachCountry;
    });

    this.languages = this.languages.filter(eachLang => {
      eachLang.displayLanguage = this.translateService.instant(eachLang.language);
      return eachLang;
    });

    
    // this.cdr.detectChanges(); // if change is not detected then use
  }

  ngOnInit() {
    this.languages.forEach(eachLanguage => {
      eachLanguage['displayLanguage'] = eachLanguage?.language;
    });
    this.languages = sortByPosition(this.languages, 'language');
    
    this.config = this.configService.configData;
    
    // fetch accounts function when logged in
    if (this.currentUser) {
      this.fetchCountries();
      this.getAccounsURLs();
      this.fetchCompanyBalance(); // fetch company limit on initial init

      /**
       * fetch company balance on every page change as it might get used by many admins
       */
      this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.fetchCompanyBalance();
        }
      });

      this.notificationService.mutedNotifArray$
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.allNotificationsTurnedOff = response?.muteAllNotificationStatus;
      });
    }    
  }

  // fetch country using the countryCode of the logged in user
  fetchCountries() {
    if (
      this.currentUser.setUpCompleted &&
      this.currentUser.businessUser &&
      this.currentUser.gdprAccepted
    ) {
      this.countriesService
        .getCountryConfiguration()
        .subscribe(
          (response) => {
            this.multiCountryEnabled = response?.multiCountryEnabled;
            this.countries = response?.countriesDetails;
            this.countries.forEach(eachCountry => {
              eachCountry['displayName'] = eachCountry?.name;
            });
            this.countries = sortByPosition(this.countries, 'name');

            // get the stored country and language of the user
            this.getSelectedUserDetails();
          }
        );
    }
  }
  
  // save the country object to the localStorage for the logged-in user
  setCountry(country: any) {
    this.defaultCountry = country; 
    this.languageService.setCountry(country);   
  }

  setLanguage(item: any) {
    this.defaultLanguage = item;   
  }

  getSelectedUserDetails() {
    this.languageLoading = true;
    let currentCountry = this.multiCountryEnabled ? (this.currentUser?.userCountryCode || 'GB') : 'GB';
    const countryVal = this.countries.filter((x) => x.code === currentCountry);
    if (countryVal.length > 0) {
      this.defaultCountry = countryVal[0];
    } else {
      // if the countryCode does not match with the list of countries then use default GB.
      currentCountry = 'GB';
    }
    this.setCountry(this.defaultCountry);
    this.getExRate();
    let currentLanguage = this.currentUser?.userLanguageCode || 'en_GB';
    const val = this.languages.filter((x) => x.code === currentLanguage);
    if (val.length > 0) {
      this.setLanguage(val[0]);
      this.translateService.use(currentLanguage).subscribe((res) => {
        this.languageLoading = false;
        if(res === undefined) {
          // do nothing
        } else {
          if(!res) {
            this.toastrService.error(this.translateService.instant(extractTsText(`There is a problem loading the language ${this.defaultLanguage?.language}`)));
          } else {
            this.translateService.setDefaultLang(currentLanguage);
            this.languageService.setLanguageStatus(val[0]);
            this.languageService.setLanguageData(res);
            this.translateCountryLanguageMenu();
          }
        }
      }, error => {
        this.languageLoading = false;
      });
      
    }

    // set EP cookie with country and language details
    this.updateCookie(currentCountry, currentLanguage, this.defaultLanguage?.rtl);
  }

  updateCookie(country: string, lang: string, rtl: boolean) {
    let details = {
      countryCode: country,
      languageCode: lang,
      rtl: rtl
    };

    setEPCookie('country_language', JSON.stringify(details));
  }

  getExRate() {
    this.countriesService.getExchangeRate(this.defaultCountry).subscribe();
  }

  /**
   * this getLimit could be done in the BalanceComponent itself but
   * as the BalanceComponent is used at the top of the multiple components,
   * and called every two times in each component due to the use of the component twice for different device sizes
   *
   * we are fetching the company balance in the HeaderComponent on every page change
   */
  fetchCompanyBalance() {
    if (
      this.currentUser.setUpCompleted &&
      (this.currentUser.role === "superAdmin" ||
        this.currentUser.role === "manager") &&
      this.currentUser.businessUser &&
      this.currentUser.gdprAccepted
    ) {
      this.grantUsersService.getLimit().subscribe(
        (response) => {
          let balance = {
            companyBalanceInEpoints: 0,
          };
          balance.companyBalanceInEpoints = response;

          /***
           * as the balance of the ShareBalanceService is used by components to prevent multiple API fetch
           * such as recognise, event (birthday/anniversary)
           * the change should reflect to these components  */
          this.shareBalanceService.setCompanyBalance(balance);
        });
    }
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        this.config.layout.sidebar.backgroundColor + "-sidebar"
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
    if (this.currentUser){
      this.manageBiometricsInfo()
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  openMobileSidebar(event: any) {
    if(window.innerWidth < 768) {
      // for mobile view
      this.layoutService.updateSidebarMobileView(true)
    } else {
      // for desktop view
      this.layoutService.updateSidebarMobileView(false)
      this.mobileMenuSidebarOpen(event, 'overlay-open')
    }
    
  }

  // hamburger click - desktop
  mobileMenuSidebarOpen(event: any, className: string) {
    if (window.innerWidth < 1251 && window.innerWidth >= 768) {
      const hasClass = event.target.classList.contains(className);
      if (hasClass) {
        this.renderer.removeClass(this.document.body, className);
        this.renderer.addClass(this.document.body, "sidebar-gone");
      } else {
        this.renderer.addClass(this.document.body, className);
        this.renderer.removeClass(this.document.body, "sidebar-gone");
      }
    } else {
      const hasClass = this.document.body.classList.contains("side-closed");
      if (hasClass) {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }
  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar =
        !this.dataService.currentStatus._isScalar)
    );
  }
  logout() {
    this.authService.logout();
  }  

  getAccounsURLs() {
    var isSsoUser = this.authService.getSsoUser();
    var account = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=account`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/account`,
      activity = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=activity`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/activity`,
      details = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=details`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/details`,
      orders = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=orders`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/orders`,
      addresses = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=addresses`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/addresses`,
      preferences = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=preferences`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/preferences`,
      membership = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=membership`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/membership`,
      payments = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=payments`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/payments`,
      profile = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=profile`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/profile`;

    this.accountsUrl = {
      account,
      activity,
      details,
      orders,
      addresses,
      preferences,
      membership,
      payments,
      profile,
    };
  }

  // when the notification bell icon is clicked
  onClickSettingsIcon(event: boolean) {
    let oldOverlay = this.elementRef.nativeElement.querySelector('.content-overlay');
    if (oldOverlay) {
      this.renderer.removeChild(oldOverlay.parentElement, oldOverlay);
      oldOverlay = null;
    }
    if(event) {
      
      // Use nativeElement to access the DOM element from the ElementRef
      const dropdown = this.elementRef.nativeElement.querySelector('#settings-dropdown-menu');      
      
      if (dropdown) {
        // Create a new div element
        const contentOverlay = this.renderer.createElement('div');     
        
        // Add a class to the new div element
        this.renderer.addClass(contentOverlay, 'content-overlay');

        // Insert the new div element after the dropdown
        this.renderer.insertBefore(dropdown.parentElement, contentOverlay, dropdown.nextSibling);
      }

      // add a div after dropdown to cover the whole page in the mobile view and a class in body to prevent body scroll
      this.document.body.classList.add('noscroll-phone');
    } else {
      this.document.body.classList.remove('noscroll-phone');
    } 
    
  }

  async toggleBiometricsStatus(){
    let fetchingData = true
    this.fetchingBiometricAuth = fetchingData
    let error = null;

    const getAuthStatus = async () => {
      try {
        if (typeof this.gonativeGlobalClass.checkBiometricStatus === 'function') {
          return await this.gonativeGlobalClass.checkBiometricStatus();
        } else {
          throw new Error(this.translateService.instant(extractTsText('checkBiometricStatus is not defined')));
        }
      } catch (error) {
        throw new Error(this.translateService.instant(extractTsText('Failed to fetch biometric auth info: ')) + error);
      }
    };

    const timeout = (ms) => {
      return new Promise((_, reject) => {
        setTimeout(() => {
          reject(this.translateService.instant(extractTsText('Timeout occurred while fetching biometric status data from device. Re-loading your page!')));
        }, ms);
      });
    };

    const recursive = async (retries = 5, delay = 1000) => {
      // this.toastr.error('I am called');
      let authStatus = null;
      try {
        authStatus = await getAuthStatus();
      } catch (biometricError) {
        // alert(`failed fetching biometric status info ${retries}`);
        if (retries > 0) {          
          setTimeout(() => {
            recursive(retries - 1, delay);
          }, delay);
        } else {
          fetchingData = false
          error = biometricError + retries;
        }
      }

      this.fetchingBiometricAuth = fetchingData
      if (error) {
        // device info not found
        this.toastrService.error(this.translateService.instant(extractTsText('Could not fetch biometric status. Re-loading your page! '))+error)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        return
      } else if (authStatus) {
        this.fetchingBiometricAuth = false
        if (!authStatus?.hasTouchId) {          
          this.modalService.open(this.setupDeviceBiometricsModelElement,  { centered: true, backdrop: 'static' });
          return;
        }
        if (!this.enabled){
          this.modalService.open(this.promptPasswordModal,  { centered: true, backdrop: 'static' });
        } else {
          this.saveBiometricState()
        }
      }
    }

    try {
      await Promise.race([recursive(), timeout(5000)]);
    } catch (error) {
      // Handle the error or timeout here
      this.toastrService.error(error);
      this.fetchingBiometricAuth = false
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  async manageBiometricsInfo(){
    if (navigator.userAgent.indexOf('gonative') > -1) {
      this.biometricsObj = this.authService.getBiometricStatus()
      if ((this.biometricsObj?.uuid !== this.currentUser?.id) || this.userFlags?.ssoUser){
        return
      }
      // alert('found data')
      let error = null

      const getDeviceData = async () => {
        try {
          if (typeof this.gonativeGlobalClass.loadDeviceInfo === 'function') {
            return await this.gonativeGlobalClass.loadDeviceInfo();
          } else {
            throw new Error(this.translateService.instant(extractTsText('loadDeviceInfo is not defined')));
          }
        } catch (error) {
          throw new Error(this.translateService.instant(extractTsText('Failed to fetch device info: ')) + error);
        }
      };
  
      const timeout = (ms) => {
        return new Promise((_, reject) => {
          setTimeout(() => {
            reject(this.translateService.instant(extractTsText('Timeout occurred while fetching device info from device. Re-loading your page!')));
          }, ms);
        });
      };

      // fetch onesignal data from localStorage
      let gonativeData = this.authService.getGonativeData();
  
      const recursive = async (retries: number = 7, delay: number = 1000) => {
        // this.toastr.error('I am called');
        let device = null;
        try {
          device = await getDeviceData();
        } catch (deviceError) {
          // alert('failed fetching device info'+retries)
          if (retries > 0) {          
            setTimeout(() => {
              recursive(retries - 1, delay);
            }, delay);
          } else {
            error = deviceError;
            // alert('retried 5 times but could not get device info'+e)
            // if retries complete but no device data
          }
        }
  
        if (error) {
          // device info not found
          return
        } else if (device) {
          gonativeData.deviceInfo = device
          this.authService.storeGonativeData(gonativeData)

          // alert('line 102 - found device: '+JSON.stringify(device) + " retries: "+retries)
          this.deviceInfo = device
          this.enabled = this.biometricsObj?.enabled
          this.params.email = this.currentUser.login
          //validating the user whether they are eligible to change this setting (i.e. same user )
          this.updateTextValues()
          this.displayBiometricsSettings = true
        }
      }
  
      try {
        await Promise.race([recursive(), timeout(5000)]);
      } catch (error) {
        // Handle the error or timeout here
        // if timeout error and found deviceInfo from localStorage, then use that data
        if(gonativeData?.deviceInfo) {
          this.deviceInfo = gonativeData?.deviceInfo
          this.enabled = this.biometricsObj?.enabled
          this.params.email = this.currentUser.login
          //validating the user whether they are eligible to change this setting (i.e. same user )
          this.updateTextValues()
          this.displayBiometricsSettings = true
        } else{
          this.toastrService.error(error);
          this.fetchingBiometricAuth = false
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }
    }
  }

  confirmPassword(){
    this.confirmPasswordSubmitting = true
    this.authService.login(this.params).subscribe((res) => {
      this.saveBiometricState()
    }, error => {
      this.confirmPasswordSubmitting = false
      this.toastrService.error(this.translateService.instant(extractTsText('Invalid Password! Biometrics status not updated')));
    })
  }

  saveBiometricState(){
    this.allowToggle = true
    let _data = {
      deviceId : this.deviceInfo.installationId,
      deviceInformation: JSON.stringify(this.deviceInfo),
      uuid: this.currentUser?.id,
      credentialStatus: false,
      biometricStatus: !this.enabled
    }
    this.authService.saveBiometricsData(_data).subscribe(async (res) => {
      this.allowToggle = false
      this.confirmPasswordSubmitting = false
      this.biometricsObj.enabled = !this.enabled
      this.authService.setBiometrics(this.biometricsObj)
      this.modalService.dismissAll()
      this.enabled = !this.enabled
      if (this.enabled){
        setCred({
          username: this.params.email,
          password: this.params.password,
          uuid: this.currentUser?.id
        })
        const secretSaved = await this.gonativeGlobalClass.saveSecret()
      }
      this.toastrService.success(`${this.biometricsType} ${this.enabled ? 'enabled':'disabled'} `);
    }, err => {
      this.confirmPasswordSubmitting = false
      this.allowToggle = false
      this.toastrService.error(this.translateService.instant(extractTsText(`Failed to update biometrics`)));
    })
  }

  closeSetupPrompt(){
    this.modalService.dismissAll()
  }

  async updateTextValues(){
    let auth = await this.gonativeGlobalClass.checkBiometricStatus();

    //if android always show touch id
    if (this.deviceInfo?.os === 'Android'){
      this.biometricsType = extractTsText('Touch ID');
      this.biometricsImg = this.touch_id;
      return
    }

    //if iOS check biometrics, no touch id means no face or touch id this show default
    if (!auth || !auth.hasTouchId){
      this.biometricsType = 'Face / Touch ID';
      this.biometricsImg = this.face_id;
      return
    }

    //if touch id then match finger or face
    if (this.deviceInfo?.os === 'iOS'){
      if (auth.biometryType === 'touchId'){
        this.biometricsType = 'Touch ID';
        this.biometricsImg = this.touch_id;
        return
      }
      if (auth.biometryType === 'faceId'){
        this.biometricsType = extractTsText('Face ID');
        this.biometricsImg = this.face_id;
        return
      }
      if (auth.biometryType === 'none'){
        this.biometricsType = 'Face / Touch ID';
        this.biometricsImg = this.face_id;
        return
      }
    }
  }

  // start: notification setings  
  getNotificationMutedStatus(afterToggle?: boolean) {
    this.notificationService.fetchNotificationMutedStatus().subscribe(
      response => {
        this.toggleNotificationProcessing = false;
        this.allNotificationsTurnedOff = response?.muteAllNotificationStatus;
        if(afterToggle) {
          this.toastrService.success(`${this.allNotificationsTurnedOff ? this.translateService.instant(extractTsText('All notifications turned off')) : this.translateService.instant(extractTsText('All notifications turned on'))}`)
        }
      },
      err => {
        this.toggleNotificationProcessing = false;
      }
    )
  }

  toggleAllNotifications() {
    this.toggleNotificationProcessing = true;
    let apiTrigger = this.allNotificationsTurnedOff ? this.notificationService.unmuteAllNotifications() : this.notificationService.muteAllNotifications()
    apiTrigger
    .subscribe(response => {           
      this.getNotificationMutedStatus(true);
    }, error => {
      this.toggleNotificationProcessing = false;
      this.toastrService.error(error);
    });
  }
  // end: notification settings

  initSettingsForm() {
    this.settingsForm = this.formBuilder.group({
      country: [null],
      old_country: [null],
      language: [null],
      old_lang: [null]
    });
    
    this.settingsForm.get('country').setValue(this.defaultCountry);
    this.settingsForm.get('language').setValue(this.defaultLanguage);
    this.settingsForm.get('old_country').setValue(this.defaultCountry);
    this.settingsForm.get('old_lang').setValue(this.defaultLanguage);
  }

  openLangSettingsModal() {
    this.initSettingsForm();
    setTimeout(() => {
      this.modalService.open(this.langSettingsModal, {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "language-selector",
      });
    }, 100);
  }

  onSaveSettings() {
    let country = this.settingsForm.get('country');
    let language = this.settingsForm.get('language');

    // if countryValue is actually changed and language value not changed
    if(country?.dirty && country?.value && !language?.dirty) {
      this.setCountry(country?.value);
      this.getExRate();
      this.updateUserDetailsToDatabase();
    }

    // country value is not changed and language value is changed
    if(!country?.dirty && language?.dirty && language?.value) {
      this.setLanguage(language?.value);
      this.translateService.use(this.defaultLanguage?.code).subscribe((res) => {
        if(!res) {
          this.toastrService.error(this.translateService.instant(extractTsText('Language not found')));

          // reset language values
          language.setValue(this.settingsForm.get('old_lang').value);
          this.defaultLanguage = this.settingsForm.get('old_lang').value;
        } else {
          this.translateService.setDefaultLang(this.defaultLanguage?.code);
          this.languageService.setLanguageStatus(this.defaultLanguage);     
          // Perform actions after language switch is complete
          this.updateUserDetailsToDatabase();
          this.translateCountryLanguageMenu();
        }
      });
    }

    if(country?.dirty && country?.value && language?.dirty && language?.value) {
      this.setCountry(country?.value);
      this.setLanguage(language?.value);
      this.getExRate();
      this.translateService.use(this.defaultLanguage?.code).subscribe((res) => {
        if(!res) {
          this.toastrService.error(this.translateService.instant(extractTsText('Language not found')));

          // reset language values
          language.setValue(this.settingsForm.get('old_lang').value);
          this.defaultLanguage = this.settingsForm.get('old_lang').value;
        } else {
          this.translateService.setDefaultLang(this.defaultLanguage?.code);         
          // Perform actions after language switch is complete
          this.updateUserDetailsToDatabase();
          this.languageService.setLanguageStatus(this.defaultLanguage);
          this.translateCountryLanguageMenu();
        }
      });
    }
    
    this.modalService.dismissAll();
  }

  updateUserDetailsToDatabase() {
    let apiPayload: any = {
      countryCode: this.defaultCountry?.code,
      languageCode: this.defaultLanguage?.code,
      rtl: this.defaultLanguage?.rtl
    };
    // update to database
    this.userService.updateUserCountryLangDetails(apiPayload).subscribe(
      response => {
        // set EP cookie with country and language details
        this.updateCookie(this.defaultCountry?.code, this.defaultLanguage?.code, this.defaultLanguage?.rtl);

        // update the layout of web page
        this.renderer.setAttribute(document.documentElement, 'lang', this.defaultLanguage?.code);
          
        if(this.defaultLanguage?.rtl) {
          this.renderer.setAttribute(document.documentElement, 'dir', 'rtl');
        } else {
          this.renderer.removeAttribute(document.documentElement, 'dir');
        }

        // postMessage to chat iframe to trigger its window close event
        // this is done to reload iframe otherwise the translation won't be seen.
        this.sendLanguageCodeToIframe(this.defaultLanguage?.code || 'en_GB', this.defaultLanguage?.rtl, 'CLOSE_CHAT_WINDOW');
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  sendLanguageCodeToIframe(langCode: string, rtlFlag: boolean, eventType: string) {
    const event = this.languageService.getLiveAgentChatEvent();
    const chatButton = document.getElementsByClassName('circleRollButtonIcon');

    // Check if the iframe exists
    if (event.origin === 'https://iat-ltd.ladesk.com' && event.data === 'EP_LA_CHAT_LOADED') {
        const languageCode = langCode || 'en_GB'; // Default to 'en_GB' if not set
        event.source.postMessage(
            { type: eventType, language: languageCode, rtlFlag },
            '*' // Replace with the specific origin if possible for security reasons
        );
        if(chatButton) {
          chatButton[0].addEventListener('click', () => {
            event.source.postMessage(
              'CHAT_BUTTON_CLICKED',
              '*' // Replace with the specific origin if possible for security reasons
          );
        });
        }
        
  
    } else {
        console.error('Iframe not found or inaccessible.');
    }
}

@HostListener("window:message", ["$event"])
  onMessage(event: any) {
    if(event.origin === 'https://iat-ltd.ladesk.com' && event.data === 'EP_LA_CHAT_LOADED') {
      this.languageService.setLiveAgentChantEvent(event);

      let updatedUser = this.authService.currentUserValue;
      if(updatedUser) {
        this.sendLanguageCodeToIframe(updatedUser?.userLanguageCode || 'en_GB', updatedUser?.rtlFlag, 'SET_LANGUAGE_CODE');
      }
    }
  }

}
