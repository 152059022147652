import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../service/language.service';
import {environment} from "src/environments/environment";

/**
 * convert currency value to the respective epoints value
 * this is used while recognising
 *
 * for example if the user is from Europe
 * his currency representation will be euro
 * as the valuation of Epoints for pound is 200
 * for 5 pound it is 1000
 *
 * conversion rate from pound to euro is 1.19
 * so for the european users, the epoints calculation should be
 * 5 euro = (5 * 200) / 1.19
 */

/**
 * only the pages that can be accessed by superAdmin from the Admin section of the sidebar will have currency valuation in pound
 * else the currency values of the other pages will be converted as per the countryCode received for that user.
 */
/**
 * A pure pipe is only called when Angular detects a change in the value or the parameters passed to a pipe.
 * An impure pipe is called for every change detection cycle no matter whether the value or parameter(s) changes.
 * This is relevant for changes that are not detected by Angular.
 */
@Pipe({ name: 'currencyToEpoints', pure:false })
export class CurrencyToEpointsPipe implements PipeTransform {
  constructor(
    private languageService: LanguageService
  ) {}

  transform(value: number, type: string = null): number {
    let translationRate = type && type === 'default' ? 1 : this.languageService.getRate();
    /**
     * ticket - https://iat-technology.atlassian.net/browse/WS-564
     * ticket - https://iat-technology.atlassian.net/jira/software/c/projects/WS/boards/133?modal=detail&selectedIssue=WS-571&assignee=557058%3Aa4a780f3-265c-4672-b79c-7de045030980
     * internationalization document: https://docs.google.com/document/d/1vEeVChJ-Mhkt6BcTHoDkyko48IR02lRfMqP45Q15-pw/edit
     * 
     * as the conversionRate provided by /rest/countries or /api/partner/countries does not give the actual or dynamic conversionRates,
     * the back-end team has made an API to return the conversion rate by using exchangeFrom of the desired country and the exchangeTo of Pound (GBP)
     * Example: https://stag-admin.eachperson.com/api/exchangeRate/base/EUR/exchangeTo/GBP
     * 
     * Before the conversionRate used to give the rate value from POund to EUR but now the conversion rate is the opposite one.
     * so need to change the calculation logic as well.
     * 
     * as the exchangeRate given by the API is from EUR to GBP: 0.887885
     * if the currency value is 10 euri 
     * 
     * then, calculation from currency to epoints is currencyValue*0.887885 - to pound and pound to Epoints is currenyValue*exchangeRate*200
     * that is; (10*0.887885*200) =  1775.77 = 1776 Epoints
     * 
     * for UK, the exchangeRate will be 1
     * so 10 pound * 200 = 2000 Epoints
     * 
     */

    return Math.round(value * translationRate * environment.defaultEpointsValuation);
  }

}
