<div class="gdpr-signup" *ngIf="showGdprSticky">
    <div class="container">
      <div class="row mx-md-n2 bg-light-primary mb-3">
        <div class="col-lg-8 col-md-6 col-12 content-inner">
          <h3 class="font-weight-bold mb-2 text-md-left px-md-4">{{ "You Haven’t Signed Up" | translate }}</h3>
          <p class="font-20 font-weight-regular text-md-left px-md-4 mb-0 font-weight-normal mb-20">{{ "Be the first to know about exclusive deals, feature launches & how to make the most out of Each Person - straight to your inbox!" | translate }}
          </p>
        </div>
        <div class="col-lg-4 col-md-6 col-12 px-md-5 btn-gdpr mobile-none">
          <button class="btn btn-reward mt-2 font-16 btn-yes text-white" (click)="handleUpdateEmailSubscription(true)" [disabled]="gdprSubmitting"><b>{{ 'Yes' | translate }}</b></button>
          <button class="btn btn-secondary btn-reward mt-2 font-16 btn-no text-white" (click)="handleUpdateEmailSubscription(false)" [disabled]="gdprSubmitting"><b>{{ 'No' | translate }}</b></button>
        </div>
        <div class="col-lg-4 col-md-6 col-12 px-md-5 btn-gdpr desktop-none">
          <button class="btn btn-secondary btn-reward mt-2 font-16 btn-no text-white" (click)="handleUpdateEmailSubscription(false)" [disabled]="gdprSubmitting"><b>{{ 'No' | translate }}</b></button>
          <button class="btn btn-reward mt-2 font-16 btn-yes text-white" (click)="handleUpdateEmailSubscription(true)" [disabled]="gdprSubmitting"><b>{{ 'Yes' | translate }}</b></button>  
        </div>
      </div>
    </div>
  </div>