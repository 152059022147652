<section class="face-id">
  <div style="text-align: center;width: 100%">
    <div class="text-right py-3 pr-3">
      <i-feather name="x" (click)="login()"></i-feather>
    </div>
  </div>
  <h3 class="text-center mb-2 font-30">Activate {{biometricsType}}</h3>

  <p class="font-16 font-weight-normal text-center m-0">Login with {{biometricsType}} instead of <br>
    your password from now on.</p>
    <div class="text-center faceid-img">
          <img width="112px" [src]="biometricsImg" alt="face-id">
  </div>
  <div class="btn-faceid text-center">
    <button [disabled]="disable" class="btn btn-primary btn-length mb-3 font-16" (click)="saveBiometricsStatus(true)">
      <b class="text-white">Sign in with {{biometricsType}}</b>
    </button>
    <button [disabled]="disable" class="btn btn-secondary btn-length font-16" (click)="saveBiometricsStatus(false)">
      <b>Sign in with Email & Password</b>
    </button>
  </div>
</section>

<ng-template #successDisplay let-c="close" let-d="dismiss">
<div class="faceid">
  <div class="modal-content modal-faceid">
  <div class="modal-body modal-faceid pt-lg-4 pt-md-3">
    <div class="success-card">
      <div class="d-flex justify-content-center mb-2">
        <img src="assets/images/check.png" style="width: 24px;" alt="check-icon">
      </div>
        <h4 class="text-center mb-2 font-20">{{biometricsType}} Successfully<br> Activated</h4>
        <p class="font-16 font-weight-normal text-center m-0">If you would like to turn this off,<br> you can do this on your account.<br>

    <div class="text-center"><button class="btn btn-secondary btn-successcard mt-3"
              (click)="login()">
        <b class="font-16">Continue</b>
      </button>
    </div>
    </div>
  </div>
  </div>
  </div>

</ng-template>

<ng-template #setup let-c="close" let-d="dismiss">
  <div class="faceid">
    <div class="modal-content modal-faceid">
      <div class="modal-body modal-faceid pt-lg-4 pt-md-3">
        <div class="success-card" style="background: white">
          <h4 class="text-center mb-3 font-20">Enable Biometrics</h4>
          <p class="font-16 font-weight-normal text-center m-0">We recommend setting up biometrics on your device so you can use it for login with eachperson, making it more convenient and secure for you .<br></p>
          <p class="font-16 font-weight-normal text-center m-0">After setting up biometric in your device, you can enable this feature from the settings tab.<br></p>
          <div class="text-center"><button class="btn btn-secondary btn-successcard mt-3">
            <b class="font-16" (click)="saveBiometricsStatus(false)()">Continue to Eachperson</b>
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
