<div *ngIf="loadingText" class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
    </div>
    <p>{{ loadingText | translate }}</p>
</div>

<ng-template #loginErrorModal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="card text-center mb-0">
                    <div class="card-body m-auto">
                        <img width="160px" alt="logo" [src]="'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png'" />
                        <hr>
                        <h4 class="page-title ">{{ errorMessages?.title || ('No Access' | translate) }}</h4>
                        <img class="mt-2 mb-3" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/rebranding-images/red-cross-icon.svg"><br>
                        <span class="px-lg-2"><b>{{ errorMessages?.message || ('Unable to log in. Please contact Each Person.' | translate) }}</b></span>
                        <button type="button" class="btn btn-secondary btn-reward mt-3" (click)="goBack()">{{ errorMessages?.buttonText || ('Back to Login' | translate) }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>