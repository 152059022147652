import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent implements OnInit {

  @Input() email: string;
  @Input('currentYear') currentYear: number;
  @Output() backButtonEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  onBackButton(){
    this.backButtonEvent.emit(false);
  }
}
