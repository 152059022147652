// reference - https://stackoverflow.com/questions/41476193/angular-2-disable-url-encoding
// https://stackoverflow.com/questions/45366391/angular2-router-url-breaks-at
// https://newbedev.com/custom-encoding-for-urls-using-angular-2-router-using-a-sign-in-place-of-a-space
import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    // parse a URL into a URL tree. parse seems to change the url as required
    parse(url: any): UrlTree {
        /**
         * if there is query state in the URL, which is used in the cognito state parameter to return to the attempted URL
         * on first return the encoded URL is decoded which makes the URL break from the second ? if exists more than one ?
         * so just replace the second ? in the state parameter with the encoded charater %3F so that the URL does not break
         * */
        
        if(url.match(/state=/g)) {
            let splittedurl = url.split('state=');
            let state = null;
            if(splittedurl && splittedurl.length > 1) {
                state = splittedurl[1].replace(/\?/g, '%3F');
                state = state.replace(/\&/g, '%26');
            }
            if(state) {
                url = splittedurl[0] + 'state='+state;
            }
        }

        if(url.match(/returnUrl=/g)) {
            let splittedurl = url.split('returnUrl=');
            let returnUrl = null;
            if(splittedurl && splittedurl.length > 1) {
                returnUrl = splittedurl[1].replace(/\?/g, '%3F');
                returnUrl = returnUrl.replace(/\&/g, '%26');
            }
            if(returnUrl) {
                url = splittedurl[0] + 'returnUrl='+returnUrl;
            }
        }

        if(url.match(/redirectUrl=/g)) {
            let splittedurl = url.split('redirectUrl=');
            let redirectUrl = null;
            if(splittedurl && splittedurl.length > 1) {
                redirectUrl = splittedurl[1].replace(/\?/g, '%3F');
                redirectUrl = redirectUrl.replace(/\&/g, '%26');
            }
            if(redirectUrl) {
                url = splittedurl[0] + 'redirectUrl='+redirectUrl;
            }
        }

        if(url.match(/intended_url=/g)) {
            let splittedurl = url.split('intended_url=');
            let intended_url = null;
            if(splittedurl && splittedurl.length > 1) {
                intended_url = splittedurl[1].replace(/\?/g, '%3F');
                intended_url = intended_url.replace(/\&/g, '%26');
            }
            if(intended_url) {
                url = splittedurl[0] + 'intended_url='+intended_url;
            }
        }

        /**
         * when the URL is directly loaded, it replaces + with space if there are + in emails.
         * So in order to not break the actual email value, replace + with %2B.
         * if encodeURIComponent is used, it encodes every special character everytime the page loads,
         * which does not work as expected.
         */
        if(url.match(/autoPopulateEmail=/g)) {
            let splittedurl = url.split('autoPopulateEmail=');
            let email = null;
            if(splittedurl && splittedurl.length > 1) {
                email = splittedurl[1].replace(/\+/g, '%2B');
            }
            if(email) {
                url = splittedurl[0] + 'autoPopulateEmail='+email;
            }
        }

        if(url.match(/email=/g)) {
            let splittedurl = url.split('email=');
            let email = null;
            if(splittedurl && splittedurl.length > 1) {
                email = splittedurl[1].replace(/\+/g, '%2B');
            }
            if(email) {
                url = splittedurl[0] + 'email='+email;
            }
        }
        
        let dus = new DefaultUrlSerializer();        
        return dus.parse(url);
    }

    // serialize a URL tree into URL. serialize seems to run after parse and also the serialize paths change frequently
    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer(),
            path = dus.serialize(tree);
            
        return path;
    }
}