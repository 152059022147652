import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSuffix'
})
export class DateSuffixPipe implements PipeTransform {

  transform(value: Date | string): string {
    let date: Date;

    // Handle null, undefined, or invalid input
    if (!value) {
      return '';
    }

    // If value is a string, try to convert it to a Date object
    if (typeof value === 'string') {
      date = new Date(value);
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return ''; // Return empty string for invalid date strings
      }
    } else {
      date = value;
    }

    // Safely extract date parts
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Function to calculate day suffix
    const daySuffix = (day: number) => {
      if (day > 3 && day < 21) return 'th'; // catch all teens
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    // Return formatted date with suffix
    return `${day}${daySuffix(day)} ${month} ${year}`;
  }
}