<section class="main-content">
    <div class="payment-sub-header-binder setup-completed d-flex">
        <h4 class="page-title mt-md-1">{{ 'Benefits Reminder' | translate }}</h4>
    </div>
    <div class="section-body">
        <div class="row">
            <div class="col-12">
                <div class="card border-apply department-body px-md-0 px-10">
                    <div *ngIf="itemsLoading" class="text-center mb-2">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                    <div class="row" *ngIf="!itemsLoading">
                        <div class="col-lg-6 col-md-8 col-12 m-auto">
                            <input type="text" class="form-control" [placeholder]="'Search clients' | translate"
                                (input)="filterClients($event)">
                            <div class="table-responsive custom-partner-table-wrapper mt-4 mb-4">
                                <table class="table mb-0 custom-nestedtree-table">
                                    <thead>
                                        <tr>
                                            <th width="60%">
                                                <div class="form-check mb-0 font-15">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" id="department-checkbox"
                                                            class="custom-control-input" [(ngModel)]="allChecked"
                                                            (change)="toggleAllClients()" [disabled]="noResultsMessage !== ''">
                                                        <label class="custom-control-label text-dark font-weight-bold"
                                                            for="department-checkbox">
                                                            {{ 'Clients' | translate }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </th>
                                            <th width="40%" colspan="2" class="text-dark font-weight-bold font-15">
                                                {{ 'Non-engaged users' | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="filteredClients.length > 0">
                                        <tr *ngFor="let client of filteredClients; let i = index">
                                            <th width="60%">
                                                <div>
                                                    <div class="form-check mb-0 font-14">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" [id]="'client-checkbox-' + i"
                                                                class="custom-control-input"
                                                                [(ngModel)]="client.isChecked"
                                                                (change)="toggleClient(client)">
                                                            <label class="custom-control-label"
                                                                [for]="'client-checkbox-' + i">
                                                                {{ client?.partnerName }} <span class="text-muted">({{
                                                                    client?.partnerId }})</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <td width="40%">
                                                <span>{{ client?.inactiveUsers }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot *ngIf="noResultsMessage">
                                        <tr>
                                            <td colspan="2">
                                                <span class="text-muted no-results">{{ noResultsMessage }}</span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="text-center mt-28">
                                <button class="btn btn-secondary btn-reward" (click)="sendReminderEmail()"
                                    [disabled]="!hasSelectedClients">{{ 'Send reminder email' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-apply configure-body px-10">
                    <div class="row">
                        <div class="col-12 col-lg-10 col-md-10 m-auto">
                            <div class="text-center">
                                <h4 class="page-title justify-content-center pt-0 info-group">{{ 'History' | translate }}</h4>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-border-apply">
                                    <thead>
                                        <tr>
                                            <th>{{ 'Date' | translate }}</th>
                                            <th>{{ 'Time' | translate }}</th>
                                            <th>{{ 'Client' | translate }}</th>
                                            <th>{{ 'No. of users notified' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <!-- during fetching list show loading icon -->
                                    <tbody *ngIf="fetchingList">
                                        <tr>
                                            <td colspan="4" class="text-center my-4">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden"></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!fetchingList">
                                        <tr *ngFor="let record of emailHistory">
                                            <td>{{ record?.sentDate | dateSuffix }}</td>
                                            <td>{{ record?.sentDate | date: 'HH:mm' }}</td>
                                            <td>{{ record?.partnerName }} <span class="text-muted">({{ record?.partnerId
                                                    }})</span>
                                            </td>
                                            <td>{{ record?.totalUsers }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <app-pagination-section *ngIf="emailHistory && emailHistory.length > 0" [paginationItems]="pagination"
                                (handlePageChanged)="onPageChanged($event)" (handlePageSizeChanged)="onPageSizeChanged($event)">
                            </app-pagination-section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>