<ng-template #newfeaturePopupModal let-c="close" let-d="dismiss">
    <div class="custom-modal-dialog">
        <div class="modal-header">
            <h4 class="page-title text-center mt-3">{{ 'New feature' | translate }}</h4>
            <button type="button" class="close custom-close" aria-label="Close" (click)="d()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body popup-body pb-4 pt-0">
            <p class="text-center font-weight-normal font-16 m-0">{{ 'You can now share your recognition with your team!' | translate }}</p>
            <div class="card border-apply details border-none mb-3">
                <div class="card-header border-none pt-4 mb-2">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/TAGGLE-ANIMATION+compressed.gif">
                </div>
                <p class="text-center font-weight-normal font-16" [innerHTML]="'To make your message “Public” when you <br>recognise, select “Make Public”' | translate"> </p>
                <p class="text-center font-weight-normal font-16" [innerHTML]="'If you would prefer to keep it private, make <br>sure the toggle is grey.' | translate"></p>
                <div class="text-center mt-2">
                    <button name="ConfirmButton" type="button" [disabled]="submitting" class="btn btn-secondary text-center btn-popup"
                        aria-label="submit" (click)="onConfirm()">
                        <b>{{ 'Confirm' | translate }}</b>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>