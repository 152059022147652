<section class="main-content activity landing-page">
    <div *ngIf="benefitsExpandedLoading" class="text-center mb-2">
        <div class="spinner-border" role="status">
        </div>
    </div>
    <div *ngIf="!benefitsExpandedLoading">
        <div class="payment-sub-header-binder setup-completed">
            <p class="mb-0 font-weight-normal text-left"><a routerLink="/benefits" class="text-dark"><strong>{{ 'Back' | translate }}</strong></a>
                <span class="d-lg-inline-block ml-2">{{ 'Benefits' | translate }} / {{ benefit?.title }}</span></p>
        </div>
        <div class="card mt-4 mt-sm-0">
            <div class="d-flex align-items-sm-end align-items-start">
                <img [src]="benefit?.iconURL" class="mb-3">
                <div class="pl-3">
                    <div class="benefit-title mb-1">{{ benefit?.title }}</div>
                    <p class="">
                        {{ benefit?.previewText }}
                    </p>
                </div>
            </div>
            <div>
                <p class="whitespace-pre-line">
                    {{ benefit?.longDescription }}
                </p>
            </div>
            <div class="benefit-button" *ngIf="benefit.buttonText && benefit.buttonURL">
                <a class="btn btn-secondary px-5 mt-2 font-16" [href]="benefit?.buttonURL">{{ benefit?.buttonText }}</a>
            </div>
        </div>
    </div>
</section>