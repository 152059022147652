import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { NgxSpinnerModule } from "ngx-spinner";
import { CurrencyToEpointsPipe } from "../core/pipes/currency-to-epoints.pipe";
import { EpointsToCurrencyPipe } from "../core/pipes/epoints-to-currency.pipe";
import { SanitizeHtmlPipe } from "../core/pipes/sanitize-html.pipe";
import { DateSuffixPipe } from "../core/pipes/date-suffix.pipe";
import { BalanceComponent } from "../hr/components-reusable/balance/balance.component";
import { DateRangeSelectionComponent } from "../hr/components-reusable/date-range-selection/date-range-selection.component";
import { EmojiComponent } from "../hr/components-reusable/emoji/emoji.component";

// include components to be shared
import { PaginationSectionComponent } from "../hr/components-reusable/pagination-section/pagination-section.component";
// import { PersonalBalanceComponent } from "../hr/components-reusable/personal-balance/personal-balance.component";
import { BootstrapModule } from "./bootstrap.module";
import { IconsModule } from "./feather-icons.module";
import { BreadCrumbComponent } from "./components/bread-crumb/bread-crumb.component";

import { GenericTreeDragComponent } from "./components/generic-tree-drag/generic-tree-drag.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { EcardTagContainerComponent } from "./components/ecard-tag-container/ecard-tag-container.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { PreviewEcardComponent } from './components/preview-ecard/preview-ecard.component';
import { NewfeaturePopupModalComponent } from './components/newfeature-popup-modal/newfeature-popup-modal.component';
import { GdprMailingSignupComponent } from './components/gdpr-mailing-signup/gdpr-mailing-signup.component';
import { MobilePersonalBalanceComponent } from './components/mobile-personal-balance/mobile-personal-balance.component';
import { CustomStripeComponent } from "./components/custom-stripe/custom-stripe.component";
import { NgxStripeModule } from "ngx-stripe";
import { getStripeKeys } from "../core/_helpers/environment-variables";
import { DynamicTranslatePipe } from "../core/pipes/dynamic-translate.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { PersonalBalanceComponent } from "../hr/components-reusable/personal-balance/personal-balance.component";

@NgModule({
  declarations: [
    BalanceComponent,
    PersonalBalanceComponent,
    PaginationSectionComponent,
    EmojiComponent,
    DateRangeSelectionComponent,
    EpointsToCurrencyPipe,
    CurrencyToEpointsPipe,
    BreadCrumbComponent,
    GenericTreeDragComponent,
    EcardTagContainerComponent,
    PreviewEcardComponent,
    NewfeaturePopupModalComponent,
    GdprMailingSignupComponent,
    SanitizeHtmlPipe,
    DateSuffixPipe,
    MobilePersonalBalanceComponent,
    CustomStripeComponent,
    DynamicTranslatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    IconsModule,
    PickerModule,
    BootstrapModule,
    DragDropModule,
    NgSelectModule,
    NgxStripeModule.forRoot(
      getStripeKeys()?.pk
    ),
    TranslateModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    IconsModule,
    NgSelectModule,
    BootstrapModule,
    TranslateModule,
    PaginationSectionComponent,
    BalanceComponent,
    PersonalBalanceComponent,
    EmojiComponent,
    // PersonalBalanceComponent,
    DateRangeSelectionComponent,
    EpointsToCurrencyPipe,
    CurrencyToEpointsPipe, // export the pipe to be used in HTML files to manipulate data
    BreadCrumbComponent,
    GenericTreeDragComponent,
    EcardTagContainerComponent,
    PreviewEcardComponent,
    CurrencyToEpointsPipe, // export the pipe to be used in HTML files to manipulate data
    NewfeaturePopupModalComponent,
    GdprMailingSignupComponent,
    SanitizeHtmlPipe,
    DateSuffixPipe,
    MobilePersonalBalanceComponent,
    CustomStripeComponent,
    DynamicTranslatePipe
  ],
  /**
   * in order to make the pipes work in components or services as dependency injection,
   * include these pipes in the providers.
   *
   * If you choose to inject your pipe into a class, you must provide it in the providers array of your NgModule.
   * You can also include it in providers on component class instead of NgModule
   */
  providers: [
    CurrencyToEpointsPipe,
    EpointsToCurrencyPipe, // provide the pipe for DI in ts file transform data use case
    SanitizeHtmlPipe,
    DateSuffixPipe,
    DynamicTranslatePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, // added to make PaginationSectionComponent work
    NO_ERRORS_SCHEMA
  ],
})
export class SharedModule {}
