import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { setEPCookie } from 'src/app/core/_helpers/global-functions';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-check-redirection',
  templateUrl: './check-redirection.component.html',
  styleUrls: ['./check-redirection.component.sass']
})
export class CheckRedirectionComponent implements OnInit {
  currentUser: User;
  code: string = null;
  state: string = null;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.checkQueryParams();

    if(this.code) {
      this.spinner.show();
      let authToken = this.authService.getLoginSession()?.token;
      let routerPath = this.state || '/hr/dashboard';
      this.authService.getTokenFromCode(this.code)
      .subscribe(
        response => { 
          /** 
           * if token already there in the localStorage, then clear session and store new one
           * because this can create conflict when a shoponly user is logged in from eachperson,
           * normal user is logged in shop and back to recognition sends back to shop even if the code is passed of
           * normal user.
          */
          if(authToken) {
            this.authService.destroyLoginSession();
          }    
          this.authService.storeLoginSession(response); // save tokens in localStorage
          setEPCookie('is_sso', 'false');
          if(!this.authService.currentUserValue) {
            this.state ? this.authService.saveCognitoState(routerPath) : null;
            this.authService.checkUser();
          } else {
            this.spinner.hide(); 
            this.router.navigate([routerPath]);
          }
        },
        error => {
          this.spinner.hide();
          
          if(authToken) {
            this.router.navigate([routerPath]);
          } else {
            this.router.navigate(['/login'], {queryParams: {
              state: this.state
            }});
          }
        }
      )
    }
  }

  checkQueryParams() {
    this.route.queryParams.subscribe(
      params => {
        this.code = params['code'] || null;
        this.state = params['state'] || null;
      }
    )
  }
}
