import { Component, Inject, Renderer2 } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { AuthService } from './core/service/auth.service';
import { UserPermissionService } from './core/service/user-permission.service';
import { HttpCancelService } from './core/service/http-cancel.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from './core/service/destroy.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [DestroyService],
})
export class AppComponent {
  constructor(
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private userPermissionService: UserPermissionService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private httpCancelService: HttpCancelService,
    private readonly destroy$: DestroyService,
    private modalService: NgbModal
  ) {
    this.monitorModalOpen();

    this._router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          // cancel all previous pending API request, when new route is changed.
          this.httpCancelService.cancelPendingRequests();

          // check the logged in user -- to see the changes if there is any and update to the localStorage
          let currentUser = this.authService.getLoginSession();
          if (currentUser.token) {
            /**
             * call me
             * the user details might get changed by the admins while editing
             * so fetch the user details on every page change
             *  */
            let notAllowedRoutes = [
              "/login",
              "/login-handler",
              "/sso",
              "/404",
              "/seamless-redirection",
            ];
            let currentUrl = routerEvent.url;
            currentUrl = currentUrl.split("?")[0];
            if (notAllowedRoutes.indexOf(currentUrl) === -1) {
              // console.log('requested me API')
              this.authService.checkMe();
            } else {
              // console.log('me api not called')
            }
          }
          /**
           * this function is called to save the eachperson's route as a history to redirect back to desired page
           * which was manually typed in the URL bar when not logged in
           * which was tried to access when logged out, once logged in the user should be redirected to the desired page.
           * this also checks the returnUrl, stores the value, returnUrl is basically used by other domains, that accesses the eachperson app.
           * In order to return to the domain URL from where eachperson was accessed, the returnUrl value is used.
           * when there is returnUrl, the redirectBackUrl should be saved when logged in or not logged in
           */
          this.authService.checkUrlRoute();
          this.spinner.show();
          location.onPopState(() => {
            window.location.reload();
          });
        }
        if (routerEvent instanceof NavigationEnd) {
          this.spinner.hide();
        }
      });

    /**
     * this function is used to add custom classes, when the user is in no-access or join/gdpr pages.
     */
    this.addRemoveCustomClassesFromBody();
  }

  private monitorModalOpen() {
    this.modalService.activeInstances.subscribe(() => {
      this.checkIfModalIsOpen();
    });
  }

  checkIfModalIsOpen() {
    const isModalOpen = this.modalService.hasOpenModals();
    if (isModalOpen) {
      // Update scrollY if modal is open
      if (window.innerWidth < 768 && window.scrollY === 0) {
        setTimeout(() => {
          window.scrollBy(0, 5);
        }, 100); // Add a short delay
      }
    } else {
      console.log("No modals are currently open.");
    }
  }

  addRemoveCustomClassesFromBody() {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata) => {
        if (userdata) {
          /**
           * as gdpr-not-accepted class is added from the component gdpr in the page join/gdpr,
           * it should be deleted when the gdpr is accepted
           * */
          if (
            userdata &&
            userdata.gdprAccepted &&
            this.document.body.classList.contains("gdpr-not-accepted")
          ) {
            this.renderer.removeClass(this.document.body, "gdpr-not-accepted");
          }

          /**
           * as no-access-body class is added from the component no-access in the page no-access,
           * it should be deleted when the user is a businessUser and has access
           * */
          if (
            this.userPermissionService.isAppAccessible(userdata) &&
            this.document.body.classList.contains("no-access-body")
          ) {
            this.renderer.removeClass(this.document.body, "no-access-body");
          }

          if (userdata?.userLanguageCode) {
            this.renderer.setAttribute(
              document.documentElement,
              "lang",
              userdata?.userLanguageCode
            );
          }
          if (userdata?.rtlFlag) {
            this.renderer.setAttribute(document.documentElement, "dir", "rtl");
          } else {
            this.renderer.removeAttribute(document.documentElement, "dir");
          }
        }
      });
  }
}
