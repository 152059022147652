import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Reaction } from 'src/app/core/models/social-aspect.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { staticReactions } from '../social-aspects-reactions';
import { encodeEmojisInMessage } from 'src/app/core/_helpers/validators/chars.validator';
import { SocialAspectService } from 'src/app/core/service/socialAspect.service';
import { sortArrayByDateInDescendingOrder } from 'src/app/core/_helpers/data-sorter';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-social-aspects-mobile',
  templateUrl: './social-aspects-mobile.component.html',
  styleUrls: ['./social-aspects-mobile.component.sass']
})
export class SocialAspectsMobileComponent implements OnInit {
  
  @Input() socialAspectData: any;
  @Input() ecardId: number;
  @Output() displaySocialAspectMobileView = new EventEmitter<any>();
  @Output() updateFetchStatusForMobileView: EventEmitter<any> = new EventEmitter<any>();
  userReactionIcon: string | null;
  userReaction: Reaction | null;
  matchingReaction: any;
  showInputBox: boolean = true;
  comments: any = [];

  // data to be shared with app-comments
  shareableDataForComments = {
    showReply: {
      show: false,
      commentId: null
    },
    totalPageReplies: null,
    isMobileView: true,
    socialFetchStatus: false
  }
  
  fetchingSocialAspects: boolean = false;

  emitvalues = {
    "callApi": true,
    "showReply": false,
    commentId: null,
    pagesToShowReplies: null
  };

  commentIdFromUrl: number = null;
  replyIdFromUrl: number = null;

  constructor(
    private authService: AuthService,
    private socialAspectService: SocialAspectService,
    private toastr: ToastrCustomMessageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {    
    this.formatComments();
    this.formatUserReactionFromAPI();

    // check the query parameter and apply the logic to scroll down to specific comment
    this.route.queryParams.subscribe(
      params => {
        this.commentIdFromUrl = params['commentId'] || null;
        this.replyIdFromUrl = params['replyId'] || null;
        if(this.commentIdFromUrl) {
          setTimeout(() => {
            this.scrollToSpecificComment();
          }, 50)
        }
      }
    )
  }

  // this is no scroll down to the specific comment in the mobile view
  scrollToSpecificComment() {
    const commentElement = document.getElementById(`comment_display_${this.commentIdFromUrl}`);
    if (commentElement) {
      commentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if(this.commentIdFromUrl && !this.replyIdFromUrl) {
        commentElement.classList.add('focused-content');
      }
    } else {
      this.onScrollDownComments();

      setTimeout(() => {
        this.scrollToSpecificComment();
      }, 50);
    }
  }

  formatComments() {
    this.comments = sortArrayByDateInDescendingOrder(this.socialAspectData?.comments, 'createdAt');
    //if number of ecard is less than 4, we will not use Infinite Scroll. If greater than 4, we will slice the response and on every scroll we will add items to companyRecognitionInfinite
    if (
      this.comments.length < this.numberOfitemsToAppendAllComments
    ) {
      this.commentsInfinite = this.comments;
    } else {
      this.commentsInfinite = this.comments.slice(
        0,
        this.numberOfitemsToAppendAllComments
      );
    }
  }
  ngOnChanges() {
    
  }

  /* Used for infinite scroll to add element in dashboard*/
  numberOfitemsToAppendAllComments = 4;
  commentsInfinite: any[] = [];
  hasMoreComments = true;

  onScrollDownComments() {
    if (this.numberOfitemsToAppendAllComments < this.comments.length) {
      this.commentsInfinite.push(
        this.comments[this.commentsInfinite.length]
      );
      this.numberOfitemsToAppendAllComments += 1;
    }
  }  

  saveComment(eventData) {
    let data = eventData?.data,
    mentions = eventData?.mentions

    if(this.fetchingSocialAspects || !data) {
      // console.log('submitting in progress')
      return false
    }
    
    this.fetchingSocialAspects = true

    let mentionedUsersId = []
    if(mentions?.length > 0) {
      mentions.forEach((eachMention: any) => {
        let eachUser = {
          userId: eachMention?.id,
          name: eachMention?.name
        }
        mentionedUsersId.push(eachUser)
      })
    }
    let commentData = {
      comment: encodeEmojisInMessage(data),
      userId: `${this.authService.currentUserValue.id}`,
      eCardId: `${this.ecardId}`,
      parentCommentId: null,
      mentionedUsersId
    }
    this.socialAspectService.saveComment(commentData).subscribe(res => {
      // on successful
    },
    error => {
      this.fetchingSocialAspects = false
      this.toastr.error('Something went wrong. Please try again!')
    },
    () => {
      this.fetchSocialAspectsForEachEcard()
    })
  }

  // formatUserReaction
  formatUserReactionFromAPI() {

    this.userReactionIcon = this.getUserReactionIcon(this.socialAspectData, this.authService.currentUserValue.id, staticReactions);
  }
  getUserReactionIcon(socialAspectData, currentUserID, defaultReactions) {
    // Find the user's reaction
     this.userReaction = this.userReaction || socialAspectData.reactions.find(reaction => reaction.reactedById === currentUserID);
    // If a user reaction was found, find the corresponding icon
    if (this.userReaction) {
         this.matchingReaction = defaultReactions.find(reaction => reaction.name === this.userReaction.reactionType);
        return this.matchingReaction ? this.matchingReaction.name==="LIKE" ? "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/liked.svg" : this.matchingReaction.icon : null;
    }
    // If no user reaction was found, return null
    return null;
  }

  

  

  // this is a method (an event) emitted from the child components after some actions
  getIndividualSocailAspect(event){
    if(event?.showReply){
      this.shareableDataForComments.showReply = {
        show: event?.showReply,
        commentId: event?.commentId
      }

    }
    if(event?.callApi) {
      this.fetchSocialAspectsForEachEcard()
    }

    if(event?.pagesToShowReplies) {
      this.shareableDataForComments.totalPageReplies = event?.pagesToShowReplies
    }
  }

  // Call the method event emitter when a button is clicked, for example
  goBack() {
    let emitData = {
      showMobileView: false,
      socialAspectData: this.socialAspectData,
      ecardId: this.ecardId
    }
    this.displaySocialAspectMobileView.emit(emitData)
  }

  trackById(index: number, item) {
    return item.id
  }

  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  // emit event to the parent components
  fetchSocialAspectsForEachEcard() {
    this.socialAspectService.getIndividualCardSocialAspectData(this.ecardId)
    .pipe(finalize(() => {
      this.fetchingSocialAspects = false
    }))
    .subscribe(res => {
      this.socialAspectData =this.deepClone(res);
    },error => {},
    ()=>{
      this.formatComments()
      this.formatUserReactionFromAPI()
      
      let emitData = {
        showMobileView: true,
        socialAspectData: this.socialAspectData,
        ecardId: this.ecardId
      }
      this.displaySocialAspectMobileView.emit(emitData)
    })    
  }

  // event emitter for fetching social aspect status
  updateSocialFetchStatus(event) {
    this.fetchingSocialAspects = false
    if(event?.socialFetchStatus) {
      this.fetchingSocialAspects = event.socialFetchStatus
    }

    this.shareableDataForComments.showReply = {
      show: event?.showReply,
      commentId: event?.commentId
    }

    if(event?.pagesToShowReplies) {
      this.shareableDataForComments.totalPageReplies = event?.pagesToShowReplies
    }
  }
}
