<div class="comments-after-input" [attr.id]="'comment_display_'+comments?.id" [ngClass]="shareableDataForComments?.isMobileView ? 'mobile-only' : 'desktop-only'">
  <div class="card card-header comment-description bg-grey-socialaspect mt-3" [ngClass]="shareableDataForComments?.isMobileView ? 'mobile-only' : 'desktop-only'">
    <!-- for desktop view: start -->
    <div *ngIf="!shareableDataForComments?.isMobileView" class="d-flex justify-content-between align-items-center p-0">
      <div class="name-container">
        <p class="font-16 font-weight-bold m-0">{{comments.commentedByName}}</p>
      </div>
      <div class="font-weight-normal d-flex text-lightgrey">{{userService.dateAgo(comments.createdAt)}}
        <!-- start: add/edit comments dropdown -->
        <div *ngIf="canUserEditOrDelete" ngbDropdown class="comment-edit-drop">
          <span ngbDropdownToggle class="three-dots ml-1"><img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/three-dots.svg"></span>
          <div ngbDropdownMenu aria-labelledby="commentActions">
            <button (click)="onEdit()" class="dropdown-item font-weight-normal edit-select m-0"><img
                src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/edit.svg">{{ 'Edit' | translate }}</button>
            <button (click)="removeParentComment()" class="dropdown-item font-weight-normal delete-select"><img
                src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/delete.svg">{{ 'Delete' | translate }}</button>
          </div>
        </div>
        <!-- end: add/edit comments dropdown -->
      </div>
    </div>
    <p *ngIf="!showEditInput && !shareableDataForComments?.isMobileView" class="font-weight-normal font-16 mb-0" [innerHTML]="formattedParentComment"></p>
    <!-- for desktop view: end -->

    <!-- for mobile view: start -->
    <div *ngIf="shareableDataForComments?.isMobileView" class="name-container">
      <p class="font-16 font-weight-bold m-0 pb-1">{{comments.commentedByName}}</p>
      <p *ngIf="!showEditInput && shareableDataForComments?.isMobileView" class="font-weight-normal font-16 mb-0" [innerHTML]="formattedParentComment"></p>
    </div>
    <div *ngIf="shareableDataForComments?.isMobileView" class="time-and-options text-lightgrey mt-n4">
      <small class="font-weight-normal mr-2">{{userService.dateAgo(comments.createdAt)}}</small>
      <span *ngIf="canUserEditOrDelete" class="three-dots ml-1" (click)="openEditDeleteModal()"><img
        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/three-dots-vertical.svg">
      </span>
    </div>
    <!-- for mobile view: end -->    

    <!-- edit comment textbox: start -->
    <app-mention
    *ngIf="showEditInput"
    [comments]="comments"
    [placeholder]="commentPlaceholder"
    [showInputBox]="showEditInput"
    [fetchingSocialAspects]="fetchingSocialAspects"
    (saveText)="updateComment($event)"
    [parentDivClass]="'aspect-commentWrapper mt-0'"
    [editText]="true"
    [textData]="comments?.commentText"
    [uniqueTextId]="comments?.id + 'edit-comment'"
    [mentionedUsersList]="comments?.mentionedUsersInComment"
    (cancelEdit)="cancelEdit($event)"
    [isMobileView]="shareableDataForComments?.isMobileView"
    ></app-mention>
    <!-- edit comment textbox: end -->
  </div>

  <!-- comment reply and count: start -->
  <div class="commentReply d-flex justify-content-between align-items-end p-0">
    <ul>
      <li>
        <span *ngIf="!(userReactionInComments | keyvalue)?.length" (click)="likeParentComment()">{{ 'Like' | translate }}</span>
        <img (click)="likeParentComment()" *ngIf="userReactionInComments?.reactionType === 'LIKE'"
          src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/liked.svg">        
      </li>
      <li>
        <!-- when the reply is toggled, it should emit the showReply boolean true/false to its parent so that
        the reply is opened/closed as desired -->
        <span (click)="toggleReply()">{{ 'reply' | translate | titlecase }} 
          <!-- <span *ngIf="comments?.replyCount > 0" class="reply-count">{{comments?.replyCount}}</span> -->
        </span>
      </li>
    </ul>

    <!-- show likes and reply count: start -->
    <p *ngIf="comments?.replyCount > 0 || comments?.reactionCount > 0" class="text-lightgrey font-weight-normal mb-0" style="line-height: 1;">
      <span class="like-count cursor" (click)="comments?.reactionCount > 0 ? openReactionModal() : null">{{comments?.reactionCount}} {{comments?.reactionCount === 1 ? ('like' | translate) : ('likes' | translate)
          }} </span>&nbsp;|&nbsp;<span class="comment-count cursor" (click)="toggleReply()">{{comments?.replyCount}}
            {{comments?.replyCount === 1 ? ('reply' | translate) : ('replies'| translate) }}</span>
    </p>
    <!-- show likes and reply count: end -->
  </div>
  <!-- comment reply and count: end -->
</div>

<ng-container *ngIf="showCommentReplies">
  <div *ngFor="let replyObj of displayReplies; trackBy: trackById">
    <app-reply class="child-reply"
    (callGetIndividualCardSocialAspectDataAPI)="getIndividualSocailAspect($event)"
    [commentId]="comments?.id"
    [isMobileView]="shareableDataForComments?.isMobileView"
    [ecardId]="ecardId"
    [replies]="replyObj"
    [fetchingSocialAspects]="fetchingSocialAspects"
    (updateSocialFetchingStatus)="updateSocialFetchStatusFromReply($event)"
    ></app-reply>
  </div>

  <!-- show view more when the number of replies loaded is less than the replyCount: start -->
  <div *ngIf="displayReplies.length < comments?.replyCount" class="text-right text-primary"><a href="javascript:void(0)"
      (click)="viewMore()" class="font-16 font-weight-bold">{{ 'View More Replies' | translate }}</a></div>
  <!-- show view more when the number of replies loaded is less than the replyCount: end -->

  <!-- show view less when the total number of replies is greater than 4 and the number of replies loaded is equal to the replyCount: start -->
  <div *ngIf="comments?.replyCount > 4 && displayReplies.length === comments?.replyCount"
    class="text-right text-primary"><a href="javascript:void(0)" (click)="viewLess()"
      class="font-16 font-weight-bold">{{ 'View less' | translate }}</a></div>
  <!-- show view less when the total number of replies is greater than 4 and the number of replies loaded is equal to the replyCount: end -->

  <!-- add reply: start -->
  <!-- here comments is passed as @Input, because the Mention component was unable to detect uniqueTextId 
  when trackById is used, so in order to detect the changes, comments is passed -->
  <div class="mobile-commentinput">
    <app-mention
    [comments]="comments" 
    [placeholder]="'Add a Reply...'"
    [showInputBox]="showCommentReplies"
    [fetchingSocialAspects]="fetchingSocialAspects"
    (saveText)="saveReply($event)"
    [parentDivClass]="'mt-3 aspect-commentWrapper replyInput'"
    [uniqueTextId]="comments?.id + 'add-reply'"
    [isAddModeFormobile]="shareableDataForComments?.isMobileView ? true : false"
    [isMobileView]="shareableDataForComments?.isMobileView"
    ></app-mention>
  </div>
  <!-- add reply: end -->
</ng-container>

<!-- edit/delete modal for mobile view: start -->
<ng-template #editDeleteModal let-c="close" let-d="dismiss" class="social-aspect-mobile">
  <div class="modal-body p-0">
    <button (click)="onEdit()" class="font-weight-normal edit-select m-0 btn-edit-modal"><span class="edit-custom"><img
      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/edit.svg">{{ 'Edit' | translate }}</span></button><br>
    <button (click)="removeCommentForMobile()"
      class="font-weight-normal delete-select btn-delete-modal"><img
      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/delete.svg">{{ 'Delete' | translate }}</button>
  </div>
</ng-template>
<!-- edit/delete modal for mobile view: end -->

<ng-template #reactionsModal let-c="close" let-d="dismiss">
  <app-social-aspect-reaction-modal
  [data]="comments"
  [currentUserId]="authService.currentUserValue.id"
  [isMobileView]="shareableDataForComments?.isMobileView"
  [showLikeOnly]="true"
  ></app-social-aspect-reaction-modal>
</ng-template>

<!-- Social aspect: end -->