import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specsavers-employee-success',
  templateUrl: './specsavers-employee-success.component.html',
  styleUrls: ['./specsavers-employee-success.component.sass']
})
export class SpecsaversEmployeeSuccessComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }



}
