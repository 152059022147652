import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/core/service/auth.service';
import {getSSOLoginURL, getWPShopDomain} from 'src/app/core/_helpers/environment-variables';
import { encryptToken, extractTsText, setEPCookie } from 'src/app/core/_helpers/global-functions';

@Component({
  selector: 'app-login-handler',
  templateUrl: './login-handler.component.html',
  styleUrls: ['./login-handler.component.sass']
})
export class LoginHandlerComponent implements OnInit {
  redirect_to: string = null;
  companyShortName: string = null;
  intended_url: string = null;
  loadingText: string = null;
  token: string = null;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkQueryParams();
    this.token = this.authService.getLoginSession()?.token;

    if(this.token) {
      // set token in the cookie
      if(this.token && this.authService.getLoginSession()?.refreshToken) {
        setEPCookie('access_token', encryptToken(this.token));
        setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
      }
      this.authorizedRedirection()
    } else {
      this.unauthorizedRedirection()
    }
  }

  checkQueryParams() {
    /**
     * the shop uses https://admin.eachperson.com/login-handler?redirect_uri=https://shop.eachperson.com/products 
     * to redirect to the specific page of the shop when not logged in.
     * but the email templates or some clients may be using https://admin.eachperson.com/login-handler?redirect_to=new-shop&redirect_uri=https://shop.eachperson.com/products
     * or intended_url and companyShortName. so these should not break
     */
    this.route.queryParams.subscribe(
      params => {
        this.redirect_to = params['redirect_to'] || null;
        this.companyShortName = params['companyShortName'] || null;
        this.intended_url = params['intended_url'] || params['redirect_uri']  || null;
      }
    )
  }

  authorizedRedirection() {    
    if (!this.intended_url && (!this.redirect_to || this.redirect_to !== "new-shop")) {
      this.router.navigate(['/hr/dashboard']);
      return;
    }
  
    this.loadingText = extractTsText('Signing in ...');
  
    this.authService.checkTokenValidity().subscribe(
      response => {
        this.commonAuthorizedRedirection()
      },
      error => {
        this.authService.refreshToken().subscribe(
          response => {
            // assign newly received token to this.token
            this.token = response.access_token
            this.commonAuthorizedRedirection()
          },
          error => {
            this.authService.removeLoginTokens();
            localStorage.removeItem("currentUser");
            this.unauthorizedRedirection();
          }
        );
      }
    );
  }

  async commonAuthorizedRedirection() {
    /**
     * as we are checking gdprAccepted, shopOnly flags before redirecting user to the shop, even if there is token in
     * localStorage but no currentUser saved, we need to hit me API.
     * For this, I am redirecting user to login component, which hits me API if there is no currentUser data in localStorage
     */
    if(!this.authService.currentUserValue) {
      this.unauthorizedRedirection()
      return
    }

    if(this.authService.currentUserValue?.businessUser && !this.authService.currentUserValue?.gdprAccepted) {
      this.router.navigate(['/join/gdpr']).then()
      return
    }
    /**
     * check if user is shopOnly user
     */
    if (this.authService.checkShopOnly(this.intended_url) && this.authService.currentUserValue?.shopOnly) {
      this.authService.shopOnlyUserRedirection()
      return;
    }

    const tokenUrl = getWPShopDomain();
    
    // if there is no intended_url but there is redirec_to=new-shop - old logic
    if (!this.intended_url) {
      window.location.href = tokenUrl;
      return;
    }

    const encodedIntendedUrl = encodeURIComponent(this.intended_url);
    const redirectUrl = `${tokenUrl}&redirect_to=${encodedIntendedUrl}`;
    window.location.href = redirectUrl;
  }
  
  unauthorizedRedirection() {
    let companyShortName = this.companyShortName || this.authService.getCompanyShortName();
    let loginPath = '';

    this.authService.saveOtherAppsRedirectURI(
      getWPShopDomain(),
      this.intended_url,
      'token'
    );
  
    if (companyShortName) {
      loginPath = getSSOLoginURL(companyShortName); 
      
      window.location.href = loginPath;
    } else {
      this.router.navigateByUrl('/login').then();
    }
  }
  
}
