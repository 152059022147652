<!-- start: desktop sidebar -->
<div class="iatDesk-sidebar" *ngIf="!loadShop else loading;">
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar main-sidebar" (mouseenter)="mouseHover($event)"
    (mouseleave)="mouseOut($event)">
    <a class="sidebar-close" (click)="closeSidebar()">
      <i-feather name="x"></i-feather>
    </a>
    <div class="sidebar-brand pr-1">
      <a routerLink="/hr/dashboard" [class.disabled]="disableSidebar || apiDocumentationRoutesBoolean ? true : null">
        <img alt="image" [src]="logoUrl || 'https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png'"
          class="header-logo" />
      </a>
    </div>
    <div class="menu">
      <ul class="list mb-2" [ngClass]="{'setUpCompleted': currentUser && currentUser.setUpCompleted }"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>

        <li class="sidebar-user-panel"
          *ngIf="currentUser && currentUser.setUpCompleted && appAccessible && currentUser.gdprAccepted &&!apiDocumentationRoutesBoolean">
          <div>

            <div *ngIf="UserDetail$ | async as UserDetail">
              <div *ngIf="UserDetail?.apiRetriedFor < 10 && UserDetail?.apiFailed" class="text-center mb-2">
                <div class="spinner-border" role="status">
                </div>
              </div>
              <div *ngIf="UserDetail?.apiRetriedFor === 10 && UserDetail?.apiFailed" class="text-center mb-2">
                <div class="alert alert-danger">
                  Failed to load personal stats. Please refresh the page again or contact
                  the support.
                </div>
              </div>
              <div class="profile-usertitle" *ngIf="!UserDetail?.apiFailed">
                <div class="sidebar-userpic-name"> {{UserDetail?.name}} </div>
                <div class="profile-usertitle-job"> {{ UserDetail?.departmentName }} </div>
              </div>
              <!-- callBalanceAPIsByDefault=false prevents default balance APIs call by default from the personal-balance component.
              It means the balance APIs are called from sidebar itself otherwise the balance APIs will be called twice. -->
              <app-personal-balance [callBalanceAPIsByDefault]="false"></app-personal-balance>
              <div class="sidebar-user-stats" *ngIf="!UserDetail?.apiFailed">
                <!-- as the css broke while adding anchor tag, for now I have made <p> type="button" and added a routerLink -->
                <p class="font-weight-bold">{{ 'people recognised' | translate | titlecase }} <span type="button" routerLink="/hr/points-allocation/choose-an-ecard">{{UserDetail?.eCardsSent
                    || 0}} </span> </p>
                <p class="font-weight-bold">
                  {{ UserDetail?.treesDonated == 1 ? ('tree planted' | translate | titlecase) : ('trees planted' | translate | titlecase) }}
                  <span type="button" [routerLink]="isMac ? undefined: '/hr/donate'">
                    {{UserDetail?.treesDonated}}</span> </p>
              </div>
            </div>
          </div>
        </li>
        <!-- comment by laxmi
        appUiAccess is a custom directive to show or hide the sidebar items as per the permissions that matches with the
        logged-in user's permissions
        accessAuthorities is a value that has two properties: withPermissions or withAny, which is checked in ui-access.directive.ts
        these properties can have value null or '' if no permission check is needed to be done in the elements -->
        <!-- Top Most level menu -->
        <ng-container *ngFor="let sidebarItem of sidebarItems">
          <li [class.active]="(level1Menu === sidebarItem.moduleName)"
            *ngIf="displayMenuItem(sidebarItem.title)"
            [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
            [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': sidebarItem.accessAuthorities}">

            <!-- groupTitle: true is just a title with no click event -->
            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{ sidebarItem.title | translate }}</div>

            <!-- start: if no sub menus and has externalPath -->
            <button *ngIf="!sidebarItem.groupTitle && sidebarItem.externalPath && sidebarItem.submenu.length === 0"
              (click)="handleRedirection(sidebarItem.externalPath, $event)"
              [hidden]="sidebarItem.sfsEligible && !isEligibleforSFSActive"
              [ngClass]="[sidebarItem.class]"
              class="menu-top" [class.disabled]="(disableSidebar || loadShop) && !sidebarItem.helpLink">
              <i-feather [name]="sidebarItem.icon" class="sidebarIcon" *ngIf="sidebarItem.icon"></i-feather>
              <!-- if there is an SVG image instead of Feathericon -->
              <div class="sidebarIcon" [innerHTML]="sidebarItem.imageCode" *ngIf="sidebarItem.imageCode">
              </div>
              <span class="hide-menu">{{ sidebarItem.title | translate }}</span>
              <span *ngIf="sidebarItem.badge != ''" [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </button>
            <!-- end: if no sub menus and has externalPath -->

            <!-- start: if no sub menus and has internal router path -->
            <button *ngIf="!sidebarItem.groupTitle && !sidebarItem.externalPath && (sidebarItem.path || (!sidebarItem.path && sidebarItem.sfsEligible)) && sidebarItem.submenu.length === 0"
              [routerLink]="sidebarItem.path ? [sidebarItem.path] : (sidebarItem.sfsEligible ? [sfsRoute] : null)"
              [hidden]="sidebarItem.sfsEligible && !isEligibleforSFSActive"
              [ngClass]="[sidebarItem.class]"
              class="menu-top" [class.disabled]="disableSidebar && !sidebarItem.helpLink">
              <i-feather [name]="sidebarItem.icon" class="sidebarIcon" *ngIf="sidebarItem.icon"></i-feather>
              <!-- if there is an SVG image instead of Feathericon -->
              <div class="sidebarIcon" [innerHTML]="sidebarItem.imageCode" *ngIf="sidebarItem.imageCode">
              </div>
              <span class="hide-menu">{{ sidebarItem.title | translate }}</span>
              <span *ngIf="sidebarItem.badge != ''" [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </button>
            <!-- end: if no sub menus and has internal router path -->

            <!-- if has sub menus -- routerLink not needed because this is causing double click issue -->
            <button
              *ngIf="!sidebarItem.groupTitle && sidebarItem.submenu.length > 0"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
              class="menu-top" [class.disabled]="disableSidebar && !sidebarItem.helpLink">
              <i-feather [name]="sidebarItem.icon" class="sidebarIcon" *ngIf="sidebarItem.icon"></i-feather>
              <!-- if there is an SVG image instead of Feathericon -->
              <div class="sidebarIcon" [innerHTML]="sidebarItem.imageCode" *ngIf="sidebarItem.imageCode">
              </div>
              <span class="hide-menu">{{ sidebarItem.title | translate }}</span>
              <span *ngIf="sidebarItem.badge != ''" [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </button>
            <!-- end: if has sub menus -- routerLink not needed because this is causing double click issue -->

            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <ng-container *ngFor="let sidebarSubItem of sidebarItem.submenu">
                <li *ngIf="displayMenuItem(sidebarSubItem.title)"
                  [class.active]="activateSubMenu(sidebarSubItem)"
                  [appUiAccess]="{'currentUser': currentUser, 'userFlags': userFlags, 'accessAuthorities': sidebarSubItem.accessAuthorities}">
                  <!-- start: if submenu has an external path -->
                  <button *ngIf="sidebarSubItem.externalPath && !sidebarSubItem.path"
                    (click)="handleRedirection(sidebarSubItem.externalPath, $event)"
                    [ngClass]="[sidebarSubItem.class]"                    
                    [class.disabled]="(disableSidebar || loadShop) && !sidebarSubItem.helpLink">
                    {{ sidebarSubItem.title | translate }}
                  </button>
                  <!-- end: if submenu has an external path -->

                  <!-- start: if submenu has internal route path -->
                  <button [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                    *ngIf="sidebarSubItem.path && !sidebarSubItem.externalPath" [ngClass]="[sidebarSubItem.class]"
                    [class.disabled]="disableSidebar && !sidebarSubItem.helpLink">{{ sidebarSubItem.title | translate }}
                  </button>
                  <!-- end: if submenu has internal route path -->
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
          
        <!-- #Menu -->
      </ul>
      <!-- badge  -->

    </div>
  </aside>
  <!-- #END# Left Sidebar -->
</div>
<!-- end: desktop sidebar -->

<!-- start: mobile sidebar -->
<app-sidebar-mobile
[ngClass]="{'d-none': !enabledSidebarMobile}"></app-sidebar-mobile>
<!-- end: mobile sidebar -->

<ng-template #loading>
  <div class="fullscreen-loader">
      <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
      </div>
      <!-- <p>Redirecting ...</p> -->
  </div>
</ng-template>