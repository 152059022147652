import { Component, OnInit } from '@angular/core';
import { GdprService } from 'src/app/core/service/gdpr.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';

@Component({
  selector: 'app-gdpr-mailing-signup',
  templateUrl: './gdpr-mailing-signup.component.html'
})
export class GdprMailingSignupComponent implements OnInit {
  gdprData: any = null;
  showGdprSticky: boolean = false;
  gdprSubmitting: boolean = false;

  constructor(
    private gdprService: GdprService,
    private toastr: ToastrCustomMessageService
  ) {}

  ngOnInit(): void {
    this.getGDPRDetails()
  }

  getGDPRDetails() {
    this.gdprService.getGDPRDetails().subscribe(
      response => {
        this.gdprData = response
        if(!response?.userInteractedForEmailSubscription && !response?.marketingPrefEmail) {
          this.showGdprSticky = true
        } else {
          this.showGdprSticky = false
        }
      }
    )
  }

  handleUpdateEmailSubscription(enableMail: boolean) {
    this.gdprSubmitting = true

    let payload = {
      "tncAccepted": this.gdprData?.tncAccepted,
      "privacyAccepted": this.gdprData?.privacyAccepted,
      "marketingPrefSMS": this.gdprData?.marketingPrefSMS,
      "marketingPrefEmail": enableMail,
      "userInteractedForEmailSubscription": true
    }

    this.gdprService.saveGDPRDetails(payload).subscribe(
      response => {
        this.gdprSubmitting = false
        // success saving mail subscription
        this.getGDPRDetails()
      },
      error => {
        this.gdprSubmitting = false
        this.toastr.error(error)
      }
    )
  }

}
