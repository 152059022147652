import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccessIfSetupCompletedGuard implements CanActivate, OnDestroy {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * since this.authService.currentUserValue which is a BehaviourSubject value, did not detect
   * changes made in localStorage using API request from the component
   * was detected late
   * the new changes, we are using observable which detects the changes made using subscribe
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data) {
            // if set up completed, then allow access
            if(data.setUpCompleted) {
              obs.next(true);
            }
            else {
              //location.reload() insde then breaks the app and redirects user to login in IOS devices so used .then() only
              this.router.navigate(['/set-up']).then()
              obs.next(false);              
            }
          } else {
            obs.next(false);  
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
