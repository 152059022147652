import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MentionConfig } from 'angular-mentions';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MentionService } from '../mention.service';
// using angular-mentions library with version 1.3.0 which iscompatible with Angular 11 - https://github.com/dmacfarlane/angular-mentions/releases/tag/v1.3.0
// example: https://stackblitz.com/edit/angular-mentions-rg2zc2?file=app%2Fapp.component.html,app%2Fapp.component.ts,styles.css
// async search example - https://github.com/dmacfarlane/angular-mentions/blob/v1.3.0/projects/demo-app/src/app/demo-async/demo-async.component.ts
// angular-mentions@1.3.0 - https://www.npmjs.com/package/angular-mentions

interface User {
  id: number;
  name: string;
}

@Component({
  selector: 'app-mention',
  templateUrl: './mention.component.html',
  styleUrls: ['./mention.component.sass']
})
export class MentionComponent implements OnInit {
  @Input() showInputBox: boolean = false; // determines whether to show container or not
  @Input() placeholder: string = ''; // since this component is shared, placeholder is also taken from the parents
  @Input() fetchingSocialAspects: boolean = false; // this is the global variable, which determine if the social aspect API is being called for every actions
  @Input() editText: boolean = false; // determines if the text input is for add/edit
  @Input() textData: string = null; // comment text from API
  @Input() uniqueTextId = null; // mention div is given unique id with the comments/replies id sent from parents
  @Input() mentionedUsersList = []; // user mentions received from API
  @Input() parentDivClass: string = "aspect-commentWrapper desktop-only"; // classes can be different for comment and reply
  @Input() comments: any = null; // used for change detection
  @Input() isAddModeFormobile: boolean = null; // this is set true for mobile view and is sent from the component where this is reuired. this boolean helps to add class 'mobile-commentinput' which helps to display comment/reply in the fixed position 
  @Input() isMobileView: boolean = false;
  @Output() saveText = new EventEmitter<any>();
  @Output() cancelEdit = new EventEmitter<any>();
  maxCharacterLength: number = 250;
  

  mentionConfig: MentionConfig = {
    mentionSelect: this.format,
    labelKey: 'name',
    allowSpace: true, // allow space while mentioning
    dropUp: false, // show mention list at top? - made false by default
  };
  mentions = [];
  format(item: any) {
    var user_name = item['name'];
    return `@${user_name}`;
  }
  
  public htmlDoc: HTMLElement;
  messageContentId = 'my-content'  
  inputData: any = '';

  // for caretPosition and inserting emoji
  selection: any;
  range: any;
  parentNode: any;
  commentMsgText: any = null;

  // this is used when event emitter is triggered while submitting text to the api
  apiTriggered: boolean = false

  hideEmojiWindow: boolean = false

  mentionItems: any[] = [] // store d<efault list here
  httpItems: Observable<any[]>;
  private searchTermStream = new BehaviorSubject<any>(null)

  constructor(
    private mentionService: MentionService
  ) { }

  ngOnInit(): void {
    this.getUsersForMentions()
  }

  ngOnChanges() {
    // in mobile view, make dropUp for mention list true
    if(this.isMobileView) {
      this.mentionConfig.dropUp = true;
    }  
    
    
    // set unique identifier for contenteditable
    this.messageContentId = this.uniqueTextId ? `my-content-${this.uniqueTextId}` : this.messageContentId;

    // if the text is submitted and api fetch is success, make inputData empty
    if(!this.fetchingSocialAspects && this.apiTriggered && !this.editText) {
      this.inputData = ''
      this.htmlDoc = document.getElementById(`${ this.messageContentId }`);
      this.htmlDoc.innerHTML = ''
    }

    if(this.editText && this.textData) {
      this.inputData = this.textData;
      setTimeout(() => {
        this.htmlDoc = document.getElementById(`${ this.messageContentId }`);
        this.htmlDoc.innerText = this.textData.replace(/\n/g, '<br>');
        this.htmlDoc.innerHTML = this.htmlDoc.innerHTML;
        if(this.mentionedUsersList?.length > 0) {
          this.mentions = []
          this.mentionedUsersList.forEach((eachMention: any) => {
            let eachUser = {
              id: eachMention?.userId,
              name: eachMention?.name
            }
            this.mentions.push(eachUser)
          })
        }
        this.replaceMentionsWithAnchors()

        this.focusAtEnd(this.htmlDoc)
      }, 10)
    }

    this.maxCharacterLimitExceeded = this.inputData.length > this.maxCharacterLength ? true : false
  }

  focusAtEnd(contentEditableElement) {
    // Set the focus to the contenteditable element
    contentEditableElement.focus();
  
    // Create a Range object
    const range = document.createRange();
  
    // Select the entire contents of the contenteditable element
    range.selectNodeContents(contentEditableElement);
  
    // Collapse the range to the end (i.e., after the content)
    range.collapse(false);
  
    // Clear any existing selections and set the new range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  getInputSelection() {
    this.commentMsgText = this.htmlDoc || document.getElementById(`${this.messageContentId}`);
    this.selection = window.getSelection();
    this.range = this.selection.getRangeAt(0);
    this.parentNode = this.range.commonAncestorContainer.parentNode;   
  }

  insertTextAtCursor(text) {
    if(!this.commentMsgText) {
      this.getInputSelection()
    }
    if(this.parentNode === this.commentMsgText || this.parentNode.parentElement === this.commentMsgText) {
      let node = document.createTextNode(text);

      this.range.deleteContents();
      this.range.insertNode(node);
      // Move the cursor to the end
      this.range.collapse(false);
      this.selection.removeAllRanges();
      this.selection.addRange(this.range);
      this.inputData = this.commentMsgText.innerText
    } else {
      this.commentMsgText.innerHTML += text;
      this.inputData = this.commentMsgText.innerText
      this.focusAtEnd(this.commentMsgText)
        
    }
}
  handleEmojiSelection(selectedEmoji) {
    this.insertTextAtCursor(selectedEmoji)
  }

  triggerEventEmitter(event) {
    event.preventDefault(); // disable default textarea newline on enter
    event.stopPropagation();
    if(this.inputData === this.textData) {
      // 'same data - no api submit'
      return;
    }

    if(this.inputData.length > this.maxCharacterLength) {
      return;
    }
    
    let emitData = {
      data: this.inputData,
      mentions: this.mentions
    }
    this.apiTriggered = true
    this.hideEmojiWindow = true
    this.saveText.emit(emitData)
  }

  onEditCancel() {
    this.showInputBox = false;
    this.cancelEdit.emit(this.showInputBox)
  }

  // angular-mentions code start
  maxCharacterLimitExceeded: boolean = false;
  onCommentChange(event) {  
    this.inputData = event.target.innerText
    if(this.mentions.length > 0) {
      let newMentions = []
      this.mentions.forEach(eachMention => {
        if(this.inputData.includes(eachMention.name)) {
          newMentions.push(eachMention)
        }
      })
      this.mentions = newMentions
    }

    this.maxCharacterLimitExceeded = this.inputData.length > this.maxCharacterLength ? true : false
  }

  // Function to replace mentions in the content editable div
  replaceMentionsWithAnchors() {
    this.htmlDoc = document.getElementById(`${ this.messageContentId }`);
    let text = this.htmlDoc.innerText;

    text = text.replace(/\n/g, '<br>')

    // Replace mentions with anchor tags
    this.mentions.forEach((mention) => {
      text = text.replace(
        `@${mention.name}`,
        `<a class="mention-highlight" href="javascript:void(0)">@${mention.name}</a>&nbsp;`
      );
    });

    this.htmlDoc.innerHTML = text;
  }

  itemSelected(event: any) {
    setTimeout(() => {
      this.htmlDoc = document.getElementById(`${ this.messageContentId }`);
      const mention = {
        name: event.name,
        id: event.id,
      };

      // Check if the mention is already in the mentions array
      const existingMentionIndex = this.mentions.findIndex(
        (m) => m.id === mention.id
      );
      
      if (existingMentionIndex === -1) {
        // Mention is not in the array, add it
        this.mentions.push(mention);
      } else {
        // Mention already exists, update it
        this.mentions[existingMentionIndex] = mention;
      }

      // Replace mentions in the content editable div
      this.replaceMentionsWithAnchors();

      // Put the cursor to the end of the field again...
      this.selectEnd();
    }, 10);
  }

  // Function to delete a mention
  deleteMention(id: any) {
    const index = this.mentions.findIndex((mention) => mention.id === id);
    if (index !== -1) {
      this.mentions.splice(index, 1);
      this.replaceMentionsWithAnchors();
    }
  }

  selectEnd() {
    let range, selection;
    range = document.createRange();
    range.selectNodeContents(this.htmlDoc);
    range.collapse(false);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  
  getUsersForMentions() {
    this.httpItems = this.searchTermStream.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term: string) => this.getItems(term))
    );
  }
  search(term: string) {
    let searchTerm = term?.length >= 3 ? term : null // search term should have a length of 3 or more
    this.searchTermStream.next(searchTerm);
  }

  // this method uses a funtion in a separate mention.service.ts file that calls the API and gives the formatted results
  getItems(term):Observable<any[]> {
    if (!term) {
      // if the search term is empty, return an empty array
      return of([]);
    }

    return this.mentionService.getUserListForMentions(term)
  }
}
