<section class="main-content activity landing-page">
    <div class="payment-sub-header-binder setup-completed mb-4">
        <div class="form-row">
            <div class="col-lg-6 pb-2 pb-lg-0">
                <h4 class="page-title mt-md-1">{{ 'Benefits' | translate }}</h4>
            </div>
        </div>
        <p class="mb-0 font-16 font-weight-normal">{{ currentUser && currentUser.role === 'superAdmin' ? ('Create a tailored benefit for your company' | translate) : ('Personalised benefits just for you' | translate) }}
        </p>
        <p class="mb-0 font-weight-normal" *ngIf="!benefitsLoading" [innerHTML]="benefitsPaginationText"></p>

        <div class="tabs">
            <a *ngFor="let tab of tabs" class="tab-item" [class.active]="tab === selectedTab" (click)="selectTab(tab)">
                {{ tab?.name }}
            </a>
        </div>
    </div>
    <div class="section-body">
        <div *ngIf="benefitsLoading" class="text-center mb-2">
            <div class="spinner-border" role="status">
            </div>
        </div>
        <div class="benefits-wrapper text-center" *ngIf="!benefitsLoading">
            <div class="benefit benefit-empty cursor" (click)="addNewBenefit()" *ngIf="currentUser && currentUser.role === 'superAdmin'">
                <div class="benefit-empty-dashes">
                    <svg width="100%" height="100%" viewBox="0 0 100% 100%" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <rect id="path-xlopttdo4y-1" x="0" y="0" width="100%" height="100%" rx="10"></rect>
                            <mask id="mask-xlopttdo4y-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0"
                                width="100%" height="100%" fill="white">
                                <use xlink:href="#path-xlopttdo4y-1"></use>
                            </mask>
                        </defs>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.718104771"
                            stroke-dasharray="7,5">
                            <g transform="translate(-287, -293)" fill="#FFFFFF"
                                stroke="#000000" stroke-width="2">
                                <g transform="translate(287, 293)">
                                    <use mask="url(#mask-xlopttdo4y-2)" xlink:href="#path-xlopttdo4y-1"></use>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="benefit-inner">
                    <div class="icon-holder">
                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/benefits/add.svg"
                            style="transform: scale(1.3);">
                    </div>
                    <div class="benefit-title" [innerHTML]="'Add a New <br />Company Benefit' | translate">
                    </div>
                    <div class="benefit-text">
                        {{ 'Click here to create a new company benefit for your people to discover and enjoy. Include an icon, button, and detailed description.' | translate }}
                    </div>
                </div>
            </div>
            <div class="benefit" *ngFor="let benefit of sortBenefits(displayedBenefits)">
                <div class="benefit-inner">
                    <div class="benefit-edit cursor" (click)="editBenefit(benefit)" *ngIf="currentUser && currentUser.role === 'superAdmin' && !benefit.isDefaultBenefit">
                        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>edit</title>
                            <g id="BENEFITS-(FRAN)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                stroke-linejoin="round">
                                <g id="Dashboard-/-Benefits---Created-View---Desk" transform="translate(-805, -310)" stroke="#000000">
                                    <g id="edit" transform="translate(806, 311)">
                                        <path
                                            d="M6.31627935,1.4887613 L1.40361763,1.4887613 C0.62842102,1.4887613 0,2.11718232 0,2.89237893 L0,12.7177024 C0,13.492899 0.62842102,14.12132 1.40361763,14.12132 L11.2289411,14.12132 C12.0041377,14.12132 12.6325587,13.492899 12.6325587,12.7177024 L12.6325587,7.80504065"
                                            id="Path" stroke-width="0.5"></path>
                                        <path
                                            d="M11.5798455,0.436048073 C12.1612429,-0.145349366 13.1038745,-0.145349356 13.6852719,0.436048095 C14.2666694,1.01744555 14.2666694,1.96007706 13.6852719,2.54147452 L7.01808817,9.20865828 L4.2108529,9.9104671 L4.91266172,7.10323183 L11.5798455,0.436048073 Z"
                                            id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="icon-holder">
                        <img [src]="benefit?.image">
                    </div>
                    <div class="benefit-title">
                        {{ benefit?.title }}
                    </div>
                    <div class="benefit-text">
                        {{ benefit?.text }}
                        <a class="view-more d-block" (click)="viewBenefitDetail(benefit)" *ngIf="benefit?.longDesc">{{ 'view more' | translate }}</a>
                    </div>
                    <div class="benefit-button" *ngIf="benefit?.buttonText && benefit?.buttonLink">
                        <a class="btn btn-secondary px-5 mt-2 font-16" href="{{ benefit?.buttonLink }}">
                            {{ benefit?.buttonText }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3" *ngIf="selectedTab?.benefits?.length > (currentUser && currentUser.role === 'superAdmin' ? 11 : 12) && !benefitsLoading">
            <button role="button" class="btn-view-more" (click)="viewAll()">{{ viewAllLabel  | translate }}</button>
        </div>
    </div>
</section>