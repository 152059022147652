import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { extractTsText, objToQueryString, setEPCookie } from "../_helpers/global-functions";
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, take} from "rxjs/operators";
import { throwError, Observable } from 'rxjs';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private httpBackend: HttpBackend,
    private authService: AuthService
  ) { }

  fetchEligibleStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/users/check-membership-tier-eligibility`);
  }

  /** V2 API */
  createUser(data) {
    return this.http.post<any>(`${ environment.apiUrl }/users/V2`, data);
  }

  uploadBulkEmployees(data){
    return this.http.post<any>(`${ environment.apiUrl }/users/bulk-uploads/V2`, data);
  }

  deleteBulkEmployees(data){
    return this.http.post<any>(`${ environment.apiUrl }/users/bulk-uploads/delete`,data);
  }

  getEmployeeHistory(params){
    params.dateFrom = params.dateFrom.replace(/\+/gi, '%2B');
    params.dateTo = params.dateTo.replace(/\+/gi, '%2B');
    return this.http.get<any>(`${ environment.apiUrl }/ecards/award?${objToQueryString(params)}`);
  }

  getEmployeesList(params) {
    /* ----- as HttpParams of newer angular versions greater than angular 5,
     converts the + sign to space by default, it is not as what we want for the datetime format
    eventhough we replace + with %2B, it again converts into its default one.
    so, lets append the query parameters to the URL itself, which works as expected.
    */
    // using HttpParams
    // return this.http.get<any>(`${ environment.apiUrl }/users`, {params});

    // appending query parameters without using default HttpParams of angular
    // replace + from datetime to %2B

    if(params.dateFrom && params.dateTo) {
      params.dateFrom = params.dateFrom.replace(/\+/gi, '%2B');
      params.dateTo = params.dateTo.replace(/\+/gi, '%2B');
    }

    let queryParams = objToQueryString(params)
    return this.http.get<any>(`${ environment.apiUrl }/users?${ queryParams }`);
  }

  getUserDetail(id) {
    return this.http.get<any>(`${ environment.apiUrl }/users/${ id }`);
  }

  updateUser(id, data, emailUpdateType) {
    return this.http.put<any>(`${ environment.apiUrl }/users/${ id }/V2`, data, { params: {emailUpdateType: emailUpdateType} });
  }

  wasUserLoogedIn(id) {
    return this.http.get<any>(`${ environment.apiUrl }/epoints/users/${ id }/was-logged-in`);
  }

  deleteUser(id) {
    return this.http.delete<any>(`${ environment.apiUrl }/users/${ id }`);
  }

  resetGlobalPassword(id) {
    return this.http.put<any>(`${ environment.apiUrl }/users/resetGlobalPassword/${ id }`, null);
  }

  enableUser(id) {
    return this.http.put<any>(`${ environment.apiUrl }/users/${ id }/enable`, null);
  }

  exportEmployeeHistory(params){
    params.dateFrom = params.dateFrom.replace(/\+/gi, '%2B');
    params.dateTo = params.dateTo.replace(/\+/gi, '%2B');
    delete params.maxResults;
    delete params.page;

    return this.http.post<Promise<any>>(`${ environment.apiUrl }/ecards/award/export?${objToQueryString(params)}`,null).toPromise();
  }

  fetchStatsForUsersAllocationHistory(params){
    params.dateFrom = params.dateFrom.replace(/\+/gi, '%2B');
    params.dateTo = params.dateTo.replace(/\+/gi, '%2B');
    delete params.maxResults;
    delete params.page;
    return this.http.get<any>(`${ environment.apiUrl }/ecards/award/stats?${objToQueryString(params)}`)
  }

  getEcardDetail(id: number, countryCode: string = 'GB'){
    let region = countryCode === 'GB' ? 'UK' : countryCode;
    let params = {
      region
    };
    return this.http.get<any>(`${ environment.apiUrl }/ecards/award/modal/${id}`, {params});
  }

  exportBrowseEmployee(params){
    params.dateFrom = params.dateFrom.replace(/\+/gi, '%2B');
    params.dateTo = params.dateTo.replace(/\+/gi, '%2B');
    delete params.maxResults;
    delete params.page;
    return this.http.patch<Promise<any>>(`${ environment.apiUrl }/users/export?${objToQueryString(params)}`,null).toPromise();
  }

  // get UI color settings like navbar color, button color set in the database
  fetchClientBrandingColors(partnerId: number){
    return this.http.get<any>(`${ environment.apiUrl }/application/branding?partnerId=${partnerId}`)
    .pipe(map(
      response => {
        setEPCookie('branding_info', JSON.stringify(response));
        return response;
      }
    ));
  }

  // get the list of managers for the department
  getDepartmentManagers(partnerId: number, departmentId: number) {
    return this.http.get<any>(`${ environment.apiUrl }/users/managers/partner/${partnerId}/department/${departmentId}`)
  }

  // set/update selected country and language for the user
  updateUserCountryLangDetails(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/users/save/users/infos`, payload)
    .pipe(response => {
      setTimeout(() => {
        this.fetchMeAPI();
      }, 500);
      
      return response;
    });
  }

  fetchMeAPI() {
    // need fresh data from API so deleting cache me data
    this.authService.deleteMeCacheItem();
    this.authService.getMe()
      .pipe(take(1)) // take(1) is used to run the subscription once and not leak the subscription
      .subscribe();
  }

  dateAgo(value) {
    if (value) {
      const dateObject = new Date(value);
      const seconds = Math.floor((+new Date() - +dateObject) / 1000);

      if (seconds < 30) {
        return this.translate.instant(extractTsText('a few seconds ago'));
      }

      let translateIntervals = [
        extractTsText('year'),
        extractTsText('month'),
        extractTsText('day'),
        extractTsText('hour'),
        extractTsText('week'),
        extractTsText('minute'),
        extractTsText('second'),
        extractTsText('years'),
        extractTsText('months'),
        extractTsText('days'),
        extractTsText('hours'),
        extractTsText('weeks'),
        extractTsText('minutes'),
        extractTsText('seconds'),
      ];
  
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
  
      for (let interval in intervals) {
        
        const counter = Math.floor(seconds / intervals[interval]);

        if (counter > 0) {
          interval = counter === 1 ? this.translate.instant(interval) : this.translate.instant(`${interval}s`);
          return this.translate.instant(extractTsText(`{{counter}} {{interval}} ago`), {
            counter,
            interval
          });
        }
      }
    }
    return value;
  }
  // update lastSelectedDashboardTag for each user 
updateUserData(lastSelectedDashboardTag: string):  Observable<any> {
  const currentUser = this.authService.currentUserValue;
    let token = this.authService.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    let queryParams = {};
      queryParams["lastSelectedDashboardTag"] = "lastSelectedDashboardTag";
    
    let requestOptions = {headers: headers, params: queryParams};

    const body = { "lastSelectedDashboardTag": lastSelectedDashboardTag };
    return this.http.put<any>(`${environment.apiUrl}/users/update-data`, body, { headers })
    .pipe(
      map(response => {
        console.log('Response Data:', response);
        return response;
      }),
      catchError(error => {
        console.error('Error updating data:', error);
        return throwError(error);
      })
    );

}

}
