<section class="main-content dashboard">
    <div class="section-title mt-3">
        <h4 class="pt-3 text-center text-lg-left">
            {{ 'Employee Assistance Programme (EAP)' | translate }} </h4>
    </div>

    
    <div class="section-body">
        <div class="bg-white eap-customise mb-4">
            <div class="row">
                <div class="col-lg-5 col-md-5 col-12 px-md-2 mobile-order-2">
                    <div class="eapHero-content">
                        <h2>{{ 'What is EAP?' | translate }}</h2>
                        <p [innerHTML]="'As an employee, why should you be utilising the Employee Assistance Programme (EAP) offered by your employer?<br> Here are some key reasons why you might find this benefit valuable!' | translate"></p>
                    </div>
                    <div class="">
                        <p class="font-16 font-weight-400 mb-0 text-dark">{{ 'Free Confidential Support 24/7' | translate }}</p>
                        <h2 class="eap-tel font-weight-bold text-left pt-0 mb-3">
                            <a *ngIf="imperialClient" href="tel:0808 802 2111" class="text-dark font-weight-bold">0808 802 2111</a>
                            <a *ngIf="!imperialClient" href="tel:{{ eapDetails[partnerId]?.phoneNumber || '0800 0882 072' }}" class="text-dark font-weight-bold">{{ eapDetails[partnerId]?.phoneNumber || '0800 0882 072' }}</a>
                        </h2>
                        <p *ngIf="!imperialClient && (eapCredentials?.username || eapCredentials?.password)" class="text-dark font-weight-bold font-20 mb-2">{{ 'Login Credentials:' | translate }}</p>
                        <!-- displaying hard-coded access code for Imperial client -->
                        <p *ngIf="imperialClient" class="font-weight-300 pt-1 mb-1 text-dark font-16"><span class="font-weight-bold">Spectrum.Life
                        </span></p>
                        <p *ngIf="imperialClient" class="font-weight-300 mb-2 text-dark font-16"><span class="font-weight-bold">{{ 'Access Code' | translate }}: </span>HAWellbeing</p>
                        <p *ngIf="!imperialClient && eapCredentials?.username" class="font-weight-300 pt-1 mb-1 text-dark font-16"><span class="font-weight-bold">{{ 'User Name' | translate }}:
                            </span>{{ eapCredentials?.username }}</p>
                        <p *ngIf="!imperialClient && eapCredentials?.password" class="font-weight-300 mb-2 text-dark font-16"><span class="font-weight-bold">{{ 'Password' | translate }}:
                            </span>{{ eapCredentials?.password }}</p>
                        <a [href]="!imperialClient ? (eapDetails[partnerId]?.loginUrl || 'https://carefirst-lifestyle.co.uk/') : 'https://hospitalityaction.spectrum.life/login'"
                            class="eap-cta-btn"
                            [target]="linkTarget">{{ 'Click to go to EAP' | translate }}</a>
                    </div>
                </div>
                <div class="heroImage mobile-order-1">
                    <img src="../../../assets/images/eap-header.png">
                </div>
            </div>
        </div>

        <div class="bg-white eap-sections">
            <div class="row py-3 justify-content-between align-items-center">
                <div class="col-lg-4">
                    <img src="../../../assets/images/support.png">
                </div>
                <div class="col-lg-6 px-5">
                    <h3 [innerHTML]="'<span>Free</span> Confidential Support:' | translate"></h3>
                    <p *ngIf="!imperialClient" [innerHTML]="'The EAP provides a safe and confidential space for you to address personal and work-related challenges. Whether you\'re dealing with stress, anxiety, relationship issues, financial concerns, or other difficulties, the EAP offers professional counselling and emotional support to help you navigate these challenges.' | translate"></p>
                    <p *ngIf="imperialClient">
                        {{ 'Your Employee Assistance Programme is a free, confidential counselling and wellbeing support service that provides you with in-the-moment support. There are multiple access points to a dedicated Advice Line Counsellor so we can respond to your needs at any time via phone, live chat, whatsapp/text and online portal.' | translate }}
                    </p>
                </div>
            </div>

            <div class="row py-3 pb-4 justify-content-between align-items-center">
                <div class="col-lg-6 px-5 mobile-order-2">
                    <h3 [innerHTML]="'<span>Mental Health &</span> Wellbeing:' | translate"></h3>
                    <p *ngIf="!imperialClient">{{ 'Your mental health and wellbeing are essential for your overall happiness and productivity. The EAP recognises this and provides resources and tools to support your mental wellbeing. From counselling sessions to stress management techniques and mental health assessments, the EAP can assist you in maintaining a healthy and balanced mind.' | translate }}</p>
                    <p *ngIf="imperialClient">{{ 'Your mental health and wellbeing are essential for your overall happiness and productivity. Whether you're dealing with stress, anxiety, relationship issues, financial concerns, or other difficulties, the EAP offers professional counselling, stress management techniques, emotional support and more to help you navigate these challenges.' | translate }}</p>
                </div>
                <div class="col-lg-4 mobile-order-1">
                    <img src="../../../assets/images/girl-support.png">
                </div>
            </div>

            <div class="row py-3 pb-4 justify-content-between align-items-center">
                <div class="col-lg-4">
                    <img src="../../../assets/images/wellness-supports.png">
                </div>
                <div class="col-lg-6 px-5">
                    <h3 [innerHTML]="'<span>Health & Wellness</span> Support:' | translate"></h3>
                    <p *ngIf="!imperialClient">{{ 'The EAP promotes your physical wellbeing by offering resources related to health and wellness. This may include programs or information on smoking cessation, weight management, nutrition, fitness, and preventive healthcare. By accessing these resources, you can make informed decisions to lead a healthier lifestyle.' | translate }}
                    <p *ngIf="imperialClient">
                        {{ 'The EAP promotes your physical wellbeing by offering resources related to health and wellness. This includes expert advice via programs or information on smoking cessation, addiction, weight management, nutrition, fitness, and preventive healthcare. By accessing these resources, you can make informed decisions to lead a healthier lifestyle.' | translate }}
                    </p>
                </div>
            </div>

            <div class="row py-3 pb-4 justify-content-between align-items-center">
                <div class="col-lg-6 px-5 mobile-order-2">
                    <h3> {{ 'Work-Life Balance' | translate }}: </h3>
                    <p *ngIf="!imperialClient">
                        {{ 'Striking a healthy work-life balance is crucial for your overall satisfaction and productivity. The EAP can help you find resources and strategies to manage your time effectively, improve work-life balance, and support your personal commitments outside of work, such as childcare or eldercare resources.' | translate }}
                    </p>
                    <p *ngIf="imperialClient">{{ 'Striking a healthy work-life balance is crucial for your overall satisfaction and productivity. The EAP can help you find resources and strategies to manage your time effectively, improve work-life balance, and support any changes to your personal life such as parenting, childcare or eldercare resources.' | translate }}</p>
                </div>
                <div class="col-lg-4 mobile-order-1">
                    <img src="../../../assets/images/work-life.png">
                </div>
            </div>

            <div class="row py-3  justify-content-between align-items-center">
                <div class="col-lg-4">
                    <img src="../../../assets/images/finance.png" >
                </div>
                <div class="col-lg-6 px-5">
                    <h3 [innerHTML]="'<span>Financial & Legal</span> Guidance:' | translate"></h3>
                    <p *ngIf="!imperialClient">
                        {{ 'Financial and legal matters can be overwhelming. The EAP offers access to financial advice and tools to help you manage your finances effectively. Additionally, it provides legal guidance or connects you with qualified professionals to address any legal concerns that you may have, such as family law, housing, or employment contracts.' | translate }}
                    </p>
                    <p *ngIf="imperialClient">{{ 'Financial and legal matters can be overwhelming. The EAP offers access to financial advice and tools to help you manage your finances effectively. Additionally, it provides legal guidance or connects you with qualified professionals to address any legal concerns that you may have, such as family law, housing, or employment contracts.' | translate }}</p>
                </div>
            </div>

            <div class="row py-3 justify-content-between align-items-center">
                <div class="col-lg-6 px-5 mobile-order-2">
                    <h3> {{ !imperialClient ? ('Crisis Management' | translate) : ('Communicate Your Way' | translate) }}:</h3>
                    <p *ngIf="!imperialClient">{{ "Life can present unexpected crises or emergencies, and during those times, the EAP can be an invaluable resource. It provides immediate support, guidance, and coping strategies to effectively help you navigate difficult situations, traumatic events, or personal crises." | translate }}
                    </p>
                    <p *ngIf="imperialClient">{{ "Life can throw unexpected challenges our way, and when those moments hit, remember that your EAP is here for you. We understand that talking on the phone might not always be easy, so we've got you covered with a text messaging service. Just text 'hi' to +44 77 001 858 61, and we'll provide immediate support and guidance in a way that feels most comfortable for you." | translate }}</p>
                </div>
                <div class="col-lg-4 mobile-order-1">
                    <img src="../../../assets/images/crisis.png">
                </div>
            </div>
        </div>
    </div>
</section>