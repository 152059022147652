import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  /**
   * 
   * read: https://medium.com/fieldcircle/error-loading-chunk-xx-failed-with-angular-lazy-loaded-modules-6c5b1b6f8b8d
   */
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      console.log('loading chunk failed: ', error.message);
      console.log('page reloading...');
      window.location.reload();
    }
  }
}