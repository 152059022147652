import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";

@Component({
  selector: "app-passwordless-login-verify",
  templateUrl: "./passwordless-login-verify.component.html",
  styleUrls: ["./passwordless-login-verify.component.sass"],
})
export class PasswordlessLoginVerifyComponent implements OnInit {
  email: string;
  authenticationCode: string;
  otherAppRedirection: { redirect_uri?; state?; response_type? }; //defining the interface of the obejct.

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.otherAppRedirection =
      this.authService.getOtherAppsRedirectURI();
    this.route.params.subscribe((params: Params) => {
      this.email = params.email;
      this.authenticationCode = params.authenticationCode;
    });
    if (this.email && this.authenticationCode) {
      this.authService
        .passwordLessSignIn(this.email, this.authenticationCode)
        .subscribe(
          (res) => {
            this.checkRedirection();
          },
          (error) => {
            this.router.navigate(["/magic-link"], {
              queryParams: {
                error: error,
              },
            });
          }
        );
    } else {
      //if there is no email and authenticaiton code in the magic link.
      this.router.navigate(["/magic-link"], {
        queryParams: {
          error: "The Link is invalid.",
        },
      });
    }
  }
  checkRedirection() {
    if (this.otherAppRedirection?.redirect_uri && !(this.otherAppRedirection?.response_type || this.otherAppRedirection?.response_type === "token")) {
      //clearing local storage if otherAppRedirection found, the value is stored in the variable otherAppRedirection
      this.authService.removeOtherAppsRedirectURI();

      // if there is only otherAppRediretion.redirect_uri then first use refreshtoken value to get authentication code required for other domain redirection and then redirect
      //calling exchange api to get authentication code
      this.authService
        .seamlessLogin(this.authService.getRefreshToken())
        .subscribe(
          (response) => {
            //redirecting to redirect uri with code and if state is present then with state.
            window.location.href = `${
              this.otherAppRedirection.redirect_uri
            }?code=${response.authenticationCode}${
              this.otherAppRedirection.state
                ? `&state=${this.otherAppRedirection.state}`
                : ""
            }`;
          },
          (error) => {
            console.log(this.otherAppRedirection)
            //saving otherappredirect uri if the api fails for other request
            this.authService.saveOtherAppsRedirectURI(
              this.otherAppRedirection.redirect_uri,
              this.otherAppRedirection.state,
              this.otherAppRedirection.response_type
            );
            this.router.navigate(["/magic-link"], {
              queryParams: {
                error: error,
              },
            });
          }
        );
    }  else {
      console.log('Going in other app no redirect uri ')
      if(!this.otherAppRedirection?.redirect_uri && this.otherAppRedirection.state){
        console.log('Going in other app no redirect uri but yes state. ')
        this.authService.saveCognitoState(this.otherAppRedirection.state);
      }
      this.authService.checkUser();
    }
  }
}
