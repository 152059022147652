<!-- ecard-tag-container.component.html -->
<div class="tabs-v2" *ngIf="version === 'v2'; else defaultVersion">
  <div class="tabs">
    <a class="tab-item" (click)="selectedTagHandler({ id: null, name: 'All' })" [ngClass]="{'active': !selectedTag.id}">
      {{ 'All' | translate }}
    </a>
    <a class="tab-item" *ngFor="let tag of filteredTagList" [ngClass]="{'active': selectedTag.id === tag.id}"
      (click)="selectedTagHandler(tag)">
      {{tag.name}}
    </a>
  </div>
</div>

<ng-template #defaultVersion>
  <div class="ecard-tags pl-0" style="position: relative">
    <span class="badge" *ngFor="let tag of filteredTagList | slice: start:end" [ngClass]="{'active': selectedTag.id === tag.id}"
      (click)="selectedTagHandler(tag)">
      {{tag.name}}
    </span>
    <span class="badge px-4 more-tags" [ngClass]="{'active': loadMore}" *ngIf="displayLoadMore" (click)="onLoadMore()">
      <i-feather name="more-horizontal" class="more-icon"></i-feather>
    </span>
  </div>
</ng-template>