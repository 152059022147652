import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { MicrosoftCallbackService } from 'src/app/core/service/microsoft-callback.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';

@Component({
  selector: 'app-microsoft-callback',
  templateUrl: './microsoft-callback.component.html',
  styleUrls: ['./microsoft-callback.component.sass'],
  providers: [DestroyService]
})
export class MicrosoftCallbackComponent implements OnInit {
  code: string = null;
  state: string = null;
  loading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private destroy$: DestroyService,
    private msCallbackService: MicrosoftCallbackService,
    private router: Router,
    private toastr: ToastrCustomMessageService,
    private translate: TranslateService
  ) {
    this.route.queryParams
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      params => {
        /**
         * you can check if the error exists in the queryParams by applying the condition as:
         * if(params.hasOwnProperty('error')) OR, if(Object.keys(params).indexOf('error') !== -1)
         */
        if(params.hasOwnProperty('code')) {
          this.code = params.code;
        }

        if(params.hasOwnProperty('state')) {
          this.state = params.state;
        }
      }
    );
  }

  ngOnInit(): void {
    if(this.code && this.state) {
      this.state = this.state.replace(/&session_/g, '');
      // the state will have batchId_ecardImageUrl
      let splittedState = this.state.split('_');
      let ecardBatchId = splittedState?.length === 2 ? splittedState[0] : null;
      let ecardImage = splittedState?.length === 2 ? atob(splittedState[1]) : '';
      
      this.msCallbackService.verfiyCode(this.code, ecardBatchId)
      .subscribe(response => {
        this.loading = false;
        this.router.navigate(["/hr/points-allocation/success"], {
          state: { selectedEcardUrl: ecardImage },
        });
      }, error => {
        this.loading = false;
        this.toastr.error(this.translate.instant(extractTsText('Unable to verify user: ')) + JSON.stringify(error));
      });
    } else {
      this.loading = false;
      this.toastr.error(this.translate.instant(extractTsText('Invalid code or state.')));
    }
  }

}
