import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {  EyecareModel } from '../models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpecsaverEyecareService {
  private eyecareSubject: BehaviorSubject<EyecareModel> = new BehaviorSubject<EyecareModel>(null);;
  public eyecareFlag: Observable<EyecareModel> = this.eyecareSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  submitEmployerEyecareForm(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/eyeCare/employer`, payload);
  }

  submitEmployeeEyecareForm(payload: any) {
    return this.http.post<any>(`${ environment.apiUrl }/eyeCare/employee`, payload);
  }


  getEmployeeEyecareStatus() {
    return this.http.get<any>(`${ environment.apiUrl }/eyeCare/employee/status`)
    .pipe(
      map((response: any) => {
        this.eyecareSubject.next(response);
        return response;
      })
    );
  }
}
