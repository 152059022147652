// example: https://codepen.io/matt-west/pen/DpmMgE
let cred: any = null

export const setCred = (data: any) => {
  cred = data
}

export const getCred = () => {
  return cred
}



