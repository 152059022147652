<div ngbDropdown #notificationDropdown="ngbDropdown" (openChange)="onClickNotificationBellIcon($event)" [autoClose]="false">
    <a ngbDropdownToggle id="dropdownBellIcon" class="nav-link bell-icon nav-link-lg message-toggle remove-caret"
>
        <i-feather name="bell" class="mailAnim1 collapse-btn"></i-feather>
        <span class="badge badge-transparent notif-badge cursor p-0" *ngIf="totalUnviewedNotification">{{totalUnviewedNotification}}</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBellIcon" id="notification-dropdown-menu" class="dropdown-menu dropdown-list notification sap-notification dropdown-menu-right pullDown">
        <div class="sap-notification-wrap">
            <div class="notif-close-icon">
                <img ngbDropdownToggle src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/cross-icon-black.png" alt="">
            </div>
            <div class="sap-notif-admin-only">
                <!-- for desktop only -->
                <div class="deleted-popup" *ngIf="!isMobileView && (notificationDeleted || notificationTurnedOff)">
                    <div class="notif-actions-inner">
                        {{ notificationDeleted ? ('Notification deleted' | translate) : ('You will no longer receive notifications of this type' | translate) }}
                        <div class="flyout-buttons">
                            <a href="javascript:void(0)"
                            (click)="notificationDeleted ? undoDeleteNotification($event) : turnOnNotification($event)">{{ 'Undo' | translate }}</a>
                            <a href="javascript:void(0)" (click)="closeAlertNotification($event)">{{ 'Close' | translate }}</a>
                        </div>
                    </div>
                </div>
                <!-- for desktop only -->
                <ul ngbNav #nav="ngbNav"
                (navChange)="onChangeNavTab($event)"
                [(activeId)]="activeTab"
                class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink *ngIf="currentUser?.role !== 'user'">{{ 'All' | translate }}</a>
                        <ng-template ngbNavContent>
                            <div class="sap-notification-list">
                                <div class="card border-apply mb-3 text-center" *ngIf="loadingNotification && notifications.all?.length === 0">
                                    <div class="card-header table-card pt-4">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="card border-apply details mb-3" *ngIf="!loadingNotification &&
                                notifications.all?.length === 0">
                                    <div class="font-12 text-muted my-3 text-center">
                                    {{ 'No notifications.' | translate }}
                                    </div>
                                </div>
                                <ul>
                                    <li *ngFor="let eachNotification of notifications.all; let allIndex = index; trackBy: trackByFunction">
                                        <!-- start: action dropdown for desktop view -->
                                        <div ngbDropdown #allDrop="ngbDropdown" *ngIf="!isMobileView">
                                            <div ngbDropdownToggle class="notif-cta-icon" id="dropdownActionAll{{allIndex}}">
                                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                            </div>
                                            <div ngbDropdownMenu aria-labelledby="dropdownActionAll{{allIndex}}" class="notif-actions">
                                                <div #notificationActions class="notif-actions-inner">
                                                    <button ngbDropdownItem (click)="deleteNotification($event, eachNotification?.id)">
                                                        <span class="dropdown-icon">
                                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/trash.svg"
                                                                alt="">
                                                        </span>
                                                        {{ 'Delete Notification' | translate }}
                                                    </button>
                                                    <button ngbDropdownItem (click)="isNotificationMuted(eachNotification?.notificationType) ? turnOnNotification($event, eachNotification?.notificationType) : turnOffNotification($event, eachNotification?.notificationType)">
                                                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/bell-off.svg" alt="">
                                                        {{ isNotificationMuted(eachNotification?.notificationType) ? ('Turn on specific notifications' | translate) : ('Turn off specific notifications' | translate) }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end: action dropdown for desktop view -->

                                        <!-- start: action cta for mobile view -->
                                        <div *ngIf="isMobileView" class="notif-cta-icon" (click)="openMobileActionModal(eachNotification)">
                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                        </div>
                                        <!-- end: action cta for mobile view -->

                                        <a href="javascript:void(0);" [ngClass]="{'unread-notif': !eachNotification.read}" (click)="onClickNotification(eachNotification)">
                                            <div class="notif-icons">
                                                <img [src]="eachNotification?.reactionIcon || socialIcons[eachNotification?.notificationType]" alt="">
                                            </div>
                                            <div class="notif-detail">
                                                <h3>{{ eachNotification?.mainTitle }}</h3>
                                                <p>{{ eachNotification?.description | translate }}</p>
                                                <span class="notif-time">{{ userService.dateAgo(eachNotification.createdAt) }}</span>
                                            </div>
                                        </a>
                                    </li>                                
                                </ul>
                                <a *ngIf="!loadingNotification && totalAllNotification > notifications.all.length" class=" mb-3 mt-1 text-center d-block font-14 font-weight-bold" href="javascript:void(0)" (click)="viewMore('ALL')">{{ 'View More' | translate }}</a>
                                <a *ngIf="!loadingNotification && totalAllNotification > notificationParams?.pageSize && totalAllNotification === notifications.all.length" class=" mb-3 mt-1 text-center d-block font-14 font-weight-bold" href="javascript:void(0)" (click)="viewLess('ALL')">{{ 'View Less' | translate }}</a>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink *ngIf="currentUser?.role !== 'user'">{{ 'Social' | translate }}</a>
                        <ng-template ngbNavContent>
                            <div class="sap-notification-list">
                                <div class="card border-apply mb-3 text-center" *ngIf="loadingNotification && notifications.social.length === 0">
                                    <div class="card-header table-card pt-4">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="card border-apply details mb-3" *ngIf="!loadingNotification &&
                                notifications.social.length === 0">
                                    <div class="font-12 text-muted my-3 text-center">
                                    {{ 'No social notifications.' | translate }}
                                    </div>
                                </div>
                                <ul>
                                    <li *ngFor="let eachSocialNotification of notifications.social; let socialIndex = index; trackBy: trackByFunction">
                                        <div ngbDropdown #myDropSocial="ngbDropdown" *ngIf="!isMobileView">
                                            <div class="notif-cta-icon" id="dropdownSocial{{socialIndex}}" ngbDropdownToggle>
                                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                            </div>
                                            <div ngbDropdownMenu aria-labelledby="dropdownSocial{{socialIndex}}" class="notif-actions">
                                                <div class="notif-actions-inner">
                                                    <button ngbDropdownItem (click)="deleteNotification($event, eachSocialNotification?.id)">
                                                        <div class="dropdown-icon">
                                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/trash.svg" alt="">
                                                        </div>
                                                        {{ 'Delete Notification' | translate }}
                                                    </button>
                                                    <button ngbDropdownItem (click)="isNotificationMuted(eachSocialNotification?.notificationType) ? turnOnNotification($event, eachSocialNotification?.notificationType) : turnOffNotification($event, eachSocialNotification?.notificationType)">
                                                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/bell-off.svg" alt="">
                                                        {{ isNotificationMuted(eachSocialNotification?.notificationType) ? ('Turn on specific notifications' | translate) : ('Turn off specific notifications' | translate) }} {{ 'specific notifications' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- start: action cta for mobile view -->
                                        <div *ngIf="isMobileView" class="notif-cta-icon" (click)="openMobileActionModal(eachSocialNotification)">
                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                        </div>
                                        <!-- end: action cta for mobile view -->
                                        <a href="javascript:void(0);" [ngClass]="{'unread-notif': !eachSocialNotification.read}" (click)="onClickNotification(eachSocialNotification)">
                                            <div class="notif-icons">
                                                <img [src]="eachSocialNotification?.reactionIcon || socialIcons[eachSocialNotification?.notificationType]" alt="">
                                            </div>
                                            <div class="notif-detail">
                                                <h3>{{ eachSocialNotification?.mainTitle }}</h3>
                                                <p>{{ eachSocialNotification?.description | translate }}</p>
                                                <span class="notif-time">{{ userService.dateAgo(eachSocialNotification.createdAt) }}</span>
                                            </div>
                                        </a>
                                    </li>                                
                                </ul>
                                <a *ngIf="!loadingNotification && totalSocialNotification > notifications.social.length" class="text-center d-block font-14 font-weight-bold mb-3 mt-1" href="javascript:void(0)" (click)="viewMore('SOCIAL')">{{ 'View More' | translate }}</a>
                                <a *ngIf="!loadingNotification && totalSocialNotification > notificationParams?.pageSize && totalSocialNotification === notifications.social.length" class="text-center d-block font-14 font-weight-bold mb-3 mt-1" href="javascript:void(0)" (click)="viewLess('SOCIAL')">{{ 'View Less' | translate }}</a>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink *ngIf="currentUser?.role !== 'user'">{{ 'Files' | translate }}</a>
                        <ng-template ngbNavContent>
                            <div class="sap-notification-list">
                                <div class="card border-apply mb-3 text-center" *ngIf="loadingNotification && notifications.file?.length === 0">
                                    <div class="card-header table-card pt-4">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="card border-apply details mb-3" *ngIf="!loadingNotification &&
                                notifications.file?.length === 0">
                                    <div class="font-12 text-muted my-3 text-center">
                                    {{ 'No file notifications.' | translate }}
                                    </div>
                                </div>
                                <ul>
                                    <li *ngFor="let eachFileNotification of notifications.file; let fileIndex = index; trackBy: trackByFunction">
                                        <div ngbDropdown #dropFile="ngbDropdown" *ngIf="!isMobileView">
                                            <div ngbDropdownToggle class="notif-cta-icon" id="dropdownFile{{fileIndex}}">
                                                <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                            </div>
                                            <div ngbDropdownMenu aria-labelledby="dropdownFile{{fileIndex}}" class="notif-actions">
                                                <div class="notif-actions-inner">
                                                    <button ngbDropdownItem (click)="deleteNotification($event, eachFileNotification?.id)">
                                                        <span class="dropdown-icon">
                                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/trash.svg" alt="">
                                                        </span>
                                                        {{ 'Delete Notification' | translate }}
                                                    </button>
                                                    <button ngbDropdownItem (click)="isNotificationMuted(eachFileNotification?.notificationType) ? turnOnNotification($event, eachFileNotification?.notificationType) : turnOffNotification($event, eachFileNotification?.notificationType)">
                                                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/bell-off.svg" alt="">
                                                        {{ isNotificationMuted(eachFileNotification?.notificationType) ? ('Turn on specific notifications' | translate) : ('Turn off specific notifications' | translate) }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- start: action cta for mobile view -->
                                        <div *ngIf="isMobileView" class="notif-cta-icon" (click)="openMobileActionModal(eachFileNotification)">
                                            <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/ellipsis-icon.svg" alt="">
                                        </div>
                                        <!-- end: action cta for mobile view -->
                                        <a href="javascript:void(0);" [ngClass]="{'unread-notif': !eachFileNotification.read}" (click)="onClickNotification(eachFileNotification)">
                                            <div class="notif-icons">
                                                <img [src]="socialIcons[eachFileNotification?.notificationType]" alt="">
                                            </div>
                                            <div class="notif-detail">
                                                <h3>{{ eachFileNotification?.mainTitle }}</h3>
                                                <p>{{ eachFileNotification?.description | translate }}</p>
                                                <span class="notif-time">{{ userService.dateAgo(eachFileNotification.createdAt) }}</span>
                                            </div>
                                        </a>
                                    </li>                                
                                </ul>
                                <a *ngIf="!loadingNotification && totalFileNotification > notifications.file.length" class="text-center d-block font-14 font-weight-bold mb-3 mt-1" href="javascript:void(0)" (click)="viewMore('FILE')">{{ 'View More' | translate }}</a>
                                <a *ngIf="!loadingNotification && totalFileNotification > notificationParams?.pageSize && totalFileNotification === notifications.file.length" class="text-center d-block font-14 font-weight-bold mb-3 mt-1" href="javascript:void(0)" (click)="viewLess('FILE')">{{ 'View Less' | translate }}</a>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>
</div>

<!-- notification actions modal for mobile view: start -->
<ng-template #mobileNotifActionsModal let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <div class="deleted-popup">
            <div class="notif-actions-inner">
                <!-- <div class="notif-holder"></div> -->
                <button ngbDropdownItem (click)="deleteNotification($event, selectedNotification?.id)">
                    <span class="dropdown-icon">
                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/trash.svg"
                            alt="">
                    </span>
                    {{ 'Delete Notification' | translate }}
                </button>
                <button ngbDropdownItem (click)="isNotificationMuted(selectedNotification?.notificationType) ? turnOnNotification($event, selectedNotification?.notificationType) : turnOffNotification($event, selectedNotification?.notificationType)">
                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/notification-icons/bell-off.svg" alt="">
                    {{ isNotificationMuted(selectedNotification?.notificationType) ? ('Turn on specific notifications' | translate) : ('Turn off specific notifications' | translate) }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!-- notification actions modal for mobile view: end -->

<!-- notification deleted status modal for mobile view: start -->
<ng-template #deletedPopupModal let-c="close" let-d="dismiss">
    <div class="modal-body p-0">
        <div class="deleted-popup" *ngIf="notificationDeleted || notificationTurnedOff">
            <div class="notif-actions-inner">
                <!-- <div class="notif-holder"
                (click)="closeAlertNotification($event)"
                ></div> -->
                {{ notificationDeleted ? ('Notification deleted' | translate) : ('You will no longer receive notifications of this type' | translate) }}
                <div class="flyout-buttons">
                    <a href="javascript:void(0)"
                    (click)="notificationDeleted ? undoDeleteNotification($event) : turnOnNotification($event)">{{ 'Undo' | translate }}</a>
                    <a href="javascript:void(0)" (click)="closeAlertNotification($event)">{{ 'Close' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- notification deleted status modal for mobile view: end -->
