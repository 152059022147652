import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators}  from 'src/app/core/_helpers/validators/customized.validator';
import { AuthService } from 'src/app/core/service/auth.service';
import { PasswordService } from 'src/app/core/service/password.service';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrCustomMessageService} from "../../core/service/toastr-custom-message.service";
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  email: string = '';
  disableBtn: boolean = false;
  errorMessage: string = '';
  currentYear: number = null;

  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private toastr: ToastrCustomMessageService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this.resetPasswordForm = this.createResetPasswordForm();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(da => {
      if (da['email']){
        this.email = da['email'].trim()
      }
    })

    this.getCurrentYear();
  }

  getCurrentYear() {
    this.currentYear = (new Date()).getFullYear();
  }

  createResetPasswordForm(): FormGroup {
    return this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
        confirmationCode: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.mustMatch('password', 'confirmPassword')
      }
    );
  }

  changeKeyType(togBool){
    switch (togBool) {
      case 'pass' : this.showPassword = !this.showPassword;break;
      case 'cPass' : this.showConfirmPassword = !this.showConfirmPassword;break;
    }
  }



  resetPassword() {
    this.errorMessage = null;
    this.disableBtn = true;
    let params =  {
      "password": this.resetPasswordForm.get('password').value,
      "confirmationCode": this.resetPasswordForm.get('confirmationCode').value.trim(),
      'username': this.email
      };
    // do signup or something
    this.passwordService.resetPassword(params).subscribe((res)=> {
      let queryParams = this.authService.getOtherAppsRedirectURI();
      this.router.navigate(['/login'], { queryParams: queryParams });
      this.disableBtn = false;
      this.toastr.success('Password changed successfully.');
     },err => {
      console.log(err);
      this.disableBtn = false;
      this.errorMessage = err?.errors?.[0].message || err;
      this.errorMessage = this.errorMessage.split('(')[0];
    })
  }

  errorCatcher(type: string, control: string): boolean{
    return this.resetPasswordForm.controls[control].hasError('required') || this.resetPasswordForm.controls[control].hasError(type)
  }
}

