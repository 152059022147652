import { Component, OnInit } from '@angular/core';
import { extractTsText, linkTargetViaGoNative } from '../core/_helpers/global-functions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LanguageService } from '../core/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '../core/service/destroy.service';

@Component({
  selector: 'app-earned-wage-access-employee',
  templateUrl: './earned-wage-access-employee.component.html',
  styleUrls: ['./earned-wage-access-employee.component.sass'],
  providers: [DestroyService]
})
export class EarnedWageAccessEmployeeComponent implements OnInit {
  linkTarget: string = linkTargetViaGoNative();
  whyEWADesc2: SafeHtml;
  percentText: SafeHtml;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private destroy$: DestroyService
  ) { }

  ngOnInit(): void {
    // to make this.translate.instant or this.translate.get to work on initial load, watch for language update observable
    this.languageService.languageUpdated
    .pipe(takeUntil(this.destroy$))
    .subscribe((updated) => {
      // since style="" attribute was not taken by innerHTML, had to sanitize HTML
      this.whyEWADesc2 = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(extractTsText('Earned Wage Access is a safe, secure way of getting access to the wages that you\'ve already earned. There are no hidden charges. No credit checks. No interest. You pay just <span style="color:#EA7287;">£1.75</span> per withdrawal. <strong>*Subject to fair usage limits.*</strong>')));
      this.percentText = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(extractTsText('<span style="color:#EA7287;">64%</span> of People Admit to Running Out of Cash Before Payday.')));
    });
  }

}
