<div class="modal-header pb-lg-1">
  <h4 class="page-title text-center">{{ selectedRowToView?.nominationName ? ('Nomination Details' | translate) : ('Ecard Details' | translate) }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body px-3 pt-lg-4 pt-md-3 pt-2 mb-4 ecard-details">
  <div class="row">
    <div class="col-lg-6 col-12 px-lg-4 ecard-details__image">
      <div class="text-center">
        <div class="image-container">
          <img *ngIf="selectedRowToView.eCardPreviewLink"
               [src]="selectedRowToView.eCardPreviewLink"
               [alt]="'preview-img'"
               class="preview-img"/>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-12 px-lg-4 pt-3 pt-lg-1 ecard-details__details">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <p class="font-weight-bold mb-1"> {{ 'From' | translate }}:</p>
          <p
            class="mb-0 font-weight-normal">{{selectedRowToView.sentFrom.name || selectedRowToView.sentFrom.firstName || selectedRowToView.sentFrom.email}}</p>
        </div>
        <div class="col-lg-12 col-md-6 col-12">
          <p class="font-weight-bold mb-1 mt-lg-3 mt-md-1 mt-3"> {{ 'When' | translate }}:</p>
          <p class="mb-0 font-weight-normal">{{userService.dateAgo(selectedRowToView.createdAt)}}</p>
        </div>
        <div class=" col-lg-12 col-md-6 col-12" *ngIf="selectedRowToView?.reason">
          <p class="font-weight-bold mb-1 mt-3">{{ 'Reason' | translate }}:</p>
          <p class="mb-0 font-weight-normal">{{selectedRowToView.reason}}</p>
        </div>
        <div class=" col-lg-12 col-md-6 col-12" *ngIf="selectedRowToView?.nominationName">
          <p class="font-weight-bold mb-1 mt-3">{{ 'Nominatinon' | translate }}:</p>
          <p class="mb-0 font-weight-normal">{{selectedRowToView.nominationName}}</p>
        </div>
        <div class=" col-lg-12 col-md-6 col-12" *ngIf="userFlags?.nominationWithoutReason && selectedRowToView?.description">
          <p class="font-weight-bold mb-1 mt-3"> {{ 'Description' | translate }}:</p>
          <p class="mb-0 font-weight-normal whitespace-pre-line">{{selectedRowToView.description}}</p>
        </div>
        <div class=" col-lg-12 col-md-6 col-12" *ngIf="userFlags?.nominationWithoutReason && selectedRowToView?.criteria">
          <p class="font-weight-bold mb-1 mt-3"> {{ 'Criteria' | translate }}:</p>
          <p class="mb-0 font-weight-normal whitespace-pre-line">{{selectedRowToView.criteria}}</p>
        </div>
        <div class=" col-lg-12 col-md-6 col-12"
             *ngIf="selectedRowToView.points > 0 && selectedRowToView.products.length === 0">
          <p class="font-weight-bold mb-1 mt-3"> {{ 'Reward' | translate }}:</p>
          <div>
            <div class="epoints-count-binder table-points">
                <span
                  class="epoints-final-total">{{currentCurrency}}{{ selectedRowToView.points | epointsToCurrency | number: '.2-2' }}</span>
              <span class="dot-epoints" *ngIf="!userFlags?.voucherOnly"><span
                class="ep-dots">.</span> <img class="epoints-logo-rebranded"
                                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/elogo-small.png"
                                              width="13px">
              <span class="epoints-points-in-nums font-weight-bold">{{ selectedRowToView.points > 0 ?
                (selectedRowToView.points | number) : '0,000' }}</span></span>
            </div>
          </div>
        </div>
      </div>


      <!-- if gift card was sent as reward -->
      <div class="row" *ngIf="selectedRowToView.products.length > 0">
        <div class=" col-lg-12 col-md-6 col-12">
          <p class="font-weight-bold mb-1 mt-3"> {{ 'Reward' | translate }}:</p>
          <div *ngFor="let item of selectedRowToView.products">
            <!-- displaying just the string value shows &pound; as it is.
            keeping it inside innerHTML solves the issue, but sometimes it can show XXS html errors
            for secrity reasons so the sanitizeHtml pipe uses DomSanitizer to bypass Angular's security and render the HTML content safely. -->
            <p class="mb-0 font-weight-normal">{{item.quantity}} x <span [innerHTML]="item.productTitle | sanitizeHtml"></span></p>
          </div>
        </div>
      </div>
      <!-- Message preview section -->
      <div class="row my-3">
        <div class="col-12">
          <p class="font-weight-bold mb-1"> {{selectedRowToView?.nominationName ? ('Nomination Message' | translate) : ('Message' | translate)}}:</p>
          <p class="mb-0 wrap-message" [ngClass]="{ 'text-muted font-weight-bold font-smooth': selectedRowToView.isMessageDeleted, 'font-weight-normal': !selectedRowToView.isMessageDeleted }">{{selectedRowToView.message}}</p>
        </div>
      </div>

            <!--        if the user is an admin or a manager, and the current card does not belong to them -->
            <div class="row my-3" *ngIf="selectedRowToView.products.length > 0 &&
                                           selectedRowToView.awardType == 'product' && currentUser.role !== 'user'  &&
                                            selectedRowToView['receiver']['id'] !== currentUser.id">
              <div class="col-12">
                <p class="font-weight-bold mb-1" [ngClass]="selectedRowToView.redeemed ? 'text-dark': 'text-danger'">
                  {{selectedRowToView.redeemed ? this.selectedRowToView.sentTo.email +( ' has redeemed their reward.' | translate) : ('Unredeemed.' | translate)}}
                </p>
              </div>
            </div>
            <!-- if already redeemed or refunded, the ecard detail's status will be fulfilled or pointexchanged
           here status fulfilled is redeemed and status pointexchanged is refunded
           so, display the below note only when the receiver is a current user -->
            <div class="row my-3"
                 *ngIf="currentUser && selectedRowToView['receiver']['id'] === currentUser.id && selectedRowToView.redeemed">
              <div class="col-12">
                <p class="font-weight-bold mb-1">{{ 'Your ecard reward has already been redeemed.' | translate }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div
                class="d-flex flex-md-row flex-column w-100 align-items-center justify-content-center justify-content-lg-start">
                <div>
                  <button class="btn btn-secondary btn-length-md mr-md-3 mr-0" type="reset" (click)="closeModal()">{{ 'Close' | translate }}
                  </button>
                </div>
                <div class="order-first order-md-last">
                  <button *ngIf="showRedeemButton" class="btn btn-primary btn-length-md mb-md-0 mb-2" type="reset"
                          (click)="onRedeem()">{{ 'Redeem reward(s)' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>


<!-- confirmEmail Modal -->
<ng-template #confirmEmailModal let-c="close" let-d="dismiss">
  <div class="modal-header pb-lg-1">
    <h4 class="page-title text-center">{{ 'Ecard Details' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="redeemEmailSubmitting" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-3 pt-lg-4 pt-md-3 pt-2 mb-4">
    <div class="row border-top border-bottom border-light border-2 ml-3 mr-3 ml-md-1 mr-md-1 pt-1 pb-1 "
         *ngFor="let item of selectedRowToView.products">
      <div class="col-4 col-md-3 text-center">
        <img width="81px" height="51px" [src]="item.imageUrl" alt="">
      </div>
      <div class="col-8 col-md-9">
        <div>
          <p class="font-weight-bold mb-1" [innerHTML]="item.productTitle | sanitizeHtml"></p>
        </div>
        <p class="mb-0 font-weight-normal">{{ 'Qty' | translate }}: {{ item.quantity }}</p>
      </div>

    </div>
    <h6 class="text-center font-weight-normal mt-2 mb-2">{{ 'Your gift card will be sent to this email address' | translate }} <span
      class="font-weight-bold">{{ selectedRowToView.receiver.email }}</span>.</h6>
    <div class="d-flex justify-content-center mt-3">
      <div class="d-flex flex-md-row flex-column">
        <div>
          <button class="btn btn-secondary btn-length-lg mr-2" type="reset" [disabled]="redeemEmailSubmitting"
                  (click)="c()">Cancel
          </button>
        </div>
        <div class="order-first order-md-last">
          <!-- WeGift API disabled as not working as expected and hit normal API. Here, false is sent in the function
          to tell that sendAddress is false. It means, in the email view, all the products are gift cards, so no need to send the
          addresses -->
          <button class="btn btn-length-lg mb-2"
                  [class.btn-secondary]="redeemEmailSubmitting"
                  [class.btn-primary]="!redeemEmailSubmitting"
                  [disabled]="redeemEmailSubmitting" type="button" (click)="onRedeemFormSubmitUsingAddress(false)">{{ 'Confirm' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- address Modal -->
<ng-template #addressModal let-c="close" let-d="dismiss">
  <div class="modal-header pb-lg-1">
    <h4 class="page-title text-center">{{ 'Ecard Details' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="redeemAddressSubmitting" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-3 pt-lg-4 pt-md-3 pt-2">
    <div class="row border-top border-bottom border-light border-2 ml-3 mr-3 ml-md-1 mr-md-1 pt-1 pb-1 "
         *ngFor="let item of selectedRowToView.products">
      <div class="col-4 col-md-3 text-center">
        <img width="81px" height="51px" [src]="item.imageUrl" alt="">
      </div>
      <div class="col-8 col-md-9">
        <div>
          <p class="font-weight-bold mb-1" [innerHTML]="item.productTitle | sanitizeHtml"></p>
        </div>
        <p class="mb-0 font-weight-normal">{{ 'Qty' | translate }}: {{ item.quantity }}</p>
      </div>

    </div>
    <h6 class="text-center font-weight-normal m-2">{{ "Your product(s) will be sent the following address. If you\'re redeeming gift cards, these will be sent to your email address." | translate }}</h6>
    <div class="ml-2 me-2">
      <form [formGroup]="addressForm" (ngSubmit)="onRedeemFormSubmitUsingAddress()">
        <div class="form-group">
          <label for="address1" class="font-weight-bold">{{ 'Delivery Address' | translate }}<span class="text-danger">*</span></label>
          <input type="text" class="form-control mb-2" id="address1" formControlName="address1"
                 [placeholder]="'Address Line 1 (House)' | translate"
                 [class.is-invalid]="addressForm.get('address1').invalid && (addressForm.get('address1').dirty || addressForm.get('address1').touched)">
          <span
            *ngIf="addressForm.get('address1').invalid && (addressForm.get('address1').dirty || addressForm.get('address1').touched)"
            class="text-danger">
                      <span *ngIf="addressForm.get('address1').hasError('required')">{{ 'Address line 1 is required.' | translate }}</span>
                  </span>

          <input type="text" class="form-control mb-2" id="address2" formControlName="address2"
                 [placeholder]="'Address Line 2 (Street)' | translate"
                 [class.is-invalid]="addressForm.get('address2').invalid && (addressForm.get('address2').dirty || addressForm.get('address2').touched)">

          <input type="text" class="form-control mb-2" id="city" formControlName="city" [placeholder]="'Town / City' | translate"
                 [class.is-invalid]="addressForm.get('city').invalid && (addressForm.get('city').dirty || addressForm.get('city').touched)">
          <span
            *ngIf="addressForm.get('city').invalid && (addressForm.get('city').dirty || addressForm.get('city').touched)"
            class="text-danger">
                      <span *ngIf="addressForm.get('city').hasError('required')">{{ 'City is required.' | translate }}</span>
                  </span>

          <input type="text" class="form-control mb-2" id="county" formControlName="county" [placeholder]="'County' | translate"
                 [class.is-invalid]="addressForm.get('county').invalid && (addressForm.get('county').dirty || addressForm.get('county').touched)">

          <div class="row">
            <div class="col-md-6 col-12">
              <input type="text" class="form-control mb-2" id="postCode" formControlName="postCode"
                     [placeholder]="'Postcode' | translate"
                     [class.is-invalid]="addressForm.get('postCode').invalid && (addressForm.get('postCode').dirty || addressForm.get('postCode').touched)">
              <span
                *ngIf="addressForm.get('postCode').invalid && (addressForm.get('postCode').dirty || addressForm.get('postCode').touched)"
                class="text-danger">
                            <span
                              *ngIf="addressForm.get('postCode').hasError('required')">{{ 'Postal code is required.' | translate }}</span>
                        </span>
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-center">
          <div class="d-flex flex-lg-row flex-column">
            <div>
              <button class="btn btn-secondary btn-length-md mr-2" type="reset" [disabled]="redeemAddressSubmitting"
                      (click)="c()">{{ 'Cancel' | translate }}
              </button>
            </div>
            <div class="order-first order-lg-last">
              <button class="btn btn-length-md mb-2"
                      [class.btn-secondary]="!addressForm.valid"
                      [class.btn-primary]="addressForm.valid"
                      [disabled]="!addressForm.valid || redeemAddressSubmitting" type="submit">{{ 'Confirm' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- cashout Modal -->
<ng-template #cashoutModal let-c="close" let-d="dismiss">
  <div class="modal-header pb-lg-1">
    <h4 class="page-title text-center">{{ 'Ecard Details' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="exchangeEpointsSubmitting" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-3 pt-lg-4 pt-md-3 pt-2">
    <h6 class="text-center font-weight-normal m-3">{{ "One or more products you\'ve been rewarded are now out of stock." | translate }}</h6>
    <h6 class="text-center font-weight-normal m-3">{{ "Instead, you can cash out and spend your points in the shop. If you\'re after the same item, you can always buy a relevant gift card." | translate }}</h6>
    <h6 class="text-center font-weight-bold m-3">{{ 'Amount To Receive' | translate }}</h6>
    <div class="ml-2 me-2">
      <div class="d-flex flex-row align-items-end mb-3" *ngFor="let item of selectedRowToView.products">
        <div class="col-8 px-2">
          <p class="font-weight-normal clamp-two mb-0" [innerHTML]="item.productTitle | sanitizeHtml"></p>
        </div>
        <div class="col-4 px-2">
          <div class="d-flex flex-md-row flex-column justify-content-between">
            <div class="text-right">{{ 'Qty' | translate }}: {{ item.quantity }}</div>
            <div
              class="font-weight-bold text-right">{{ currentCurrency }}{{ item.refundValue | epointsToCurrency | number: '.2-2' }}</div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-between total-row">
        <div class="font-weight-bold">{{ 'Total' | translate }}</div>
        <div class="font-weight-bold">{{ currentCurrency }}{{ selectedRowToView.pointsValue | epointsToCurrency | number: '.2-2' }}</div>
      </div>
      <h6
        class="text-center font-weight-bold m-3">{{ currentCurrency }}{{ selectedRowToView.pointsValue | epointsToCurrency | number: '.2-2' }}
        <span *ngIf="!userFlags?.voucherOnly" class="ep-dots">.</span>
        <img *ngIf="!userFlags?.voucherOnly" class="epoints-logo-rebranded mr-1" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/elogo-small.png"
             width="19px"> <span *ngIf="!userFlags?.voucherOnly"
          class="epoints-points-in-nums font-weight-bold">{{ selectedRowToView.pointsValue }}</span>
        {{ 'will be added to your balance.' | translate }}
      </h6>
      <div class="d-flex justify-content-center mt-3 mb-3">
        <div class="d-flex flex-md-row flex-column">
          <div>
            <button class="btn btn-secondary btn-length-lg mr-2" type="reset" [disabled]="exchangeEpointsSubmitting"
                    (click)="c()">{{ 'Cancel' | translate }}
            </button>
          </div>
          <div class="order-first order-md-last">
            <button class="btn btn-length-lg btn-primary mb-2"
                    type="button" [disabled]="exchangeEpointsSubmitting" (click)="exchangeEpoints()">{{ 'Confirm' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
