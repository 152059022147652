import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { extractTsText } from 'src/app/core/_helpers/global-functions';

@Component({
  selector: 'app-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.sass']
})
export class EmojiComponent implements OnInit {

  //for emoji mart, use local assets instead fetching emoji png from cdn. Used this as api response was quite slow.
  public backgroundImage: Emoji['backgroundImageFn'] = (set: string, sheetSize: number) => 'assets/images/32.png';


  constructor(public elementRef: ElementRef) { }

  ngOnInit(): void {
  }
  @Input() showButton?: boolean = true;
  @Input() showReactionSvg?: boolean = false;
  @Input() buttonLabel?: string= extractTsText("Insert Emoji 🙂");
  @Input() hideEmojiWindow: boolean = false;

  showEmojiWindow: boolean = false;
  @Output() selectedEmoji = new EventEmitter<any>();

  emojiSelected(event: any) {

    this.selectedEmoji.emit(event.emoji.native)

  }

  ngOnChanges() {
    if(this.hideEmojiWindow) {
      this.showEmojiWindow = false
    }
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {


    if (!this.elementRef.nativeElement.contains(event.target)) {

      this.showEmojiWindow= false;
    }
  }
  toggleEmoji() {
    this.showEmojiWindow = !this.showEmojiWindow;
  }

/*while searching for emoji in the emoji search bar;
 the enter key submitted the form which was unwanted behaviour.
 This method prevents submission of form by using 'enter key' while searching for emoji in the emoji component search bar */
  restrictSubmit(event){
    let keyCode = ('which' in event) ? event.which : event.keyCode;
    if ([13].includes(keyCode)) {
      event.preventDefault();
    }
  }

}
