<div class="form-group mb-3">
    <label for="dateRange">{{ 'Date Range' | translate }}</label>
    <div class="input-group">
        <input #dateRange class="form-control" [placeholder]="'Enter a date range (DD/MM/YYYY - DD/MM/YYYY)' | translate" name="dateRange" [value]="dateRangeItems.range" (input)="validateInput(dateRangeItems.fromDate, dateRangeItems.toDate, dateRange.value)" [ngClass]="{ 'is-invalid' : dateRangeItems.errors }">
        <div class="input-group-append">
            <button ngbDropdown class="btn btn-outline-secondary calendar" [ngClass]="{ 'is-invalid' : dateRangeItems.errors }" (click)="datepicker.toggle()" type="button"><i-feather name="calendar" class="action-icon" style="cursor: pointer;"></i-feather>
      </button>
        </div>
    </div>
    <small class="form-text text-danger" *ngIf="dateRangeItems.errors">{{ dateRangeItems.errors | translate }}</small>
</div>

<div class="form-group hidden mb-0">
    <div class="input-group">
        <input type="hidden" name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event, datepicker)" [displayMonths]="1" [dayTemplate]="datePickerContent" outsideDays="visible" [startDate]="dateRangeItems.fromDate!"
            tabindex="-1" [minDate]="minDate" [maxDate]="maxDate">
        <ng-template #datePickerContent let-date let-focused="focused" let-currentMonth="currentMonth" let-disabled="disabled">
            <span class="custom-day" [class.range-start-date]="date.equals(dateRangeItems.fromDate)" [class.range-end-date]="date.equals(dateRangeItems.toDate)" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                [class.text-muted]="currentMonth !== date.month || disabled" (mouseenter)="dateRangeItems.hoveredDate = date" (mouseleave)="dateRangeItems.hoveredDate = null">
                {{ date.day }}
            </span>
        </ng-template>
    </div>
</div>