<section class="main-content manage-people ss-flow">
    <div class="section-body">
        <div class="row">
            <div class="col-lg-7">
                <div class="activesuccess-wrap text-center">

                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/salary-sacrifice/activation-success.png"
                        alt="">
                        <h2 class="py-3">{{ "You've Been Successful!" | translate }}</h2>

                        <div class="px-5">
                            <p class="font-16">{{ 'Your voucher will be sent via the email provided within 2 working days.' | translate }}</p>
                        </div>

                    <button type="button"
                        class="btn btn-secondary d-lg-inline-block d-md-inline-block btn-nominate mr-md-2 mt-3"
                        routerLink="/hr/dashboard">
                           {{ 'Go back to dashboard' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>