import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let currentUser = this.authService.getLoginSession();

      /**
       * for each person app - if the user does not have token (can access login page)
       * 
       * for redirection to login from other apps, login can be accessed if the user is not already logged in to eachperson
       * also, when the user is already logged in and has redirect_uri in the URL
       */
      if (!(currentUser.token && this.authService.currentUserValue) || (currentUser.token && this.authService.currentUserValue && route.queryParams?.redirect_uri)) {
        return true;
      } 
      else {
        this.router.navigate(['/hr/dashboard']).then()        
        return false;              
      }
  }
  
}
