import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from "src/app/core/service/auth.service";
import { Subject } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  openedModals: any = [];

  constructor(
    private http: HttpClient,
    public authService: AuthService,
  ) {}

  /** Fetch About you section data */
  fetchAboutYouDetail(params) {
    return this.http.get<any>(`${environment.apiUrl}/stats/dashboard`, {
      params,
    });
  }

  /** Fetch Recognition in the last 30 days for
   * Whole company, team, individual, total noiminations and total recognition
   */
  fetchRecognitionData(params) {
    return this.http.get<any>(`${environment.apiUrl}/stats/dashboard`, {
      params,
    });
  }

  /** company wide stats numbers on dashboard */
  fetchCompanyWideStats() {
    // let dateF = params.dateFrom.replace(/\+/gi, '%2B');
    // let dateT = params.dateTo.replace(/\+/gi, '%2B');
    return this.http.get<any>(
      `${environment.apiUrl}/stats/dashboard?detailsType=company_wide_stats`,
      {}
    );
  }

  // start: public private - new feature popup modal API
  getNewFeaturePopupData(email: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/ecards/popup/${email}/`,
      null
    );
  }

  postInteractionNewFeature(email: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/ecards/popup/${email}/?status=true`,
      null
    );
  }
  // end: public private - new feature popup modal API

  // get EAP client detail
  getClientEAPDtail() {
    return this.http.get<any>(`${environment.apiUrl}/eap-credentials`);
  }

  deleteEcard(ecardId: number) {
    return this.http.delete<any>(
      `${environment.apiUrl}/ecards/delete-ecard/${ecardId}?partnerId=${this.authService.currentUserValue?.partnerId}`
    );
  }

  dismissDashboardModals() {
    this.openedModals.forEach((eachModal: NgbModalRef, index: number) => {
      if (eachModal) {
        eachModal.dismiss('close modal');
        // Remove the modal reference from the array
        this.openedModals = this.openedModals.filter((_, i) => i !== index);
      }
    });    
  }

  registerOpenedModals(modal: NgbModalRef) {
    this.openedModals.push(modal);
  }
}
