import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: "app-ecard-tag-container",
  templateUrl: "./ecard-tag-container.component.html",
  styleUrls: ["./ecard-tag-container.component.scss"],
})
export class EcardTagContainerComponent implements OnInit, OnChanges {
  @Input("tagList") tagList: any[] = [];
  @Input("listOfEcards") listOfEcards: any[] = [];
  @Input() version: string;
  @Output("filteredEcardData") filteredEcardData = new EventEmitter<any>();

  selectedTag: any = {};

  loadMore: boolean = false;
  displayLoadMore: boolean = false;

  start = 0;
  limit = 10;
  end = 10;
  filteredTagList: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.displayLoadMore = this.filteredTagList.length > this.limit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tagList || changes.listOfEcards) {
      this.filterTagsWithItems();
      this.displayLoadMore = this.filteredTagList.length > this.limit;
    }
  }

  filterTagsWithItems(): void {
    this.filteredTagList = this.tagList.filter((tag) => {
      return this.listOfEcards.some((ecard) =>
        ecard.tags?.some((t) => t.name === tag.name)
      );
    });
  }

  // selectedTagHandler(e: any) {
  //   if (e.id === this.selectedTag.id) {
  //     this.selectedTag = {};
  //     this.filteredEcardData.emit({ list: this.listOfEcards, filtered: false });
  //   } else {
  //     this.selectedTag = e;
  //     this.filteredEcardData.emit({
  //       list: this.createFilteredArray(),
  //       filtered: true,
  //     });
  //   }
  // }
  selectedTagHandler(tag: any): void {
    if (tag.name === "All") {
      this.selectedTag = {};
      this.filteredEcardData.emit({ list: this.listOfEcards, filtered: false });
    } else {
      this.selectedTag = tag;
      this.filteredEcardData.emit({
        list: this.createFilteredArray(tag),
        filtered: true,
      });
    }
  }

  // create a new array after filtering tag
  createFilteredArray(tag: any): Array<any> {
    if (tag.name === "All") {
      return this.listOfEcards;
    }

    let _filteredArrTemp: any[] = [];
    for (let ecard of this.listOfEcards) {
      if (ecard.tags) {
        if (this.filterArrViaTag(ecard.tags)) {
          _filteredArrTemp.push(ecard);
        }
      }
    }
    _filteredArrTemp = [
      ..._filteredArrTemp
        .reduce((acc, red) => acc.set(red.id, red), new Map())
        .values(),
    ];
    return _filteredArrTemp;
  }

  // filter provided array of ecards from the selectedTag object name
  filterArrViaTag(tagsArray: any[]) {
    for (let tag of tagsArray) {
      if (
        tag.name?.toLowerCase().trim() ==
        this.selectedTag.name?.toLowerCase().trim()
      ) {
        return true;
      }
    }
    return false;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.displayLoadMore = this.tagList.length > this.limit;
  // }

  onLoadMore() {
    this.loadMore = !this.loadMore;
    this.end = this.loadMore ? this.filteredTagList.length : this.limit;
  }

  // ngAfterViewInit(): void {
  //   console.log(document.getElementById('tag-container').clientHeight,document.getElementById('tag-container').scrollHeight)
  //   this.displayAll = document.getElementById('tag-container').scrollHeight <= 84;
  // }
  //
  // showHide(e?: any){
  //   this.viewMore = !this.viewMore;
  //   if (this.viewMore){
  //     document.getElementById('tag-container').style.maxHeight = 'none';
  //   } else {
  //     document.getElementById('tag-container').style.maxHeight = '5rem';
  //   }
  //   console.log(document.getElementById('tag-container').clientHeight,document.getElementById('tag-container').scrollHeight)
  // }
}

