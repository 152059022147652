<section class="main-content activity landing-page">
    <div class="section-body mt-4">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card mb-3 card-spacious">
            <div class="card-body py-0">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div class="page-titles">
                    <h1>{{ 'Protect Your Eyesight the Easy Way With' | translate }}</h1>
                    <img
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-eachperson.png"
                      height="50px"
                      width="auto"
                    />
                    <img
                      class="banner-image mt-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-1.png"
                      alt="Specsavers"
                    />
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 col-lg-6 d-none d-sm-block text-right pt-sm-3"
                >
                  <img
                    class="banner-image"
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-1.png"
                    alt="Specsavers"
                    style="max-width: 415px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 card-spacious">
            <div class="card-body pb-3 text-center text-sm-left pt-5">
              <div class="row mb-4 mb-sm-0">
                <div
                  class="col-12 col-md-6 col-lg-6 align-items-center d-none d-sm-block"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-2.png"
                    alt="Specsavers"
                    style="max-width: 340px"
                  />
                </div>
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <h3 class="mb-0 mb-sm-1">{{ "Why It\'s Important?" | translate }}</h3>
                    <img
                      class="banner-image mt-0 mt-sm-3 mb-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-2.png"
                      alt="Specsavers"
                    />
                    <p class="font-16">
                      {{ 'Research shows that 70% of employees may face issues like blurred vision, headaches, or migraines due to extended computer use at work. Surprisingly, a survey of UK workers found that one in five individuals hasn\'t had an eye examination in five years.' | translate }}
  
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mb-4 mb-sm-0">
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <img
                      class="banner-image mt-0 mt-sm-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-3.png"
                      alt="Specsavers"
                    />
                    <h4 class="mb-4 mb-sm-1 mt-0 mt-sm-5">
                      {{ 'Eyecare vouchers provide a seamless solution for quick and convenient access to the eye care you need.' | translate }}
                    </h4>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 col-lg-6 d-none d-sm-block text-center"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-3.png"
                    alt="Specsavers"
                    style="max-width: 390px"
                  />
                </div>
              </div>
              <div class="row mb-4 mb-sm-0">
                <div
                  class="col-12 col-md-6 col-lg-6 align-items-center d-none d-sm-block"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-4.png"
                    alt="Specsavers"
                    style="max-width: 340px"
                  />
                </div>
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <h3 class="mb-0 mb-sm-1">{{ "What\'s Included?" | translate }}</h3>
                    <img
                      class="banner-image mt-0 mt-sm-3 mb-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-4.png"
                      alt="Specsavers"
                    />
                    <ul>
                      <li class="mb-2 font-16">
                        {{ 'Get one full standard routine adult eye test.' | translate }}
                      </li>
                      <li class="mb-2 font-16">
                        {{ 'One pair of glasses from the £49 range or £49 towards other glasses, when required and specifically for Visual Display Unit (VDU) use only.' | translate }}
                      </li>
                    </ul>
                    <button
                      class="btn btn-secondary px-5 mt-2 font-16"
                      routerLink="/eyecare-employee-form"
                    >
                      {{ 'Apply Now' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 card-spacious">
            <div class="card-body pb-3 text-center text-sm-left pt-5">
              <div class="row mb-4 mb-sm-5">
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <h3 class="mb-0 mb-sm-1 mt-0 mt-sm-5">
                      {{ "Where Can the eVoucher Be Used?" | translate }}
                    </h3>
                    <img
                      class="banner-image mt-0 mt-sm-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-5.png"
                      alt="Specsavers"
                    />
                    <p class="font-16">
                      {{ 'EVouchers can be redeemed at any Specsavers store in the UK and the Channel Islands.' | translate }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 col-lg-6 d-none d-sm-block text-center"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-5.png"
                    alt="Specsavers"
                    style="max-width: 340px"
                  />
                </div>
              </div>
              <div class="row mb-4 mb-sm-5">
                <div
                  class="col-12 col-md-6 col-lg-6 align-items-center d-none d-sm-block"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-6.png"
                    alt="Specsavers"
                    style="max-width: 390px"
                  />
                </div>
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <h3 class="mb-0 mb-sm-1">{{ 'Who Is Eligible?' | translate }}</h3>
                    <img
                      class="banner-image mt-0 mt-sm-3 mb-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-6.png"
                      alt="Specsavers"
                    />
                    <p class="font-16">
                      {{ 'If you spend extended periods in front of monitors, computers, and other VDUs, eyestrain can be a concern. Employers are legally required, to provide and fund eye examinations for employees using VDUs.' | translate }}
                    </p>
                    <p class="font-16">
                      {{ 'If glasses are specifically needed for VDU use, employers are also obligated to cover the costs of basic frames and lenses.' | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mb-4 mb-sm-5">
                <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div>
                    <h3 class="mb-0 mb-sm-1">{{ 'What Happens After I Apply?' | translate }}</h3>
                    <img
                      class="banner-image mt-0 mt-sm-3 d-sm-none"
                      src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-7.png"
                      alt="Specsavers"
                    />
                    <p class="font-16">
                      {{ 'You\'ll receive your eVoucher from your employer via email' | translate }}
                    </p>
                    <p class="font-16">
                      {{ "Book an appointment at Specsavers online or by phone Present eVoucher before the eye test" | translate }}
                    </p>
                    <button
                      class="btn btn-secondary px-5 mt-2 font-16"
                      routerLink="/eyecare-employee-form"
                    >
                      {{ 'Apply now' | translate }}
                    </button>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 col-lg-6 d-none d-sm-block text-center"
                >
                  <img
                    class=""
                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/specsavers/specsavers-7.png"
                    alt="Specsavers"
                    style="max-width: 390px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 card-spacious">
            <div class="bg-white gp-customise-faq eap-customise mb-4">
              <div class="gpOnDemand-faq">
                <div class="text-dark panel-for-tc">
                  <h3 class="text-center">{{ "FAQ\'s" | translate }}</h3>
                  <ngb-accordion #faqAcc="ngbAccordion">
                    <ngb-panel id="faq-1">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button
                          ngbPanelToggle
                          class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"
                        >
                          <p class="font-weight-bold m-0">
                            {{ "Where can the Voucher be used?" | translate }}
                          </p>
                          <div>
                            <img
                              class="plus-icon"
                              alt="plus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg"
                            />
                            <img
                              class="minus-icon"
                              alt="minus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg"
                            />
                          </div>
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        {{ 'EVouchers can be redeemed at any Specsavers store in the UK and the Channel Islands.' | translate }}
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-2">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button
                          ngbPanelToggle
                          class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"
                        >
                          <p class="font-weight-bold m-0">{{ 'Who is eligible?' | translate }}</p>
                          <div>
                            <img
                              class="plus-icon"
                              alt="plus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg"
                            />
                            <img
                              class="minus-icon"
                              alt="minus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg"
                            />
                          </div>
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        {{ 'If you spend extended periods in front of monitors, computers, and other VDU\'s, you will be entitled to a free eye test paid by your employer. However, since your employer pays for the voucher, the number available for redemption may be limited. Therefore, we recommend checking with your employer before requesting a voucher.' | translate }}
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-3">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button
                          ngbPanelToggle
                          class="btn w-100 p-0 btn-w-full d-flex align-items-center justify-content-between customised-panel-border"
                        >
                          <p class="font-weight-bold m-0">
                            {{ 'What Happens After I Apply?' | translate }}
                          </p>
                          <div>
                            <img
                              class="plus-icon"
                              alt="plus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg"
                            />
                            <img
                              class="minus-icon"
                              alt="minus-icon"
                              src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg"
                            />
                          </div>
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        {{ 'Following your application, the Each Person support team will distribute your voucher via the email provided. This will be within 72 business hours.' | translate }}
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>