import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient
  ) { }

  getSfsStatus() {
    return this.http.get<any>(`${ environment.accessTokenAPI }/save-from-salary/status`);
  }

  getOrderDetails() {
    return this.http.get<any>(`${ environment.accessTokenAPI }/users/rewards/V2?size=100&duration=30`)
  }

  getEmployeeInfo(){
    return this.http.get<any>(`${ environment.accessTokenAPI }/employee/save-from-salary`);
  }

  getWithdrawData(){
    return this.http.get<any>(`${ environment.accessTokenAPI }/employee/save-from-salary/withdraw`);
  }

  getStatement(duration){
    return this.http.get<any>(`${ environment.accessTokenAPI }/employee/save-from-salary/transactions?duration=${duration}`);
  }
  
  handlePostEmployeeData(data) {
    return this.http.post<any>(`${ environment.accessTokenAPI }/employee/save-from-salary`, data);
  }

  handlePostWithDrawData(data) {
    return this.http.post<any>(`${ environment.accessTokenAPI }/employee/save-from-salary/withdraw`, data);
  }

  updateSavingAmount(data) {
    return this.http.put<any>(`${ environment.accessTokenAPI }/employee/save-from-salary`, data);
  }

  pauseAndResumeContribution(data) {
    return this.http.put<any>(`${ environment.accessTokenAPI }/employee/save-from-salary`, data);
  }


}
