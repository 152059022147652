<section class="main-content activity landing-page">
    <div class="section-body mt-4">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="card mb-3 card-spacious">
                    <div class="card-body py-0">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                                <div class="page-titles">
                                    <h3>{{ 'Say Hello to' | translate }}</h3>
                                    <h1>{{ 'Earned Wage Access' | translate }}</h1>
                                    <img class="banner-image mt-3 d-sm-none"
                                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-1.png"
                                        alt="Earned Wage" />
                                    <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/flexearn-logo.png"
                                        height="42px" width="auto">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-none d-sm-flex text-right pt-sm-3 pb-sm-3">
                                <img class="banner-image"
                                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-1.png"
                                    alt="Earned Wage" style="max-width: 415px;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3 card-spacious">
                    <div class="card-body pb-3 text-center text-sm-left pt-5">
                        <h3 class="text-center mt-0 mt-sm-2">{{ "You\'ve Earned It. Why Wait?" | translate }}</h3>
                        <p class="font-16 text-center mb-4 mb-sm-5">{{ "Earned Wage Access gives you immediate access to the money you\'ve earned." | translate }}</p>
                        <div class="row mb-5 pt-4 pt-sm-0">
                            <div class="col-12 col-md-6 col-lg-6 align-items-center d-none d-sm-flex align-items-center justify-content-start">
                                <img class="pr-md-4"
                                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-7.png"
                                    [attr.alt]="'Salary Sacrifice' | translate" style="max-width: 360px;" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                                <div>
                                    <h3>{{ 'Why Earned Wage Access?' | translate }}</h3>
                                    <img class="banner-image mt-0 mt-sm-3 d-sm-none"
                                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-7.png"
                                        [attr.alt]="'Salary Sacrifice' | translate" />
                                    <p class="font-16">
                                        {{ "We get it. Money is already tight, and you don\'t get paid for ages. But it doesn\'t have to be that way!" | translate }}
                                    </p>
                                    <p class="font-16" [innerHTML]="whyEWADesc2">

                                    <p class="font-16">
                                        {{ "Once you\'re signed up, you\'ll also get access to a full range of Financial Wellbeing resources." | translate }}
                                    </p>
                                    <a class="btn btn-secondary px-5 mt-2 font-16" href="https://flexearnprod.page.link/?link=https%3A%2F%2Fportal.flexearn.com&amv=16&apn=com.flexearn.flexearn&ibi=com.flexearn.flexearn&isi=1489632128" [target]="linkTarget">{{ 'Get Started' | translate }}</a>
                                </div>
                            </div>
                        </div>
                        <h3 class="mb-5 pb-3 pt-3 pt-sm-3 text-center" [innerHTML]="percentText"></h3>
                        <div class="row mb-0 mb-sm-5 ">
                            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                                <div>
                                    <h3 class="mb-0 mb-sm-1">{{ 'Take Control of Your Finances' | translate }}</h3>
                                    <img class="banner-image mt-0 mt-sm-3 d-sm-none"
                                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-6.png"
                                        [attr.alt]="'Salary Sacrifice' | translate" />
                                    <p class="font-16">
                                        <!-- dont forget to use backslash when you are using single quotation too inside the string. Otherwise, they will not be extracted for translation -->
                                        {{ "Remember pay-as-you-go phones? You\'d top up your phone every week or month with a prepaid token, and it would tide you over for the next couple of weeks. Earned Wage Access is pretty similar!" | translate }}
                                    </p>
                                    <p class="font-16">
                                        {{ "With Earned Wage Access, you get immediate access to your wages, as soon as they\'re earned. Your employer works with a third-party payroll advance scheme by signing up through their HR department. They don\'t need to set up new accounts or change the way they pay you: our system simply sits on top of theirs." | translate }}
                                    </p>
                                    <p class="font-16">
                                        {{ 'So you can get your wages early, without any impact on your workplace!' | translate }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-none d-sm-flex align-items-center justify-content-center text-center">
                                <img class=""
                                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-6.png"
                                    [attr.alt]="'Salary Sacrifice' | translate" style="max-width: 340px;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3 card-spacious">
                    <div class="card-body pb-3 text-center text-sm-left pt-3">
                        <div class="row mb-2 mb-sm-5 mb-4">
                            <div class="col-12 col-md-6 col-lg-6 d-none d-sm-flex align-items-center justify-content-start">
                                <img class="pr-md-4"
                                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-4-reverse.png"
                                    alt="Earned Wage" style="max-width: 340px;" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center pt-2 pt-sm-5">
                                <div>
                                    <h3>{{ 'Draw Down From Your Earned Salary' | translate }}</h3>
                                    <img class="banner-image mt-0 d-sm-none"
                                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-4-reverse.png"
                                        alt="Earned Wage" />
                                        <p class="font-16 text-left"><strong>{{ 'Get Access to Your Wages In Just Three Simple Steps:' | translate }}</strong></p>
                                    <ul>
                                        <li class="mb-2 font-16">
                                            {{ 'Click through to get started.' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ "Check your balance: You\'ll see how much pay you've accrued based on the hours or days you\'ve worked this month." | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ "Get your cash: Choose how much you\'d like to withdraw and hit SEND. The money will be transferred to your bank account immediately." | translate }}
                                        </li>
                                    </ul>
                                    <a class="btn btn-secondary px-5 mt-2 font-16"
                                        href="https://flexearnprod.page.link/?link=https%3A%2F%2Fportal.flexearn.com&amv=16&apn=com.flexearn.flexearn&ibi=com.flexearn.flexearn&isi=1489632128" [target]="linkTarget">{{ 'Get Started' | translate }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5 pt-4 pt-sm-5">
                            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center pr-0 pr-sm-5">
                                <div>
                                    <h3>{{ 'Use Earned Wage Access to:' | translate }}</h3>
                                    <img class="banner-image mt-3 d-sm-none"
                                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-9-reverse.png"
                                        [attr.alt]="'Salary Sacrifice' | translate" />
                                    <ul>
                                        <li class="mb-2 font-16">
                                            {{ 'Help you budget' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Cover unexpected costs' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Save faster and earn more interest over time' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Cut your credit card spending' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Avoid payday loans' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Feel happier at work (no more embarrassing salary advance requests!)' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Feel more in control of your finances' | translate }}
                                        </li>
                                        <li class="mb-2 font-16">
                                            {{ 'Improve your financial wellbeing' | translate }}
                                        </li>
                                    </ul>
                                    <a class="btn btn-secondary px-5 mt-2 font-16" href="https://flexearnprod.page.link/?link=https%3A%2F%2Fportal.flexearn.com&amv=16&apn=com.flexearn.flexearn&ibi=com.flexearn.flexearn&isi=1489632128" [target]="linkTarget">{{ 'Get Started' | translate }}</a>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-none d-sm-flex align-items-center justify-content-center">
                                <img class="pr-md-4"
                                    src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/earned-wage-access/earned-wage-9-reverse.png"
                                    [attr.alt]="'Salary Sacrifice' | translate" style="max-width: 340px;"/>
                            </div>
                        </div>
                        <div class="travel-loan-footer text-center pb-2 pb-sm-4 pt-3 pt-sm-0">
                            <p class="font-16">{{ 'Still unsure?' | translate }} <br />
                                <strong>{{ 'Set up a call with our team today:' | translate }}</strong>
                            </p>
                            <p class="font-16">{{ 'Contact us:' | translate }}<br />
                                <strong>0118 230 5531</strong><br />
                                <strong><a href="mailto:support@eachperson.com">support@eachperson.com</a></strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>