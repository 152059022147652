import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specsevers-employee',
  templateUrl: './specsevers-employee.component.html',
  styleUrls: ['./specsevers-employee.component.sass']
})
export class SpecseversEmployeeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}