import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/core/service/auth.service";

export interface ApiClient {
  partnerName: string;
  inactiveUsers: number;
  partnerId: any;
}

export interface EmailHistory {
  sentDate: string;
  partnerName: string;
  totalUsers: number;
  partnerId: any;
}

@Injectable({
  providedIn: "root",
})
export class BenefitsReminderService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private getApiUrl(): string {
    return `${environment.apiUrl}/benefits/fetchActivePartners`;
  }

  getClients(): Observable<ApiClient[]> {
    const apiUrl = this.getApiUrl();
    return this.http.get<ApiClient[]>(apiUrl);
  }

  sendBenefitReminder(payload: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/benefits/sendReminderEmail`,
      payload
    );
  }

  // getEmailHistory(): Observable<EmailHistory[]> {
  //   return of(this.emailHistory);
  // }

  private getAPiHistory(): string {
    return `${environment.apiUrl}/benefits/benefitsReminderHistory`;
  }

  getEmailHistory(queryParams?: any): Observable<EmailHistory[]> {
    const apiHistory = this.getAPiHistory();
    let params = new HttpParams();
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        params = params.append(key, queryParams[key]);
      });
    }

    return this.http.get<EmailHistory[]>(apiHistory, { params });
  }
}
