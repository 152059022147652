<section class="main-content manage-people ss-flow">
  <div class="text-center text-md-left w-100 mt-4">
    <p class="mb-3 font-weight-normal text-left"><a routerLink="/eyecare-employee-form"
            class="text-dark"><strong>{{ 'Back' | translate }}</strong></a> <span class="d-lg-inline-block ml-2"><strong>{{ 'Eyecare' | translate }}</strong>  /
            {{ 'Order eVouchers' | translate }}</span></p>
</div>
  <div class="payment-sub-header-binder setup-completed d-flex">
    <h4 class="page-title mt-md-1"> {{ "Specsavers Eyecare" | translate }}</h4>
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body">
            <p class="font-16 btn-sm-screen-text font-weight-bold pt-2">
               {{ "Your Summary" | translate }}
            </p>
            <p class="btn-sm-screen-text">
              {{ "Review your choices and make any edits before submitting your request." | translate }}
            </p>
            <div class="card card-privacy">
              <div class="card-body">
                <p class="font-16 font-weight-bold">1. {{ "Personal Details" | translate }}</p>
                <div class="pl-3">
                  <p > <span class="font-weight-bold">{{ "First Name" | translate }}:
                  </span> {{ firstName }}  </p>

                  <p > <span class="font-weight-bold">{{ "Surname" | translate }}:</span>

                 {{ surname }}</p>
                  <p > <span class="font-weight-bold">{{ "Email Address" | translate }}:</span>
               {{ email }}</p>
                </div>
              </div>
            </div>
            <div class="card card-privacy">
              <div class="card-body">
                <p class="font-16 font-weight-bold"> 2. {{ "Visual Display Unit" | translate }}</p>

                <p class=" pl-3"><span class="font-weight-bold">
                  {{ "Work with a VDU" | translate }}:
                </span>
                {{ workWithVDU ? 'Yes' : 'No' }}</p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary d-none d-lg-inline-block d-md-inline-block btn-nominate mr-md-2"
              routerLink="/eyecare-employee-form/"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-nominate d-lg-none d-md-none d-inline-block"
              routerLink="/eyecare-employee-form/"
            >
              {{ "Back" | translate }}
            </button>
            <button class="mobile-order-1 btn btn-continue btn-nominate" type="button" (click)="submitForm()">
              {{ "Submit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
