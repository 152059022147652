import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/core/models/user";
import { DestroyService } from "src/app/core/service/destroy.service";
import { AuthService } from "src/app/core/service/auth.service";

@Component({
  selector: "app-hotjar-feedback",
  templateUrl: "./hotjar-feedback.component.html",
})
export class HotjarFeedbackComponent implements OnInit, OnDestroy {
  currentUser: User;
  showHotjarScript = true; // Flag to control showing Hotjar script
  private scriptElement: HTMLScriptElement | null = null; // Reference to the script element

  constructor(
    private authService: AuthService,
    private readonly destroy$: DestroyService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    let partnerId = window.location.hostname === 'admin.eachperson.com' ? 
    100252 :
    (window.location.hostname === 'stag-admin.eachperson.com' ? 
      100461 : 
      21447702501
    );
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata) => {
        this.currentUser = userdata;
        if (this.currentUser && this.currentUser?.partnerId === partnerId) {
          this.showHotjarScript = false;
        }
        
        if (this.showHotjarScript) {
          this.loadHotjarScript();
        } else {
          this.removeHotjarScript();
        }
      });
  }

  ngOnDestroy(): void {
    this.removeHotjarScript(); // Clean up the script on component destroy
  }

  loadHotjarScript(): void {
    let hotjarId = window.location.hostname === 'admin.eachperson.com' ? 2690570 : 5052977;
    if (!document.querySelector('script[data-hotjar-script="true"]')) {
      this.scriptElement = this.renderer.createElement("script");
      this.scriptElement.type = "text/javascript";
      this.scriptElement.setAttribute("data-hotjar-script", "true");
      this.scriptElement.text = `
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: ${hotjarId}, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `;
      this.renderer.appendChild(document.head, this.scriptElement);
    }
  }

  removeHotjarScript(): void {
    const existingScript = document.querySelector('script[data-hotjar-script="true"]');
    if (existingScript) {
      this.renderer.removeChild(document.head, existingScript);
      this.scriptElement = null;
    }
    
    // for production
    const elements = document.querySelectorAll('div[class^="_hj_feedback_container"]');
    elements.forEach(element => {
        element.remove();
    });

    // for others
    const otherElems = document.querySelectorAll('div[class^="_hj-widget-container"]');
    otherElems.forEach(element => {
        element.remove();
    });
  }
}
