import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { getWPShopDomain } from 'src/app/core/_helpers/environment-variables';
import { encryptToken, extractTsText, linkTargetViaGoNative, setEPCookie } from 'src/app/core/_helpers/global-functions';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { GdprService } from 'src/app/core/service/gdpr.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.sass'],
  providers: [DestroyService]
})
export class GdprComponent implements OnInit {
  currentUser: User;
  gdprAccepted: boolean = true;
  gdprForm: FormGroup;
  submitLoading: boolean = false;
  load: boolean = false;
  tncText: SafeHtml;
  privacyText: SafeHtml;

  constructor(
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private gdprService: GdprService,
    private toastr: ToastrCustomMessageService,
    private readonly destroy$: DestroyService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private el: ElementRef
  ) {}

  initForm() {
    this.gdprForm = this.formBuilder.group({
      tncAccepted: [false, Validators.required],
      marketingPrefEmail: false,
      marketingPrefSMS: false
    });
  }

  ngOnInit(): void {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      userdata => {
        this.currentUser = userdata;
        this.gdprAccepted = (this.currentUser && this.currentUser.businessUser && !this.currentUser.gdprAccepted) ? false : true;
        this.initialLoad();

        this.tncText = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(extractTsText(`* I confirm that I have read and agree to the <a href="javascript:void(0)" data-link="terms-conditions">Terms & Conditions</a>`)));
        this.privacyText = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(extractTsText(`Please read our <a href="javascript:void(0)" data-link="privacy-policy">Privacy Policy</a>`)));
        this.enableClick();
      }
    );
  }

  enableClick() {
    // Use setTimeout to ensure the DOM is fully rendered and updated
    setTimeout(() => {
      const anchor = this.el.nativeElement.querySelector('a[data-link="terms-conditions"]');
      if (anchor) {
        this.renderer.listen(anchor, 'click', (event) => {
          this.goToExternalLink(event, 'terms-conditions');
        });
      }

      const privacyAnchor = this.el.nativeElement.querySelector('a[data-link="privacy-policy"]');
      if (privacyAnchor) {
        this.renderer.listen(privacyAnchor, 'click', (event) => {
          this.goToExternalLink(event, 'privacy-policy');
        });
      }
    }, 0);
  }

  initialLoad(): void {
    // add class gdpr-not-accepted to body tag
    if(!this.gdprAccepted && location.pathname === '/join/gdpr') {
      this.renderer.addClass(this.document.body, 'gdpr-not-accepted');
    } else {
      this.renderer.removeClass(this.document.body, 'gdpr-not-accepted');
    }

    if(this.gdprAccepted) {
      this.authService.doRedirection(this.currentUser);
    } else {
      this.initForm();
    }
  }

  onSubmit() {
    if(this.gdprForm.invalid || this.gdprForm.get('tncAccepted').value === false) {
      return false;
    }
    this.submitLoading = true;

    const data = {
      tncAccepted: this.gdprForm.get('tncAccepted').value,
      marketingPrefEmail: this.gdprForm.get('marketingPrefEmail').value,
      marketingPrefSMS: this.gdprForm.get('marketingPrefSMS').value,
      privacyAccepted: this.gdprForm.get('tncAccepted').value === true ? true : false
    };

    /**
     * if marketintPrefEmail = true, then make userInteractedForEmailSubscription = true else false
     * 
     * Depending on these values, the GDPR sticky view for the mailing should be shown on the dashboard or any pages the user lands.
     */
    data['userInteractedForEmailSubscription'] = data?.marketingPrefEmail ? true : false;
    
    this.gdprService.saveGDPRDetails(data).subscribe(
      response => {
        /**
         * once the gdpr details saved, need to redirect the user
         * for this, user's session is old with gdprAccepted = false
         * so checkUser() should be called so that the session is updated with the new details
         * normal redirection does not work as the guard restricts the user to access dashboard
         */
        this.authService.checkUser();
        // this.submitLoading = false;
      }, error => {
        this.submitLoading = false;
        error.errors ? this.toastr.error(error.errors[0].message) : this.toastr.error(error);
      }
    )
  }

  goToExternalLink(event: any, path: string) {
    console.log('dsjds')
    event.preventDefault();
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    let hrefTarget = linkTargetViaGoNative()
    let redirectTo = `${getWPShopDomain()}/${path}/`;
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }

    if(hrefTarget === '_blank') {
      this.load = false;
    }
    let shopUrl = `${getWPShopDomain()}/?redirect_to=${encodeURIComponent(redirectTo)}`;
    window.open(shopUrl, hrefTarget);
  }
}
