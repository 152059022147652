import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { User, UserFlags } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ShareBalanceService } from 'src/app/core/service/share-balance.service';

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.sass"],
  providers: [DestroyService],
})
export class BalanceComponent implements OnInit {
  currentUser: User;
  userFlags: UserFlags;
  balance = {
    companyBalanceInEpoints: 0,
  };
  /**
   * as Observable is used to get updated currency symbol,
   * undefined or null value cannot detect change of observable
   * so assign value first
   */
  currency: string = this.languageService.getCurrency(); // get if there is value in the localStorage

  constructor(
    private authService: AuthService,
    private shareBalanceService: ShareBalanceService,
    public languageService: LanguageService,
    private readonly destroy$: DestroyService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata) => {
        this.currentUser = userdata;
      });

    this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.userFlags = flags;
      });

    /**
     * update the balance value with the company balance set in the service used by
     * the recognise and event send ecard pages
     * used for manager and superAdmin roles
     *  */
    this.shareBalanceService.companyBalance
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.balance = response;
      });

    // get the country selected from localStorage -- by checking if changed value
    this.languageService
      .watchCountryStorage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.currency = this.languageService.getCurrency();
      });
  }

  /**
   * as BalanceComponent is used in HTML of multiple components and API is called twice in each because of the device size criteria
   * in order to prevent double API calls on the same page, we are calling the limit API from HeaderComponent on every page change
   */
  ngOnInit(): void {}
}
