import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../core/service/auth.service";
import {GonativeGlobal} from "../../../assets/custom-JS/gonative-global-class";
import { DestroyService } from 'src/app/core/service/destroy.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getCred } from 'src/app/core/_helpers/gonativeGlobal';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';

@Component({
  selector: 'app-face-id',
  templateUrl: './face-id.component.html',
  styleUrls: ['./face-id.component.scss'],
  providers: [DestroyService]
})
export class FaceIdComponent implements OnInit, AfterViewInit {
  // create object for the class GonativeGlobal
  gonativeGlobalClass = new GonativeGlobal();
  biometricsObj: any  =  null;
  user: any = null
  disable:boolean = false
  @ViewChild('successDisplay') successModalElement: ElementRef;
  @ViewChild('setup') setupDeviceBiometricsModelElement: ElementRef;

  biometricsType: string = ''
  face_id: string = 'assets/images/face-id.png'
  touch_id: string = 'assets/images/finger-print.bmp'
  biometricsImg : string = ""
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private toastr: ToastrCustomMessageService
  ) {
  }

  ngOnInit(): void {
    this.biometricsObj = this.authService.getBiometricStatus()
    this.user = getCred()
  }

  ngAfterViewInit() {
    this.checkDeviceForBiometrics()
  }

  async saveBiometricsStatus(status: boolean){
    //fetch device info here
    if(navigator.userAgent.indexOf('gonative') > -1) {
      this.disable = true
      const deviceInfo = await this.gonativeGlobalClass.loadDeviceInfo()
      let _data = {
        deviceId : deviceInfo.installationId,
        deviceInformation: JSON.stringify(deviceInfo),
        uuid: this.user?.uuid,
        credentialStatus: false,
        biometricStatus: status
      }
      this.authService.saveBiometricsData(_data).subscribe(async (res) => {
        if (status){
          const secretSaved = await this.gonativeGlobalClass.saveSecret()
        }
        this.biometricsObj.enabled = status
        this.biometricsObj.setup = true
        this.biometricsObj.credentialsChanged = false
        this.biometricsObj.uuid = this.user?.uuid
        this.authService.setBiometrics(this.biometricsObj)
        if (status){
          this.modalService.open(this.successModalElement,  { centered: true, backdrop: 'static' });
        } else {
          this.login()
        }
      }, err => {
        this.toastr.error('failed to save details : ' + JSON.stringify(err))
        this.disable = false
      })
    }
  }

  async checkDeviceForBiometrics(){
    if(navigator.userAgent.indexOf('gonative') > -1) {
      const authStatus = await this.gonativeGlobalClass.checkBiometricStatus();
      this.updateTextValues(authStatus)
      if (!authStatus) {
        this.modalService.open(this.setupDeviceBiometricsModelElement,  { centered: true, backdrop: 'static' });
        return
      }
      if (!authStatus.hasTouchId) {
        this.modalService.open(this.setupDeviceBiometricsModelElement,  { centered: true, backdrop: 'static' });
      }
    }
  }


  login(){
    this.modalService.dismissAll()
    //redirect after select here
    window.location.reload();
  }

  async updateTextValues(auth: any){
    const deviceInfo = await this.gonativeGlobalClass.loadDeviceInfo();

    //if android always show touch id
    if (deviceInfo?.os === 'Android'){
      this.biometricsType = 'Touch ID';
      this.biometricsImg = this.touch_id;
      return
    }

    //if iOS check biometrics, no touch id means no face or touch id this show default
    if (!auth || !auth.hasTouchId){
      this.biometricsType = 'Face / Touch ID';
      this.biometricsImg = this.face_id;
      return
    }

    //if touch id then match finger or face
    if (deviceInfo?.os === 'iOS'){
      if (auth.biometryType === 'touchId'){
        this.biometricsType = 'Touch ID';
        this.biometricsImg = this.touch_id;
        return
      }
      if (auth.biometryType === 'faceId'){
        this.biometricsType = 'Face ID';
        this.biometricsImg = this.face_id;
        return
      }
      if (auth.biometryType === 'none'){
        this.biometricsType = 'Face / Touch ID';
        this.biometricsImg = this.face_id;
        return
      }
    }
  }
}
