import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftCallbackService {

  constructor(
    private authService: AuthService,
    private httpBackend: HttpBackend
  ) { }

  verfiyCode(code: string, state: string) {
    const token = this.authService.getLoginSession()
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);

    let params = {
      code,
      state
    };
    const requestOptions = { params };
    
    
    return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/callback`, requestOptions);
  }
}
