import {Injectable} from "@angular/core";
import {BehaviorSubject, throwError, defer} from "rxjs";

import {catchError, delay, map, retryWhen, take, tap} from "rxjs/operators";

import {
  HttpClient,
  HttpBackend,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {AuthService} from "./auth.service";
import { User } from "../models/user";

export class User_Detail {
  treesDonated: number;
  eCardSent: number;
  name: string;
  departmentName: string;
  apiRetriedFor: number; // this to know number of times failed API has been retried - helps in sidebar to display info accordingly
  apiFailed: boolean; // to set true in case of failed and false in case of success (this value will be set true as default) - helps in sidebar to display info accordingly
}

@Injectable({
  providedIn: "root",
})
export class SideNavUserDetailService {
  queryParams = {
    detailsType: "",
  };

  userData: User_Detail = {
    treesDonated: 0,
    eCardSent: 0,
    name: "",
    departmentName: "",
    apiRetriedFor: 0,
    apiFailed: true // set true by default
  };

  private _userDetail = new BehaviorSubject<User_Detail>(this.userData);
  public userDetail$ = this._userDetail.asObservable();

  constructor(
    private authService: AuthService,
    private httpBackend: HttpBackend
  ) {}
  
  // used HttpBackend which lets us skip the interceptor, as the interceptor was canceliing the request on page reload..
  fetchUserDetail() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.queryParams.detailsType = "user_details";
    let token = this.authService.getLoginSession();
    const httpWithoutInterceptor = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${token.token}`,
    });
    //adding role user in query params, if the user has role=user.
    if (currentUser && currentUser.role === "user") {
      this.queryParams["role"] = "user";
    }
    let requestOptions = {headers: headers, params: this.queryParams};

    return defer(() => {
      return httpWithoutInterceptor.get<any>(`${environment.apiUrl}/stats/dashboard`, requestOptions);
    }).pipe(
      map((response) => {
        // update behaviour subject
        response['apiRetriedFor'] = 0; // set default value on success
        response['apiFailed'] = false; // since API is success here, set apiFailed to false
        this._userDetail.next(response);
      }), catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }), retryWhen((error) => {
        return error.pipe(
          delay(500),
          tap(() => {
            requestOptions.headers = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `${this.authService.getLoginSession()?.token}`,
            });

            this.userData.apiRetriedFor++;
            this.userData.apiFailed = true
            this._userDetail.next(this.userData);
          }),
          take(10)
        );
      })
    );
  }

  /**
   * get menu used in the constructor - for level 1 menu to make opened if the router url 
   * exists in activateMenuFor array from the sidebar-items.ts, then make that menu active, with its parent menu
   * making active.
   * 
   * the menu path can be linked with many other routes so to make it enabled even if the other linked routes are 
   * visited, this logic is applied.
   */
  getActiveMenu(currentUser: User, routeInfo: any, currentUrl: string) {
    // customization by laxmi on // logic for select active menu in dropdown
    if (currentUser && currentUser.setUpCompleted) {
      const activate = routeInfo.filter((menu) => {
        if(menu.submenu.length === 0) {
          return menu.activateMenuFor.indexOf(currentUrl) > -1;
        } else {
          const activeSubmenu = menu.submenu.filter((eachsubMenu) => {
            return eachsubMenu.activateMenuFor.indexOf(currentUrl) > -1;
          });
          return activeSubmenu.length > 0 ? true : false;
        }
      });
      return activate.length > 0 ? activate[0].moduleName : null;
    } else {
      return null;
    }
  }

  getSubmenuActive(currentUser: User, submenu: any, currentUrl: string) {
    return currentUser && currentUser.setUpCompleted ? submenu.activateMenuFor.indexOf(currentUrl) > -1 : false;
  }
}
