import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GrantUsersService } from './grant-users.service';

@Injectable({
  providedIn: 'root'
})
export class ShareBalanceService {
  private balance = new BehaviorSubject({
    companyBalanceInEpoints : 0,

  });
  companyBalance = this.balance.asObservable();

  epoints: any = {
    confirmedEpoints: 0,
    loadingEpointsBalance: false
  };

  private personalBal = new BehaviorSubject(this.epoints);
  personalBalance = this.personalBal.asObservable();

  constructor(
    private grantUsersSerivce: GrantUsersService
  ) { }

  setCompanyBalance(balance: any) {
    this.balance.next(balance);
  }

  setPersonalBalance(balance: any) {
    this.personalBal.next(balance);
  }

  //fetch personal epoints balance
  fetchPersonalPot() {
    this.epoints.loadingEpointsBalance = true;
    this.grantUsersSerivce.getPersonalBalance()
      .pipe(
        finalize(() => {
          this.epoints.loadingEpointsBalance = false;
          this.setPersonalBalance(this.epoints)
          
        })
      )
      .subscribe((response) => {
        this.epoints.loadingEpointsBalance = false;
        this.epoints = response;
      }, error => {
        this.epoints = {
          confirmedEpoints: 0,
          loadingEpointsBalance: false
        }
      }
    ); 
  }
}
