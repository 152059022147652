import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  hasTokenExpired: boolean = false;

  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loggedInUser = this.authService.getLoginSession();
    let token = loggedInUser.token;
    if (token) {
      request = this.addToken(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        /**
         * Access is denied message comes if the token is expired or
         * if token is invalid due to global logout or something
         *  */

        /**
         * done on 24/11/2022
         * here, when the error status codes or messages from APIs are not uniform, the retry for those failed APIs
         * in case of token expiry and refresh token success did not happen. This caused many data in UIs like recognition reason not load.
         * 
         * What I did is if the token is expired or Access is denied message is there in the APIs failed cases, handle the error followed by
         * refresh token API call and retry those APIs.
         * 
         * but if the error messages do not fall in this case, then check for refreshTokenSubject and if the observable is updated then retry the left APIs.
         */
        if ((token && this.authService.isTokenExpired()) || error === "Access is denied") {
          this.hasTokenExpired = true
          console.log("refresh token called in case of token expiry or API has error message 'Access is denied' and retried failed APIs")
          return this.handleAccessDeniedError(request, next);
        } else {          
          return this.refreshTokenSubject.pipe(
            take(1),
            switchMap((jwt: any) => {
              if(jwt) {
                console.log("recalled failed api in case of token expiry (those did not have uniform error message as 'Access is denied')")
                return next.handle(this.addToken(request)); // next.handle(this.addToken(request)) retries the failed API with a new/updated token in the header
              } else {
                /**
                 * hasTokenExpired is checked in worst case scenario for those APIs which do not have error message "Access is denied"
                 * in case of token expiry and which cannot enter inside the jwt condition when subject value is not updated.
                 */
                console.log('has token expired: '+this.hasTokenExpired)
                if(this.hasTokenExpired) {
                  console.log('hasTokenExpired boolean is true so retry')
                  return next.handle(this.addToken(request)); 
                }
                console.log('no refresh token so just throw API error: ', error)
                return throwError(error)
              }              
            })
          );          
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>) {
    
    let loggedInUser = this.authService.getLoginSession();
    let token = loggedInUser.token;
    if(!request.url.includes('https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/dashboard-i18n')) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }

    return request;
  }

  private handleAccessDeniedError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          console.log('refresh token success')
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request));
        })
      );
    }
  }
}
