import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getWPShopDomain, globalHiddenRoutes, menuAccessControl } from 'src/app/core/_helpers/environment-variables';
import { SSChildcareModel, SSCycle2workModel, SSEmployeeHolidayModel, SSEVFlags, SSTechModel, User, UserFlags } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { SideNavUserDetailService, User_Detail } from 'src/app/core/service/sidenavUserDetails.service';
import { UserPermissionService } from 'src/app/core/service/user-permission.service';
import { ROUTES, fetchAPIDocRoutes } from '../sidebar-items';
import { LayoutService } from 'src/app/core/service/layout.service';
import { NavigationEnd, Router } from '@angular/router';
import { SalarySacrificeService } from 'src/app/core/service/salary-sacrifice.service';
import { WellbeingHubService } from 'src/app/core/service/wellbeing-hub.service';
import { encryptToken, matchingPartner, setEPCookie } from 'src/app/core/_helpers/global-functions';


@Component({
  selector: 'app-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
  styleUrls: ['./sidebar-mobile.component.sass']
})
export class SidebarMobileComponent implements OnInit {
  UserDetail$: Observable<User_Detail>;
  currentUser: User;
  appAccessible: boolean;
  disableSidebar: boolean = false;
  logoUrl: string = "";
  isMac: boolean = false;
  apiDocumentationRoutesBoolean: boolean = false;
  routerObj: any = null;
  currentUrl: string = '';
  userFlags: UserFlags;
  routeInfo = ROUTES;
  activeMenu: string = '';
  salarySacrificeEVStatus: SSEVFlags = null;
  salarySacrificeTechStatus: SSTechModel = null;
  salarySacrificeHolidayStatus: SSEmployeeHolidayModel = null;
  salarySacrificeChildcareStatus: SSChildcareModel = null;
  salarySacrificeCycle2workStatus: SSCycle2workModel = null;
  loadShop: boolean = false;
  imperialClient: boolean = false;

  isEligibleforSFSActive: boolean = false; // is eligible to view sfs
  sfsRoute: string = '/hr/save-from-salary/information';

  constructor(
    private authService: AuthService,
    private readonly destroy$: DestroyService,
    private userPermissionService: UserPermissionService,
    private sidenavDetail: SideNavUserDetailService,
    private layoutService: LayoutService,
    private router: Router,
    private salarySacrificeService: SalarySacrificeService,
    private wellbeingService: WellbeingHubService
  ) {
    // Hide Donate menu on IOS devices
    this.isMac = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    // get the current user value
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        if(userData) {
          this.currentUser = userData;
          this.appAccessible = this.userPermissionService.isAppAccessible(userData);

          // enable sidebar if the setup is completed
          if (this.currentUser && this.currentUser.setUpCompleted) {
            this.disableSidebar = false;
          } else {
            this.disableSidebar = true;
          }

          this.imperialClient = matchingPartner(this.currentUser?.partnerId);
        }
      });

      this.routerObj = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url?.split("?")[0];
          if (this.currentUrl.split('/')[1] === 'reporting-documentation') {
            this.routeInfo = fetchAPIDocRoutes();
            this.apiDocumentationRoutesBoolean = true;
          } else {
            this.apiDocumentationRoutesBoolean = false;
          }

          // make menu with or withous submenus
          this.activeMenu = this.sidenavDetail.getActiveMenu(this.currentUser, this.routeInfo, this.currentUrl);
        }
      })
  }

  ngOnInit(): void {
    // this.currentUser in sidebar seems to give the updated data everytime
    if (
      this.currentUser &&
      this.appAccessible &&
      this.currentUser.gdprAccepted
    ) {
      /** as there are two sidebar components, one for mobile and other for desktop
       * do not call APIs on both components.
       * the required APIs are called in main-layout component, updated observables and subscribed in the components.
       * this is done to avoid duplicate API calls.
       */

      //observavble to subscribe in template with async pipe
      this.UserDetail$ = this.sidenavDetail.userDetail$;

      // subscribe user flags observable
      this.authService.userFlags
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.userFlags = flags
      })

      // get electric vehicles status
      this.salarySacrificeService.ssEvFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.salarySacrificeEVStatus = status
      })

      // subscribe salary sacrifice - technology flags observable
      this.salarySacrificeService.ssTechFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeTechStatus = status
      })

      // subscribe salary sacrifice - childcare flags observable
      this.salarySacrificeService.ssChildcareFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeChildcareStatus = status
      })

        // subscribe salary sacrifice - childcare flags observable
        this.salarySacrificeService.ssHolidayFlag
        .pipe(takeUntil(this.destroy$))
        .subscribe(status => {
          this.salarySacrificeHolidayStatus = status
        })

      // subscribe salary sacrifice - cycle2work flags observable
      this.salarySacrificeService.ssCycle2WorkFlag
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.salarySacrificeCycle2workStatus = status
      })
      
      // get sfs detail
      this.layoutService.sfsDetail$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sfs) => {
        this.isEligibleforSFSActive = sfs?.isEligibleforSFSActive;
        this.sfsRoute = sfs?.sfsRoute;
      })

      // get dynamic logo
      this.layoutService.companyLogoUrl$
      .pipe(takeUntil(this.destroy$))
      .subscribe((logo) => {
        this.logoUrl = logo;
      })

      if(!this.currentUser.setUpCompleted) {
        this.disableSidebar = true;
      }
    }
  }

  async openShop() {
    this.loadShop = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    setTimeout(() => {
      this.loadShop = false;
    }, 30000);

    let shopUrl = getWPShopDomain();
    window.location.href = shopUrl;
  }

  displayMenuItem(title: string){
    //check if companies need any menu control
    let _menuAccessControl = menuAccessControl
    let companiesToCompare = Object.keys(_menuAccessControl)
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let matchCompany = companiesToCompare.find((client) => client == user?.companyShortName)
    if (!matchCompany){
      //hide globally disabled paths
      let hiddenItem = globalHiddenRoutes.find((path) => path == title)
      return !(!!hiddenItem)
    }

    //if control required find if it needs to be hidden or displayed; since only globally hidden items needs to be displayed it needs to be hidden again as done in else

    let toBeEnabled =  _menuAccessControl[user?.companyShortName].enable?.find((_title) => _title === title)
    let toBeDisabled =  _menuAccessControl[user?.companyShortName].disable?.find((_title) => _title === title)
    if (toBeEnabled){
      return true
    } else if (toBeDisabled){
      return false
    } else {
      let hiddenItem = globalHiddenRoutes.find((path) => path == title)
      return !(!!hiddenItem)
    }
  }

  // for submenus only
  activateSubMenu(title: string) {
    if(this.currentUser && this.currentUser.setUpCompleted) {
      const activate = this.routeInfo.filter((menu) => {
        const activeSubmenu = menu.submenu.filter((eachsubMenu) => {
          return eachsubMenu.activateMenuFor.indexOf(this.currentUrl) > -1 && title === eachsubMenu.title
        });
        return activeSubmenu.length > 0 ? true : false;
      });
      return activate.length > 0 ? true : false;
    } else {
      return false;
    }
  }

  onToggle(menuName: string) {
    this.activeMenu = menuName
  }

  closeSidebar() {
    this.layoutService.updateSidebarMobileView(false)
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

}
