import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Reaction, Comment, SocialAspect, ReactionPayload, CommentDataPayload } from "../models/social-aspect.model"
import { map, catchError, retryWhen, delayWhen, delay, tap, take } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { extractTsText } from '../_helpers/global-functions';

@Injectable({
  providedIn: "root",
})
export class SocialAspectService {
  private commentSource = new BehaviorSubject<Comment[]>([]);
  comments = this.commentSource.asObservable();

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  saveComment(commentData: CommentDataPayload): Observable<Comment> {
    return this.http.post<Comment>(`${environment.apiUrl}/comment`, commentData);
  }

  updateComment(commentId, commentData : CommentDataPayload ): Observable<Comment> {
    return this.http.put<Comment>(`${environment.apiUrl}/comment/${commentId}`, commentData);
  }

  getIndividualCardSocialAspectData(eCardId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/comment/get-comments-with-reactions-on-ecard/${eCardId}`)
  }


  getCommentById(commentId): Observable<Comment> {
    return this.http.get<Comment>(`${environment.apiUrl}/comment/${commentId}`);
  }

  deleteComment(commentId): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/comment/${commentId}`);
  }
  saveReaction(data: ReactionPayload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/reaction`, data);
  }

  updateReaction(reactionId: string, data: ReactionPayload): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/reaction/${reactionId}`, data);
  }

  fetchAllReaction(eCardId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/reaction/get-reactions-on-ecard/${eCardId}`);
  }

  fetchCommentReaction(commentId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/reaction/get-reactions-on-comment/${commentId}`);
  }

  fetchReactionById(reactionId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/reaction/${reactionId}`);
  }

  deleteReaction(reactionId: string): Observable<any> {
    if(!reactionId) {
      return throwError(
        this.translate.instant(extractTsText('Something went wrong. Please try again!'))
      )
    }
    return this.http.delete<any>(`${environment.apiUrl}/reaction/${reactionId}`);
  }

  getSocialAspectDetail(ecardId: number, countryCode: string = 'GB') {
    let region = countryCode === 'GB' ? 'UK' : countryCode;
    let params = {
      region      
    };
    return this.http.get<any>(`${environment.apiUrl}/ecards/award/modal/socialAspectView/${ecardId}?social=true`, {params});
  }

}
