let hostname = window.location.hostname;
let redirectURI = `${location.origin}/login-verify`;
let alternativeRedirectURI = `${location.origin}/sso?companyShortName=${JSON.parse(localStorage.getItem("viaSso"))?.companyShortName}`;

let eachpersonProdDomain = "admin.eachperson.com";
let eachpersonQaDomain = "admin.qa.eachperson.com";
let eachpersonStageDomain = "stag-admin.eachperson.com";

// for QA
let epointsDomain = "http://epoints-ui";
// check if prod environment
if (hostname === eachpersonProdDomain) {
  epointsDomain = "https://www.epoints.com";
} else if(hostname === eachpersonStageDomain) {
  epointsDomain = "https://stag.epoints.com";
}
let authAPI = epointsDomain + "/rest";
export {
  epointsDomain,
  authAPI,
  redirectURI,
  alternativeRedirectURI,
};

// WP shop domain
export function getWPShopDomain() {
  let wpDomain = '';
  switch(hostname) {
    case eachpersonProdDomain: wpDomain = 'https://shop.eachperson.com'; break;
    case eachpersonStageDomain: wpDomain = 'https://staging-shop.eachperson.com'; break;
    case eachpersonQaDomain: wpDomain = 'https://qa-shop.eachperson.com'; break;
    default: wpDomain = 'https://qa-shop.eachperson.com'; break;
  }
  return wpDomain;
}

//for stripe keys
export const getStripeKeys = (): any => {
  let apiKeys = {
    /**
     * prod keys contain the live stripe keys. Do not misuse these keys
     */
    prod: {
      pk: 'pk_live_51L0QLYDKxzbseRHbg2OgIbOorXfgoZHnXgGMaLMVqL90pxuCp8x7yKv9vhptpdUjptrcjZJAudWTt6gViFGEFosv00HQYc3ZFV',
      sk: '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'
    },
    qa: {
      pk: 'pk_test_51M7GEhBldenAehyDICyfncyhLlymGkKFcilAABPlrG1nNkVpyhCFC7bGVug9SMCpKVNTkFoZJRKXmwuWb3HpTUOm00hA4SQrk5',
      sk: 'sk_test_51M7GEhBldenAehyDc5W8tikNEG69mRmFzV4D8AUqUp3GCTxWHSFDstEiNbo57l0KiKRRhqazB81nsg5PU3YVnoCX00i3gvqgXv'
    },
    stag: {
      pk: 'pk_test_51MwHsNFMBcfXmUKM0OQPWG7zpCEQa7XTB5qPFp2PwiYrrsOsY7S2Clw1p51EmNFQF0mG5zZvvqBGahve634KSbLr004WGFQMkq',
      sk: 'sk_test_51MwHsNFMBcfXmUKMNUZgulDWUWdc6JOsFgm0KNG5QY4bKeENpFmrYvvpattm7akyShwEPOfRaXbo3eKGGlxrQGph00esMmFYmg'
    },
    localhost: {
      pk: 'pk_test_51PglnHBaOFd3zSkyFI6xKenKkeNhGDU9Q7NaTUNKeWbpmfGuOBirHY5u42xm93YjStZDW8IENUyi1gnSrOyC9sSx00VQupXF6W',
      sk: 'sk_test_51PglnHBaOFd3zSkym8FigqVSfJgZ4KPHnYbffI2Miteds15VpkC6DRDUR8Fa8M6FufdqXjnDEKRVDsl4VtCovPNT00asHh5iiC'
    }
  }
  switch(hostname) {
    case eachpersonProdDomain: return apiKeys.prod; break;
    case eachpersonStageDomain: return apiKeys.stag; break;
    case eachpersonQaDomain: return apiKeys.qa; break;
    default: return apiKeys.localhost; break;
  }
}

export function getAccountsBaseURL() {
  let localAccountsUrl = 'http://localhost:3000';
  let qaAccountsURL = "https://accounts.qa.eachperson.com";
  let prodAccountsURL = "https://accounts.epoints.com";
  let stagAccountsURL = "https://accounts.stag.epoints.com";

  if (hostname === "localhost") return localAccountsUrl;
  else if (hostname === eachpersonProdDomain) return prodAccountsURL;
  else if (hostname === eachpersonQaDomain) return qaAccountsURL;
  else if (hostname === eachpersonStageDomain) return stagAccountsURL;
  else return qaAccountsURL;
}

// sso accounts base --> in use
export function getSsoBaseLinks() {
  return {
    ssoAccountsBase: `${getAccountsBaseURL()}/sso?companyShortName=${JSON.parse(localStorage.getItem("viaSso"))?.companyShortName}`,
  };
}

// get environment-specific Hapi sso API
export function getHapiAPI() {
  let hapiDomain = '';
  switch(hostname) {
    case eachpersonProdDomain: hapiDomain = 'https://www.hapibenefits.com/lc_ssoframework/saml_inbound.aspx'; break;
    case eachpersonStageDomain: hapiDomain = 'https://hapi-dev.outsystemsenterprise.com/lc_ssoframework/saml_inbound.aspx'; break;
    case eachpersonQaDomain: hapiDomain = 'https://hapi-dev.outsystemsenterprise.com/lc_ssoframework/saml_inbound.aspx'; break;
    default: hapiDomain = 'https://hapi-dev.outsystemsenterprise.com/lc_ssoframework/saml_inbound.aspx'; break;
  }
  return hapiDomain;
}

//for new sso conditions
let query = '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&';
let clientId = {
  omnicom: {
    qa: '2psom8uqb572qrt4uhqm8qjrum',
    prod: '3h07p71k246vu1r0nr99qs8n0e',
    stage: '525udqifbhjmungubaa8v5ahip'
  },
  phg: {
    qa: '4qg4r2lepembpj72537vti1qm5',
    prod: '528emai9eebmgju3bcgfr6p5i9',
    stage:'2g6ohce2jdp8v571q061n1n9l5'
  },
  btg: {
    qa: '16btsq1qrubqvobpmfv1bu816r',
    prod: '5u9ibr8lfn280k1mli30ftvi0d',
    stage:'6amsjb5mt2ki7spvib3q8velj0'
  },
  testnewidp: {
    qa: '1digucpfi97t2hpt58er6s6dlr',
    prod: '1digucpfi97t2hpt58er6s6dlr',
    stage:'1digucpfi97t2hpt58er6s6dlr'
  },
  allegis: {
    qa: 'ditigu0rs700t3o5139cuu5ik',
    prod: 'ditigu0rs700t3o5139cuu5ik',
    stage:'ditigu0rs700t3o5139cuu5ik'
  },
  "allegis-sso": {
    qa: '7pammmrpvi324tdvhtpps46ric',
    prod: '7pammmrpvi324tdvhtpps46ric',
    stage:'7pammmrpvi324tdvhtpps46ric'
  },
  secamb: {
    qa: '4ag9pt32cukpplsifquu1cghp8',
    prod: '79ulnjsfkdh260odflqvj8okhp',
    stage:'4ag9pt32cukpplsifquu1cghp8'
  },
  cote: {
    qa: '7sni8put3876s249ngpeid97d0',
    prod: '25210mg5sihbjfq2oi0tpr97aa',
    stage:'7sni8put3876s249ngpeid97d0'
  },
  sbhl: {
    qa: '66b7t00prv1puj1aojh63o7i5o',
    prod: '44fi2schae0qb9nqsjo2v7id5l',
    stage:'2opgpv1ihrvic5800s2294uhp'
  },
  virgin_active: {
    qa: '1egtgs41ucbankkoof1n4jrsj1',
    prod: '25210mg5sihbjfq2oi0tpr97aa',
    stage:'1egtgs41ucbankkoof1n4jrsj1'
  },
  each_person: {
    qa: '7sni8put3876s249ngpeid97d0',
    prod: '5r5afmcd8mi9qdjatmv10kelig',
    stage:'193kltn0vs7vg5434v4mua1skm'
  },
};

function getClientId(company: string): string{
  let id = '';
  switch (hostname) {
    case eachpersonProdDomain: id = clientId[company]?.prod;break;
    case eachpersonStageDomain: id = clientId[company]?.stage;break;
    default: id = clientId[company]?.qa;break;
  }
  return id;
}

function getSSOBasePath(): string{
  let path = '';
  switch (hostname) {
    case eachpersonProdDomain: path = 'https://login.epoints.com/';break;
    case eachpersonStageDomain: path = 'https://stag-login.auth.eu-west-1.amazoncognito.com/';break;
    default: path = 'https://qa-k8s.auth.eu-west-1.amazoncognito.com/';break;
  }
  return `${path}`;
}

export function getAlternativeRedirectURI(companyShortName: string): string{
  return `${location.origin}/sso?companyShortName=${companyShortName}`
}

export function getSSOLoginURL(companyName?: string){
  if (!companyName) companyName = JSON.parse(localStorage.getItem("viaSso"))?.companyShortName;
  return `${getSSOBasePath()}login?client_id=${getClientId(companyName)}${query}redirect_uri=${getAlternativeRedirectURI(companyName)}`
}

export function getSSOLogoutURL(){
  let companyName = JSON.parse(localStorage.getItem("viaSso"))?.companyShortName
  return `${getSSOBasePath()}logout?client_id=${getClientId(companyName)}${query}logout_uri=${location.origin}/logout`
}

//for guard
export const disableNominationRoutes: string[] = ['scottish_rugby', 'uat_testing']
export const enableAPIRoutes: string[] = ['uat_testing', 'iat_hr', 'each_person', 'AndyCompanyTest2', 'genese_hello','phg','omnicom', 'code_himalaya']

//to check globally hidden items
export const globalHiddenRoutes: string[] = ['API Documentation']

export const menuAccessControl = {
  phg: {
    enable: ['API Documentation']
  },
  code_himalaya: {
    enable: ['API Documentation']
  },
  scottish_rugby: {
    enable: [],
    disable: ['Nominate', 'Nomination Settings', 'Nomination Activity', 'Nomination Winners']
  },
  uat_testing: {
    enable: ['API Documentation'],
    disable: ['Nominate', 'Nomination Settings', 'Nomination Activity', 'Nomination Winners']
  },
  iat_hr: {
    enable: ['API Documentation'],
  },
  each_person: {
    enable: ['API Documentation'],
  },
  AndyCompanyTest2: {
    enable: ['API Documentation']
  },
  genese_hello: {
    enable: ['API Documentation']
  },
  omnicom: {
    enable: ['API Documentation']
  }
}

export function sliceArray(array, pageSize, pageNumber) {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export function envSpecificCookiePrefix() {
  let hostname = window.location.hostname;
  let prefix = 'qa';

  switch(hostname) {
    case eachpersonProdDomain: prefix = "prod"; break;
    case eachpersonStageDomain: prefix = "stag"; break;
    case eachpersonQaDomain: prefix = "qa"; break;
    default: prefix = "qa"; break;
  }

  return prefix;
}
