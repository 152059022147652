import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarService } from './service/rightsidebar.service';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './service/auth.service';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader.service';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { SetupAccessGuard } from './guard/setup-access.guard';
import { AccessIfSetupCompletedGuard } from './guard/access-if-setup-completed.guard';
import { UiAccessDirective } from './directive/ui-access.directive';
import {ApiDocumentationService} from "./service/api-documentation.service";

/**
 * here we have created a custom directive UiAccessDirective inside CoreModule (it is a nested module to the app.module).
 * Since the directive is inside the nested core module, only including it inside declarations does not work, it should be
 * included inside exports array as well whereas in app.module, it is not necessary to export. Only declarations can help.
 */
@NgModule({
  declarations: [UiAccessDirective],
  imports: [CommonModule],
  providers: [
    RightSidebarService,
    AuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    SetupAccessGuard,
    AccessIfSetupCompletedGuard,
    ApiDocumentationService
  ],
  exports: [UiAccessDirective]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
