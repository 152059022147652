import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from 'src/app/core/service/password.service';
import { ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-reset-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  disableBtn: boolean = false;
  errorMessage: string = '';
  currentYear: number = null;
  autoPopulateEmail: string = null;

  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      param => {
        if(param?.email) {
          this.autoPopulateEmail = param?.email
        }
      }
    )
    this.initForm();
    this.getCurrentYear();
  }

  getCurrentYear() {
    this.currentYear = (new Date()).getFullYear();
  }

  get f(){
    return this.forgotPasswordForm.controls
  }

  initForm(){
    this.forgotPasswordForm  = this.fb.group(
      {
        email: [
          this.autoPopulateEmail || null,
          Validators.compose([Validators.email, Validators.required])
        ],
      })
  }

  onResetSubmit(){
    this.disableBtn = true;
    this.passwordService.forgotPassword({email: this.f.email.value}).subscribe(da => {
      this.router.navigate(['/reset-password'], { queryParams: {email: this.f.email.value}, queryParamsHandling: 'merge' } );
      this.disableBtn = false
    }, err => {
      this.disableBtn = false;
      this.errorMessage = err?.errors?.[0].message || err;      
      this.errorMessage = this.errorMessage.split('(')[0];
    })
  }

}
