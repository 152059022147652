import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessWithRolesGuard implements CanActivate, OnDestroy {
  // avoid memory leak issue --> reference - https://medium.com/angular-in-depth/how-to-create-a-memory-leak-in-angular-4c583ad78b8b#:~:text=As%20soon%20as%20we%20subscribe,not%20referenced%20by%20Angular%20directly.
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * this guard is a common guard that provides access to the users with roles passed in data from the routing.ts files
   * and restricts to the users those do not have matching roles specified in the roles passed in data from routing file.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let roles = route.data["roles"];
    return new Observable<boolean>(obs => {
      this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data) {
            if (roles && roles.indexOf(data.role) !== -1) {
              obs.next(true);
            } else {
              // failed
              if(data.setUpCompleted) {
                //location.reload() insde then breaks the app and redirects user to login in IOS devices so used .then() only
                this.router.navigate(['/hr/dashboard']).then()
              } else {
                this.router.navigate(['/set-up']).then()
              }
              obs.next(false);         
            }
          } else {
            obs.next(false);  
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
