import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {
  loadingText = 'Signing out ...';

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    let logoutUrl = this.authService.getCustomLogoutURL();
    if(logoutUrl) {
      this.authService.removeCustomLogoutURL();
      window.location.href = logoutUrl;
    } else {
      window.location.href = location.origin;
    }
  }

}
