import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentProcessService {
  planSelected: any;
  
  constructor(
    private http: HttpClient
  ) { }

  setMembershipPlan(selectedPlan: any) {
    this.planSelected = selectedPlan;
  }

  getMembershipPlan() {
    return this.planSelected;
  }

  processStripePayment(params: any) {
    return this.http.get<any>(`${ environment.apiUrl }/payment/process/stripe`, {params});
  }
}
