import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { envSpecificCookiePrefix } from './environment-variables';

// default dates
var defaultDateFrom = new Date(moment().add(-30, 'days').format());
var defaultDateTo = new Date(moment().add(1, 'days').format());


//default 2000-01-01 fromDate for browse people
var default2000 = new Date(2000, 0, 1);
var default2000Formated = moment(default2000).format();

// formatted using moment - to send to API
export function getFormattedDefaultFromDate() {
  return moment(defaultDateFrom).format();
}

// formatted using moment - to send to API
export function getFormattedDefaultToDate() {
  return moment(defaultDateTo).format();
}


// exports 2000-01-01 for the browse people default data table
// formatted using moment - to send to API
export function getFormattedDefaultFromDate2000(){
  return moment(default2000Formated).format();
}



export function getDateRangeItems() {
  /* here .getMonth() of the date gives 1 month less than the current month so need to add 1,
  but no need to add 1, if the getMonth() is done inside new Date().
  */
  var items = {
    hoveredDate: <NgbDate>null,
    fromDate: <NgbDate>new NgbDate(defaultDateFrom.getFullYear(), defaultDateFrom.getMonth() + 1, defaultDateFrom.getDate()),
    toDate: <NgbDate>new NgbDate(defaultDateTo.getFullYear(), defaultDateTo.getMonth() + 1, defaultDateTo.getDate()),
    range: <string>'',
    errors: <string>null
  };
  return items;
}

export function getFormattedDefaultFromDate2() {
  return moment(new Date(moment().add(-31, 'days').format())).format("DD-MM-yyyy+HH:mmT");
}
export function getFormattedDefaultToDate2() {
  return moment(defaultDateTo).format("DD-MM-yyyy+HH:mmT");
}

export function getAreaLineChartItem(colorCode, series, type) {
  let seriesData = []
  series.forEach(element => {
    let temp = {
      name: element,
      type: type,
      smooth: !0,
      areaStyle: {
        type: 'default',
      },
      data: [],
    }
    seriesData.push(temp);
  });
  const area_line_chart = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: [],
      textStyle: {
        color: '#9aa0ac',
        padding: [0, 5, 0, 5],
      },
    },
    toolbox: {
      show: !0,
      feature: {
        magicType: {
          show: !0,
          title: {
            line: 'Line',
            bar: 'Bar',
            stack: 'Stack',
            tiled: 'Tiled',
          },
          type: ['line', 'bar', 'stack', 'tiled'],
        },
        restore: {
          show: !0,
          title: 'Restore',
        },
        saveAsImage: {
          show: !0,
          title: 'Save Image',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: !1,
        data: [],
        axisLabel: {
          fontSize: 10,
          color: '#9aa0ac',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          fontSize: 10,
          color: '#9aa0ac',
        },
      },
    ],
    series: seriesData,
    color: colorCode,
  };
  return area_line_chart;
}

export function getBarChartItem(colorCode, series, type) {
  let seriesData = []
  series.forEach(element => {
    let temp = {
      name: element,
      type: type,
      data: [],
    }
    seriesData.push(temp);
  });
  let linechart = {
    xAxis: {
      data: [],
      axisLabel: {
        fontSize: 10,
        color: '#9aa0ac',
      },
    },
    tooltip: {
      show: true,
      showContent: true,
      alwaysShowContent: false,
      triggerOn: 'mousemove',
      trigger: 'axis',
    },
    toolbox: {
      show: !0,
      feature: {
        magicType: {
          show: !0,
          title: {
            line: 'Line',
            bar: 'Bar',
            stack: 'Stack',
            tiled: 'Tiled',
          },
          type: ['line', 'bar', 'stack', 'tiled'],
        },
        restore: {
          show: !0,
          title: 'Restore',
        },
        saveAsImage: {
          show: !0,
          title: 'Save Image',
        },
      },
    },
    yAxis: {
      axisLabel: {
        fontSize: 10,
        color: '#9aa0ac',
      },
    },
    series: seriesData,
    color: colorCode,
  };
  return linechart;
}

// actual donut being used
export function getDonutChartItem() {
  let pieChartOptions = {
    //series2: [18, 22, 14, 31, 15], //chart data
    series2: [],
    chart: {
      type: 'donut',
      width: 280,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: '22px',
              fontWeight: 600,
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 400,
              color: '#9aa0ac',
            },
            total: {
              show: true,
              showAlways: false,
              label: 'Total',
              fontSize: '22px',
              fontWeight: 600,
              color: '#6777EF',
            },
          },
        },
      },
    },
    colors: giveRandomColorCodes(),  //color codes for donut chart
    //colors: [],
    //labels: ['Project 1', 'Project 2', 'Project 3', 'Project 4', 'Project 5'],  //label names
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {},
      },
    ],
  };
  return pieChartOptions;
}

export function getLineChartItem(series) {
  let seriesData = []

  series.forEach(element => {
    let temp = {
      name: element,
      type: 'line',
      smooth: true,
      lineStyle: {
        width: 3,
        //shadowColor: 'rgba(0,0,0,0.4)',
        //shadowBlur: 10,
        //shadowOffsetY: 10,
      },
      symbolSize: 10,
      // size: 10,
      data: [],
      // color: ["#009DA0"]

    }
    seriesData.push(temp);
  });
  let lineChart = {
    // grid: {
    //   top: '6',
    //   right: '0',
    //   bottom: '17',
    //   left: '25',
    // },
    toolbox: {
      show: !0,
      feature: {
        magicType: {
          show: !0,
          title: {
            line: 'Line',
            bar: 'Bar',
            stack: 'Stack',
            tiled: 'Tiled',
          },
          type: ['line', 'bar', 'stack', 'tiled'],
        },
        restore: {
          show: !0,
          title: 'Restore',
        },
        saveAsImage: {
          show: !0,
          title: 'Save Image',
        },
      },
    },
    xAxis: {
      data: [],
      axisLine: {
        lineStyle: {
          color: '#eaeaea',
        },
      },
      axisLabel: {
        fontSize: 10,
        color: '#9aa0ac',
      },
    },
    tooltip: {
      show: true,
      showContent: true,
      alwaysShowContent: false,
      triggerOn: 'mousemove',
      trigger: 'axis',
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: '#eaeaea',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#eaeaea',
        },
      },
      axisLabel: {
        fontSize: 10,
        color: '#9aa0ac',
      },
    },
    series: seriesData,
    color: ['#3FA7DC', '#F6A025'],
  };
  return lineChart;
}

/**to return unique color codes for donut chart  */
export function giveRandomColorCodes() {
  let colors = [
    "#9A8BE7",
    "#2AC3CB",
    "#FFAA00",
    "#FA62BB",
    "#FFD000",
    "#000000",
    "#0000ff",
    "#a52a2a",
    "#00008b",
    "#008b8b",
    "#a9a9a9",
    "#006400",
    "#bdb76b",
    "#8b008b",
    "#556b2f",
    "#ff8c00",
    "#9932cc",
    "#8b0000",
    "#e9967a",
    "#9400d3",
    "#ff00ff",
    "#ffd700",
    "#008000",
    "#4b0082",
    "#f0e68c",
    "#add8e6",
    "#e0ffff",
    "#90ee90",
    "#d3d3d3",
    "#ffb6c1",
    "#ffffe0",
    "#00ff00",
    "#ff00ff",
    "#800000",
    "#000080",
    "#808000",
    "#ffa500",
    "#ffc0cb",
    "#800080",
    "#800080",
    "#ff0000",
    "#c0c0c0",
    "#ffffff",
    "#ffff00",
    "#e500bc",
    "#3380aa",
    "#0048ba",
    "#b0bf1a",
    "#f0f8ff",
    "#e32636",
    "#e52b50",
    "#ffbf00",
    "#0048ba",
    "#a99a86",
    "#2a3439",
    "#446ccf",
    "#5218fa",
    "#3fff00",
    "#da9100",
    "#df73ff",
    "#00ab66",
    "#87421f",
    "#e48400",
    "#8806ce",
    "#77b5fe",
    "#86608e",
    "#856d4d",
    "#a67b5b",
    "#fbceb1",
    "#00ffff",
    "#7fffd4",
    "#76eec6",
    "#458b74",
    "#4b5320",
    "#e9d66b",
    "#b2beb5",
    "#87a96b",
    "#fae7b5",
    "#ffe135",
    "#7c0a02",
    "#8b7d6b",
    "#3d2b1f",
    "#cae00d",
    "#bfff00",
    "#fe6f5e",
    "#bf4f51",
    "#000000",
    "#3b2f2f",
  ];
  // let colorCodes = [];
  // for (let i = 0; i < number; i++){
  //     colorCodes.push(colors[i]);
  //     if (i === colors.length){
  //         break;
  //     }
  // }
  return colors;
}

export function getNewsObjectModal(number: number) {
  let wellbeingPublishedContentDetailsListObject = [],
      newsObject = {};

  for (let i = 0; i < number; i++) {
    let tempObject = {
      "sourceName": "",
      "sourceImageUrl": "",
      "categoryName": "",
      "displayTypeIdentifier": "",
      "title": "",
      "summary": "",
      "imageUrl": "",
      "linkToFullArticle": "",
      "trendingFlag": 0,
      "featuredFlag": 0,
      "publishedDate": ""
    }
    wellbeingPublishedContentDetailsListObject.push(tempObject);
  }

  newsObject = {
    searchResultsCount : 0,
    pageSize: 0,
    wellbeingPublishedContentDetailsList: wellbeingPublishedContentDetailsListObject
  }
  return newsObject;
}

// global regex patterns
export function regexPatterns() {
  let twoDecimalNumberRegex = /^-?\d*[.,]?\d{0,2}$/;

  return {
    twoDecimalNumberRegex
  };
}

// to parse queries for special characters
export function objToQueryString(params: any){
  let queryParams = '';
  let paramsKeys = Object.keys(params);
  paramsKeys.forEach((key, index) => {
    queryParams += `${key}=${params[key]}`;
    if(index+1 < paramsKeys.length) {
      queryParams += '&';
    }
  });
  return queryParams
}

export function linkTargetViaGoNative(){
  return (navigator.userAgent.indexOf('gonative') > -1 ? "_self": "_blank")
}

export function matchingPartner(partnerId: number) {
  let partners = [21447702862, 100461, 100267] // qa, stage, prod(100267)
  let partnerMatched = partners.find((id) => id === partnerId);
  if(partnerMatched) {
    return true
  } else {
    return false
  }
}

export function getCycle2workMaxLimit(partnerId: number) {
  let partners = {
    prod: [
      {
        id: 100461, // prod - imperial
        limit: 3300,
      },
      {
        id: 100465, // RNOH
        limit: 2500
      },
      {
        id: 100472, // tops day nursery
        limit: 2000
      }
    ],
    stage: [
      {
        id: 100267, // stage
        limit: 3300,
      },
      {
        id: 100409,
        limit: 2500
      }
    ],
    qa: [
      {
        id: 21447702862, // qa
        limit: 3300,
      },
      {
        id: 21447702501,
        limit: 2500
      }
    ]
  };

  let partnerMatched = null;
  let eachpersonProdDomain = "admin.eachperson.com";
  let eachpersonStageDomain = "stag-admin.eachperson.com";
  switch (window.location.hostname) {
    case eachpersonProdDomain: partnerMatched = partners?.prod.find((partner) => partner?.id === partnerId);break;
    case eachpersonStageDomain: partnerMatched = partners?.stage.find((partner) => partner?.id === partnerId);break;
    default: partnerMatched = partners?.qa.find((partner) => partner?.id === partnerId);break;
  }
  
  if(partnerMatched) {
    return partnerMatched?.limit;
  } else {
    return 1000;
  }
}

export function aragPartner(partnerId: number) {
  let partners = {
    prod: [
      100472 // Arag partner
    ],
    stage: [],
    qa: []
  };

  let partnerMatched = null;
  let eachpersonProdDomain = "admin.eachperson.com";
  let eachpersonStageDomain = "stag-admin.eachperson.com";
  switch (window.location.hostname) {
    case eachpersonProdDomain: partnerMatched = partners?.prod.find((partner) => partner === partnerId);break;
    case eachpersonStageDomain: partnerMatched = partners?.stage.find((partner) => partner === partnerId);break;
    default: partnerMatched = partners?.qa.find((partner) => partner === partnerId);break;
  }
  
  if(partnerMatched) {
    return true;
  } else {
    return false;
  }
  
}
export function cotePartner(partnerId: number) {
  let partners = {
    prod: [
      100475 // Cote
    ],
    stage: [
      100461
    ],
    qa: [
      21447702501
    ]
  };

  let partnerMatched = null;
  let eachpersonProdDomain = "admin.eachperson.com";
  let eachpersonStageDomain = "stag-admin.eachperson.com";
  switch (window.location.hostname) {
    case eachpersonProdDomain: partnerMatched = partners?.prod.find((partner) => partner === partnerId);break;
    case eachpersonStageDomain: partnerMatched = partners?.stage.find((partner) => partner === partnerId);break;
    default: partnerMatched = partners?.qa.find((partner) => partner === partnerId);break;
  }
  
  if(partnerMatched) {
    return true;
  } else {
    return false;
  }
}

export function btgPartner(partnerId: number) {
  let partners = {
    prod: [
      100426 // BTG
    ],
    stage: [
      100409
    ],
    qa: [
      2147335217
    ]
  };

  let partnerMatched = null;
  let eachpersonProdDomain = "admin.eachperson.com";
  let eachpersonStageDomain = "stag-admin.eachperson.com";
  switch (window.location.hostname) {
    case eachpersonProdDomain: partnerMatched = partners?.prod.find((partner) => partner === partnerId);break;
    case eachpersonStageDomain: partnerMatched = partners?.stage.find((partner) => partner === partnerId);break;
    default: partnerMatched = partners?.qa.find((partner) => partner === partnerId);break;
  }
  
  if(partnerMatched) {
    return true;
  } else {
    return false;
  }
}

// partner-specific EAP information
export function eapDetails() {
  return {
    100467: { // prod
      loginUrl: 'https://wisdom.healthassured.org/login',
      phoneNumber: '0800 028 0199'
    },
    // 100461: { // stage
    //   loginUrl: 'https://wisdom.healthassured.org/login',
    //   phoneNumber: '0800 028 0199'
    // },
    // 21447702501: { // qa
    //   loginUrl: 'https://wisdom.healthassured.org/login',
    //   phoneNumber: '0800 028 0199'
    // }
  }
}

/**
 * some html entity characters cannot parse characters like &nbsp; &pound; if directly displayed as string.
 * so, they should be displayed inside innerHTML.
 */
export function decodeHtmlEntities(html: any) {
  var txt = document.createElement('div');
  txt.innerHTML = html;
  return txt;
}

// used by social aspect to group by reactionType
export function groupByReactionTypeFunc(arry) {
  // Check if the input is null or not an array
    if (!Array.isArray(arry)) {
    // Return an empty object or throw an error
    return {};
    // throw new Error('Invalid input: expected an array');
  }
  // Initialize an empty object to store the grouped data
  let groupedReactions = arry.reduce((groupedData, currentReaction) => {
    // Check if the reactionType already exists in the groupedData object
    if(groupedData[currentReaction.reactionType]) {
      // If it does, append the current reaction to the existing array
      groupedData[currentReaction.reactionType].push(currentReaction);
    } else {
      // If it doesn't, create a new array with the current reaction
      groupedData[currentReaction.reactionType] = [currentReaction];
    }
    
    // Return the updated groupedData object for the next iteration
    return groupedData;
  }, {});

  // Return the grouped data
  return groupedReactions;
}

// setting cookie from dashboard for the shop to improve performance by reducing some API calls from the shop
// Set a cookie with a domain attribute
export function setEPCookie(name: string, value: any) {
  // Set environment-specific cookies - set prefix
  let prefix = envSpecificCookiePrefix();
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 1); // Set expiry date to one day from now
  const expires = '; expires=' + expiryDate.toUTCString();
  let finalValue = value || "";
  // Include SameSite=None and Secure attributes
  document.cookie = `${prefix}_${name}=${finalValue}${expires}; path=/; domain=.eachperson.com; SameSite=None; Secure`;
}

export function deleteEPCookie() {
  let prefix = envSpecificCookiePrefix();
  // Include SameSite=None and Secure attributes for deletion
  document.cookie = `${prefix}_access_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_refresh_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_is_sso=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_user_type=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_user_balance=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_branding_info=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_country_language=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
  document.cookie = `${prefix}_multiCountryEnabled=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.eachperson.com; SameSite=None; Secure`;
}


function decryptData(encryptedData: string): any {
  return atob(encryptedData);
}

export function encryptToken(token: string) {
  // Sample usage
  const dataToEncrypt = token;

  // Encrypt the data
  const encryptedData = btoa(dataToEncrypt);
  return encryptedData;
}

export function extractTsText(text: string, key?: string): string {
  return text.replace(/\n\s*/g, ' ').trim();
}

// List of countries wrapped in extractTsText
export const countries: string[] = [
  extractTsText('Afghanistan'),
  extractTsText('Albania'),
  extractTsText('Algeria'),
  extractTsText('Andorra'),
  extractTsText('Angola'),
  extractTsText('Antigua and Barbuda'),
  extractTsText('Argentina'),
  extractTsText('Armenia'),
  extractTsText('Australia'),
  extractTsText('Austria'),
  extractTsText('Azerbaijan'),
  extractTsText('Bahamas'),
  extractTsText('Bahrain'),
  extractTsText('Bangladesh'),
  extractTsText('Barbados'),
  extractTsText('Belarus'),
  extractTsText('Belgium'),
  extractTsText('Belize'),
  extractTsText('Benin'),
  extractTsText('Bhutan'),
  extractTsText('Bolivia'),
  extractTsText('Bosnia and Herzegovina'),
  extractTsText('Botswana'),
  extractTsText('Brazil'),
  extractTsText('Brunei'),
  extractTsText('Bulgaria'),
  extractTsText('Burkina Faso'),
  extractTsText('Burundi'),
  extractTsText('Cabo Verde'),
  extractTsText('Cambodia'),
  extractTsText('Cameroon'),
  extractTsText('Canada'),
  extractTsText('Central African Republic'),
  extractTsText('Chad'),
  extractTsText('Chile'),
  extractTsText('China'),
  extractTsText('Colombia'),
  extractTsText('Comoros'),
  extractTsText('Congo, Democratic Republic of the'),
  extractTsText('Congo, Republic of the'),
  extractTsText('Costa Rica'),
  extractTsText('Croatia'),
  extractTsText('Cuba'),
  extractTsText('Cyprus'),
  extractTsText('Czech Republic'),
  extractTsText('Denmark'),
  extractTsText('Djibouti'),
  extractTsText('Dominica'),
  extractTsText('Dominican Republic'),
  extractTsText('Ecuador'),
  extractTsText('Egypt'),
  extractTsText('El Salvador'),
  extractTsText('Equatorial Guinea'),
  extractTsText('Eritrea'),
  extractTsText('Estonia'),
  extractTsText('Eswatini'),
  extractTsText('Ethiopia'),
  extractTsText('Fiji'),
  extractTsText('Finland'),
  extractTsText('France'),
  extractTsText('Gabon'),
  extractTsText('Gambia'),
  extractTsText('Georgia'),
  extractTsText('Germany'),
  extractTsText('Ghana'),
  extractTsText('Greece'),
  extractTsText('Grenada'),
  extractTsText('Guatemala'),
  extractTsText('Guinea'),
  extractTsText('Guinea-Bissau'),
  extractTsText('Guyana'),
  extractTsText('Haiti'),
  extractTsText('Honduras'),
  extractTsText('Hong Kong'),
  extractTsText('Hungary'),
  extractTsText('Iceland'),
  extractTsText('India'),
  extractTsText('Indonesia'),
  extractTsText('Iran'),
  extractTsText('Iraq'),
  extractTsText('Ireland'),
  extractTsText('Israel'),
  extractTsText('Italy'),
  extractTsText('Jamaica'),
  extractTsText('Japan'),
  extractTsText('Jordan'),
  extractTsText('Kazakhstan'),
  extractTsText('Kenya'),
  extractTsText('Kiribati'),
  extractTsText('Korea'),
  extractTsText('Korea, North'),
  extractTsText('Korea, South'),
  extractTsText('Kosovo'),
  extractTsText('Kuwait'),
  extractTsText('Kyrgyzstan'),
  extractTsText('Laos'),
  extractTsText('Latvia'),
  extractTsText('Lebanon'),
  extractTsText('Lesotho'),
  extractTsText('Liberia'),
  extractTsText('Libya'),
  extractTsText('Liechtenstein'),
  extractTsText('Lithuania'),
  extractTsText('Luxembourg'),
  extractTsText('Madagascar'),
  extractTsText('Malawi'),
  extractTsText('Malaysia'),
  extractTsText('Maldives'),
  extractTsText('Mali'),
  extractTsText('Malta'),
  extractTsText('Marshall Islands'),
  extractTsText('Mauritania'),
  extractTsText('Mauritius'),
  extractTsText('Mexico'),
  extractTsText('Micronesia'),
  extractTsText('Moldova'),
  extractTsText('Monaco'),
  extractTsText('Mongolia'),
  extractTsText('Montenegro'),
  extractTsText('Morocco'),
  extractTsText('Mozambique'),
  extractTsText('Myanmar'),
  extractTsText('Namibia'),
  extractTsText('Nauru'),
  extractTsText('Nepal'),
  extractTsText('Netherlands'),
  extractTsText('New Zealand'),
  extractTsText('Nicaragua'),
  extractTsText('Niger'),
  extractTsText('Nigeria'),
  extractTsText('North Macedonia'),
  extractTsText('Norway'),
  extractTsText('Oman'),
  extractTsText('Pakistan'),
  extractTsText('Palau'),
  extractTsText('Panama'),
  extractTsText('Papua New Guinea'),
  extractTsText('Paraguay'),
  extractTsText('Peru'),
  extractTsText('Philippines'),
  extractTsText('Poland'),
  extractTsText('Portugal'),
  extractTsText('Puerto Rico'),
  extractTsText('Qatar'),
  extractTsText('Romania'),
  extractTsText('Russia'),
  extractTsText('Rwanda'),
  extractTsText('Saint Kitts and Nevis'),
  extractTsText('Saint Lucia'),
  extractTsText('Saint Vincent and the Grenadines'),
  extractTsText('Samoa'),
  extractTsText('San Marino'),
  extractTsText('Sao Tome and Principe'),
  extractTsText('Saudi Arabia'),
  extractTsText('Senegal'),
  extractTsText('Serbia'),
  extractTsText('Seychelles'),
  extractTsText('Sierra Leone'),
  extractTsText('Singapore'),
  extractTsText('Slovakia'),
  extractTsText('Slovenia'),
  extractTsText('Solomon Islands'),
  extractTsText('Somalia'),
  extractTsText('South Africa'),
  extractTsText('South Sudan'),
  extractTsText('Spain'),
  extractTsText('Sri Lanka'),
  extractTsText('Sudan'),
  extractTsText('Suriname'),
  extractTsText('Sweden'),
  extractTsText('Switzerland'),
  extractTsText('Syria'),
  extractTsText('Taiwan'),
  extractTsText('Tajikistan'),
  extractTsText('Tanzania'),
  extractTsText('Thailand'),
  extractTsText('Timor-Leste'),
  extractTsText('Turkey'),
  extractTsText('Turkmenistan'),
  extractTsText('Tuvalu'),
  extractTsText('Uganda'),
  extractTsText('Ukraine'),
  extractTsText('United Arab Emirates'),
  extractTsText('UAE'),
  extractTsText('United Kingdom'),
  extractTsText('UK'),
  extractTsText('United States'),
  extractTsText('US'),
  extractTsText('Uruguay'),
  extractTsText('Uzbekistan'),
  extractTsText('Vanuatu'),
  extractTsText('Vatican City'),
  extractTsText('Venezuela'),
  extractTsText('Vietnam'),
  extractTsText('Yemen'),
  extractTsText('Zambia'),
  extractTsText('Zimbabwe')
];

export const languages: any[] = [
  {"language": extractTsText("English (UK)"), "code": "en_GB", "slug": "gb", "rtl": false},
  {"language": extractTsText("English (United States)"), "code": "en_US", "slug": "en", "rtl": false},
  {"language": extractTsText("English (South Africa)"), "code": "en_ZA", "slug": "en_za", "rtl": false},
  {"language": extractTsText("English (Canada)"), "code": "en_CA", "slug": "en_ca", "rtl": false},
  {"language": extractTsText("English (Australia)"), "code": "en_AU", "slug": "en_au", "rtl": false},
  {"language": extractTsText("English (New Zealand)"), "code": "en_NZ", "slug": "en_nz", "rtl": false},
  {"language": extractTsText("Afrikaans"), "code": "af", "slug": "af", "rtl": false},
  {"language": extractTsText("Amharic"), "code": "am", "slug": "am", "rtl": false},
  {"language": extractTsText("Arabic"), "code": "ar", "slug": "ar", "rtl": true},
  {"language": extractTsText("Assamese"), "code": "as", "slug": "as", "rtl": false},
  {"language": extractTsText("Azerbaijani"), "code": "az", "slug": "az", "rtl": false},
  {"language": extractTsText("Bulgarian"), "code": "bg_BG", "slug": "bg_bg", "rtl": false},
  {"language": extractTsText("Bengali (Bangladesh)"), "code": "bn_BD", "slug": "bn_bd", "rtl": false},
  {"language": extractTsText("Bosnian"), "code": "bs_BA", "slug": "bs", "rtl": false},
  {"language": extractTsText("Catalan"), "code": "ca", "slug": "ca", "rtl": false},
  {"language": extractTsText("Cebuano"), "code": "ceb", "slug": "ceb", "rtl": false},
  {"language": extractTsText("Czech"), "code": "cs_CZ", "slug": "cs", "rtl": false},
  {"language": extractTsText("Welsh"), "code": "cy", "slug": "cy", "rtl": false},
  {"language": extractTsText("Danish"), "code": "da_DK", "slug": "da", "rtl": false},
  {"language": extractTsText("German (Austria)"), "code": "de_AT", "slug": "de", "rtl": false},
  {"language": extractTsText("German (Switzerland)"), "code": "de_CH", "slug": "de_ch", "rtl": false},
  {"language": extractTsText("German"), "code": "de_DE", "slug": "de_de", "rtl": false},
  {"language": extractTsText("Greek"), "code": "el", "slug": "el", "rtl": false},
  {"language": extractTsText("Esperanto"), "code": "eo", "slug": "eo", "rtl": false},
  {"language": extractTsText("Spanish (Chile)"), "code": "es_CL", "slug": "es", "rtl": false},
  {"language": extractTsText("Spanish (Mexico)"), "code": "es_MX", "slug": "es_mx", "rtl": false},
  {"language": extractTsText("Spanish (Colombia)"), "code": "es_CO", "slug": "es_co", "rtl": false},
  {"language": extractTsText("Spanish (Venezuela)"), "code": "es_VE", "slug": "es_ve", "rtl": false},
  {"language": extractTsText("Spanish (Costa Rica)"), "code": "es_CR", "slug": "es_cr", "rtl": false},
  {"language": extractTsText("Spanish (Ecuador)"), "code": "es_EC", "slug": "es_ec", "rtl": false},
  {"language": extractTsText("Spanish (Dominican Republic)"), "code": "es_DO", "slug": "es_do", "rtl": false},
  {"language": extractTsText("Spanish (Peru)"), "code": "es_PE", "slug": "es_pe", "rtl": false},
  {"language": extractTsText("Spanish (Uruguay)"), "code": "es_UY", "slug": "es_uy", "rtl": false},
  {"language": extractTsText("Spanish (Puerto Rico)"), "code": "es_PR", "slug": "es_pr", "rtl": false},
  {"language": extractTsText("Spanish (Guatemala)"), "code": "es_GT", "slug": "es_gt", "rtl": false},
  {"language": extractTsText("Spanish (Argentina)"), "code": "es_AR", "slug": "es_ar", "rtl": false},
  {"language": extractTsText("Spanish (Spain)"), "code": "es_ES", "slug": "es_es", "rtl": false},
  {"language": extractTsText("Estonian"), "code": "et", "slug": "et", "rtl": false},
  {"language": extractTsText("Basque"), "code": "eu", "slug": "eu", "rtl": false},
  {"language": extractTsText("Persian (Afghanistan)"), "code": "fa_AF", "slug": "fa", "rtl": true},
  {"language": extractTsText("Persian"), "code": "fa_IR", "slug": "fa_ir", "rtl": true},
  {"language": extractTsText("Finnish"), "code": "fi", "slug": "fi", "rtl": false},
  {"language": extractTsText("French (Belgium)"), "code": "fr_BE", "slug": "fr", "rtl": false},
  {"language": extractTsText("French (Canada)"), "code": "fr_CA", "slug": "fr_ca", "rtl": false},
  {"language": extractTsText("French (France)"), "code": "fr_FR", "slug": "fr_fr", "rtl": false},
  {"language": extractTsText("Frisian"), "code": "fy", "slug": "fy", "rtl": false},
  {"language": extractTsText("Scottish Gaelic"), "code": "gd", "slug": "gd", "rtl": false},
  {"language": extractTsText("Galician"), "code": "gl_ES", "slug": "gl", "rtl": false},
  {"language": extractTsText("Gujarati"), "code": "gu", "slug": "gu", "rtl": false},
  {"language": extractTsText("Hebrew"), "code": "he_IL", "slug": "he", "rtl": true},
  {"language": extractTsText("Hindi"), "code": "hi_IN", "slug": "hi", "rtl": false},
  {"language": extractTsText("Croatian"), "code": "hr", "slug": "hr", "rtl": false},
  {"language": extractTsText("Hungarian"), "code": "hu_HU", "slug": "hu", "rtl": false},
  {"language": extractTsText("Armenian"), "code": "hy", "slug": "hy", "rtl": false},
  {"language": extractTsText("Indonesian"), "code": "id_ID", "slug": "id", "rtl": false},
  {"language": extractTsText("Icelandic"), "code": "is_IS", "slug": "is", "rtl": false},
  {"language": extractTsText("Italian"), "code": "it_IT", "slug": "it", "rtl": false},
  {"language": extractTsText("Japanese"), "code": "ja", "slug": "ja", "rtl": false},
  {"language": extractTsText("Javanese"), "code": "jv_ID", "slug": "jv", "rtl": false},
  {"language": extractTsText("Georgian"), "code": "ka_GE", "slug": "ka", "rtl": false},
  {"language": extractTsText("Kazakh"), "code": "kk", "slug": "kk", "rtl": false},
  {"language": extractTsText("Khmer"), "code": "km", "slug": "km", "rtl": false},
  {"language": extractTsText("Kannada"), "code": "kn", "slug": "kn", "rtl": false},
  {"language": extractTsText("Korean"), "code": "ko_KR", "slug": "ko", "rtl": false},
  {"language": extractTsText("Kurdish (Sorani)"), "code": "ckb", "slug": "ku", "rtl": true},
  {"language": extractTsText("Lao"), "code": "lo", "slug": "lo", "rtl": false},
  {"language": extractTsText("Lithuanian"), "code": "lt_LT", "slug": "lt", "rtl": false},
  {"language": extractTsText("Latvian"), "code": "lv", "slug": "lv", "rtl": false},
  {"language": extractTsText("Macedonian"), "code": "mk_MK", "slug": "mk", "rtl": false},
  {"language": extractTsText("Malayalam"), "code": "ml_IN", "slug": "ml", "rtl": false},
  {"language": extractTsText("Mongolian"), "code": "mn", "slug": "mn", "rtl": false},
  {"language": extractTsText("Marathi"), "code": "mr", "slug": "mr", "rtl": false},
  {"language": extractTsText("Malay"), "code": "ms_MY", "slug": "ms", "rtl": false},
  {"language": extractTsText("Myanmar (Burmese)"), "code": "my_MM", "slug": "my", "rtl": false},
  {"language": extractTsText("Norwegian (Bokmål)"), "code": "nb_NO", "slug": "nb", "rtl": false},
  {"language": extractTsText("Nepali"), "code": "ne_NP", "slug": "ne", "rtl": false},
  {"language": extractTsText("Dutch (Belgium)"), "code": "nl_BE", "slug": "nl", "rtl": false},
  {"language": extractTsText("Dutch"), "code": "nl_NL", "slug": "nl_nl", "rtl": false},
  {"language": extractTsText("Panjabi (India)"), "code": "pa_IN", "slug": "pa", "rtl": false},
  {"language": extractTsText("Polish"), "code": "pl_PL", "slug": "pl", "rtl": false},
  {"language": extractTsText("Pashto"), "code": "ps", "slug": "ps", "rtl": true},
  {"language": extractTsText("Portuguese (Portugal)"), "code": "pt_PT", "slug": "pt", "rtl": false},
  {"language": extractTsText("Portuguese (Brazil)"), "code": "pt_BR", "slug": "pt_br", "rtl": false},
  {"language": extractTsText("Portuguese (Angola)"), "code": "pt_AO", "slug": "pt_ao", "rtl": false},
  {"language": extractTsText("Romanian"), "code": "ro_RO", "slug": "ro", "rtl": false},
  {"language": extractTsText("Russian"), "code": "ru_RU", "slug": "ru", "rtl": false},
  {"language": extractTsText("Sinhala"), "code": "si_LK", "slug": "si", "rtl": false},
  {"language": extractTsText("Slovak"), "code": "sk_SK", "slug": "skr", "rtl": false},
  {"language": extractTsText("Slovenian"), "code": "sl_SI", "slug": "sl", "rtl": false},
  {"language": extractTsText("Albanian"), "code": "sq", "slug": "sq", "rtl": false},
  {"language": extractTsText("Serbian"), "code": "sr_RS", "slug": "sr", "rtl": false},
  {"language": extractTsText("Swedish"), "code": "sv_SE", "slug": "sv", "rtl": false},
  {"language": extractTsText("Swahili"), "code": "sw", "slug": "sw", "rtl": false},
  {"language": extractTsText("Tamil"), "code": "ta_IN", "slug": "ta", "rtl": false},
  {"language": extractTsText("Tamil (Sri Lanka)"), "code": "ta_LK", "slug": "ta_lk", "rtl": false},
  {"language": extractTsText("Telugu"), "code": "te", "slug": "te", "rtl": false},
  {"language": extractTsText("Thai"), "code": "th", "slug": "th", "rtl": false},
  {"language": extractTsText("Tagalog"), "code": "tl", "slug": "tl", "rtl": false},
  {"language": extractTsText("Turkish"), "code": "tr_TR", "slug": "tr", "rtl": false},
  {"language": extractTsText("Tatar"), "code": "tt_RU", "slug": "tt", "rtl": false},
  {"language": extractTsText("Uighur"), "code": "ug_CN", "slug": "ug", "rtl": false},
  {"language": extractTsText("Ukrainian"), "code": "uk", "slug": "uk", "rtl": false},
  {"language": extractTsText("Urdu"), "code": "ur", "slug": "ur", "rtl": true},
  {"language": extractTsText("Uzbek"), "code": "uz_UZ", "slug": "uz", "rtl": false},
  {"language": extractTsText("Vietnamese"), "code": "vi", "slug": "vi", "rtl": false},
  {"language": extractTsText("Chinese (Taiwan)"), "code": "zh_TW", "slug": "zh", "rtl": false},
  {"language": extractTsText("Chinese (China)"), "code": "zh_CN", "slug": "zh_cn", "rtl": false},
  {"language": extractTsText("Chinese (Hong Kong)"), "code": "zh_HK", "slug": "zh_hk", "rtl": false}
];

export const targetLanguages = () => {
  let languageCodes = languages.map(eachLang => {
    return eachLang?.Code;
  });
  return languageCodes;
}
