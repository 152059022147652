import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/service/auth.service';
import { DestroyService } from 'src/app/core/service/destroy.service';
import { getAccountsBaseURL, getSsoBaseLinks, getWPShopDomain } from 'src/app/core/_helpers/environment-variables';
import { encryptToken, linkTargetViaGoNative, setEPCookie } from 'src/app/core/_helpers/global-functions';
import { UserFlags } from 'src/app/core/models/user';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  providers: [DestroyService]
})
export class FooterComponent implements OnInit {

  toggleObj: any = {
    account: false,
    action: false,
    readMore: false,
    legal: false
  }

  accountsUrl: any;
  accountsBaseURL = getAccountsBaseURL();
  ssoAccountsBaseUrl = getSsoBaseLinks().ssoAccountsBase;
  shopUrl: any;

  showDonationFooter: Boolean = false;
  showShopProductsFooter: Boolean = false;

  currentYear: number = null;

  userFlags: UserFlags
  load: boolean = false;

  constructor(
    public authService: AuthService,
    private readonly destroy$: DestroyService
  ) {
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe(userdata => {
      // enable donate link, shop products link, as it is only accesible by  users with countrycode GB or "".
      this.enableLinkForUkUser(userdata);
    })

    this.getCurrentYear();

    this.authService.userFlags
    .pipe(takeUntil(this.destroy$))
    .subscribe(flags => {
      this.userFlags = flags
    })
  }

  ngOnInit(): void {
    this.getAccounsURLs();
  }

  getCurrentYear() {
    this.currentYear = (new Date()).getFullYear();
  }
  // enable donate link, shop products link, as it is only accesible by  users with countrycode GB or "".
  enableLinkForUkUser(userdata){
    if( userdata && (userdata.userCountryCode === "GB" ||
    !this.authService.currentUserValue.userCountryCode ||
    this.authService.currentUserValue.userCountryCode == "")){
      let _isMac =  /(iPhone|iPod|iPad)/i.test(navigator.platform);
      this.showDonationFooter = !_isMac;
      this.showShopProductsFooter = true;
    }

  }

  onToggle(val: string){
    switch(val) {
      case 'acc': this.toggleObj.account = !this.toggleObj.account;break;
      case 'act': this.toggleObj.action = !this.toggleObj.action;break;
      case 'red': this.toggleObj.readMore = !this.toggleObj.readMore;break;
      case 'leg': this.toggleObj.legal = !this.toggleObj.legal;break;
    }
  }

  getAccounsURLs() {
    var isSsoUser = this.authService.getSsoUser();
    var account = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=account`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/account`,
      activity = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=activity`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/activity`,
      details = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=details`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/details`,
      orders = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=orders`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/orders`,
      addresses = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=addresses`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/addresses`,
      preferences = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=preferences`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/preferences`,
      membership = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=membership`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/membership`,
      payments = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=payments`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/payments`,
      profile = isSsoUser
        ? `${this.ssoAccountsBaseUrl}&redirectUrl=profile`
        : `${location.origin}/login?redirect_uri=${this.accountsBaseURL}/profile`;

    this.accountsUrl = {
      account,
      activity,
      details,
      orders,
      addresses,
      preferences,
      membership,
      payments,

      profile,
    };
  }

  goToShopGiftCards() {
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    let hrefTarget = linkTargetViaGoNative()
    let redirectTo = `${getWPShopDomain()}/product-category/gift-card/`;
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }

    if(hrefTarget === '_blank') {
      this.load = false;
    }
    let shopUrl = `${getWPShopDomain()}/?redirect_to=${encodeURIComponent(redirectTo)}`
    window.open(shopUrl, hrefTarget);   
  }

  goToShopProducts() {
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    let hrefTarget = linkTargetViaGoNative()
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }
    if(hrefTarget === '_blank') {
      this.load = false;
    }
    let shopUrl = getWPShopDomain();
    window.open(shopUrl, hrefTarget);  
  }

  goToExternalLink(event: any, path: string) {
    event.preventDefault();
    this.load = true;

    // set token in the cookie
    if(this.authService.getLoginSession()?.token && this.authService.getLoginSession()?.refreshToken) {
      setEPCookie('access_token', encryptToken(this.authService.getLoginSession()?.token));
      setEPCookie('refresh_token', encryptToken(this.authService.getLoginSession()?.refreshToken));
    }

    let hrefTarget = linkTargetViaGoNative()
    let redirectTo = `${getWPShopDomain()}/${path}/`;
    //if the redirect doesnot happen somehow, setting a timeout so a user doesnot see loading screen infinitely
    if(hrefTarget === '_self') {
      setTimeout(() => {
        this.load = false 
      }, 30000)
    }
    
    if(hrefTarget === '_blank') {
      this.load = false;
    }
    let shopUrl = `${getWPShopDomain()}/?redirect_to=${encodeURIComponent(redirectTo)}`
    window.open(shopUrl, hrefTarget);
  }
}
