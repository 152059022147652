import { Directive, Input, ElementRef, SimpleChanges } from '@angular/core';
import { UserPermissionService } from '../service/user-permission.service';

@Directive({
  selector: '[appUiAccess]'
})
export class UiAccessDirective {

  @Input('appUiAccess') uiAccessData: any;
  authorities = null;
  constructor(
    private elementRef: ElementRef,
    private userPermissionService: UserPermissionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const {
      uiAccessData: {
        currentValue: { currentUser, userFlags, accessAuthorities },
      },
    } = changes;
    this.authorities = accessAuthorities;
    if(currentUser && currentUser.setUpCompleted) {
      // set userdata in UserPermissionService so that the data can be used to check permissions of the logged-in user
      this.userPermissionService.setCurrentUser(currentUser, userFlags);
      this.checkAccess();
    } 
  }

  ngOnInit() {
    let currentUser = this.uiAccessData.currentUser;
    let userFlags = this.uiAccessData.userFlags;
    this.authorities = this.uiAccessData.accessAuthorities;

    if(currentUser && currentUser.setUpCompleted) {
      // set userdata in UserPermissionService so that the data can be used to check permissions of the logged-in user
      this.userPermissionService.setCurrentUser(currentUser, userFlags);
      this.checkAccess();
    }    
  }

  checkAccess() {
    if(this.authorities) {
      this.elementRef.nativeElement.style.display = "block"; // display on initial phase
      /**
       * check the menu that has hideIfDisableUserUI is true
       * if true then check if the logged-in user's disableUserUI is true
       * if both true then hide the menu
       * else check other permissions passed in the menus
       */
      var hideMenu = this.authorities.hideIfDisableUserUI || null;
      if(hideMenu && this.userPermissionService.checkDisableUI()) {
        this.elementRef.nativeElement.style.display = "none";
      } else {
        
        var roles = this.authorities.withRoles || null;
        var anyPermissions = this.authorities.withAny || null;
        var withPermissions = this.authorities.withPermissions || null;
        if(anyPermissions) {
          var permissionsToCheck = anyPermissions.split(','),
          isPermitted = this.userPermissionService.hasPermissionsAny(permissionsToCheck);
          if(!isPermitted) {
            this.elementRef.nativeElement.style.display = "none";
          }
        }

        if(withPermissions) {
          var permissionsToCheck = withPermissions.split(','),
          isPermitted = this.userPermissionService.hasPermissions(permissionsToCheck);
          if(!isPermitted) {
            this.elementRef.nativeElement.style.display = "none";
          }
        }

        if(roles) {
          let isPermitted = this.userPermissionService.hasRoles(roles);
          if(!isPermitted) {
            this.elementRef.nativeElement.style.display = "none";
          }
        }
      }
    }
  }
}
