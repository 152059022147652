<div *ngIf="!load else loading;">
    <div class="header-wrapper" *ngIf="!gdprAccepted">
        <div class="header-container">
            <app-header></app-header>
        </div>
    </div>

    <div class="body-wrapper" *ngIf="!gdprAccepted">
        <div class="body-container">
            <app-sidebar></app-sidebar>
            <section class="main-content">
                <div class="section-body px-md-5 px-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="card border-apply terms-conditions mt-4">
                                <div class="card-header text-center mt-md-2 mb-2">
                                    <h3 class="page-title">{{ 'Set Permissions' | translate }}</h3>
                                </div>
                                <div class="card-body">

                                    <form class="setting-form mt-2" [formGroup]="gdprForm" (ngSubmit)="onSubmit()">
                                        <h5>{{ 'Terms & Conditions' | translate }} <span class="text-danger">*</span></h5>
                                        <div class="row mb-3">
                                            <label class="col-10 col-form-label" [innerHTML]="tncText">
                                                
                                            </label>
                                            <div class="col-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="tncAccepted" formControlName="tncAccepted" [ngClass]="{ 'is-invalid' : !gdprForm.get('tncAccepted').valid && (gdprForm.get('tncAccepted').touched || gdprForm.get('tncAccepted').dirty) && gdprForm.get('tncAccepted').errors }">
                                                    <label class="custom-control-label" for="tncAccepted"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <small class="form-text text-danger" *ngIf="!gdprForm.get('tncAccepted').valid && (gdprForm.get('tncAccepted').touched || gdprForm.get('tncAccepted').dirty)">You must accept the terms and conditions.</small>
                                        <h5>{{ 'Communication Preferences' | translate }}</h5>
                                        <label>
                                            {{ "We\'d like to keep in touch & occasionally send you information about special offers and new ways to earn Epoints and rewards." | translate }}
                                        </label>
                                        <div class="row mb-3">
                                            <label class="col-10 col-form-label">
                                                {{ 'I agree to receive promotional messages from Epoints by email.' | translate }}
                                            </label>
                                            <div class="col-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="marketingPrefEmail" formControlName="marketingPrefEmail">
                                                    <label class="custom-control-label" for="marketingPrefEmail"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <label class="col-10 col-form-label">
                                                {{ 'I agree to receive promotional messages from Epoints by SMS.' | translate }}
                                            </label>
                                            <div class="col-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="marketingPrefSMS" formControlName="marketingPrefSMS">
                                                    <label class="custom-control-label" for="marketingPrefSMS"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <span [innerHTML]="privacyText"></span>

                                            </div>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-12 mb-3">
                                                <button class="btn btn-primary mr-3" [disabled]="!gdprForm.valid || !gdprForm.get('tncAccepted').value || submitLoading">{{ 'Save' | translate }}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="footer-wrapper" *ngIf="!gdprAccepted">
        <div class="footer-container">
            <app-footer></app-footer>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="fullscreen-loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
        </div>
        <!-- <p>Redirecting ...</p> -->
    </div>
</ng-template>
