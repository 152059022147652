import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination-section',
  templateUrl: './pagination-section.component.html',
  styleUrls: ['./pagination-section.component.sass']
})
export class PaginationSectionComponent implements OnInit {
  limitOptions = [
    {
      key: '10',
      value: 10
    },
    {
      key: '25',
      value: 25
    },
    {
      key: '50',
      value: 50
    }
  ];

  @Input('paginationItems') pagination;
  @Output() handlePageChanged = new EventEmitter();
  @Output() handlePageSizeChanged = new EventEmitter();
  @Input('hidePageSize') hidePageSize = false;
  constructor() { }

  ngOnInit(): void {
  }

  onPageChanged(event) {
    this.handlePageChanged.emit(event);
  }

  onPageSizeChanged(event) {
    this.handlePageSizeChanged.emit(event);
  }

}
