<div class="login-wrapper">
    <header>
        <!-- Header content -->
    </header>
    <nav>
        <!-- Navigation content -->
    </nav>
    <section class="login">
        <div class="resetpassword-dialog">
            <form class="form" [formGroup]="forgotPasswordForm" (ngSubmit)=" onResetSubmit()">
                <div class="logo text-center mb-4" aria-hidden="true">
                    <img width="180px" alt="eachperson-logo"
                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" />
                </div>
                <h5 class="text-center mb-1">Forgot Your Password?</h5>
                <p class="font-weight-normal text-center">Enter your email below and we will send
                    a message to reset your password</p>
                <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>
                <label for="signInForgetPassword" class="label-customizable pb-0 mb-1">Email</label>
                <input id="signInForgetPassword" (ngModelChange)="errorMessage = null" name="text" type="text" class="form-control inputField-customizable"
                    placeholder="Email" formControlName="email"
                    [ngClass]="{ 'is-invalid' : !f.email.valid && (f.email.touched || f.email.dirty)}" title="Enter your email address">
                <small class="form-text text-danger" *ngIf="!f.email.valid && (f.email.touched || f.email.dirty)">Email
                    is Required.</small>
                <button name="signInSubmitButton" type="Submit" class="btn btn-primary resetButton-customizable"
                    aria-label="submit" [disabled]="!forgotPasswordForm.valid || disableBtn" title="Reset my password">
                    Reset my password
                </button>
                <div class="logo text-center mt-4" aria-hidden="true">
                    <img width="80px" alt="eachperson-logo"
                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png"
                        class="logo-customise" /><span> @ Each Person {{ currentYear }}</span>
                </div>
                <div class="logo text-center" aria-hidden="true">
                    Powered by <img width="38px" alt="epoints-logo"
                        src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
                </div>
            </form>
        </div>
    </section>
    <footer>
        <!-- Footer content -->
    </footer>
</div>