import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { SocialAspect, Reaction, ReactionPayload } from '../../core/models/social-aspect.model'
import { SocialAspectService } from "../../core/service/socialAspect.service"
import { staticReactions } from '../social-aspects-reactions';
import { AuthService } from 'src/app/core/service/auth.service';
import { encodeEmojisInMessage } from 'src/app/core/_helpers/validators/chars.validator';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import { sliceArray } from 'src/app/core/_helpers/environment-variables';
import { sortArrayByDateInDescendingOrder } from 'src/app/core/_helpers/data-sorter';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { extractTsText, groupByReactionTypeFunc } from 'src/app/core/_helpers/global-functions';
import { ActivatedRoute } from '@angular/router';

// library being used - https://flxng.codeeve.com/#/mentions
// https://snyk.io/advisor/npm-package/@flxng/mentions
// css - https://github.com/seidme/flxng/blob/master/projects/mentions/src/lib/text-input-highlight/text-input-highlight.component.scss
// other libraries: angular-mentions
// https://github.com/seidme/flxng/issues/32
interface User {
  id: number;
  name: string;
}
@Component({
  selector: 'app-social-aspects',
  templateUrl: './social-aspects.component.html',
  styleUrls: ['./social-aspects.component.scss']
})
export class SocialAspectsComponent implements OnInit {
  @ViewChild('reactionsModal') reactionsModal: TemplateRef<any>;
  @Input() socialAspectData: SocialAspect ;
  @Input() ecardId: string;
  @Input() updatedSocialAspectOfSelectedEcard: any = null; // comes from mobile view
  @Output() displaySocialAspectMobileView = new EventEmitter<any>();
  @Output() updateEcardSocialAspectOnBothTabs = new EventEmitter<any>();
  @Input() showInputBox: boolean = false
  inputValue: string = "";
  defaultReactions = staticReactions;
  showDefaultReactions: boolean = false;
  userReactionIcon: string | null;
  userReaction: Reaction | null;
  shouldTriggerClick = true; // Initially set to true
  clickTimeout: any;
  isMouseOverPopup: boolean = false;
  private timeoutId: any;
  matchingReaction: any;
  socialAspectTemplateData : any  ;
  fetchingSocialAspects: boolean = false;

  // data to be shared with app-comments
  shareableDataForComments = {
    showReply: {
      show: false,
      commentId: null
    },
    totalPageReplies: null
  }
  
  groupedReactions: {};
  commentPagination = {
    commentsPerPage: 4,
    currentPage: 1
  }
  groupByReactionType = [];

  displayComments = []

  private longPressTimeout: any; // reaction long press on mobile
  private longPressDuration: number = 1000; // milliseconds - reaction long press on mobile

  commentIdFromUrl: number = null; // used to scroll to the specific comment
  replyIdFromUrl: number = null;

  commentPlaceholder: string = extractTsText('Add a Comment...');
  
  constructor(
    private sociaAspectService: SocialAspectService,
    public authService: AuthService,
    private toastr: ToastrCustomMessageService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {

    // when clicked outside of the popup, then hide popup
    document.addEventListener('click', (event) => {
      // Check if the clicked element is outside the popup
      const clickedElement = event.target as HTMLElement;
      if (!clickedElement.closest('.like-popup')) {
        this.hideReactionPopup();
      }
    });
  }

  ngOnInit(): void {
    
  }
  ngOnChanges() {
    // updatedSocialAspectOfSelectedEcard comes from mobile view, or from nav change
    if(this.updatedSocialAspectOfSelectedEcard && this.updatedSocialAspectOfSelectedEcard?.ecard === this.ecardId) {
      this.socialAspectData = this.updatedSocialAspectOfSelectedEcard?.socialData
    }
    this.formatUserReactionFromAPI()

    //comment slicing for desktop show only 4
    this.displayComments = []
    this.socialAspectData.comments = sortArrayByDateInDescendingOrder(this.socialAspectData.comments, 'createdAt');
    this.displayComments = this.socialAspectData.comments.slice(0, this.commentPagination.commentsPerPage);

    // check the query parameter and apply the logic to scroll down to specific comment in a desktop view
    this.route.queryParams.subscribe(
      params => {
        this.commentIdFromUrl = params['commentId'] || null;
        this.replyIdFromUrl = params['replyId'] || null;
        if(this.commentIdFromUrl) {
          setTimeout(() => {
            this.scrollToSpecificComment();
          }, 50)
        }
      }
    )
    
  }

  // this is no scroll down to the specific comment in the desktop view
  scrollToSpecificComment() {
    const commentElement = document.getElementById(`comment_display_${this.commentIdFromUrl}`);
    if (commentElement) {
      commentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      if(this.commentIdFromUrl && !this.replyIdFromUrl) {
        commentElement.classList.add('focused-content');
      }
    
    // if the comment is not found in the displayed view, then apply view more until the comment is not found
    } else if(this.displayComments.length < this.socialAspectData?.commentCount) {
      this.viewMore();

      setTimeout(() => {
        this.scrollToSpecificComment();
      }, 50);
    }
  }
  
  openReactionModal() {
    this.modalService.open(this.reactionsModal, {centered: true, windowClass:'reactionList-modal'});
  }

  sliceComments(page?) {   
    let comments = sliceArray(this.socialAspectData?.comments, this.commentPagination.commentsPerPage, page || this.commentPagination.currentPage)
    comments.forEach(eachComment => {
      this.displayComments.push({...eachComment})
    });
  }

  viewMore() {
    this.commentPagination.currentPage = this.commentPagination.currentPage + 1
    this.sliceComments()
    this.checkForViewLess();
  }
  viewLessbool = false

  checkForViewLess() {
    if (this.socialAspectData?.commentCount < this.commentPagination.currentPage * this.commentPagination.commentsPerPage) {
      this.viewLessbool = true;
    } else {
      this.viewLessbool = false;
    }
  }
  

  viewLess() {
    this.commentPagination.currentPage = 1
    let comments = sliceArray(this.socialAspectData?.comments, this.commentPagination.commentsPerPage, this.commentPagination.currentPage)
    this.displayComments = comments
    this.viewLessbool = false;
  }

  // formatUserReaction
  formatUserReactionFromAPI() {
    this.groupByReactionType = null;

    this.userReactionIcon = this.getUserReactionIcon(this.socialAspectData, this.authService.currentUserValue.id, staticReactions);
    this.groupByReactionType = groupByReactionTypeFunc(this.socialAspectData.reactions);
  }


  // this is a method (an event) emitted from the child components after some actions
  getIndividualSocailAspect(event){
    if(event?.showReply){
      this.shareableDataForComments.showReply = {
        show: event?.showReply,
        commentId: event?.commentId
      }

    }
    if(event?.callApi) {
      this.fetchSocialAspectsForEachEcard()
    }

    if(event?.pagesToShowReplies) {
      this.shareableDataForComments.totalPageReplies = event?.pagesToShowReplies
    }
  }
  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }
  fetchSocialAspectsForEachEcard() {
    this.sociaAspectService.getIndividualCardSocialAspectData(this.ecardId)
    .pipe(finalize(() => {
      this.fetchingSocialAspects = false
    }))
    .subscribe(res => {
      this.socialAspectData =this.deepClone(res);

      /**
       * this is done to update the changed social data of the same ecard from one tab to aother.
       */
      let emitData = {
        socialAspectData: this.socialAspectData,
        fetchingSocialAspects: this.fetchingSocialAspects,
        ecardId: this.ecardId,
      }
      this.updateEcardSocialAspectOnBothTabs.emit(emitData)
    },error => {},
    ()=>{
      // keep the old state as it is
      // if currentPage is > 1, show all the comments upto the currentPage that were previously shown
      // that is, if the comments were shown upto page 3, then show all the comments upto page 3 after every action
      if(this.commentPagination.currentPage > 1) {
        this.displayComments = []
        // enable this if to be sorted by createdAt date
        this.socialAspectData.comments = sortArrayByDateInDescendingOrder(this.socialAspectData?.comments, 'createdAt');
        for (let i = 0; i < this.commentPagination.currentPage; i++) {
          this.sliceComments(i + 1)
        }
        this.formatUserReactionFromAPI()
      } else {
        this.socialAspectData.comments = sortArrayByDateInDescendingOrder(this.socialAspectData.comments, 'createdAt');
        this.displayComments = this.socialAspectData.comments.slice(0, this.commentPagination.commentsPerPage);
        this.formatUserReactionFromAPI()
      }      
    })    
  }  

  // event emitted from mention component
  saveComment(eventData) {
    let data = eventData?.data,
    mentions = eventData?.mentions;

    if (this.fetchingSocialAspects || !data) {
      return false
    }

    this.fetchingSocialAspects = true

    let mentionedUsersId = []
    if(mentions?.length > 0) {
      mentions.forEach((eachMention: any) => {
        let eachUser = {
          userId: eachMention?.id,
          name: eachMention?.name
        }
        mentionedUsersId.push(eachUser)
      })
    }

    let commentData = {
      comment: encodeEmojisInMessage(data),
      userId: `${this.authService.currentUserValue.id}`,
      eCardId: `${this.ecardId}`,
      parentCommentId: null,
      mentionedUsersId
    }
    this.sociaAspectService.saveComment(commentData).subscribe(res => {
    },
    error => {
      this.fetchingSocialAspects = false // if error
      this.toastr.error('Something went wrong. Please try again!')
    },
    () => {
      this.fetchSocialAspectsForEachEcard()
    })
  }

  reactionPending: boolean = false
  addReaction(type: string) {
    if (this.fetchingSocialAspects) {
      return false
    }
    this.fetchingSocialAspects = true
    let reactionPayload: ReactionPayload = {
      commentId: null,
      eCardId: `${this.ecardId}`,
      userId: `${this.authService.currentUserValue.id}`,
      reaction: type
    }

    // on selecting reaction hide reaction popup
    this.hideReactionPopup()
    
    if(this.userReaction){
      //performing the same reaction with the type means, revert the reaction
      if(this.userReaction.reactionType === type){
        let _userReaction = this.userReaction
        // commmented out the optimistic update as the div is disabled until API response completed
        // this.userReaction = null;
        // this.userReactionIcon = null 
           
        this.sociaAspectService.deleteReaction(`${_userReaction?.id}`)
        .subscribe(Response => {
          this.matchingReaction = null;
        },error => {
          this.fetchingSocialAspects = false // if error
          this.toastr.error("Could not complete the request, Please try again later!")
          //reverting the state of userReaction
          this.userReaction = _userReaction;
        }, () => {
          this.fetchSocialAspectsForEachEcard()
        })
      }else {
        //optimistic update
        let _userReaction = this.userReaction
        const now = new Date();
        const datetimeSignature = now.toISOString();
        // commmented out the optimistic update as the div is disabled until API response completed
        // this.userReaction = {
        //   "id":+`${_userReaction.id}`,
        //   "reactionType": `${reactionPayload.reaction}`,
        //   "reactedById": `${this.authService.currentUserValue.id}`,
        //   "reactedByName": `${this.authService.currentUserValue.name}`,
        //   "createdAt": `${_userReaction?.createdAt}`,
        //   "updatedAt": `${datetimeSignature}`
        // }
        this.formatUserReactionFromAPI()
        this.sociaAspectService.updateReaction(`${this.userReaction.id}`,reactionPayload)
        .subscribe(
          res => {},
          error => {
            this.fetchingSocialAspects = false // if error
            this.toastr.error('Something went wrong, please try again later!')
            //reverting the state of userReaction
            this.userReaction = _userReaction 
          },             
          () => {
            this.fetchSocialAspectsForEachEcard()
          }
        )
      }
    }else{
      //optimistic update
      let _userReaction = this.userReaction
      const now = new Date();
      const datetimeSignature = now.toISOString();
      this.sociaAspectService.saveReaction(reactionPayload)
      .subscribe(
        res => {},
        error => {
          this.fetchingSocialAspects = false // if error
          this.toastr.error('Something went wrong, please try again later!')
          //reverting the state of userReaction
          this.userReaction = _userReaction 
        },
        () => {
          this.fetchSocialAspectsForEachEcard()
        }
      )
    }
  }  

  // for mobile view - start
  onTouchStart(event: TouchEvent) {
    this.longPressTimeout = setTimeout(() => {
      this.showReactionOptions(true)
    }, this.longPressDuration);
  }

  onTouchEnd(event: TouchEvent) {
    clearTimeout(this.longPressTimeout);
  }

  onContextMenu(event: Event) {
    event.preventDefault(); // Prevent the context menu from appearing on long press
  }

  hideReactionPopup() {
    this.showDefaultReactions = false;
  }
  // for mobile view - end

  showReactionOptions(show: boolean) {
    if (show) {
      this.showDefaultReactions = true;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    } else {
      this.timeoutId = setTimeout(() => {
        if (!this.isMouseOverPopup) {
          this.showDefaultReactions = false;
        }
      }, 300); // delay of 300ms
    }
  }

  onMouseEnterPopup() {
    this.isMouseOverPopup = true;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  onMouseLeavePopup() {
    this.isMouseOverPopup = false;
    this.showReactionOptions(false);
  }

  getUserReactionIcon(socialAspectData, currentUserID, defaultReactions) {
    // Find the user's reaction

    // since no optimistic value is used, reset every data 
    // so that new data will be replaced after fetching social aspect API.
    // this.userReaction = this.userReaction || socialAspectData.reactions.find(reaction => reaction.reactedById === currentUserID); // when optimistic update is done 
    this.userReaction = socialAspectData.reactions.find(reaction => reaction.reactedById === currentUserID);
    // If a user reaction was found, find the corresponding icon
    if (this.userReaction) {
         this.matchingReaction = defaultReactions.find(reaction => reaction.name === this.userReaction.reactionType);
        return this.matchingReaction ? this.matchingReaction.name==="LIKE" ? "https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/socialAspect/liked.svg" : this.matchingReaction.icon : null;
    }
    // If no user reaction was found, return null
    return null;
  }

  trackById(index: number, item) {
    return item.id
  }

  enableMobileView: boolean = window.innerWidth <= 767 ? true : false
  @HostListener("window:resize")  
  windowResizecall() {
    if (window.innerWidth <= 767) {
      this.enableMobileView = true
    } else {
      this.enableMobileView = false
    }
  }

  // Call the method when a 'Comments' button is clicked, for example
  showDesktopOrMobileView() {
    if(!this.enableMobileView) {
      return false
    }
    let emitData = {
      showMobileView: this.enableMobileView && this.showInputBox ? true : false,
      socialAspectData: this.socialAspectData,
      fetchingSocialAspects: this.fetchingSocialAspects,
      ecardId: this.ecardId,
      componentType: 'SocialAspectComments'
    }
    this.displaySocialAspectMobileView.emit(emitData)
  }

  updateSocialFetchStatus(event) {
    this.fetchingSocialAspects = false
    if(event?.socialFetchStatus) {
      this.fetchingSocialAspects = event.socialFetchStatus
    }

    this.shareableDataForComments.showReply = {
      show: event?.showReply,
      commentId: event?.commentId
    }

    if(event?.pagesToShowReplies) {
      this.shareableDataForComments.totalPageReplies = event?.pagesToShowReplies
    }
  }

  

}
