import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { extractTsText } from '../core/_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../core/service/language.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '../core/service/destroy.service';

@Component({
  selector: 'app-gp-on-demand',
  templateUrl: './gp-on-demand.component.html',
  styleUrls: ['./gp-on-demand.component.sass'],
  providers: [DestroyService]
})
export class GpOnDemandComponent implements OnInit {
  viewAll: boolean = false;
  familyCoverText: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private languageService: LanguageService,
    private destroy$: DestroyService
  ) { }

  ngOnInit(): void {
    // to make this.translate.instant or this.translate.get to work on initial load, watch for language update observable
    this.languageService.languageUpdated
    .pipe(takeUntil(this.destroy$))
    .subscribe((updated) => {
      // since style="" attribute was not taken by innerHTML, had to sanitize HTML
      this.familyCoverText = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(extractTsText('Extend coverage to your entire household, your family are included if they reside in the same property as <span class="mobile-break"> you.</span> We believe in sharing these benefits with your entire adult family to ensure the Wellbeing and Health of all of your family')));
    });
  }

  viewAllFaqs() {
    this.viewAll = true;
  }

  viewLessFaqs() {
    this.viewAll = false;
  }

}
