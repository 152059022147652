import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

interface Benefit {
  id: number;
  createdDate: string;
  title: string;
  iconURL: string;
  previewText: string;
  longDescription: string;
  buttonText?: string;
  buttonURL?: string;
  filterValue: string;
  partnerId: number;
}

@Component({
  selector: "app-benefits-expanded",
  templateUrl: "./benefits-expanded.component.html",
  styleUrls: ["./benefits-expanded.component.sass"],
})
export class BenefitsExpandedComponent implements OnInit {
  benefitsExpandedLoading: boolean = false;
  benefit: Benefit | undefined;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    this.benefitsExpandedLoading = true;
    this.route.paramMap.subscribe((params) => {
      const benefitId = Number(params.get("id"));
      this.fetchBenefitDetails(benefitId).subscribe((data: Benefit) => {
        this.benefitsExpandedLoading = false;
        this.benefit = data;
      });
    });
  }

  // Fetch benefit details from the API
  fetchBenefitDetails(benefitId: number): Observable<Benefit> {
    const apiUrl = `api/benefits/${benefitId}`;
    return this.http.get<Benefit>(apiUrl);
  }
}
