import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {linkTargetViaGoNative} from "../_helpers/global-functions";

@Injectable({
  providedIn: 'root'
})
export class WellbeingHubService {

  constructor(private http: HttpClient) { }

  fetchWellbeingHubData(params){
    return this.http.get<any>(`${ environment.apiUrl }/wellbeing/publish/content`,{params});
  }

  insideGoNativeWrapper(){
    return linkTargetViaGoNative()
  }
}
