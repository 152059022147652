import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(
    private httpBackend: HttpBackend
  ) { }

  forgotPassword(data) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestOptions = { headers };
    return httpWithoutInterceptor.post<any>(`${environment.apiUrl}/user-authentication/forgot-password`, data, requestOptions)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        var errorArray = err.error && err.error.errors ? err.error.errors[0].message : null;
        const error =  errorArray || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  resetPassword(data) {
    let httpWithoutInterceptor = new HttpClient(this.httpBackend)
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestOptions = { headers };
    return httpWithoutInterceptor.post<any>(`${environment.apiUrl}/user-authentication/reset-forgotten-password`, data, requestOptions)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        var errorArray = err.error && err.error.errors ? err.error.errors[0].message : null;
        const error =  errorArray || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
