import { Component, Input, OnInit, ViewChild, Output, EventEmitter, SimpleChanges, TemplateRef  } from '@angular/core';
import { Comment, Reaction, ReactionPayload } from 'src/app/core/models/social-aspect.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { SocialAspectService } from 'src/app/core/service/socialAspect.service';
import { ToastrCustomMessageService } from 'src/app/core/service/toastr-custom-message.service';
import { encodeEmojisInMessage, unicodeToChar } from 'src/app/core/_helpers/validators/chars.validator';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MentionService } from '../mentions/mention.service';
import { UserService } from 'src/app/core/service/user.service';
import { extractTsText } from 'src/app/core/_helpers/global-functions';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/core/service/dashboard.service';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.sass']
})
export class ReplyComponent implements OnInit {
  @ViewChild('editDeleteModal') editDeleteModal: TemplateRef<any>;
  @ViewChild('reactionsModal') reactionsModal: TemplateRef<any>;
  editDeleteModalRef: NgbModalRef;
  reactionsModalRef: NgbModalRef;
  @Input() replies?: Comment | null;
  @Input() ecardId: string;
  @Input() commentId: string;
  @Input() isMobileView: boolean = false;
  @Input() fetchingSocialAspects: boolean = false;
  canUserEditOrDelete: boolean = false;
  userReactionInReply: Reaction | null;
  editReplyFormControl: any;
  editReplyData: string;
  showEditInput: boolean = false;
  formattedReply: string = '';

  @Output() callGetIndividualCardSocialAspectDataAPI = new EventEmitter<any>();
  @Output() updateSocialFetchingStatus = new EventEmitter<any>();
  emitvalues = {
    "callApi": true,
    "showReply": true,
    commentId: null,
    socialFetchStatus: false
  }

  replyPlaceholder: string = extractTsText('Add a Reply...');

  constructor(
    private toastr: ToastrCustomMessageService,
    private socialAspectService: SocialAspectService,
    public authService: AuthService,
    private modalService: NgbModal,
    private mentionService: MentionService,
    public userService: UserService,
    private translate: TranslateService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
  }

  dismissModals() {
    if(this.editDeleteModalRef) {
      this.editDeleteModalRef.dismiss('close modal');
    }

    if(this.reactionsModalRef) {
      this.reactionsModalRef.dismiss('close modal');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.replies = { ...changes.replies.currentValue };
    // get commentText with highlighted mentions
    this.replies.commentText = unicodeToChar(this.replies.commentText)
    this.formattedReply = this.mentionService.getMentionedText(this.replies?.commentText, this.replies?.mentionedUsersInComment) || this.replies.commentText.replace(/\n/g, '<br>');

    this.canUserEditOrDeleteFunc();
    this.getUserReactionInReply(this.replies, this.authService.currentUserValue.id)
  }

  canUserEditOrDeleteFunc() {
    if (this.authService.currentUserValue.id === this.replies.commentedById) {
      this.canUserEditOrDelete = true
    }
  }
  onEdit() {
    if(this.isMobileView) {
      this.modalService.dismissAll()
    }
    this.showEditInput = true
  }
  updateReply(eventData) {
    let data = eventData?.data,
    mentions = eventData?.mentions;
    // if on edit, the text is not changed by typing, then do not send data
    if (this.fetchingSocialAspects || !data) {
      return false
    }

    this.emitvalues.commentId = this.commentId
    this.emitvalues.showReply = true
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchingStatus.emit(this.emitvalues)

    let mentionedUsersId = []
    if(mentions?.length > 0) {
      mentions.forEach((eachMention: any) => {
        let eachUser = {
          userId: eachMention?.id,
          name: eachMention?.name
        }
        mentionedUsersId.push(eachUser)
      })
    }

    let replyData = {
      comment: encodeEmojisInMessage(data),
      userId: `${this.authService.currentUserValue.id}`,
      eCardId: `${this.ecardId}`,
      parentCommentId: null,
      mentionedUsersId
    }

    let _replyText = this.replies.commentText
    this.replies.commentText = unicodeToChar(replyData.comment)
    this.socialAspectService.updateComment(this.replies.id, replyData).subscribe(
      res => {
        this.showEditInput = false        
      },
      error => {
        //reverting the state of commentText
        this.replies.commentText = _replyText;
        this.updateSocialFetchingStatus.emit(null)
        this.toastr.error('Something went wrong. Please try again!')
      },
      () => {
        this.emitvalues.commentId = this.commentId
        this.emitvalues.showReply = true
        this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
      })
  }
  getUserReactionInReply(replyData, currentUserID) {
    // Find the user's reaction
    if (replyData) {
      this.userReactionInReply = replyData.reactions.find(reaction => reaction.reactedById === currentUserID);
      // const updatedReactions = replyData.reactions.map(reaction => {
      //   if (reaction.reactedById === currentUserID) {
      //     return { ...reaction, /* updated properties */ };
      //   }
      //   return reaction;
      // });
      // this.userReactionInReply = updatedReactions.length > 0 ? { ...updatedReactions[0] } : null
    }
  }

  likeReplyComment() {
    if (this.fetchingSocialAspects) {
      return false
    }

    this.emitvalues.commentId = this.commentId
    this.emitvalues.showReply = true
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchingStatus.emit(this.emitvalues)
    let reactionPayload: ReactionPayload = {
      commentId: `${this.replies.id}`,
      eCardId: `${this.ecardId}`,
      userId: `${this.authService.currentUserValue.id}`,
      reaction: 'LIKE',
    }

    if (this.userReactionInReply) {
      //IF THERE IS ALREADY USER REACTION IN COMMENTS, DELETE THE REACTION
      //optimistic update
      let _userReactionInReply = this.userReactionInReply;
      // commmented out the optimistic update as the div is disabled until API response completed
      // this.userReactionInReply = null;
      this.socialAspectService.deleteReaction(`${_userReactionInReply.id}`)
        .subscribe(
          res => { },
          error => {
            this.updateSocialFetchingStatus.emit(null)
            this.toastr.error('Something went wrong, please try again later!')
            //reverting the state of userReactionInComments
            this.userReactionInReply = _userReactionInReply;

          },
          () => {
            this.emitvalues.commentId = this.commentId
            this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
          }
        )
    } else {
      //optimistic update
      let _userReactionInReply = this.userReactionInReply;
      // commmented out the optimistic update as the div is disabled until API response completed
      // this.userReactionInReply = null;
      const now = new Date();
      const datetimeSignature = now.toISOString();

      //optimistic update
      // this.userReactionInReply = {
      //   "id": null,
      //   "reactionType": `${reactionPayload.reaction}`,
      //   "reactedById": `${this.authService.currentUserValue.id}`,
      //   "reactedByName": `${this.authService.currentUserValue.name}`,
      //   "createdAt": `${_userReactionInReply?.createdAt}`,
      //   "updatedAt": `${datetimeSignature}`
      // }
      this.socialAspectService.saveReaction(reactionPayload)
        .subscribe(
          res => { },
          error => {
            this.updateSocialFetchingStatus.emit(null)
            this.toastr.error('Something went wrong, please try again later!')
            //reverting the state of userReactionInComments
            this.userReactionInReply = _userReactionInReply;
          },
          () => {
            this.emitvalues.commentId = this.commentId
            this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
          }
        )
    }
  }

  removeReplyComment() {
    if(this.isMobileView) {
      this.modalService.dismissAll()
    }

    if(this.fetchingSocialAspects) {
      return false
    }

    this.emitvalues.commentId = this.commentId
    this.emitvalues.showReply = true
    this.emitvalues.socialFetchStatus = true
    this.updateSocialFetchingStatus.emit(this.emitvalues)
    const removeParentTemplate = Swal.mixin({
      customClass: {
        cancelButton: 'btn btn-secondary',
        confirmButton: 'btn btn-success',
        popup: 'social-alert-popup'
      },
      buttonsStyling: false
    })
    removeParentTemplate
      .fire({
        title: this.translate.instant(extractTsText(`Are You Sure You Want to Delete Your Reply?`)),
        // width: '1000px',
        // text: `You want to delete this template ?`,
        html: ` 
      <div class="custom-close-btn">
        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/cross-icon-black.png">  
      </div>
      <div class="comments-after-input desktop-only">
      <div class="card card-header comment-description bg-grey-socialaspect desktop-only mt-3">
        <div class="d-flex justify-content-between flex-column align-items-start p-0">
          <div class="name-container">
            <p class="font-16 font-weight-bold m-0 mb-1 text-left">${this.replies.commentedByName}</p>
            <p class="font-weight-normal font-16 mb-1 text-left">${this.formattedReply}</p>
          </div>
          <small class="font-weight-normal text-lightgrey font-10">${this.userService.dateAgo(this.replies.createdAt)}
          </small>
        </div>
      </div>
    </div>`,
        showCloseButton: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.translate.instant("Delete"),
        cancelButtonText: this.translate.instant('Cancel')
      })
      .then((result) => {
        if (result.value) {
          this.deleteReplyAction();
        } else {
          this.updateSocialFetchingStatus.emit(null);
        }
      });

    // listen to the click event of custom close button and fire close event of sweetalert
    const customButton = document.getElementsByClassName('custom-close-btn');
    
    customButton[0].addEventListener('click', function () {
      Swal.close()
    })
  }

  removeReplyForMobile() {
    this.modalService.dismissAll();
    if (this.fetchingSocialAspects) {
      return false;
    }

    this.emitvalues.commentId = this.commentId;
    this.emitvalues.showReply = true;
    this.emitvalues.socialFetchStatus = true;
    this.updateSocialFetchingStatus.emit(this.emitvalues);
    const removeParentTemplate = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-secondary",
        confirmButton: "btn btn-success",
        container: "socialaspect-mobile-delete-container",
        popup: "socialaspect-mobile-delete-popup",
      },
      buttonsStyling: false,
    });
    removeParentTemplate
      .fire({
        title: this.translate.instant(extractTsText(`Are You Sure You Want <br> to Delete Your Comment?`)),
        // width: '1000px',
        // text: `You want to delete this template ?`,
        html: ` 
      <div class="custom-close-btn">
        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/cross-icon-black.png">  
      </div>
      <div class="comments-after-input custom-modal">
      <div class="card card-header comment-description bg-lightgrey-socialaspect mt-2">
        <div class="d-flex justify-content-between flex-column align-items-start p-0">
          <div class="name-container">
            <p class="font-16 font-weight-bold m-0 mb-1 text-left">${this.replies.commentedByName}</p>
            <p class="font-weight-normal font-16 mb-1 text-left">${this.replies.commentText}</p>
          </div>
          <small class="font-weight-normal text-lightgrey font-10">${this.userService.dateAgo(this.replies.createdAt)}
          </small>
        </div>
      </div>
    </div>`,
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.translate.instant("Delete"),
        cancelButtonText: this.translate.instant('Cancel')
      })
      .then((result) => {
        if (result.value) {
          // hit delete API
          this.deleteReplyAction();
        } else {
          this.updateSocialFetchingStatus.emit(null);
        }
      });
    // listen to the click event of custom close button and fire close event of sweetalert
    const customButton = document.getElementsByClassName("custom-close-btn");

    customButton[0].addEventListener("click", function () {
      Swal.close();
    });
  }

  deleteReplyAction() {
    this.socialAspectService.deleteComment(this.replies.id).subscribe(
      response => {
        this.toastr.success('Deleted!');
      },
      error => {
        this.updateSocialFetchingStatus.emit(null)
        this.toastr.error('Unabled to delete the selected reply!');
      },
      () => {
        this.emitvalues.commentId = this.commentId
        this.emitvalues.showReply = true
        this.callGetIndividualCardSocialAspectDataAPI.emit(this.emitvalues)
      }
    )
  }

  // for mobile view
  openEditDeleteModal() {
    this.editDeleteModalRef = this.modalService.open(this.editDeleteModal, {centered: true, windowClass:'social-aspect-mobile'});
    this.dashboardService.registerOpenedModals(this.editDeleteModalRef);
  }

  cancelEdit(event) {
    this.showEditInput = event
  }

  openReactionModal() {
    this.editDeleteModalRef = this.modalService.open(this.reactionsModal, {centered: true, windowClass:'reactionList-modal'}); 
    this.dashboardService.registerOpenedModals(this.editDeleteModalRef); 
  }

}
