import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
import { takeUntil } from "rxjs/operators";
import { DestroyService } from "src/app/core/service/destroy.service";
import { User } from "src/app/core/models/user";
import { ToastrCustomMessageService } from "src/app/core/service/toastr-custom-message.service";
import Swal from "sweetalert2";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { BenefitsService } from "src/app/core/service/benefits.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { extractTsText } from "src/app/core/_helpers/global-functions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-benefits-form",
  templateUrl: "./benefits-form.component.html",
  styleUrls: ["./benefits-form.component.sass"],
})
export class BenefitsFormComponent implements OnInit {
  benefitsForm: FormGroup;
  currentUser: User;
  buttonUrlTooltip: string =
    extractTsText("Enter the full URL where the button should direct users.");
  buttonLabelTooltip: string =
    extractTsText("This is the text that will appear on the button.");
  iconHelperTooltip: string =
    extractTsText("Recommended size: 67x53 pixels for the best fit.");
  filterLabelTooltip: string = extractTsText("Add or select a filter to tag this benefit.");
  filterInfoTooltip: string =
    extractTsText("If you delete this filter all benefits tagged will become untagged.");
  isEditMode: boolean = false;
  benefitId: number | null = null;
  previewData: any = {};
  disableFileExplorer: boolean = false;
  benefitFilters: any[] = [];
  benefitFiltersInput$ = new Subject<string>();
  loading: boolean = false;
  showNewFilterInput: boolean = false;
  newFilterPlaceholder: string = extractTsText("Select a benefit filter");
  imageUploading: boolean = false;
  files: File[] = [];
  formSubmitting: boolean = false;
  @ViewChild("filterSelect") filterSelect: NgSelectComponent;
  @ViewChild("mobilePreviewModal") mobilePreviewModal: ElementRef;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrCustomMessageService,
    public authService: AuthService,
    private readonly destroy$: DestroyService,
    private benefitsService: BenefitsService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userdata: any) => {
        this.currentUser = userdata;
      });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id) {
        this.isEditMode = true;
        this.benefitId = Number(id);
        this.loadBenefit(this.benefitId);
      }
    });

    this.initForm();
    this.loadBenefitFilters();
  }

  initForm(): void {
    this.benefitsForm = this.fb.group({
      title: ["", [Validators.required, Validators.maxLength(30)]],
      iconURL: ["", Validators.required],
      previewText: ["", [Validators.required, Validators.maxLength(100)]],
      longDescription: ["", [Validators.required, Validators.maxLength(1000)]],
      buttonText: ["", [Validators.maxLength(30)]],
      buttonURL: ["", [this.urlValidator]],
      filterValue: [""],
      newFilterValue: [""],
      partnerId: [this.currentUser?.partnerId, Validators.required],
    }, { validator: this.buttonTextButtonUrlValidator() });

    this.updateFilterValidity();
  }

  buttonTextButtonUrlValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const buttonText = formGroup.get('buttonText');
      const buttonUrl = formGroup.get('buttonURL');
  
      if (buttonText && buttonUrl) {
        if (!buttonText.value && buttonUrl.value) {
          buttonText.setErrors({ buttonTextRequired: true });
          return { buttonTextRequired: true };
        }
        if (buttonText.value && !buttonUrl.value) {
          buttonUrl.setErrors({ buttonUrlRequired: true })
          return { buttonUrlRequired: true };
        }
        if(!buttonText.value && !buttonUrl.value) {
          buttonText.setErrors(null);
          buttonUrl.setErrors(null);
          return null;
        }
      }
  
      return null;
    };
  }

  urlValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    if (control.value && !urlPattern.test(control.value)) {
      return { invalidUrl: true };
    }
    return null;
  }

  updateFilterValidity() {
    if (this.showNewFilterInput) {
      this.benefitsForm.get("filterValue").setValidators(null);
      this.benefitsForm
        .get("newFilterValue")
        .setValidators([Validators.required]);
    } else {
      this.benefitsForm.get("filterValue").setValidators([Validators.required]);
      this.benefitsForm.get("newFilterValue").setValidators(null);
    }

    this.benefitsForm.get("filterValue").updateValueAndValidity();
    this.benefitsForm.get("newFilterValue").updateValueAndValidity();
  }

  loadBenefitFilters(): void {
    this.loading = true;
    this.fetchBenefitFilters().subscribe((filters) => {
      this.benefitFilters = filters.filter((val: any) => {
        val.editInputValue = "";
        return val;
      });
      this.loading = false;
    });
  }

  updatePreview(controlName: string): void {
    const value = this.benefitsForm.get(controlName)?.value;
    // Update the preview section based on the form value
    // For example:
    switch (controlName) {
      case "title":
        this.previewData.title = value;
        break;
      case "iconURL":
        this.previewData.iconURL = value;
        break;
      case "previewText":
        this.previewData.previewText = value;
        break;
      case "longDescription":
        this.previewData.longDescription = value;
        break;
      case "buttonText":
        this.previewData.buttonText = value;
        break;
    }
  }

  fetchBenefitFilters(): Observable<any[]> {
    return this.benefitsService.getBenefitFilters().pipe(
      map((response) => {
        // Process and sort filters
        const sortedFilters = response
          .map((filter) => ({
            ...filter,
            id: filter.filterId,
            isEditing: false,
            editValue: filter.filterValue,
          }))
          .sort((a, b) => {
            if (a.filterType === "DEFAULT" && b.filterType !== "DEFAULT") {
              return -1;
            }
            if (a.filterType !== "DEFAULT" && b.filterType === "DEFAULT") {
              return 1;
            }
            return 0;
          });

        return sortedFilters;
      })
    );
  }

  loadBenefit(id: number): void {
    this.benefitsService.getBenefitDetail(id).subscribe(
      (benefit) => {
        this.benefitsForm.patchValue(benefit);
        this.previewData = benefit; // update preview as well
      },
      (error) => {
        this.toastr.error(this.translate.instant(extractTsText("Benefit does not exist.")));
        this.router.navigate(["/benefits"]);
      }
    );
  }

  onKeyup(controlName: string): void {
    const control = this.benefitsForm.get(controlName);
    if (control.errors?.maxlength) {
      control.markAsTouched();
    }
    control.updateValueAndValidity();
  }

  onFilterChange(event: any): void {
    this.showNewFilterInput = false;
    const selectedFilterValue = event ? event.filterValue : null;
    this.benefitsForm.patchValue({
      filterValue: selectedFilterValue,
      // newFilterValueControl: "", // Clear new filter value control (optional)
    });
    this.updateFilterValidity();
  }

  onNewClick() {
    this.showNewFilterInput = true;
    this.filterSelect?.close();
    this.benefitsForm.patchValue({
      filterValue: null,
    });
    this.updateFilterValidity();
  }

  onFileUpload(event: any): void {
    const file = event.addedFiles[0];
    this.files = [file];
    this.uploadIcon(file);
  }

  onRemove(): void {
    this.disableFileExplorer = true;
    this.files = [];
    this.benefitsForm.get("iconURL").setValue(null);
    this.updatePreview("iconURL");

    // Delay enabling the file explorer to prevent immediate reopening
    setTimeout(() => {
      this.disableFileExplorer = false;
    }, 50);
  }

  uploadIcon(file: File): void {
    const formData = new FormData();
    var newFilename = file.name.replace(/\s/g, "");
    formData.append("image", file, newFilename);

    this.imageUploading = true;
    this.benefitsService.uploadBenefitIcon(formData).subscribe({
      next: (response) => {
        this.benefitsForm.get("iconURL").setValue(response.imageUrl);
        this.updatePreview("iconURL");
        this.imageUploading = false;
        this.toastr.success(this.translate.instant(extractTsText("Icon uploaded successfully.")));
      },
      error: (error) => {
        this.toastr.error(this.translate.instant(extractTsText("Unable to upload icon.")));
        this.imageUploading = false;
      },
    });
  }

  onSubmit(): void {
    if (this.benefitsForm.invalid) {
      return;
    }

    this.formSubmitting = true;
    if (this.showNewFilterInput) {
      // Add the new filter before submitting the form
      const newFilter = {
        filterValue: this.benefitsForm.get("newFilterValue").value,
        partnerId: this.currentUser?.partnerId,
      };

      this.benefitsService.addNewFilter(newFilter).subscribe({
        next: (response) => {
          // New filter added successfully

          // Assign the new filter value to the form control
          this.benefitsForm
            .get("filterValue")
            .setValue(this.benefitsForm.get("newFilterValue").value);

          // Submit the main form
          this.submitMainForm();
        },
        error: (error) => {
          this.formSubmitting = false;
          this.toastr.error(this.translate.instant(extractTsText("Unable to add new filter.")));
        },
      });
    } else {
      this.submitMainForm();
    }
  }

  submitMainForm(): void {
    if (this.benefitsForm.valid) {
      const benefitData = this.benefitsForm.value;

      if (this.isEditMode && this.benefitId !== null) {
        this.benefitsService
          .updateBenefit(this.benefitId, benefitData)
          .subscribe({
            next: (response) => {
              this.formSubmitting = false;
              this.toastr.success(this.translate.instant(extractTsText("Benefit updated!")));
              this.router.navigate(["/benefits"]);
            },
            error: (error) => {
              this.formSubmitting = false;
              this.toastr.error(this.translate.instant(extractTsText("Unable to update benefit.")));
            },
          });
      } else {
        this.benefitsService.addBenefit(benefitData).subscribe({
          next: (response) => {
            this.formSubmitting = false;
            this.toastr.success(this.translate.instant(extractTsText("Benefit added!")));
            this.router.navigate(["/benefits"]);
          },
          error: (error) => {
            this.formSubmitting = false;
            this.toastr.error(this.translate.instant(extractTsText("Unable to add benefit.")));
          },
        });
      }
    } else {
      this.validateFormFields(this.benefitsForm);
    }
  }
  deleteBenefit(benefit: any): void {
    if (this.isEditMode && this.benefitId !== null) {
      const removeBenefit = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-secondary",
        },
        buttonsStyling: false,
      });

      removeBenefit
        .fire({
          title: this.translate.instant(`Are you sure?`),
          text: this.translate.instant(extractTsText(`You want to delete this benefit?`)),
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: this.translate.instant("Delete"),
          cancelButtonText: this.translate.instant('Cancel')
        })
        .then((result) => {
          if (result.value) {
            this.benefitsService.deleteBenefit(this.benefitId).subscribe({
              next: (response) => {
                this.toastr.success(this.translate.instant(extractTsText("Benefit deleted!")));
                this.router.navigate(["/benefits"]);
              },
              error: (error) => {
                this.toastr.error(this.translate.instant(extractTsText("Unable to delete benefit.")));
              },
            });
          }
        });
    }
  }

  cancelBenefit(): void {
    this.router.navigate(["/benefits"]);
  }

  validateFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  editFilter(item: any, event: MouseEvent): void {
    event.stopPropagation(); // Prevent selection

    // timeout is used because the input field is not open at the begining so can give null value
    setTimeout(() => {
      let filterEditInput = document.getElementById(
        `filterItem${item.filterId}`
      );
      if (filterEditInput) {
        // focus on the input field when edit is clicked
        filterEditInput.focus();
      }
    }, 10);

    // onDropdownClose method makes the isEditing false of all items
    this.onDropdownClose();

    // make isEditing true of the item where needed
    item.isEditing = true;
    item.editInputValue = item.filterValue;
  }

  handleKeydown(event: KeyboardEvent, item: any): void {
    if (event.key === "Escape") {
      item.isEditing = false;
    }
  }

  saveFilter(filter: any, event: MouseEvent): void {
    event.stopPropagation(); // Prevent selection
    if (!filter.editInputValue) {
      return;
    }
    this.formSubmitting = true;

    let updatedFilter = { ...filter, filterValue: filter.editInputValue };

    this.benefitsService.saveFilter(filter.id, updatedFilter).subscribe({
      next: (response) => {
        this.formSubmitting = false;
        // Update the filter value in the benefitFilters array
        const index = this.benefitFilters.findIndex((f) => f.id === filter.id);
        if (index !== -1) {
          this.benefitFilters[index].filterValue = filter.editInputValue;
        }

        filter.isEditing = false; // Exit editing mode
        this.toastr.success(this.translate.instant(extractTsText("Filter updated successfully!"))); // Log the sent value
      },
      error: (error) => {
        this.formSubmitting = false;
        this.toastr.error(this.translate.instant(extractTsText("Unable to update filter.")));
      },
    });
  }

  deleteFilter(filter: any, event: MouseEvent): void {
    event.stopPropagation();
    const removeBenefit = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });

    removeBenefit
      .fire({
        title: this.translate.instant(`Are you sure?`),
        text: this.translate.instant(extractTsText(`Are you sure you want to delete this filter? This will untag all benefits attached to this filter.`)),
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: this.translate.instant("Delete"),
        cancelButtonText: this.translate.instant('Cancel')
      })
      .then((result) => {
        if (result.value) {
          this.benefitsService.deleteFilter(filter.id).subscribe({
            next: () => {
              this.benefitFilters = this.benefitFilters.filter(
                (f) => f.id !== filter.id
              );
              this.toastr.success(this.translate.instant(extractTsText("Filter deleted successfully!")));
            },
            error: (error) => {
              this.toastr.error(this.translate.instant(extractTsText("Unable to delete filter.")));
            },
          });
        }
      });
  }

  preventSelection(event: MouseEvent): void {
    event.stopImmediatePropagation(); // Prevent selection
  }

  onDropdownClose(): void {
    this.benefitFilters.forEach((filter) => (filter.isEditing = false));
  }

  openPreviewModal() {
    this.modalService.open(this.mobilePreviewModal);
  }
}
