import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EyecareService } from '../../hr/eyecare/eyecare-service/eyecare-service.service';
import { extractTsText } from 'src/app/core/_helpers/global-functions';

@Component({
  selector: 'app-specsavers-employee-form',
  templateUrl: './specsavers-employee-form.component.html',
  styleUrls: ['./specsavers-employee-form.component.scss']
})
export class SpecsaversEmployeeFormComponent implements OnInit {

  specsaverForm: FormGroup;
  submitting: boolean = false;
  buttonLabelTooltip: string = extractTsText('Usage of  a VDU for a prolonged period of time can damage your eyes');

  constructor(
    private formBuilder: FormBuilder,
    private eyecareService: EyecareService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    const savedData = this.eyecareService.employeeData;
    this.specsaverForm = this.formBuilder.group({
      email: [savedData?.email ? savedData?.email : '', [Validators.required, Validators.email]],
      surname: [savedData?.surname ? savedData?.surname : '', Validators.required],
      firstName: [savedData?.firstName ? savedData?.firstName : '', Validators.required],
      workWithVDU:[savedData?.workWithVDU ? savedData?.workWithVDU : '',Validators.required]
    });
    this.submitting = this.specsaverForm.get('workWithVDU').value;
  }



  onSubmit() {
    if(this.specsaverForm.invalid) {
      return;
    }

    this.submitting = true;
    const data = {

      email: this.specsaverForm.get('email').value,
      surname: this.specsaverForm.get('surname').value,
      firstName: this.specsaverForm.get('firstName').value,
      workWithVDU : this.specsaverForm.get('workWithVDU').value

    };
        this.eyecareService.employeeData = {
          email:data.email,
          surname:data.surname,
          firstName:data.firstName,
          workWithVDU:data.workWithVDU
        }
        this.router.navigate(['/eyecare-employee-form-summary']);



  }

  changeWorkWithVDU()
  {
    this.submitting = this.specsaverForm.get('workWithVDU').value;
  }


  }



