<!-- here login-wrapper class is added only if the displayBiometricsSetup is false
because login-wrapper takes 100% height and displays grey background.

And since the displayBiometricsSetup with true value shows the <app-face-id> parameter inside the login-wrapper div,
the app-face-id component takes the background of login as grey. This is not in the design-->
<div *ngIf="loginPage; else loading"  [ngClass]="{'login-wrapper' : !displayBiometricsSetup}">
  <div  *ngIf="emailConfirmed; else confirmEmail">
    <section class="login" *ngIf="!displayBiometricsSetup; else biometrics">
      <div class="signin-dialog">
        <form class="form" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()" *ngIf="!forceChangePassword">
          <div class="logo text-center mb-4">
            <img width="180px" alt="eachperson-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" />
          </div>
          <h5 class="text-center mb-3" *ngIf="!credentialsChanged">Sign in With Your <br>
          Email and Password</h5>
          <h5 class="text-center mb-3" *ngIf="credentialsChanged">Sign in</h5>
          <p class="credential-changed-class" *ngIf="credentialsChanged">Email/Password has changed, please
            login to resume Face/Touch ID.</p>
          <div *ngIf="errorMessage" class="alert alert-danger text-center">
            {{ errorMessage }}
          </div>
          <label for="signInFormUsername" class="label-customizable pb-0 mb-1">Email</label>
          <input id="signInFormUsername" name="text" type="text" class="form-control inputField-customizable"
                 placeholder="Email" formControlName="email"
                 [ngClass]="{ 'is-invalid' : !loginForm.get('email').valid && (loginForm.get('email').touched || loginForm.get('email').dirty)}"
                 title="Enter your email address">
          <small class="form-text text-danger"
                 *ngIf="!loginForm.get('email').valid && (loginForm.get('email').touched || loginForm.get('email').dirty)">Please
            enter your email.</small>

          <label for="signInFormPassword" class="label-customizable mt-3 mb-1">Password</label>
          <div class="customised-password">
            <input id="signInFormPassword" (ngModelChange)= "errorMessage = null" name="password" [type]=" showPassword ? 'text' : 'password' "
                   class="form-control inputField-customizable" placeholder="Password" formControlName="password"
                   [ngClass]="{ 'is-invalid' : !loginForm.get('password').valid && (loginForm.get('password').touched || loginForm.get('password').dirty) }" title="Enter your password">
            <i-feather [name]="!showPassword ? 'eye' : 'eye-off'" class="text-secondary icon-sm customise-icon"
                       (click)="changeKeyType()"></i-feather>

            <small class="form-text text-danger"
                   *ngIf="!loginForm.get('password').valid && (loginForm.get('password').touched || loginForm.get('password').dirty)">Please
              enter your password</small>
          </div>

          <input name="signInSubmitButton" type="submit" value="Sign in"
                 class="btn btn-primary submitButton-customizable" aria-label="submit"
                 [disabled]="!loginForm.valid || loginSubmitting">
<!--                          <input name="magicLinkButton" aria-label="signin" type="button" routerLink="/magic-link" value="Sign in without password"-->
<!--                              class="btn btn-secondary submitButton-customizable mt-2" aria-label="magic-link">-->
          <div class="my-3 text-center" aria-hidden="true">
            <a routerLink="/forgot-password" [queryParams]="{email: this.loginForm.get('email').value}" class="text-center">Forgot your password?</a>
          </div>

          <!-- <div class="sfs faq">
            <div class="sfs-binder faq">
              <div class="panel-for-tc faq">
                <ngb-accordion #myaccordion="ngbAccordion" activeIds="panelTwo" closeOthers="true"
                               type="'success'">
                  <ngb-panel id="custom-panel-1">
                      <ng-template ngbPanelHeader let-opened="opened">
                          <div
                              class="d-flex align-items-center justify-content-between customised-panel-border">
                              <button ngbPanelToggle class="btn faq p-0 btn-w-full">
                                  <p class="font-weight-bold m-0">How Do I Sign in Without a Password?
                                  </p>
                              </button>
                              <button ngbPanelToggle class="btn p-0">
                                  <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                                  <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                              </button>

                          </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <p class="m-0 font-light">No password? No problem. Simply click on the ‘Sign-in
                              without a password’ link located on the login page. Then, enter the email
                              address associated with your account.<br>
                              After this, you will receive an email containing a log-in link to help you
                              access your account. Please be aware that this link is only valid for 4
                              hours.</p>

                      </ng-template>
                  </ngb-panel>

                  <ngb-panel id="custom-panel-2">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div
                        class="d-flex align-items-center justify-content-between customised-panel-border">
                        <button ngbPanelToggle class="btn p-0 btn-w-full">
                          <p class="font-weight-bold m-0">What Do I Do if I Have Forgotten My Password?
                          </p>
                        </button>
                        <button ngbPanelToggle class="btn p-0">
                          <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                          <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                        </button>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="m-0 font-light">
                        We all lose track of our passwords now and again, so not to worry, we’re
                        happy to help. Go to the login page and click on the forgotten password
                        link. Then enter the email address associated with your account. Keep the
                        forgotten password window open and open a new tab to access your emails. You
                        will receive an email containing a unique code. Enter this unique code on
                        the login page and follow the steps to create a new password.
                      </p>
                    </ng-template>
                  </ngb-panel>

                  <ngb-panel id="custom-panel-3">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div
                        class="d-flex align-items-center justify-content-between customised-panel-border">
                        <button ngbPanelToggle class="btn p-0 btn-w-full">
                          <p class="font-weight-bold m-0">Why Is My Password Not Being Accepted?
                          </p>
                        </button>
                        <button ngbPanelToggle class="btn p-0">
                          <img class="plus-icon" alt ="plus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/plus-icon.svg" />
                          <img class="minus-icon" alt ="minus-icon" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/sfs-assets/minus-icon.svg" />
                        </button>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="m-0 font-light">
                        If you’re having trouble logging into your account using your password, we
                        can help you with some of our useful tips. Please be aware that the login
                        process is case-sensitive. So make sure you are entering your password using
                        any necessary CAPITAL letters.<br>
                        If you are still having trouble, try resetting your password. You can do
                        this by using the forgotten password route.
                        Need additional help? No problem. Please contact us: support@eachperson.com.
                      </p>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
          </div> -->

          <!-- FAQ end -->
          <div class="logo text-center mt-1" aria-hidden="true">
            <img width="80px" alt="eachperson-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/logo.png" class="logo-customise" /> @ Each Person
            {{ currentYear }}
          </div>
          <div class="logo text-center" aria-hidden="true">
            Powered by <img width="38px" alt="epoints-logo" src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/images/epoints.png" />
          </div>
        </form>

        <!-- if the user is newly created and the login response has data that tells the user has to set new password, then
        make forceChangePassword=true and enable force-change-password view -->
        <app-force-change-password *ngIf="forceChangePassword" [errorMessage]="errorMessage" [loginSubmitting]="loginSubmitting" (firstTimePassword)="loginFormSubmit($event)" [currentYear]="currentYear"></app-force-change-password>
      </div>
    </section>
  </div>
</div>

<ng-template #loading>
    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh;">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
      <p>Redirecting ...</p>
    </div>
</ng-template>

<ng-template #confirmEmail>
  <app-check-sso-signin (emailConfirmed)="onEmailConfirmed($event)"
  (biometricLogin)="onBiometricLogin($event)"
  [errorMessage]="errorMessage"
  [invalidLoginCreds]="invalidLoginCreds"
  (credentialsChanged)="onCredentialsChanged($event)" [redirect_uri]='redirect_uri' [response_type]="response_type" [state]="state" [currentYear]="currentYear"></app-check-sso-signin>
</ng-template>

<ng-template #biometrics>
  <app-face-id></app-face-id>
</ng-template>

